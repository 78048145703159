/* p-top-mainimg
---------------------------------------------------------- */
.p-top-mainimg {

  @include mq-down(md) {
    margin-left: -15px;
    margin-right: -15px;
    padding-top: 65px;
  }

  > .inner {
    position: relative;
  }

  .box-detail {
    position: absolute;
    left: 24px;

    @include mq-up(md) {
      bottom: 46px;
    }

    @include mq-down(md) {
      top: 50%;
      left: 18px;
      transform: translateY(-50%);
      z-index: 2;
    }

    > .title {
      font-size: 5.2rem;
      font-weight: 700;
      line-height: 1.45;
      color: $color-gray-01;
      margin-top: 14px;

      @include mq-up(md) {
        text-shadow: 0px -1px 20px rgba(255, 255, 255, 1);
      }

      @include mq-down(md) {
        font-size: 3.1rem;
        line-height: 1;
        margin-top: 10px;
      }

      @include mq-down(360) {
        letter-spacing: -.1rem;
      }

      span {
        @include mq-down(md) {
          display: inline-block;
          padding-top: 3px;
          background-color: rgba(255, 255, 255, .7);

          &:not(:first-child) {
            margin-top: 10px;
          }
        }
      }
    }

    > .text-desc {
      font-size: 1.6rem;
      font-weight: 600;

      @include mq-down(md) {
        display: inline-block;
        font-size: 1rem;
        padding: 0 2px;
        line-height: 1.7;
        background-color: rgba(255, 255, 255, .7);
      }
    }

    > .c-btn {
      margin-top: 102px;
    }
  }

  .box-video {
    display: flex;
    justify-content: flex-end;
    overflow: hidden;

    @include mq-up(md) {
      margin-right: -100px;
    }
  }

  .video {
    overflow: hidden;

    @include mq-up(md) {
      width: 836px;
      height: 610px;
    }

    @include mq-down(md) {
      display: block;
      position: relative;
      height: 440px;
      width: calc((100vw * 331) / 375);
      overflow: hidden;
      margin-right: 0;
      border-bottom-left-radius: calc-vw375(46);
      -webkit-border-bottom-left-radius: 46px;
      -webkit-transform: translateZ(0);
    }

    video {
      @include mq-up(md) {
        height: 100%;
        //height: 720px;
        border-bottom-left-radius: 95px;
      }

      @include mq-down(md) {
        position: absolute;
        height: 440px;
        width: auto;
        display: block;
        top: 0;
        right: 50%;
        transform: translateX(50%);
      }
    }
  }

  .c-btn {
    @include mq-down(md) {
      margin-top: 24px;
    }
  }
}

/* p-top-service
---------------------------------------------------------- */
.p-top-service {
  padding-top: 120px;

  @include mq-up(md) {
    display: flex;
  }

  @include mq-down(md) {
    padding-top: 53px;
  }

  .box-detail {
    @include mq-up(md) {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 50%;
      padding-left: 140px;
      padding-right: 80px;
    }

    @include mq-down(md) {
      margin-top: 48px;
    }

    > .text-desc {
      font-size: 2rem;
      font-weight: 600;
      color: $color-gray-01;
      line-height: 1.7;
      letter-spacing: .11rem;

      @include mq-down(md) {
        font-size: 1.4rem;
        line-height: 2;
      }
    }

    > .text {
      margin-top: 13px;
      line-height: 1.9;
      color: $color-gray-11;

      @include mq-down(md) {
        line-height: 2;
        margin-top: 7px;
      }
    }

    .c-btn {
      margin-top: 25px;

      @include mq-down(md) {
        margin-top: 17px;
      }
    }
  }

  .image {
    @include mq-up(md) {
      width: calc(50% + 100px);
      margin-left: -100px;
    }

    @include mq-down(md) {
      margin-left: -15px;
      padding-right: 5px;
    }
  }
}

/* p-top-voice
---------------------------------------------------------- */
.p-top-voice {
  padding-top: 218px;

  @include mq-down(md) {
    padding-top: 70px;
    padding-bottom: 0;
  }

  .c-bg-full::before {
    height: calc(100% - 96px);
  }

  .c-list-voice-wrap {
    @include mq-down(md) {
      padding-bottom: 51px;
    }
  }

  .c-list-voice {
    @include mq-up(md) {
      margin-bottom: 63px;
    }
  }

  .c-btn-center {
    @include mq-up(md) {
      position: relative;
      bottom: -28px;
    }

    @include mq-down(md) {
      margin-top: 38px;
    }
  }
}

/* p-top-feature
---------------------------------------------------------- */
.p-top-feature {
  padding-top: 233px;
  padding-bottom: 26px;

  @include mq-down(md) {
    padding-top: 50px;
    padding-bottom: 0;
  }

  .box-feature {
    @include mq-up(md) {
      display: flex;
      margin-bottom: -10px;
    }

    @include mq-down(md) {
      padding: 0 10px;
    }
  }

  .list-feature {
    @include mq-up(md) {
      display: flex;
      flex: 1 0 auto;
      width: 780px;
      margin-left: -15px;
      margin-right: -15px;
    }

    > .item {
      @include mq-up(md) {
        width: 33.33%;
        padding: 0 15px;
      }

      @include mq-down(md) {
        margin-top: 2px;
      }
    }

    .link {
      display: block;
      @include is-opacity;
    }
  }

  .card-feature {
    @include mq-down(md) {
      position: relative;
      text-align: center;
      background-color: $color-green-01;
      border-radius: $border-radius-pc;
      padding: 33px 5px 26px;
    }

    /*&::after {
      @include mq-down(md) {
        position: absolute;
        content: '';
        top: 50%;
        right: -10px;
        transform: translateY(-50%);
        background-image: url(/assets/images/common/ico_arrow_black.png);
        background-repeat: no-repeat;
        width: 22px;
        height: 22px;
        background-size: 22px 22px;
      }
    }*/

    img {
      @include mq-down(md) {
        height: 66px;
      }
    }
  }

  .image {
    width: 100%;

    @include mq-up(md) {
      margin-right: -60px;
    }

    @include mq-down(md) {
      position: relative;
      width: 98%;
      margin-top: 21px;
      margin-left: 7px;
    }

    img {
      @include mq-up(md) {
        position: relative;
        left: 14px;
        top: -50px;
      }
    }
  }

  .c-btn-center {
    @include mq-down(md) {
      margin-top: 22px;
    }
  }
}

/* p-top-achievement
---------------------------------------------------------- */
.p-top-achievement {
  padding-top: 147px;

  @include mq-down(md) {
    padding-top: 88px;
  }

  .box-slider {
    overflow: hidden;
  }

  .image {
    img {
      width: 100%;
    }
  }

  .list-slider {
    .item {
      position: relative;
    }

    .l-inner {
      @include mq-down(md) {
        display: none;
      }
    }

    .box-caption {
      position: relative;
      display: block;

      .caption {
        position: absolute;
        left: 0;
        bottom: 20px;
        font-weight: 700;
        color: $white;
        background-color: rgba(0, 0, 0, .4);
        border-radius: $border-radius-pc;
        padding: 29px 39px 26px 34px;

        > .text {
          letter-spacing: .1rem;
        }

        > .title {
          font-size: 2rem;
          letter-spacing: .2rem;
          margin-top: 8px;
        }
      }
    }
  }

  .c-btn-center {
    margin-top: 10px;

    @include mq-down(md) {
      margin-top: 15px;
    }
  }
}

/* p-top-seminar
---------------------------------------------------------- */
.p-top-seminar {
  padding-top: 119px;

  @include mq-down(md) {
    padding-top: 75px;
  }

  .c-bg-left::before {
    height: calc(100% - 52px);

    @include mq-down(md) {
      height: calc(100% - 28px);
    }
  }

  .c-btn-center {
    @include mq-up(md) {
      margin-top: 42px;
    }

    @include mq-down(md) {
      margin-top: 25px;
    }
  }
}

/* p-top-know
---------------------------------------------------------- */
.p-top-know {
  padding-top: 162px;

  @include mq-down(md) {
    padding-top: 92px;
  }

  .c-btn-center {
    @include mq-up(md) {
      margin-top: 42px;
    }

    @include mq-down(md) {
      margin-top: 28px;
    }
  }
}

/* p-top-tour
---------------------------------------------------------- */
.p-top-tour {
  padding-top: 148px;

  @include mq-down(md) {
    padding-top: 97px;
  }

  .box-tour {
    @include mq-up(md) {
      display: flex;
    }

    > .image {
      @include mq-up(md) {
        position: relative;
        z-index: 1;
        width: 800px;
        flex: 1 0 auto;
      }

      @include mq-down(md) {
        margin-left: -15px;
        margin-right: -15px;
      }
    }

    > .detail {
      background-color: $color-lightgreen-01;
      border-radius: 15px;
      margin-top: 60px;
      padding: 61px 10px 52px 127px;

      @include mq-down(md) {
        position: relative;
        z-index: 1;
        margin-top: -23px;
        padding: 30px 25px 20px 25px;
      }

      @include mq-up(md) {
        width: 100%;
        margin-left: -100px;
        padding-left: 127px;
        border-radius: 30px;
      }

      > .c-heading-01 {
        @include mq-up(md) {
          font-size: 3rem;
          margin-bottom: 21px;
        }

        @include mq-down(md) {
          margin-bottom: 11px;
        }
      }

      > .text {
        font-size: 1.5rem;
        line-height: 1.8;
        color: $color-gray-11;

        @include mq-down(md) {
          font-size: 1.2rem;
          line-height: 1.6;
        }
      }

      > .c-btn {
        margin-top: 15px;
        padding: 16px 12px 14px 19px;

        @include mq-down(md) {
          margin-top: 12px;
          padding: 13px 12px 11px 19px;
        }
      }
    }
  }
}
