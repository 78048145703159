/* p-customers
---------------------------------------------------------- */

.p-section-customers {
  margin-top: 82px;

  @include mq-down(md) {
    margin-top: 58px;
  }
}

.p-list-customers {
  padding-bottom: 65px;
  border-bottom: 1px dotted #b2b2b2;

  @include mq-down(md) {
    padding-bottom: 0;
    border-bottom: none;
  }

  > .item {

    &:not(:first-child) {
      margin-top: 65px;
      padding-top: 65px;
      border-top: 1px dotted #b2b2b2;

      @include mq-down(md) {
        border-top: none;
        padding-top: 0;
        margin-top: 58px;
      }
    }

    > .link {
      display: flex;
      width: 100%;
      @include is-img;

      @include mq-down(md) {
        display: block;
      }

      .image {
        position: relative;
        width: 370px;
        flex: 0 0 auto;
        margin-right: 42px;
        border-radius: 2px;
        overflow: hidden;
        clip-path: content-box;

        @include mq-up(md) {
          height: 250px;
        }

        > * {
          @include mq-up(md) {
            height: 250px;
            border-radius: 2px;
          }
        }

        @include mq-down(md) {
          width: 100%;
          margin-right: 0;
        }

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          right: 0;
          width: 40px;
          height: 40px;
          background-image: url(/assets/images/common/ico_arrow_right_01.png);
          background-size: 7px 12px;
          background-repeat: no-repeat;
          background-color: $color-green-01;
          background-position: center;
        }

        img {
          width: 100%;
        }
      }

      .details {
        margin-top: 7px;
        width: 100%;

        @include mq-down(md) {
          margin-top: 20px;
        }

        .customer-name {
          font-size: 1.6rem;
          font-weight: bold;

          @include mq-down(md) {
            font-size: 1.4rem;
          }
        }

        > .title {
          margin-top: 10px;
          font-size: 2.6rem;
          font-weight: bold;

          @include mq-down(md) {
            font-size: 1.6rem;
          }
        }

        .sub-title {
          margin-top: 5px;
          font-size: 1.6rem;

          @include mq-down(md) {
            margin-top: 0;
            font-size: 1.4rem;
            line-height: 2;
            font-weight: normal;
          }
        }

        .list {
          margin-top: 28px;

          @include mq-down(md) {
            margin-top: 14px;
          }

          .item {
            display: flex;
            align-items: center;
            margin-top: 5px;

            .title {
              margin-right: 15px;
              padding: 2px 0;
              flex: 0 0 auto;
              width: 120px;
              background-color: #979797;
              text-align: center;
              color: $white;
              border-radius: 3px;
              font-size: 1.2rem;

              @include mq-down(md) {
                margin-right: 8px;
                width: 85px;
              }
            }

            .text {
              font-size: 1.3rem;

              @include mq-down(md) {
                font-size: 1.2rem;
              }
            }
          }
        }
      }
    }
  }
}
