/* c-box-contact
---------------------------------------------------------- */
.c-box-contact {
  @include mq-up(md) {
    display: flex;
    background: url(/assets/images/common/bg_footer_pc.png) no-repeat;
    background-size: cover;
    padding: 68px 0 48px;
  }

  @include mq-down(md) {
    background: url(/assets/images/common/bg_footer_sp.png) no-repeat;
    background-size: cover;
    padding: 43px 24px 33px;
    border-radius: 15px;
  }

  > .col {
    text-align: center;

    @include mq-up(md) {
      width: 50%;
      padding: 22px 0;
    }

    &:first-child {
      @include mq-up(md) {
        border-right: 1px solid #b0aeab;
      }
    }
  }

  .title {
    font-size: 2rem;
    color: $white;

    @include mq-down(md) {
      font-size: 1.6rem;
    }
  }

  .box-info {
    margin-right: 23px;

    @include mq-down(md) {
      margin-top: 42px;
      margin-right: 0;
    }

    > .tel {
      display: inline-block;
      font-size: 4rem;
      letter-spacing: .1rem;
      line-height: 1.25;
      color: $white;
      margin-top: 20px;

      @include mq-up(md) {
        pointer-events: none;
      }

      @include mq-down(md) {
        font-size: 3rem;
        margin-top: 5px;
      }
    }

    > .time {
      font-size: 1.6rem;
      color: $white;

      @include mq-down(md) {
        font-size: 1.2rem;
      }
    }
  }

  .c-list-button {
    width: 305px;
    margin: 17px auto 0;
    padding-left: 5px;

    @include mq-down(md) {
      width: 100%;
      padding-left: 0;
      margin-top: 14px;
    }

    .c-btn {
      span {
        transition: .25s;
      }

      &:hover {
        @include mq-up(md) {
          span {
            color: $white;
          }
        }
      }
    }
  }
}

/* c-box-branch
---------------------------------------------------------- */
.c-box-branch {
  padding: 105px 0 57px;

  @include mq-down(md) {
    padding: 45px 0 29px;
  }

  .c-heading-line {
    font-weight: 600;
  }

  .c-list-branch {
    @include mq-up(md) {
      display: flex;
      flex-wrap: wrap;
    }

    > .item {
      margin-top: 45px;


      @include mq-up(md) {
        width: 33.33%;
      }

      @include mq-down(md) {
        margin-top: 17px;
      }

      &:not(:first-child) {
        @include mq-down(md) {
          margin-top: 15px;
        }
      }
    }
  }

  .detail {
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.75;
    color: $color-gray-04;
    margin-top: 4px;

    @include mq-down(md) {
      font-size: 1.2rem;
      margin-top: 0;
    }

    a {
      color: $color-gray-04;
    }
  }
}



/* c-footer
---------------------------------------------------------- */
.c-footer {
  position: relative;
  width: 100%;
  background-color: $white;
  @include minWidth1200;

  &-in {
    @include width1200;
  }

  .pagetop {
    opacity: 0;
    position: fixed;
    bottom: 33px;
    right: 40px;
    z-index: 5;
    backface-visibility: hidden;
    visibility: hidden;
    transition: .3s ease-out;
    background-color: #939393;
    border-radius: 50px;
    overflow: hidden;

    @include mq-down(md) {
      bottom: 20px;
      right: 20px;
    }

    &.is-active {
      display: block;
      visibility: visible;
      opacity: 1;
    }

    &.stop {
      top: -40px;
      right: 40px;
      bottom: auto;
      position: absolute;
      margin: 0;

      @include mq-down(md) {
        top: -77px;
        right: 20px;
      }
    }

    .link {
      position: relative;
      display: block;
      width: 80px;
      height: 80px;
      text-align: center;
      @include is-opacity();

      @include mq-down(md) {
        width: 40px;
        height: 40px;
      }

      &::before {
        content: '';
        position: absolute;
        display: block;
        content: '';
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 32px;
        height: 16px;
        background-image: url(/assets/images/common/ico_pagetop.png);
        background-repeat: no-repeat;
        background-size: 32px 16px;

        @include mq-down(md) {
          width: 16px;
          height: 8px;
          top: 47%;
          background-size: 16px 8px;
        }
      }
    }
  }

  .footer-content {
    @include mq-up(md) {
      padding: 48px 0 17px;
      background-color: $color-gray-02;
    }

    .c-footer-in {
      @include mq-up(md) {
        display: flex;

        > * {
          width: 50%;
        }
      }

      @include mq-down(md) {
        padding: 0;
      }
    }
  }

  .footer-detail {
    color: $white;

    @include mq-down(md) {
      padding: 34px 15px 28px;
      background-color: $color-gray-02;
    }

    > .logo {
      width: 245px;
      flex: 1 0 auto;
      margin-top: 5px;

      @include mq-down(md) {
        width: 184px;
        margin-top: 2px;
      }

      > a {
        display: block;
      }

      .text-logo {
        font-size: 1.5rem;
        letter-spacing: .1rem;
        color: $white;
        margin-top: 9px;

        @include mq-down(md) {
          font-size: 1.1rem;
          margin-top: 0;
        }
      }
    }

    > .detail {
      margin-top: 20px;

      @include mq-down(md) {
        margin-top: 19px;
      }

      .text {
        font-size: 1.4rem;
        line-height: 1.75;
        letter-spacing: .1rem;
        margin-bottom: 20px;
        opacity: .6;

        @include mq-down(md) {
          font-size: 1.2rem;
          line-height: 1.6;
          margin-bottom: 0;
        }
      }

      a {
        color: $white;
      }
    }
  }

  .footer-nav {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;

    @include mq-down(md) {
      background-color: $color-green-01;
      margin: 0;
    }

    .list-link {
      width: 50%;
      padding: 0 10px;

      @include mq-down(md) {
        padding: 0;
      }

      &:nth-child(odd){
        @include mq-down(md) {
          border-left: 1px solid rgba(255, 255, 255, .5);

          .link-footer {
            padding: 14px 15px 15px 20px;
          }
        }
      }

      &:nth-child(even){
        @include mq-down(md) {
          border-left: 1px solid rgba(255, 255, 255, .5);

          .item:last-child {
            border-bottom: none;
          }

          .link-footer {
            padding: 14px 15px 15px;
          }
        }
      }

      &.-full {
        width: 100%;
        margin-bottom: -17px;

        @include mq-down(md) {
          margin-bottom: 0;

          .link-footer {
            padding: 14px 15px 15px;
          }
        }
      }

      > .item {
        width: 100%;
        margin-top: 3px;

        @include mq-down(md) {
          margin-top: 0;
          border-bottom: 1px solid rgba(255, 255, 255, .5);
        }
      }
    }

    .link-footer {
      @include mq-down(md) {
        display: block;
        padding-right: 0;
      }

      > span {
        @include mq-down(md) {
          font-size: 1.2rem;
          letter-spacing: 0;
        }
      }
    }
  }

  .link-footer {
    display: inline-block;
    padding-right: 17px;
    // @include is-opacity;

    span {
      font-size: 1.2rem;
      font-weight: 500;
      letter-spacing: .2rem;
      color: $white;

      @include mq-down(md) {
        font-size: 1.4rem;
      }

      span:hover{
        text-decoration: underline;
      }
    }

    &[target="_blank"] {
      &::after {
        content: '';
        position: relative;
        display: inline-block;
        content: '';
        top: 3px;
        width: 13px;
        height: 16px;
        background-image: url(/assets/images/common/ico_note_white.png);
        background-repeat: no-repeat;
        background-size: 13px 16px;
        margin-left: 8px;

        @include mq-up(md) {
          background-image: url(/assets/images/common/ico_note_white_pc.png);
        }

        @include mq-down(md) {
          width: 10px;
          height: 12px;
          background-size: 10px 12px;
        }
      }
    }
  }

  .footer-copy {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: $color-gray-03;
    padding: 42px 0 62px;

    @include mq-down(md) {
      flex-direction: column;
      background-color: $white;
      padding: 40px 0 12px;
    }

    .c-footer-in {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
    }

    .logo {
      width: 174px;
      line-height: 1;

      @include mq-down(md) {
        width: 166px;
      }

      img {
        width: 100%;
      }
    }

    .list-link {
      display: flex;
      margin-left: 30px;

      @include mq-down(md) {
        margin-top: 19px;
        margin-left: 0;
      }

      > .item {
        position: relative;
        line-height: 1;

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 2px;
          left: 0;
          width: 1px;
          height: calc(100% - 6px);
          background-color: #cfcfcf;

          @include mq-down(md) {
            height: calc(100% - 8px);
          }
        }

        @include mq-down(md) {
          line-height: 1.3;
        }

        .link-footer {
          > span {
            @include is-underline;

            @include mq-down(md) {
              color: $color-gray-05;
            }
          }
        }

        &:last-child {
          position: relative;

          @include mq-down(md) {
            &::after {
              content: '';
              display: block;
              position: absolute;
              top: 2px;
              right: 0;
              width: 1px;
              height: calc(100% - 8px);
              background-color: #cfcfcf;
            }
          }
        }

        .link-footer {
          color: $color-gray-03;
          background: none;
          padding-left: 21px;
          padding-right: 21px;

          @include mq-down(md) {
            padding-right: 8px;
            padding-left: 9px;
            position: relative;
            top: -2px;
          }

          span {
            @include mq-down(md) {
              font-size: 1.2rem;
              letter-spacing: 0;
            }
          }

          &[target="_blank"] {
            &::after {
              display: none;
            }
          }

        }
      }
    }

    .copy {
      width: 100%;
      font-size: 1rem;
      font-weight: 500;
      color: $white;
      opacity: .6;
      letter-spacing: .15rem;
      margin-top: 16px;

      @include mq-down(md) {
        font-size: 1.0rem;
        letter-spacing: 0;
        color: #949494;
        margin-top: 7px;
      }
    }
  }
}
