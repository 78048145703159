/* c-tag
---------------------------------------------------------- */
.c-tag {
  min-width: 120px;
  font-size: 1.2rem;
  text-align: center;
  color: $white;
  background-color: $black;
  border-radius: 3px;
  padding: 4px 13px 2px;

  @include mq-down(md) {
    font-size: 1.2rem;
    min-width: 85px;
    padding: 4px 13px 4px;
  }
}