.c-text-underline {
  text-align: center;


  @include mq-down(md) {
    text-align: left;
  }

  span {
    font-size: 2rem;
    font-weight: 700;
    line-height: 3;
    border-bottom: 1px solid $black;
    padding-bottom: 2px;

    @include mq-down(md) {
      font-size: 1.4rem;
      line-height: 2.5;
    }
  }
}

.c-text-black {
  color: $black !important;
  text-decoration: underline;
  @include is-opacity;
}
