/* p-case-detail
--------------------------------------------------------- */
.p-case-detail {
  .mainimg {
    img {
      width: 100%;
    }

    & + * {
      margin-top: 90px;

      @include mq-down(md) {
        margin-top: 50px;
      }
    }
  }

  .customer-name {
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: .15rem;
    margin-bottom: 9px;

    @include mq-down(md) {
      font-size: 1.6rem;
      letter-spacing: 0;
      margin-bottom: 2px;
    }
  }

  .title {
    font-size: 2.8rem;
    font-weight: 700;
    color: $color-black-01;
    letter-spacing: .2rem;

    @include mq-down(md) {
      font-size: 1.6rem;
      letter-spacing: 0;
    }
  }

  .text-desc {
    line-height: 1.9;
    color: $color-black-01;
    margin-top: 46px;

    a{
      color: $color-blue-01;
      @include is-opacity;
      @include is-underline;    
    }

    @include mq-down(md) {
      line-height: 2;
      letter-spacing: -.02rem;
      margin-top: 30px;
    }
  }

  .box-gallery {
    &-wrap {
      width: 800px;
      margin: 56px auto 0;

      @include mq-down(md) {
        width: 100%;
        margin-top: 37px;
      }
    }

    .image img{
      overflow: hidden;
      border-radius: 8px;
      clip-path: content-box;
      
      @include mq-down(md) {
        border-radius: 4px;
      }
    }

    .caption {
      font-size: 1.4rem;
      margin-top: 10px;

      @include mq-down(md) {
        font-size: 1.2rem;
        margin-top: 5px;
      }
    }

    + .box-gallery {
      margin-top: 61px;

      @include mq-down(md) {
        margin-top: 31px;
      }
    }
  }
}

.p-case-relate {
  padding-top: 115px;
  padding-bottom: 10px;

  @include mq-down(md) {
    padding-top: 75px;
    padding-bottom: 26px;
  }

  .p-heading-case-01 {
    @include mq-down(md) {
      margin-bottom: 30px;
    }
  }
}

