@charset "UTF-8";
/* Font
---------------------------------------------------------- */
/* Breakpoint
---------------------------------------------------------- */
/* Color
---------------------------------------------------------- */
/* line-height
---------------------------------------------------------- */
/* border-radius
---------------------------------------------------------- */
/* calc-vw1800
---------------------------------------------------------- */
/* calc-vw1280
---------------------------------------------------------- */
/* calc-vw1280
---------------------------------------------------------- */
/* calc-vw375
---------------------------------------------------------- */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes progress {
  0% {
    transform: translateX(-100%);
  }
  21% {
    transform: translateX(0%);
  }
  27% {
    transform: translateX(0%);
  }
  48% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}

/* ==========================================================
 fonts
========================================================== */
@font-face {
  font-family: "DINAlternateBold";
  font-style: normal;
  font-weight: 700;
  src: local("Noto Sans CJK JP Light"), url("/assets/webfonts/DINAlternate-Bold.woff") format("woff"), url("/assets/webfonts/DINAlternate-Bold.ttf") format("truetype");
}

/* ==========================================================
 vendor
========================================================== */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track::before,
.slick-track::after {
  display: table;
  content: '';
}

.slick-track::after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0;
  margin-top: 30px;
  margin-left: -25px;
  width: 100%;
}

.slick-dots li {
  position: relative;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  display: block;
  height: 5px;
  width: 5px;
  outline: none;
  color: transparent;
  margin: 0 7px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button::before {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  margin: 0 7px;
  width: 5px;
  height: 5px;
  background-color: #E2E6F2;
  border-radius: 100%;
}

.slick-dots li.slick-active button {
  width: 10px;
  height: 10px;
}

.slick-dots li.slick-active button::before {
  width: 10px;
  height: 10px;
  background-color: #9FA8AF;
}

/* ==========================================================
 mixin
========================================================== */
/* up
---------------------------------------------------------- */
/* down
---------------------------------------------------------- */
/* ==========================================================
 base
========================================================== */
/* ==========================================================
 layout
========================================================== */
html {
  font-size: 62.5%;
}

html.is-locked {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  overflow-y: scroll;
}

body {
  background-color: #fff;
  font-family: "NotoSansCJKjp", sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  color: #000;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
}

@media (max-width: 896px) {
  body {
    font-size: 1.4rem;
  }
}

/* contents
---------------------------------------------------------- */
.l-contents {
  position: relative;
  margin-bottom: 143px;
}

@media (min-width: 897px) {
  .l-contents {
    min-width: 1400px;
  }
}

@media (max-width: 896px) {
  .l-contents {
    margin-bottom: 80px;
  }
}

.l-contents.-lower-page {
  padding-top: 120px;
  margin-bottom: 75px;
}

@media (max-width: 896px) {
  .l-contents.-lower-page {
    padding-top: 58px;
    margin-bottom: 50px;
  }
}

/* container
---------------------------------------------------------- */
@media (min-width: 897px) {
  .l-container {
    min-width: 1400px;
  }
}

.l-inner {
  margin: 0 auto;
  padding-left: 100px;
  padding-right: 100px;
}

@media (min-width: 897px) {
  .l-inner {
    width: 1400px;
    min-width: 1400px;
  }
}

@media (max-width: 896px) {
  .l-inner {
    padding: 0 15px;
  }
}

@media (max-width: 896px) {
  .l-inner {
    padding-top: 0;
    padding-bottom: 0;
  }
}

#top .l-container {
  padding: 0;
}

/* container-column
---------------------------------------------------------- */
.l-container-column {
  display: flex;
}

@media (max-width: 896px) {
  .l-container-column {
    flex-wrap: wrap;
  }
}

@media (max-width: 896px) {
  .l-container-column.-detail > .l-container-left {
    order: 1;
  }
}

@media (max-width: 896px) {
  .l-container-column.-detail > .l-container-right {
    order: 3;
  }
}

@media (max-width: 896px) {
  .l-container-column.-detail > .p-journal-relate {
    order: 2;
  }
}

@media (min-width: 897px) {
  .l-container-column > .l-container-left {
    flex: 1 0 auto;
    width: 860px;
    padding-right: 75px;
  }
}

@media (max-width: 896px) {
  .l-container-column > .l-container-left {
    width: 100%;
    padding-right: 0;
  }
}

.l-container-column > .l-container-right {
  width: 100%;
}

.c-line-top {
  border-top: 1px dotted #979797;
}

/* l-grid-row
---------------------------------------------------------- */
.l-grid-row {
  display: flex;
  flex-wrap: wrap;
}

.l-grid-row.-gutter_5 {
  margin-top: -10px !important;
  margin-left: -5px !important;
  margin-right: -5px !important;
}

.l-grid-row.-gutter_5 > [class^="col"] {
  margin-top: 10px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.l-grid-row.-gutter_x_5 {
  margin-left: -5px !important;
  margin-right: -5px !important;
}

.l-grid-row.-gutter_x_5 > [class^="col"] {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.l-grid-row.-gutter_y_5 {
  margin-top: -10px !important;
}

.l-grid-row.-gutter_y_5 > [class^="col"] {
  margin-top: 10px !important;
}

.l-grid-row.-gutter_10 {
  margin-top: -20px !important;
  margin-left: -10px !important;
  margin-right: -10px !important;
}

.l-grid-row.-gutter_10 > [class^="col"] {
  margin-top: 20px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.l-grid-row.-gutter_x_10 {
  margin-left: -10px !important;
  margin-right: -10px !important;
}

.l-grid-row.-gutter_x_10 > [class^="col"] {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.l-grid-row.-gutter_y_10 {
  margin-top: -20px !important;
}

.l-grid-row.-gutter_y_10 > [class^="col"] {
  margin-top: 20px !important;
}

.l-grid-row.-gutter_15 {
  margin-top: -30px !important;
  margin-left: -15px !important;
  margin-right: -15px !important;
}

.l-grid-row.-gutter_15 > [class^="col"] {
  margin-top: 30px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.l-grid-row.-gutter_x_15 {
  margin-left: -15px !important;
  margin-right: -15px !important;
}

.l-grid-row.-gutter_x_15 > [class^="col"] {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.l-grid-row.-gutter_y_15 {
  margin-top: -30px !important;
}

.l-grid-row.-gutter_y_15 > [class^="col"] {
  margin-top: 30px !important;
}

.l-grid-row.-gutter_20 {
  margin-top: -40px !important;
  margin-left: -20px !important;
  margin-right: -20px !important;
}

.l-grid-row.-gutter_20 > [class^="col"] {
  margin-top: 40px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.l-grid-row.-gutter_x_20 {
  margin-left: -20px !important;
  margin-right: -20px !important;
}

.l-grid-row.-gutter_x_20 > [class^="col"] {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.l-grid-row.-gutter_y_20 {
  margin-top: -40px !important;
}

.l-grid-row.-gutter_y_20 > [class^="col"] {
  margin-top: 40px !important;
}

.l-grid-row.-gutter_25 {
  margin-top: -50px !important;
  margin-left: -25px !important;
  margin-right: -25px !important;
}

.l-grid-row.-gutter_25 > [class^="col"] {
  margin-top: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.l-grid-row.-gutter_x_25 {
  margin-left: -25px !important;
  margin-right: -25px !important;
}

.l-grid-row.-gutter_x_25 > [class^="col"] {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.l-grid-row.-gutter_y_25 {
  margin-top: -50px !important;
}

.l-grid-row.-gutter_y_25 > [class^="col"] {
  margin-top: 50px !important;
}

.l-grid-row.-gutter_30 {
  margin-top: -60px !important;
  margin-left: -30px !important;
  margin-right: -30px !important;
}

.l-grid-row.-gutter_30 > [class^="col"] {
  margin-top: 60px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.l-grid-row.-gutter_x_30 {
  margin-left: -30px !important;
  margin-right: -30px !important;
}

.l-grid-row.-gutter_x_30 > [class^="col"] {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.l-grid-row.-gutter_y_30 {
  margin-top: -60px !important;
}

.l-grid-row.-gutter_y_30 > [class^="col"] {
  margin-top: 60px !important;
}

@media (max-width: 896px) {
  .l-grid-row.-gutter_sm_5 {
    margin-top: -10px !important;
    margin-left: -5px !important;
    margin-right: -5px !important;
  }
  .l-grid-row.-gutter_sm_5 > [class^="col"] {
    margin-top: 10px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .l-grid-row.-gutter_x_sm_5 {
    margin-left: -5px !important;
    margin-right: -5px !important;
  }
  .l-grid-row.-gutter_x_sm_5 > [class^="col"] {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .l-grid-row.-gutter_y_sm_5 {
    margin-top: -10px !important;
  }
  .l-grid-row.-gutter_y_sm_5 > [class^="col"] {
    margin-top: 10px !important;
  }
  .l-grid-row.-gutter_sm_10 {
    margin-top: -20px !important;
    margin-left: -10px !important;
    margin-right: -10px !important;
  }
  .l-grid-row.-gutter_sm_10 > [class^="col"] {
    margin-top: 20px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .l-grid-row.-gutter_x_sm_10 {
    margin-left: -10px !important;
    margin-right: -10px !important;
  }
  .l-grid-row.-gutter_x_sm_10 > [class^="col"] {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .l-grid-row.-gutter_y_sm_10 {
    margin-top: -20px !important;
  }
  .l-grid-row.-gutter_y_sm_10 > [class^="col"] {
    margin-top: 20px !important;
  }
  .l-grid-row.-gutter_sm_15 {
    margin-top: -30px !important;
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
  .l-grid-row.-gutter_sm_15 > [class^="col"] {
    margin-top: 30px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .l-grid-row.-gutter_x_sm_15 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
  .l-grid-row.-gutter_x_sm_15 > [class^="col"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .l-grid-row.-gutter_y_sm_15 {
    margin-top: -30px !important;
  }
  .l-grid-row.-gutter_y_sm_15 > [class^="col"] {
    margin-top: 30px !important;
  }
  .l-grid-row.-gutter_sm_20 {
    margin-top: -40px !important;
    margin-left: -20px !important;
    margin-right: -20px !important;
  }
  .l-grid-row.-gutter_sm_20 > [class^="col"] {
    margin-top: 40px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .l-grid-row.-gutter_x_sm_20 {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }
  .l-grid-row.-gutter_x_sm_20 > [class^="col"] {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .l-grid-row.-gutter_y_sm_20 {
    margin-top: -40px !important;
  }
  .l-grid-row.-gutter_y_sm_20 > [class^="col"] {
    margin-top: 40px !important;
  }
  .l-grid-row.-gutter_sm_25 {
    margin-top: -50px !important;
    margin-left: -25px !important;
    margin-right: -25px !important;
  }
  .l-grid-row.-gutter_sm_25 > [class^="col"] {
    margin-top: 50px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .l-grid-row.-gutter_x_sm_25 {
    margin-left: -25px !important;
    margin-right: -25px !important;
  }
  .l-grid-row.-gutter_x_sm_25 > [class^="col"] {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .l-grid-row.-gutter_y_sm_25 {
    margin-top: -50px !important;
  }
  .l-grid-row.-gutter_y_sm_25 > [class^="col"] {
    margin-top: 50px !important;
  }
  .l-grid-row.-gutter_sm_30 {
    margin-top: -60px !important;
    margin-left: -30px !important;
    margin-right: -30px !important;
  }
  .l-grid-row.-gutter_sm_30 > [class^="col"] {
    margin-top: 60px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .l-grid-row.-gutter_x_sm_30 {
    margin-left: -30px !important;
    margin-right: -30px !important;
  }
  .l-grid-row.-gutter_x_sm_30 > [class^="col"] {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .l-grid-row.-gutter_y_sm_30 {
    margin-top: -60px !important;
  }
  .l-grid-row.-gutter_y_sm_30 > [class^="col"] {
    margin-top: 60px !important;
  }
}

@media (min-width: 897px) {
  .l-grid-row.-gutter_md_5 {
    margin-top: -10px !important;
    margin-left: -5px !important;
    margin-right: -5px !important;
  }
  .l-grid-row.-gutter_md_5 > [class^="col"] {
    margin-top: 10px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .l-grid-row.-gutter_x_md_5 {
    margin-left: -5px !important;
    margin-right: -5px !important;
  }
  .l-grid-row.-gutter_x_md_5 > [class^="col"] {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .l-grid-row.-gutter_y_md_5 {
    margin-top: -10px !important;
  }
  .l-grid-row.-gutter_y_md_5 > [class^="col"] {
    margin-top: 10px !important;
  }
  .l-grid-row.-gutter_md_10 {
    margin-top: -20px !important;
    margin-left: -10px !important;
    margin-right: -10px !important;
  }
  .l-grid-row.-gutter_md_10 > [class^="col"] {
    margin-top: 20px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .l-grid-row.-gutter_x_md_10 {
    margin-left: -10px !important;
    margin-right: -10px !important;
  }
  .l-grid-row.-gutter_x_md_10 > [class^="col"] {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .l-grid-row.-gutter_y_md_10 {
    margin-top: -20px !important;
  }
  .l-grid-row.-gutter_y_md_10 > [class^="col"] {
    margin-top: 20px !important;
  }
  .l-grid-row.-gutter_md_15 {
    margin-top: -30px !important;
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
  .l-grid-row.-gutter_md_15 > [class^="col"] {
    margin-top: 30px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .l-grid-row.-gutter_x_md_15 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
  .l-grid-row.-gutter_x_md_15 > [class^="col"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .l-grid-row.-gutter_y_md_15 {
    margin-top: -30px !important;
  }
  .l-grid-row.-gutter_y_md_15 > [class^="col"] {
    margin-top: 30px !important;
  }
  .l-grid-row.-gutter_md_20 {
    margin-top: -40px !important;
    margin-left: -20px !important;
    margin-right: -20px !important;
  }
  .l-grid-row.-gutter_md_20 > [class^="col"] {
    margin-top: 40px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .l-grid-row.-gutter_x_md_20 {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }
  .l-grid-row.-gutter_x_md_20 > [class^="col"] {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .l-grid-row.-gutter_y_md_20 {
    margin-top: -40px !important;
  }
  .l-grid-row.-gutter_y_md_20 > [class^="col"] {
    margin-top: 40px !important;
  }
  .l-grid-row.-gutter_md_25 {
    margin-top: -50px !important;
    margin-left: -25px !important;
    margin-right: -25px !important;
  }
  .l-grid-row.-gutter_md_25 > [class^="col"] {
    margin-top: 50px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .l-grid-row.-gutter_x_md_25 {
    margin-left: -25px !important;
    margin-right: -25px !important;
  }
  .l-grid-row.-gutter_x_md_25 > [class^="col"] {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .l-grid-row.-gutter_y_md_25 {
    margin-top: -50px !important;
  }
  .l-grid-row.-gutter_y_md_25 > [class^="col"] {
    margin-top: 50px !important;
  }
  .l-grid-row.-gutter_md_30 {
    margin-top: -60px !important;
    margin-left: -30px !important;
    margin-right: -30px !important;
  }
  .l-grid-row.-gutter_md_30 > [class^="col"] {
    margin-top: 60px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .l-grid-row.-gutter_x_md_30 {
    margin-left: -30px !important;
    margin-right: -30px !important;
  }
  .l-grid-row.-gutter_x_md_30 > [class^="col"] {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .l-grid-row.-gutter_y_md_30 {
    margin-top: -60px !important;
  }
  .l-grid-row.-gutter_y_md_30 > [class^="col"] {
    margin-top: 60px !important;
  }
}

@media (min-width: 1601px) {
  .l-grid-row.-gutter_lg_5 {
    margin-top: -10px !important;
    margin-left: -5px !important;
    margin-right: -5px !important;
  }
  .l-grid-row.-gutter_lg_5 > [class^="col"] {
    margin-top: 10px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .l-grid-row.-gutter_x_lg_5 {
    margin-left: -5px !important;
    margin-right: -5px !important;
  }
  .l-grid-row.-gutter_x_lg_5 > [class^="col"] {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .l-grid-row.-gutter_y_lg_5 {
    margin-top: -10px !important;
  }
  .l-grid-row.-gutter_y_lg_5 > [class^="col"] {
    margin-top: 10px !important;
  }
  .l-grid-row.-gutter_lg_10 {
    margin-top: -20px !important;
    margin-left: -10px !important;
    margin-right: -10px !important;
  }
  .l-grid-row.-gutter_lg_10 > [class^="col"] {
    margin-top: 20px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .l-grid-row.-gutter_x_lg_10 {
    margin-left: -10px !important;
    margin-right: -10px !important;
  }
  .l-grid-row.-gutter_x_lg_10 > [class^="col"] {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .l-grid-row.-gutter_y_lg_10 {
    margin-top: -20px !important;
  }
  .l-grid-row.-gutter_y_lg_10 > [class^="col"] {
    margin-top: 20px !important;
  }
  .l-grid-row.-gutter_lg_15 {
    margin-top: -30px !important;
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
  .l-grid-row.-gutter_lg_15 > [class^="col"] {
    margin-top: 30px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .l-grid-row.-gutter_x_lg_15 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
  .l-grid-row.-gutter_x_lg_15 > [class^="col"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .l-grid-row.-gutter_y_lg_15 {
    margin-top: -30px !important;
  }
  .l-grid-row.-gutter_y_lg_15 > [class^="col"] {
    margin-top: 30px !important;
  }
  .l-grid-row.-gutter_lg_20 {
    margin-top: -40px !important;
    margin-left: -20px !important;
    margin-right: -20px !important;
  }
  .l-grid-row.-gutter_lg_20 > [class^="col"] {
    margin-top: 40px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .l-grid-row.-gutter_x_lg_20 {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }
  .l-grid-row.-gutter_x_lg_20 > [class^="col"] {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .l-grid-row.-gutter_y_lg_20 {
    margin-top: -40px !important;
  }
  .l-grid-row.-gutter_y_lg_20 > [class^="col"] {
    margin-top: 40px !important;
  }
  .l-grid-row.-gutter_lg_25 {
    margin-top: -50px !important;
    margin-left: -25px !important;
    margin-right: -25px !important;
  }
  .l-grid-row.-gutter_lg_25 > [class^="col"] {
    margin-top: 50px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .l-grid-row.-gutter_x_lg_25 {
    margin-left: -25px !important;
    margin-right: -25px !important;
  }
  .l-grid-row.-gutter_x_lg_25 > [class^="col"] {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .l-grid-row.-gutter_y_lg_25 {
    margin-top: -50px !important;
  }
  .l-grid-row.-gutter_y_lg_25 > [class^="col"] {
    margin-top: 50px !important;
  }
  .l-grid-row.-gutter_lg_30 {
    margin-top: -60px !important;
    margin-left: -30px !important;
    margin-right: -30px !important;
  }
  .l-grid-row.-gutter_lg_30 > [class^="col"] {
    margin-top: 60px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .l-grid-row.-gutter_x_lg_30 {
    margin-left: -30px !important;
    margin-right: -30px !important;
  }
  .l-grid-row.-gutter_x_lg_30 > [class^="col"] {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .l-grid-row.-gutter_y_lg_30 {
    margin-top: -60px !important;
  }
  .l-grid-row.-gutter_y_lg_30 > [class^="col"] {
    margin-top: 60px !important;
  }
}

.l-grid-row > .col {
  width: 100%;
}

.l-grid-row > .col-12 {
  width: 100%;
}

.l-grid-row > .col-11 {
  width: 91.66667%;
}

.l-grid-row > .col-10 {
  width: 83.33333%;
}

.l-grid-row > .col-9 {
  width: 75%;
}

.l-grid-row > .col-8 {
  width: 66.66667%;
}

.l-grid-row > .col-7 {
  width: 58.33333%;
}

.l-grid-row > .col-6 {
  width: 50%;
}

.l-grid-row > .col-5 {
  width: 41.66667%;
}

.l-grid-row > .col-4 {
  width: 33.33333%;
}

.l-grid-row > .col-3 {
  width: 25%;
}

.l-grid-row > .col-2 {
  width: 16.66667%;
}

.l-grid-row > .col-1 {
  width: 8.33333%;
}

@media (max-width: 896px) {
  .l-grid-row > .col-sm-12 {
    width: 100%;
  }
  .l-grid-row > .col-sm-11 {
    width: 91.66667%;
  }
  .l-grid-row > .col-sm-10 {
    width: 83.33333%;
  }
  .l-grid-row > .col-sm-9 {
    width: 75%;
  }
  .l-grid-row > .col-sm-8 {
    width: 66.66667%;
  }
  .l-grid-row > .col-sm-7 {
    width: 58.33333%;
  }
  .l-grid-row > .col-sm-6 {
    width: 50%;
  }
  .l-grid-row > .col-sm-5 {
    width: 41.66667%;
  }
  .l-grid-row > .col-sm-4 {
    width: 33.33333%;
  }
  .l-grid-row > .col-sm-3 {
    width: 25%;
  }
  .l-grid-row > .col-sm-2 {
    width: 16.66667%;
  }
  .l-grid-row > .col-sm-1 {
    width: 8.33333%;
  }
}

@media (min-width: 897px) {
  .l-grid-row > .col-md-12 {
    width: 100%;
  }
  .l-grid-row > .col-md-11 {
    width: 91.66667%;
  }
  .l-grid-row > .col-md-10 {
    width: 83.33333%;
  }
  .l-grid-row > .col-md-9 {
    width: 75%;
  }
  .l-grid-row > .col-md-8 {
    width: 66.66667%;
  }
  .l-grid-row > .col-md-7 {
    width: 58.33333%;
  }
  .l-grid-row > .col-md-6 {
    width: 50%;
  }
  .l-grid-row > .col-md-5 {
    width: 41.66667%;
  }
  .l-grid-row > .col-md-4 {
    width: 33.33333%;
  }
  .l-grid-row > .col-md-3 {
    width: 25%;
  }
  .l-grid-row > .col-md-2 {
    width: 16.66667%;
  }
  .l-grid-row > .col-md-1 {
    width: 8.33333%;
  }
}

@media (min-width: 1601px) {
  .l-grid-row > .col-lg-12 {
    width: 100%;
  }
  .l-grid-row > .col-lg-11 {
    width: 91.66667%;
  }
  .l-grid-row > .col-lg-10 {
    width: 83.33333%;
  }
  .l-grid-row > .col-lg-9 {
    width: 75%;
  }
  .l-grid-row > .col-lg-8 {
    width: 66.66667%;
  }
  .l-grid-row > .col-lg-7 {
    width: 58.33333%;
  }
  .l-grid-row > .col-lg-6 {
    width: 50%;
  }
  .l-grid-row > .col-lg-5 {
    width: 41.66667%;
  }
  .l-grid-row > .col-lg-4 {
    width: 33.33333%;
  }
  .l-grid-row > .col-lg-3 {
    width: 25%;
  }
  .l-grid-row > .col-lg-2 {
    width: 16.66667%;
  }
  .l-grid-row > .col-lg-1 {
    width: 8.33333%;
  }
}

.l-grid-row .w-100 {
  width: 100% !important;
}

/* ==========================================================
 html5 elements
========================================================== */
article,
aside,
figure,
figcaption,
footer,
header,
nav,
section {
  display: block;
}

/* ==========================================================
 reseting
========================================================== */
html {
  font-size: 62.5%;
}

body,
div,
pre,
p,
blockquote,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
th,
td,
button,
figure,
figcaption {
  margin: 0;
  padding: 0;
}

input,
textarea {
  margin: 0;
  font-size: 100%;
}

input,
textarea,
select {
  -webkit-appearance: none;
}

textarea {
  resize: none;
}

button {
  border: none;
  overflow: visible;
  background: none;
  vertical-align: top;
  font-size: 100%;
  color: inherit;
  cursor: pointer;
  -webkit-appearance: none;
}

label {
  cursor: pointer;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 100%;
}

fieldset {
  border: 0;
}

img {
  max-width: 100%;
  border: 0;
  height: auto;
  vertical-align: top;
}

address,
caption,
code,
em,
th {
  font-style: normal;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

caption,
th {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong,
em {
  font-size: 100%;
  font-weight: normal;
}

input {
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  overflow-wrap: break-word;
  word-wrap: break-word;
}

a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  overflow-wrap: break-word;
  word-wrap: break-word;
}

sup {
  vertical-align: top;
}

sub {
  vertical-align: sub;
}

strong {
  font-weight: 500;
}

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

/* ==========================================================
 base Link
========================================================== */
a {
  text-decoration: none;
  color: #000;
}

a:hover, a:active, a:focus, a:visited {
  text-decoration: none;
}

/* ==========================================================
 clearfix
========================================================== */
.clearfix::before, .clearfix::after {
  content: '';
  display: table;
}

.contents::before, .contents::after {
  content: '';
  display: table;
}

.clearfix::after,
.contents::after {
  clear: both;
}

/* ==========================================================
 components
========================================================== */
/* c-box-customers-filters
---------------------------------------------------------- */
.c-box-customers-filters {
  padding: 62px 0 50px;
  background-color: #e3f4f0;
}

@media (max-width: 896px) {
  .c-box-customers-filters {
    padding: 32px 0 45px;
  }
}

/* c-box-filters
---------------------------------------------------------- */
@media (max-width: 896px) {
  .c-box-filters {
    padding: 0 10px;
  }
}

.c-box-filters .box-title {
  position: relative;
  border-radius: 10px;
  padding: 28px 0;
  height: 80px;
  background-color: #45b49b;
  text-align: center;
  cursor: pointer;
}

@media (max-width: 896px) {
  .c-box-filters .box-title {
    padding: 14px 0;
    height: 44px;
  }
}

.c-box-filters .box-title::before {
  content: '';
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #5d5d5d;
  border-radius: 10px;
  transition: .25s;
}

.c-box-filters .box-title:hover::before {
  width: 100%;
}

.c-box-filters .box-title .title {
  position: relative;
  display: inline-block;
  padding-left: 30px;
  font-size: 2rem;
  letter-spacing: .1em;
  font-weight: bold;
  color: #fff;
}

@media (max-width: 896px) {
  .c-box-filters .box-title .title {
    font-size: 1.4rem;
  }
}

.c-box-filters .box-title .title::before {
  content: '';
  position: absolute;
  top: 5px;
  left: 0;
  width: 19px;
  height: 19px;
  background: url("/assets/images/common/ico_search.png") 0 0 no-repeat;
}

@media (max-width: 896px) {
  .c-box-filters .box-title .title::before {
    top: 2px;
    width: 15px;
    height: 15px;
    background-size: 15px 15px;
  }
}

.c-box-filters .box-title.is-open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.c-box-filters .box-content {
  border-left: 4px solid #45b49b;
  border-right: 4px solid #45b49b;
  border-bottom: 4px solid #45b49b;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #fff;
  padding: 25px 42px 28px;
  display: none;
}

@media (max-width: 896px) {
  .c-box-filters .box-content {
    border-left: 2px solid #45b49b;
    border-right: 2px solid #45b49b;
    border-bottom: 2px solid #45b49b;
    padding: 12px 15px 38px;
  }
}

.c-box-filters .box-content .item-content {
  display: block;
}

.c-box-filters .box-content .item-content .item-row {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #d4d4d4;
}

@media (max-width: 896px) {
  .c-box-filters .box-content .item-content .item-row {
    align-items: flex-start;
  }
}

.c-box-filters .box-content .item-content .item-row .title {
  width: 176px;
  flex: 0 0 auto;
  text-align: center;
}

@media (max-width: 896px) {
  .c-box-filters .box-content .item-content .item-row .title {
    padding-top: 5px;
    width: 60px;
    text-align: left;
  }
}

.c-box-filters .box-content .item-content .item-row .details {
  padding: 21px 0 20px;
  border-left: 1px solid #d4d4d4;
}

@media (max-width: 896px) {
  .c-box-filters .box-content .item-content .item-row .details {
    padding: 10px 0;
  }
}

.c-box-filters .box-content .item-content .item-row .details .list {
  display: flex;
  margin: -15px 0 0 -50px;
  padding-left: 45px;
  flex-wrap: wrap;
}

@media (max-width: 896px) {
  .c-box-filters .box-content .item-content .item-row .details .list {
    display: block;
    margin-left: 0;
    padding-left: 0;
  }
}

.c-box-filters .box-content .item-content .item-row .details .list .item {
  margin: 15px 0 0 50px;
}

@media (max-width: 896px) {
  .c-box-filters .box-content .item-content .item-row .details .list .item {
    margin-top: 10px;
    margin-left: 8px;
  }
}

@media (max-width: 896px) {
  .c-box-filters .box-content .item-content .item-row .details .list .item:not(:first-child) {
    margin-top: 3px;
  }
}

.c-box-filters .box-content .item-content .item-row.-row-01 .details {
  padding: 17px 0;
}

@media (max-width: 896px) {
  .c-box-filters .box-content .item-content .item-row.-row-01 .details {
    padding: 10px 0;
  }
}

.c-box-filters .wrap-button {
  position: relative;
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

@media (max-width: 896px) {
  .c-box-filters .wrap-button {
    margin-right: 10px;
    margin-top: 36px;
    flex-wrap: wrap;
  }
}

/* c-box-button
---------------------------------------------------------- */
.c-box-button {
  margin-top: 50px;
  padding: 48px 0;
  text-align: center;
  background-color: #e3f4f0;
  border-radius: 10px;
}

@media (max-width: 896px) {
  .c-box-button {
    padding: 28px 18px;
    border-radius: 14px;
  }
}

.c-box-button .c-btn {
  font-weight: 700;
}

@media (min-width: 897px) {
  .c-box-button .c-btn {
    padding: 17px 45px 13px 55px;
  }
}

.c-box-button.-lg {
  padding: 40px 0;
}

@media (min-width: 897px) {
  .c-box-button.-lg {
    width: calc(100% - 15px);
    margin: 124px auto 0;
  }
}

@media (max-width: 896px) {
  .c-box-button.-lg {
    padding: 35px 15px;
  }
}

@media (min-width: 897px) {
  .c-box-button.-lg .c-btn {
    min-width: 500px;
    padding: 23px 45px 23px 55px;
  }
  .c-box-button.-lg .c-btn::after {
    right: -8px;
  }
}

@media (min-width: 897px) {
  .c-box-button.-full.-lg {
    width: 100%;
    margin-top: 45px;
    padding: 69px 0 60px;
  }
}

/* c-box-info
---------------------------------------------------------- */
/* c-box-info */
.c-box-info {
  margin-top: 43px;
  padding: 22px 0 24px 2px;
  border-top: 2px solid #080808;
  border-bottom: 2px solid #080808;
  /* box-contact */
}

@media (max-width: 896px) {
  .c-box-info {
    margin-top: 44px;
    border-top: 1px solid #080808;
    border-bottom: 1px solid #080808;
  }
}

@media (min-width: 897px) {
  .c-box-info {
    display: flex;
  }
}

@media (max-width: 896px) {
  .c-box-info {
    padding-left: 27px;
    padding-right: 16px;
  }
}

@media (max-width: 896px) and (max-width: 896px) {
  .c-box-info {
    padding-left: 0;
    padding-right: 0;
  }
}

.c-box-info > .box-contact {
  width: 100%;
}

@media (min-width: 897px) {
  .c-box-info > .box-contact {
    width: 50%;
    padding-right: 25px;
  }
}

@media (max-width: 896px) {
  .c-box-info > .box-contact {
    padding-left: 5px;
  }
}

.c-box-info > .box-contact > .title {
  font-size: 1.4rem;
  font-weight: 700;
  color: #080808;
}

.c-box-info > .box-contact > .customer {
  font-size: 1.4rem;
  font-weight: 700;
  color: #080808;
  margin-top: 6px;
}

@media (max-width: 896px) {
  .c-box-info > .box-contact > .customer {
    margin-top: 3px;
  }
}

.c-box-info > .box-contact > .name {
  font-size: 1.4rem;
  line-height: 1.95;
  color: #080808;
  margin-top: 3px;
}

@media (max-width: 896px) {
  .c-box-info > .box-contact > .name {
    font-size: 1.2rem;
    line-height: 1.7;
    margin-top: 0;
  }
}

.c-box-info .list-detail {
  margin-top: 5px;
}

@media (max-width: 896px) {
  .c-box-info .list-detail {
    padding-right: 8px;
  }
}

.c-box-info .list-detail > .item {
  display: flex;
  line-height: 1.9;
}

.c-box-info .list-detail > .item:not(:first-child) {
  margin-top: 2px;
}

@media (max-width: 896px) {
  .c-box-info .list-detail > .item:not(:first-child) {
    margin-top: 0;
  }
}

.c-box-info .list-detail > .item > .title {
  display: flex;
  justify-content: space-between;
  width: 80px;
  font-size: 1.4rem;
  flex: 1 0 auto;
}

@media (max-width: 896px) {
  .c-box-info .list-detail > .item > .title {
    width: 60px;
    font-size: 1.2rem;
  }
}

.c-box-info .list-detail > .item > .title::after {
  position: relative;
  top: -1px;
  content: '：';
  font-size: 1.4rem;
}

@media (max-width: 896px) {
  .c-box-info .list-detail > .item > .title::after {
    font-size: 1.2rem;
  }
}

.c-box-info .list-detail > .item > .text {
  width: 100%;
  font-size: 1.4rem;
}

@media (max-width: 896px) {
  .c-box-info .list-detail > .item > .text {
    font-size: 1.2rem;
  }
}

.c-box-info .list-detail > .item a {
  color: #0000ff;
}

@media (min-width: 897px) {
  .c-box-info .list-detail > .item a {
    opacity: 1;
    transition: opacity .3s ease-out;
  }
  .c-box-info .list-detail > .item a:hover {
    opacity: 0.6;
    text-decoration: none;
  }
}

@media (min-width: 897px) {
  .c-box-info .list-detail > .item a:hover {
    text-decoration: underline;
  }
}

.c-box-info .box-sns {
  margin: 6px 0 0 -16px;
}

@media (min-width: 897px) {
  .c-box-info .box-product-detail {
    width: 50%;
  }
}

.c-box-info .list-product-detail {
  margin-top: 30px;
}

@media (max-width: 896px) {
  .c-box-info .list-product-detail {
    margin-top: 7px;
  }
}

.c-box-info .list-product-detail > .item {
  display: flex;
}

.c-box-info .list-product-detail > .item:not(:first-child) {
  margin-top: 6px;
}

@media (max-width: 896px) {
  .c-box-info .list-product-detail > .item:not(:first-child) {
    margin-top: 4px;
  }
}

.c-box-info .list-product-detail .title {
  flex: 1 0 auto;
  width: 100px;
}

@media (max-width: 896px) {
  .c-box-info .list-product-detail .title {
    width: 75px;
  }
}

.c-box-info .list-product-detail .title > span {
  display: block;
  font-size: 1.2rem;
  font-weight: 500;
  color: #fff;
  text-align: center;
  border-radius: 3px;
  background-color: #979797;
  padding: 3px 5px 2px;
}

@media (max-width: 896px) {
  .c-box-info .list-product-detail .title > span {
    font-size: 1.2rem;
    border-radius: 2px;
    padding: 1px 5px;
  }
}

.c-box-info .list-product-detail .text {
  width: 100%;
  font-size: 1.4rem;
  line-height: 1.7;
  color: #080808;
  padding-left: 10px;
  letter-spacing: -.05rem;
}

@media (max-width: 896px) {
  .c-box-info .list-product-detail .text {
    font-size: 1.2rem;
    padding-left: 7px;
  }
}

/* c-box-green
---------------------------------------------------------- */
.c-box-green {
  padding: 48px 52px 38px;
  background-color: #e3f4f0;
  border-radius: 10px;
}

@media (max-width: 896px) {
  .c-box-green {
    padding: 35px 20px 26px 25px;
    border-radius: 13px;
  }
}

/* c-box-line
---------------------------------------------------------- */
.c-box-line {
  margin-top: 70px;
  padding: 42px 0 43px 3px;
  border-top: 2px solid #080808;
  border-bottom: 2px solid #080808;
}

@media (max-width: 896px) {
  .c-box-line {
    margin-top: 44px;
    padding: 31px 0 30px 7px;
    border-top: 1px solid #080808;
    border-bottom: 1px solid #080808;
  }
}

/* c-box-link
---------------------------------------------------------- */
.c-box-link {
  margin-top: 74px;
  padding: 41px 0 42px 2px;
}

.c-box-link .title {
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 8px;
}

/* c-box-detail-info
---------------------------------------------------------- */
.c-box-detail-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.c-box-detail-info .c-tag {
  position: relative;
  top: -4px;
}

@media (max-width: 896px) {
  .c-box-detail-info .c-tag {
    top: -7px;
  }
}

@media (max-width: 896px) {
  .c-box-detail-info {
    margin-bottom: 26px;
  }
}

/* c-box-sns
---------------------------------------------------------- */
.c-box-sns.-right {
  text-align: right;
}

.c-box-sns .twitter {
  position: relative;
  top: -1px;
}

.c-box-sns .twitter img {
  height: 21px;
}

/* c-box-speaker
---------------------------------------------------------- */
.c-box-speaker {
  display: flex;
  margin-top: 68px;
  padding: 57px 50px 36px 60px;
}

@media (max-width: 896px) {
  .c-box-speaker {
    flex-wrap: wrap;
    margin-top: 33px;
    padding: 45px 23px 36px 23px;
  }
}

.c-box-speaker > .box-detail {
  padding-right: 50px;
}

@media (min-width: 897px) {
  .c-box-speaker > .box-detail {
    flex: 1 0 auto;
    width: 514px;
  }
}

@media (max-width: 896px) {
  .c-box-speaker > .box-detail {
    width: 100%;
  }
}

.c-box-speaker > .box-detail .c-heading-04 {
  margin-bottom: 5px;
}

@media (max-width: 896px) {
  .c-box-speaker > .box-detail .c-heading-04 {
    margin-bottom: 14px;
  }
}

.c-box-speaker > .box-detail p {
  line-height: 2;
}

@media (max-width: 896px) {
  .c-box-speaker > .box-detail p {
    font-size: 1.2rem;
    line-height: 1.7;
  }
}

.c-box-speaker > .box-detail ul {
  margin-top: 4px;
}

@media (max-width: 896px) {
  .c-box-speaker > .box-detail ul {
    margin-top: 0;
  }
}

.c-box-speaker > .box-detail ul li:not(:first-child) {
  margin-top: 9px;
}

@media (max-width: 896px) {
  .c-box-speaker > .box-detail ul li:not(:first-child) {
    margin-top: 0;
  }
}

@media (max-width: 896px) {
  .c-box-speaker > .box-detail ul li {
    font-size: 1.2rem;
    line-height: 1.7;
    padding-left: 12px;
  }
}

@media (max-width: 896px) {
  .c-box-speaker > .box-detail ul li::before {
    width: 12px;
  }
}

.c-box-speaker > .box-image {
  width: 100%;
}

@media (max-width: 896px) {
  .c-box-speaker > .box-image {
    margin-top: 26px;
  }
}

.c-box-speaker > .box-image .image {
  margin-top: 9px;
  clip-path: content-box;
}

@media (max-width: 896px) {
  .c-box-speaker > .box-image .image {
    width: 150px;
    margin: 0 auto;
  }
}

.c-box-speaker > .box-image .image img {
  width: 100%;
  border-radius: 200px;
}

.c-box-speaker > .box-image .caption {
  font-size: 2rem;
  text-align: center;
}

@media (max-width: 896px) {
  .c-box-speaker > .box-image .caption {
    font-size: 1.4rem;
    margin-top: 10px;
  }
}

.c-box-speaker > .box-image .caption > span {
  display: block;
  font-size: 1.2rem;
  margin-top: 8px;
}

/* c-box-post-relate
---------------------------------------------------------- */
.c-box-post-relate {
  padding-top: 128px;
}

@media (max-width: 896px) {
  .c-box-post-relate {
    padding-top: 132px;
  }
}

@media (min-width: 897px) {
  .c-box-post-relate .c-heading-03 {
    margin-bottom: 45px;
  }
}

@media (min-width: 897px) {
  .c-box-post-relate > ul > li {
    width: 50% !important;
  }
}

@media (max-width: 896px) {
  .c-box-post-relate .c-list-know > .item {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (max-width: 896px) {
  .c-box-post-relate .c-list-know > .item:not(:first-child) {
    margin-top: 45px;
  }
}

/* c-box-youtube
---------------------------------------------------------- */
.c-box-youtube .c-heading-04 {
  margin-bottom: 15px;
}

.c-box-youtube .video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}

.c-box-youtube .video > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

* + .c-box-youtube {
  margin-top: 203px;
}

@media (max-width: 896px) {
  * + .c-box-youtube {
    margin-top: 82px;
  }
}

/* c-box-flow
---------------------------------------------------------- */
.c-box-flow {
  border-radius: 10px;
  background-color: #e3f4f0;
}

@media (min-width: 897px) {
  .c-box-flow {
    display: flex;
    align-items: center;
    min-height: 120px;
    padding-top: 37px;
    padding-bottom: 27px;
  }
}

@media (max-width: 896px) {
  .c-box-flow {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    padding: 25px 15px 32px;
  }
}

.c-box-flow > .title {
  font-size: 1.8rem;
  font-weight: 700;
  color: #45b49b;
}

@media (min-width: 897px) {
  .c-box-flow > .title {
    width: 250px;
    flex: 1 0 auto;
    text-align: center;
    padding: 0px 36px 0 48px;
  }
}

@media (max-width: 896px) {
  .c-box-flow > .title {
    font-size: 1.6rem;
    text-align: center;
  }
}

.c-box-flow > .desc {
  width: 100%;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.8;
  color: #080808;
}

@media (min-width: 897px) {
  .c-box-flow > .desc {
    position: relative;
    padding-left: 39px;
    padding: 0 35px 0 38px;
  }
  .c-box-flow > .desc::after {
    content: '';
    position: absolute;
    top: 48%;
    left: 0;
    transform: translateY(-50%);
    width: 1px;
    height: calc(100% + 13px);
    min-height: 69px;
    background-color: #45b49b;
  }
}

@media (max-width: 896px) {
  .c-box-flow > .desc {
    font-size: 1.4rem;
    line-height: 2;
    margin-top: 4px;
  }
}

@media (min-width: 897px) {
  .c-box-flow > .desc a {
    opacity: 1;
    transition: opacity .3s ease-out;
  }
  .c-box-flow > .desc a:hover {
    opacity: 0.6;
    text-decoration: none;
  }
}

/* c-box-faq
---------------------------------------------------------- */
.c-box-faq {
  position: relative;
  border-top: 1px dotted #979797;
}

.c-box-faq .box-text {
  position: relative;
}

@media (min-width: 897px) {
  .c-box-faq .box-text {
    display: flex;
    align-items: baseline;
  }
}

.c-box-faq .text-cmn {
  font-size: 1.8rem;
}

@media (max-width: 896px) {
  .c-box-faq .text-cmn {
    display: block;
    font-size: 1.6rem;
    margin-top: 4px;
  }
}

.c-box-faq .box-question {
  display: block;
  position: relative;
  padding: 21px 0 16px 0;
  cursor: pointer;
}

@media (min-width: 897px) {
  .c-box-faq .box-question {
    opacity: 1;
    transition: opacity .3s ease-out;
  }
  .c-box-faq .box-question:hover {
    opacity: 0.6;
    text-decoration: none;
  }
}

@media (max-width: 896px) {
  .c-box-faq .box-question {
    padding: 25px 32px 17px 0;
  }
}

.c-box-faq .box-question.is-open::before {
  background-image: url(/assets/images/common/ico_accordion_open_pc.png);
}

@media (max-width: 896px) {
  .c-box-faq .box-question.is-open::before {
    background-image: url(/assets/images/common/ico_accordion_open.png);
  }
}

.c-box-faq .box-question::before {
  position: absolute;
  content: '';
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  background-image: url(/assets/images/common/ico_accordion_close_pc.png);
  background-size: 24px 24px;
  background-repeat: no-repeat;
}

@media (max-width: 896px) {
  .c-box-faq .box-question::before {
    top: 35px;
    right: 2px;
    width: 18px;
    height: 18px;
    background-image: url(/assets/images/common/ico_accordion_close.png);
    background-size: 18px 18px;
  }
}

.c-box-faq .box-question .box-text {
  font-size: 2rem;
  font-weight: 700;
  padding-left: 52px;
}

@media (max-width: 896px) {
  .c-box-faq .box-question .box-text {
    font-size: 1.6rem;
    padding-left: 36px;
  }
}

.c-box-faq .box-question .box-text::before {
  content: '';
  position: absolute;
  display: block;
  top: -5px;
  left: 4px;
  width: 36px;
  height: 36px;
  background-image: url(/assets/images/common/txt_faq_pc.png);
  background-size: 36px 36px;
  background-repeat: no-repeat;
}

@media (max-width: 896px) {
  .c-box-faq .box-question .box-text::before {
    top: -4px;
    left: 0px;
    width: 28px;
    height: 27px;
    background-image: url(/assets/images/common/txt_faq_sp.png);
    background-size: 28px 27px;
  }
}

.c-box-faq .box-answer {
  display: none;
  position: relative;
  padding: 0 20px 20px 53px;
  line-height: 1.85;
}

@media (max-width: 896px) {
  .c-box-faq .box-answer {
    padding: 0px 10px 19px 36px;
  }
}

.c-box-faq .box-answer::before {
  position: absolute;
  content: '';
  top: 0;
  right: 20px;
  width: calc(100% - 40px);
  height: 2px;
}

@media (max-width: 896px) {
  .c-box-faq .box-answer::before {
    height: 1px;
  }
}

/* c-box-form
---------------------------------------------------------- */
.c-box-form {
  border: 1px solid #b9b9b9;
  border-radius: 10px;
  margin-top: 19px;
  padding: 42px 30px 37px;
}

@media (max-width: 896px) {
  .c-box-form {
    border-radius: 5px;
    margin-top: 20px;
    padding: 23px 15px 37px;
  }
}

.c-box-form .title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 18px;
}

@media (max-width: 896px) {
  .c-box-form .title {
    font-size: 1.6rem;
    margin-bottom: 4px;
  }
}

.c-box-form ol,
.c-box-form ul,
.c-box-form p {
  font-size: 1.2rem;
}

.c-box-form ol > li {
  font-weight: 600;
}

.c-box-form ul > li {
  font-weight: 400;
}

.c-box-form p {
  line-height: 2.1;
}

@media (max-width: 896px) {
  .c-box-form p {
    line-height: 1.7;
  }
}

.c-box-form strong {
  font-weight: 600;
}

/* c-box-form-check
---------------------------------------------------------- */
.c-box-form-check {
  margin-top: 39px;
  margin-left: 54px;
}

@media (max-width: 896px) {
  .c-box-form-check {
    margin-top: 27px;
    margin-left: 7px;
  }
}

@media (min-width: 897px) {
  .c-box-form-check .c-checkbox input:checked + label::after {
    top: 8px;
    left: 10px;
    width: 5px;
    height: 13px;
    border-width: 0 2px 2px 0;
  }
}

@media (max-width: 896px) {
  .c-box-form-check .c-checkbox input:checked + label::after {
    top: 5px;
    left: 6px;
  }
}

@media (max-width: 360px) {
  .c-box-form-check .c-checkbox input:checked + label::after {
    top: 5px;
  }
}

.c-box-form-check .c-checkbox label {
  font-size: 2.4rem;
  font-weight: 700;
}

@media (max-width: 896px) {
  .c-box-form-check .c-checkbox label {
    font-size: 1.6rem;
    display: flex;
    align-items: flex-start;
  }
}

@media (min-width: 897px) {
  .c-box-form-check .c-checkbox label::before {
    padding: 11px;
    top: 4px;
    margin-right: 10px;
  }
}

@media (max-width: 896px) {
  .c-box-form-check .c-checkbox label::before {
    top: 3px;
  }
}

@media (max-width: 896px) {
  .c-box-form-check .c-checkbox label::after {
    top: 5px;
  }
}

.c-box-form-check .text-note {
  font-weight: 700;
  margin-top: 11px;
  margin-left: 38px;
}

@media (max-width: 896px) {
  .c-box-form-check .text-note {
    font-size: 1.2rem;
    line-height: 1.7;
    margin-top: 4px;
    margin-left: 20px;
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .c-box-form-check .c-checkbox input:checked + label::after {
      top: 9px;
    }
    @media (max-width: 896px) {
      .c-box-form-check .c-checkbox input:checked + label::after {
        top: 5px;
      }
    }
  }
}

/* c-box-form-external
---------------------------------------------------------- */
.c-box-form-external {
  display: none;
  margin-top: 35px;
}

@media (max-width: 896px) {
  .c-box-form-external {
    margin-top: 25px;
  }
}

.c-breadcrumb {
  display: flex;
  padding-left: 15px;
}

@media (max-width: 896px) {
  .c-breadcrumb {
    display: none;
  }
}

.c-breadcrumb > .item {
  position: relative;
  color: #505050;
  font-size: 1.1rem;
}

.c-breadcrumb > .item:not(:last-child) {
  margin-right: 20px;
}

.c-breadcrumb > .item:not(:last-child)::after {
  width: 10px;
  height: 14px;
  content: '>';
  display: block;
  position: absolute;
  top: 0;
  right: -14px;
  color: #505050;
}

.c-breadcrumb > .item > a {
  display: block;
  color: #808080;
}

@media (min-width: 897px) {
  .c-breadcrumb > .item > a {
    opacity: 1;
    transition: opacity .3s ease-out;
  }
  .c-breadcrumb > .item > a:hover {
    opacity: 0.6;
    text-decoration: none;
  }
}

/* c-btn
---------------------------------------------------------- */
.c-btn {
  width: 300px;
  position: relative;
  display: inline-block;
  padding: 15px 45px 12px 55px;
  border-radius: 10px;
  text-align: center;
  color: #fff;
  background-color: #45b49b;
  transition: .25s;
}

.c-btn:hover::before {
  width: 100%;
}

@media (max-width: 896px) {
  .c-btn {
    width: calc(100% - 20px);
    padding: 12px 5px 11px;
  }
}

.c-btn::before {
  content: '';
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #5d5d5d;
  border-radius: 10px;
  transition: .25s;
}

.c-btn::after {
  position: absolute;
  content: '';
  top: 50%;
  right: -13px;
  transform: translateY(-50%);
  width: 26px;
  height: 26px;
  background-image: url(/assets/images/common/ico_arrow_black.png);
  background-repeat: no-repeat;
  background-size: 26px 26px;
}

@media (max-width: 896px) {
  .c-btn::after {
    width: 22px;
    height: 22px;
    right: -10px;
    background-size: 22px 22px;
  }
}

.c-btn span {
  display: inline-block;
  position: relative;
  font-size: 1.6rem;
  color: #fff;
  margin-left: -6px;
}

@media (max-width: 896px) {
  .c-btn span {
    font-size: 1.4rem;
  }
}

.c-btn.-full {
  width: 100%;
}

@media (min-width: 897px) {
  .c-btn.-full-pc {
    width: 100%;
  }
}

@media (max-width: 896px) {
  .c-btn.-full-sp {
    width: 100%;
  }
}

@media (min-width: 897px) {
  .c-btn.-lg {
    width: 500px;
    padding: 25px 45px 21px 55px;
  }
  .c-btn.-lg::after {
    right: -8px;
  }
}

.c-btn.-icon > span {
  padding-right: 22px;
}

.c-btn.-icon > span::after {
  position: absolute;
  content: '';
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-repeat: no-repeat;
}

.c-btn.-icon.-note span {
  padding-right: 18px;
  transition: .25s;
}

.c-btn.-icon.-note span::after {
  width: 14px;
  height: 17px;
  background-image: url(/assets/images/common/ico_note.png);
  background-size: 14px 17px;
}

.c-btn.-icon.-note:hover span {
  color: #fff;
}

.c-btn.-icon.-note:hover span::after {
  background-image: url(/assets/images/common/ico_note_white.png);
}

.c-btn.-icon.-document:hover > .text-wrap::after {
  background-image: url(/assets/images/common/ico_note_white.png);
}

.c-btn.-icon.-document:hover > .text-wrap > .jp {
  color: #fff;
}

.c-btn.-icon.-office {
  width: 390px;
  padding: 22px 20px 17px 28px;
}

@media (max-width: 896px) {
  .c-btn.-icon.-office {
    width: calc(100% - 40px);
    padding: 19px 13px 13px 28px;
    margin-left: 15px;
  }
}

.c-btn.-icon.-office > .text-wrap {
  position: relative;
  padding-left: 65px;
  padding-right: 0;
  text-align: left;
}

@media (max-width: 896px) {
  .c-btn.-icon.-office > .text-wrap {
    padding-left: 53px;
  }
}

.c-btn.-icon.-office > .text-wrap::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 47px;
  height: 47px;
  background-image: url(/assets/images/common/ico_office.png);
  background-size: 47px 47px;
}

@media (max-width: 896px) {
  .c-btn.-icon.-office > .text-wrap::after {
    width: 33px;
    height: 33px;
    background-size: 33px 33px;
  }
}

.c-btn.-icon.-office > .text-wrap > .en {
  display: block;
  font-family: "Roboto", sans-serif;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.3;
}

@media (max-width: 896px) {
  .c-btn.-icon.-office > .text-wrap > .en {
    font-size: 1.8rem;
    line-height: 1;
  }
}

.c-btn.-icon.-office > .text-wrap > .jp {
  display: block;
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 1.45;
  margin-bottom: -3px;
}

@media (max-width: 896px) {
  .c-btn.-icon.-office > .text-wrap > .jp {
    font-size: 2rem;
  }
}

.c-btn.-icon.-office > .text-wrap > span {
  font-size: 1.4rem;
  font-weight: 700;
}

@media (max-width: 896px) {
  .c-btn.-icon.-office > .text-wrap > span {
    font-size: 1.2rem;
  }
}

.c-btn.-icon.-document {
  width: 390px;
  padding: 35px 50px 34px 60px;
  background-color: #f8d818;
}

@media (max-width: 896px) {
  .c-btn.-icon.-document {
    width: calc(100% - 40px);
    padding: 25px 20px 22px 60px;
    margin-left: 15px;
  }
}

.c-btn.-icon.-document > .text-wrap {
  position: relative;
  padding-left: 42px;
  padding-right: 0;
  text-align: left;
}

@media (max-width: 896px) {
  .c-btn.-icon.-document > .text-wrap {
    padding-left: 39px;
  }
}

.c-btn.-icon.-document > .text-wrap::after {
  content: '';
  position: absolute;
  top: 49%;
  left: 0;
  transform: translateY(-50%);
  width: 26px;
  height: 31px;
  background-image: url(/assets/images/common/ico_note_gray.png);
  background-size: 26px 31px;
}

@media (max-width: 896px) {
  .c-btn.-icon.-document > .text-wrap::after {
    right: 42px;
    width: 21px;
    height: 26px;
    background-size: 21px 26px;
  }
}

.c-btn.-icon.-document > .text-wrap > .jp {
  display: block;
  font-size: 2.0rem;
  font-weight: 600;
  line-height: 1.45;
  margin-bottom: -3px;
  color: #3a3a3a;
}

@media (max-width: 896px) {
  .c-btn.-icon.-document > .text-wrap > .jp {
    font-size: 1.6rem;
  }
}

.c-btn.-yellow {
  background-color: #f5d006;
}

.c-btn.-uncheck {
  width: 150px;
  font-size: 1.6rem;
  font-weight: bold;
  text-align: center;
  background-color: #efefef;
  border: 3px solid #dedede;
  padding: 12px 5px 9px;
  cursor: pointer;
}

@media (min-width: 897px) {
  .c-btn.-uncheck {
    opacity: 1;
    transition: opacity .3s ease-out;
  }
  .c-btn.-uncheck:hover {
    opacity: 0.6;
    text-decoration: none;
  }
}

@media (min-width: 897px) {
  .c-btn.-uncheck {
    position: absolute;
    top: 0;
    right: 50%;
    transform: translateX(calc(50% + 265px));
  }
}

@media (max-width: 896px) {
  .c-btn.-uncheck {
    width: calc(100% - 50px);
    border: 2px solid #dedede;
    padding: 8px 5px;
  }
  .c-btn-search + .c-btn.-uncheck {
    margin-top: 18px;
  }
}

.c-btn.-uncheck span {
  color: #282828;
}

@media (min-width: 897px) {
  .c-btn.-uncheck span {
    width: 146px;
  }
}

.c-btn.-uncheck::before {
  display: none;
}

.c-btn.-uncheck::after {
  display: none;
}

.c-btn-center {
  text-align: center;
}

.c-btn-wrap {
  margin-top: 116px;
  padding: 43px 0 40px;
  background-color: #e3f4f0;
  border-radius: 10px;
  text-align: center;
}

@media (max-width: 896px) {
  .c-btn-wrap {
    padding: 32px 15px;
    margin-top: 34px;
  }
}

@media (min-width: 897px) {
  .c-btn-wrap .-xl {
    width: 500px;
    padding: 25px 45px 21px 55px;
  }
}

.c-btn-search {
  width: 300px;
  position: relative;
  display: inline-block;
  padding: 15px 45px 12px 55px;
  border-radius: 10px;
  text-align: center;
  background-color: #5d5d5d;
}

.c-btn-search::before {
  content: '';
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  border-radius: 10px;
  transition: .25s;
}

.c-btn-search:hover::before {
  width: 100%;
}

@media (max-width: 896px) {
  .c-btn-search {
    width: calc(100% - 50px);
    padding: 12px 5px 11px;
  }
}

.c-btn-search::after {
  position: absolute;
  content: '';
  top: 50%;
  right: -13px;
  transform: translateY(-50%);
  width: 26px;
  height: 26px;
  background-image: url(/assets/images/common/ico_arrow_black.png);
  background-repeat: no-repeat;
  background-size: 26px 26px;
}

@media (max-width: 896px) {
  .c-btn-search::after {
    width: 22px;
    height: 22px;
    right: -10px;
    background-size: 22px 22px;
  }
}

.c-btn-search span {
  display: inline-block;
  margin-left: -10px;
  position: relative;
  font-size: 1.6rem;
  letter-spacing: .1em;
  font-weight: bold;
  color: #fff;
  padding-left: 24px;
}

@media (max-width: 896px) {
  .c-btn-search span {
    font-size: 1.4rem;
  }
}

.c-btn-search span::after {
  position: absolute;
  content: '';
  top: 3px;
  left: 0;
  width: 17px;
  height: 17px;
  background-image: url(/assets/images/common/ico_search.png);
  background-repeat: no-repeat;
  background-size: 17px 17px;
}

/* c-card-customer
---------------------------------------------------------- */
.c-card-customer .image {
  position: relative;
  border-radius: 3px;
  overflow: hidden;
  clip-path: content-box;
}

@media (min-width: 897px) {
  .c-card-customer .image {
    height: 220px;
  }
}

@media (min-width: 897px) {
  .c-card-customer .image > * {
    height: 220px;
    border-radius: 3px;
  }
}

.c-card-customer .image img {
  width: 100%;
}

.c-card-customer .image::before {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1.3rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
  padding: 6px 21px 5px;
}

@media (max-width: 896px) {
  .c-card-customer .image::before {
    padding: 5px 17px 4px;
  }
}

.c-card-customer .image::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background-image: url(/assets/images/common/ico_arrow_right_01.png);
  background-size: 7px 12px;
  background-repeat: no-repeat;
  background-color: #45b49b;
  background-position: center;
}

.c-card-customer .box-detail {
  position: relative;
  margin-top: 22px;
}

@media (max-width: 896px) {
  .c-card-customer .box-detail {
    margin-top: 16px;
  }
}

.c-card-customer .box-detail > .name {
  font-size: 1.4rem;
  font-weight: 700;
}

@media (max-width: 896px) {
  .c-card-customer .box-detail > .name {
    font-size: 1.4rem;
  }
}

.c-card-customer .box-detail > .title {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.7;
  margin-top: 8px;
}

@media (max-width: 896px) {
  .c-card-customer .box-detail > .title {
    font-size: 1.6rem;
    line-height: 1.6;
    margin-top: 5px;
  }
}

.c-card-customer .box-detail > .text {
  font-size: 1.4rem;
  line-height: 2;
}

@media (max-width: 896px) {
  .c-card-customer .box-detail > .text {
    font-size: 1.2rem;
    line-height: 1.65;
    margin-top: 4px;
  }
}

/* c-card-know
---------------------------------------------------------- */
.c-card-know .image {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  clip-path: content-box;
}

@media (min-width: 897px) {
  .c-card-know .image {
    height: 220px;
  }
}

@media (min-width: 897px) {
  .c-card-know .image > * {
    height: 220px;
    border-radius: 5px;
  }
}

@media (max-width: 896px) {
  .c-card-know .image {
    border-radius: 2px;
  }
}

.c-card-know .image img {
  width: 100%;
}

.c-card-know .image.-arrow::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background-image: url(/assets/images/common/ico_arrow_right_01.png);
  background-size: 7px 12px;
  background-repeat: no-repeat;
  background-color: #45b49b;
  background-position: center;
}

@media (max-width: 896px) {
  .c-card-know .image.-arrow::after {
    width: 37px;
    height: 37px;
  }
}

.c-card-know .box-detail {
  position: relative;
  margin-top: 14px;
}

@media (max-width: 896px) {
  .c-card-know .box-detail {
    margin-top: 10px;
  }
}

.c-card-know .box-detail .box-category {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.c-card-know .box-detail .box-category > .date {
  font-size: 1.4rem;
  color: #45b49b;
}

@media (max-width: 896px) {
  .c-card-know .box-detail .box-category > .date {
    font-size: 1.2rem;
  }
}

.c-card-know .box-detail .category {
  display: inline-block;
  min-width: 120px;
  font-size: 1.2rem;
  text-align: center;
  color: #fff;
  background-color: #000;
  border-radius: 3px;
  padding: 4px 13px 2px;
}

@media (max-width: 896px) {
  .c-card-know .box-detail .category {
    font-size: 1.2rem;
    min-width: 130px;
    padding: 4px 13px 4px;
  }
}

.c-card-know .box-detail > .title {
  font-weight: 700;
  line-height: 1.7;
  margin-top: 15px;
}

@media (max-width: 896px) {
  .c-card-know .box-detail > .title {
    font-size: 1.4rem;
    line-height: 1.6;
    margin-top: 7px;
  }
}

@media (max-width: 896px) {
  .c-card-know .box-detail > .title.-md {
    font-size: 1.6rem;
    line-height: 1.6;
    margin-top: 14px;
  }
}

.c-card-know .box-detail > .date {
  font-size: 1.4rem;
  color: #282828;
  margin-top: 3px;
}

.c-card-know .box-detail > .text {
  font-size: 1.4rem;
  line-height: 2;
  margin-top: 2px;
}

@media (max-width: 896px) {
  .c-card-know .box-detail > .text {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-top: 5px;
  }
}

.c-card-know .box-detail > .link-detail {
  font-size: 1.4rem;
  text-align: right;
  margin-top: 3px;
}

@media (max-width: 896px) {
  .c-card-know .box-detail > .link-detail.-sm {
    font-size: 1.2rem;
    margin-top: 0;
  }
}

.c-card-know.-top .image {
  border-radius: 3px;
  clip-path: content-box;
}

@media (min-width: 897px) {
  .c-card-know.-top .image {
    height: 165px;
  }
}

@media (min-width: 897px) {
  .c-card-know.-top .image > * {
    height: 165px;
    border-radius: 3px;
  }
}

.c-card-know.-top .box-detail {
  margin-top: 11px;
}

@media (max-width: 896px) {
  .c-card-know.-top .box-detail {
    margin-top: 10px;
  }
}

/* c-card-voice
---------------------------------------------------------- */
.c-card-voice .image {
  border-radius: 30px;
  overflow: hidden;
  clip-path: content-box;
}

@media (min-width: 897px) {
  .c-card-voice .image {
    height: 255px;
  }
}

@media (min-width: 897px) {
  .c-card-voice .image > * {
    height: 255px;
    border-radius: 30px;
  }
}

@media (max-width: 896px) {
  .c-card-voice .image {
    border-radius: 14px;
  }
}

.c-card-voice .image img {
  width: 100%;
}

.c-card-voice .box-detail {
  position: relative;
  width: calc(100% - 20px);
  margin: 0 auto;
  padding: 32px 30px 17px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 8px 8px 0px rgba(142, 142, 142, 0.2);
  margin-top: -32px;
  z-index: 2;
}

@media (max-width: 896px) {
  .c-card-voice .box-detail {
    padding: 28px 26px 18px;
  }
}

.c-card-voice .box-detail > .text-desc-en {
  font-size: 1.2rem;
  font-weight: 700;
  color: #45b49b;
}

.c-card-voice .box-detail > .text-desc {
  font-size: 1.4rem;
  font-weight: 700;
  color: #282828;
  margin-top: 4px;
}

@media (max-width: 896px) {
  .c-card-voice .box-detail > .text-desc {
    font-size: 1.2rem;
  }
}

.c-card-voice .box-detail > .title {
  font-size: 1.8rem;
  font-weight: 700;
  color: #282828;
  margin-top: 15px;
  letter-spacing: -.05rem;
}

@media (max-width: 896px) {
  .c-card-voice .box-detail > .title {
    font-size: 1.6rem;
    margin-top: 10px;
  }
}

.c-card-voice .list-voice {
  margin-top: 7px;
}

@media (max-width: 896px) {
  .c-card-voice .list-voice {
    margin-top: 12px;
  }
}

.c-card-voice .list-voice > .item {
  display: flex;
}

.c-card-voice .list-voice > .item:not(:first-child) {
  margin-top: 4px;
}

@media (max-width: 896px) {
  .c-card-voice .list-voice > .item:not(:first-child) {
    margin-top: 1px;
  }
}

.c-card-voice .list-voice > .item > * {
  font-size: 1.2rem;
  color: #6f6f6f;
}

.c-card-voice .list-voice > .item > .title {
  position: relative;
  width: 50px;
  flex: 1 0 auto;
}

.c-card-voice .list-voice > .item > .title::after {
  position: absolute;
  right: 0;
  top: 0;
  content: '：';
  display: inline-block;
}

.c-card-voice .list-voice > .item > .text {
  width: 100%;
}

/* c-card-seminar
---------------------------------------------------------- */
.c-card-seminar .image {
  position: relative;
  border-radius: 3px;
  overflow: hidden;
  clip-path: content-box;
}

@media (min-width: 897px) {
  .c-card-seminar .image {
    height: 220px;
  }
}

@media (min-width: 897px) {
  .c-card-seminar .image > * {
    height: 220px;
    border-radius: 3px;
  }
}

.c-card-seminar .image img {
  width: 100%;
}

.c-card-seminar .image::before {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1.3rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
  padding: 6px 21px 5px;
  z-index: 2;
}

@media (max-width: 896px) {
  .c-card-seminar .image::before {
    padding: 5px 17px 4px;
  }
}

.c-card-seminar .image::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background-image: url(/assets/images/common/ico_arrow_right_01.png);
  background-size: 7px 12px;
  background-repeat: no-repeat;
  background-color: #45b49b;
  background-position: center;
  z-index: 2;
}

.c-card-seminar .image.-accepting::before {
  content: '受付中';
  background-color: #eb6100;
}

.c-card-seminar .image.-accep-end::before {
  content: '終了';
  background-color: #707070;
}

.c-card-seminar .box-detail {
  position: relative;
  margin-top: 20px;
}

@media (max-width: 896px) {
  .c-card-seminar .box-detail {
    margin-top: 16px;
  }
}

.c-card-seminar .box-detail > .date {
  font-size: 1.4rem;
  color: #45b49b;
}

@media (max-width: 896px) {
  .c-card-seminar .box-detail > .date {
    font-size: 1.2rem;
  }
}

.c-card-seminar .box-detail > .place {
  font-size: 1.4rem;
  color: #707070;
  margin-top: 5px;
}

@media (max-width: 896px) {
  .c-card-seminar .box-detail > .place {
    font-size: 1.2rem;
  }
}

.c-card-seminar .box-detail > .title {
  font-weight: 700;
  line-height: 1.7;
  margin-top: 4px;
}

@media (max-width: 896px) {
  .c-card-seminar .box-detail > .title {
    font-size: 1.6rem;
    line-height: 1.6;
    margin-top: 5px;
  }
}

.c-card-seminar .box-detail > .box-text {
  font-size: 1.3rem;
  color: #45b49b;
  text-align: center;
  border: 1px solid #45b49b;
  border-radius: 3px;
  padding: 8px 10px 5px;
  margin-top: 25px;
}

@media (max-width: 896px) {
  .c-card-seminar .box-detail > .box-text {
    font-size: 1.2rem;
    padding: 6px 10px 6px;
    margin-top: 13px;
  }
}

.c-card-seminar .box-detail > .box-text.-grey {
  color: #000;
  border: 1px solid #969696;
}

.c-card-seminar .box-detail > .link-detail {
  font-size: 1.4rem;
  text-align: right;
  margin-top: 7px;
}

@media (max-width: 896px) {
  .c-card-seminar .box-detail > .link-detail {
    font-size: 1.2rem;
    margin-top: 2px;
  }
}

.c-card-seminar .list-recommend {
  margin-top: 15px;
  margin-left: 6px;
}

@media (max-width: 896px) {
  .c-card-seminar .list-recommend {
    margin-top: 10px;
    margin-left: 7px;
  }
}

.c-card-seminar .list-recommend > .item {
  position: relative;
  display: flex;
  font-size: 1.4rem;
  padding-left: 20px;
  color: #505050;
}

@media (max-width: 896px) {
  .c-card-seminar .list-recommend > .item {
    font-size: 1.2rem;
    padding-left: 12px;
  }
}

.c-card-seminar .list-recommend > .item::before {
  content: '';
  position: absolute;
  top: 7px;
  left: 7px;
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background-color: #000;
}

@media (max-width: 896px) {
  .c-card-seminar .list-recommend > .item::before {
    width: 5px;
    height: 5px;
    top: 6px;
    left: 0;
  }
}

.c-card-seminar .list-recommend > .item:not(:first-child) {
  margin-top: 5px;
}

@media (max-width: 896px) {
  .c-card-seminar .list-recommend > .item:not(:first-child) {
    margin-top: 1px;
  }
}

/* c-card-seminar-timeline
---------------------------------------------------------- */
.c-card-seminar-timeline {
  display: flex;
}

.c-card-seminar-timeline > .box-detail {
  padding-right: 36px;
}

@media (min-width: 897px) {
  .c-card-seminar-timeline > .box-detail {
    flex: 1 0 auto;
    width: 680px;
  }
}

@media (max-width: 896px) {
  .c-card-seminar-timeline > .box-detail {
    width: 100%;
    padding-right: 20px;
  }
}

.c-card-seminar-timeline > .box-detail .title {
  line-height: 1.9;
}

.c-card-seminar-timeline > .box-detail .detail {
  font-size: 1.4rem;
  line-height: 2;
  margin-top: 6px;
}

@media (max-width: 896px) {
  .c-card-seminar-timeline > .box-detail .detail {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-top: 10px;
  }
}

.c-card-seminar-timeline > .box-detail .speaker {
  font-size: 1.4rem;
  line-height: 2;
  margin-top: 3px;
}

@media (max-width: 896px) {
  .c-card-seminar-timeline > .box-detail .speaker {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-top: 20px;
  }
}

.c-card-seminar-timeline > .image {
  width: 100%;
  margin-top: 3px;
  clip-path: content-box;
}

@media (max-width: 896px) {
  .c-card-seminar-timeline > .image {
    flex: 1 0 auto;
    width: 85px;
  }
}

/* c-card-seminar-report
---------------------------------------------------------- */
.c-card-seminar-report {
  display: flex;
}

@media (max-width: 896px) {
  .c-card-seminar-report {
    display: block;
  }
}

.c-card-seminar-report .image {
  flex: 0 0 auto;
  overflow: hidden;
  width: 370px;
  position: relative;
  margin-right: 42px;
  clip-path: content-box;
}

@media (min-width: 897px) {
  .c-card-seminar-report .image {
    height: 220px;
  }
}

@media (min-width: 897px) {
  .c-card-seminar-report .image > * {
    height: 220px;
  }
}

@media (max-width: 896px) {
  .c-card-seminar-report .image {
    width: 100%;
    margin-right: 0;
  }
}

.c-card-seminar-report .image img {
  width: 100%;
}

.c-card-seminar-report .image::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background-image: url(/assets/images/common/ico_arrow_right_01.png);
  background-size: 7px 12px;
  background-repeat: no-repeat;
  background-color: #45b49b;
  background-position: center;
}

.c-card-seminar-report .box-detail {
  position: relative;
  margin-top: 3px;
}

@media (max-width: 896px) {
  .c-card-seminar-report .box-detail {
    margin-top: 15px;
  }
}

.c-card-seminar-report .box-detail > .date {
  font-size: 1.4rem;
  color: #45b49b;
}

@media (max-width: 896px) {
  .c-card-seminar-report .box-detail > .date {
    font-size: 1.2rem;
  }
}

.c-card-seminar-report .box-detail > .title {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.7;
  margin-top: 4px;
}

@media (max-width: 896px) {
  .c-card-seminar-report .box-detail > .title {
    font-size: 1.6rem;
    line-height: 1.6;
    margin-top: 5px;
  }
}

.c-card-seminar-report .box-detail > .detail {
  font-size: 1.4rem;
  color: #000;
  margin-top: 10px;
}

@media (max-width: 896px) {
  .c-card-seminar-report .box-detail > .detail {
    font-size: 1.2rem;
    margin-top: 3px;
  }
}

.c-card-seminar-report .box-detail > .link-detail {
  font-size: 1.4rem;
  text-align: right;
  margin-top: 7px;
}

@media (max-width: 896px) {
  .c-card-seminar-report .box-detail > .link-detail {
    font-size: 1.2rem;
    margin-top: 2px;
  }
}

/* c-card-feature
---------------------------------------------------------- */
.c-card-feature {
  position: relative;
  height: 100%;
  padding: 91px 30px 30px;
  background-color: #e3f4f0;
  border-radius: 10px;
}

@media (max-width: 896px) {
  .c-card-feature {
    padding: 70px 24px 23px 26px;
    border-radius: 5px;
  }
}

.c-card-feature .number {
  position: absolute;
  width: 100%;
  text-align: center;
  top: -33px;
  left: 0;
}

@media (max-width: 896px) {
  .c-card-feature .number {
    top: -24px;
  }
}

@media (max-width: 896px) {
  .c-card-feature .number img {
    height: 71px;
  }
}

.c-card-feature .title {
  font-size: 2.6rem;
  font-weight: 700;
}

@media (max-width: 896px) {
  .c-card-feature .title {
    font-size: 2.2rem;
  }
}

.c-card-feature .text {
  line-height: 2;
  margin-top: 9px;
}

.c-card-feature > .icon {
  display: block;
  text-align: center;
  margin-top: 20px;
}

@media (max-width: 896px) {
  .c-card-feature > .icon {
    margin-top: 34px;
  }
}

@media (max-width: 896px) {
  .c-card-feature > .icon.-icon-01 img {
    height: 60px;
  }
}

@media (max-width: 896px) {
  .c-card-feature > .icon.-icon-02 img {
    height: 63px;
  }
}

@media (max-width: 896px) {
  .c-card-feature > .icon.-icon-03 img {
    height: 68px;
  }
}

/* c-card-service-menu
---------------------------------------------------------- */
@media (min-width: 897px) {
  .c-card-service-menu {
    display: flex;
    align-items: flex-start;
  }
}

@media (min-width: 897px) {
  .c-card-service-menu > .image {
    width: 785px;
    flex: 1 0 auto;
    border-radius: 10px;
    clip-path: content-box;
  }
}

@media (max-width: 896px) {
  .c-card-service-menu > .image img {
    width: 76%;
    border-radius: 5px;
  }
}

.c-card-service-menu > .detail {
  position: relative;
  margin-top: 60px;
}

@media (max-width: 896px) {
  .c-card-service-menu > .detail {
    display: flex;
    z-index: 1;
    margin-top: -23px;
  }
}

@media (min-width: 897px) {
  .c-card-service-menu > .detail {
    width: 100%;
    z-index: 1;
    margin-top: 253px;
  }
}

.c-card-service-menu > .detail > .inner {
  position: relative;
  background-color: #f0f0f0;
  padding: 87px 24px 42px 46px;
  border-radius: 10px;
}

@media (max-width: 896px) {
  .c-card-service-menu > .detail > .inner {
    width: 87%;
    padding: 67px 20px 25px 20px;
    border-radius: 5px;
  }
}

.c-card-service-menu > .detail .number {
  position: absolute;
  top: -33px;
  left: 45px;
}

@media (max-width: 896px) {
  .c-card-service-menu > .detail .number {
    top: -24px;
    left: 24px;
  }
  .c-card-service-menu > .detail .number img {
    height: 71px;
  }
}

.c-card-service-menu > .detail .c-heading-01 {
  letter-spacing: 0;
}

@media (min-width: 897px) {
  .c-card-service-menu > .detail .c-heading-01 {
    font-size: 2.8rem;
    margin-bottom: 13px;
  }
}

@media (max-width: 896px) {
  .c-card-service-menu > .detail .c-heading-01 {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.6;
    margin-bottom: 13px;
  }
}

.c-card-service-menu > .detail .text {
  line-height: 2;
  color: #505050;
}

@media (max-width: 896px) {
  .c-card-service-menu > .detail .text {
    font-size: 1.4rem;
    line-height: 2;
  }
}

.c-card-service-menu > .detail .c-btn {
  margin-top: 15px;
  padding: 16px 12px 14px 19px;
}

@media (max-width: 896px) {
  .c-card-service-menu > .detail .c-btn {
    margin-top: 12px;
    padding: 13px 12px 11px 19px;
  }
}

/* c-cms-wysiwyg
---------------------------------------------------------- */
.c-cms-wysiwyg *:first-child {
  margin-top: 0;
}

.c-cms-wysiwyg *:first-child + h3 {
  margin-top: 63px;
}

@media (max-width: 896px) {
  .c-cms-wysiwyg *:first-child + h3 {
    margin-top: 35px;
  }
}

.c-cms-wysiwyg p {
  line-height: 2.4;
}

@media (max-width: 896px) {
  .c-cms-wysiwyg p {
    line-height: 2;
  }
}

.c-cms-wysiwyg p .sm {
  color: #000;
}

@media (max-width: 896px) {
  .c-cms-wysiwyg p .sm {
    font-size: 1.2rem;
  }
}

.c-cms-wysiwyg span {
  color: #45b49b;
}

.c-cms-wysiwyg a {
  color: #0000ff;
}

@media (min-width: 897px) {
  .c-cms-wysiwyg a {
    opacity: 1;
    transition: opacity .3s ease-out;
  }
  .c-cms-wysiwyg a:hover {
    opacity: 0.6;
    text-decoration: none;
  }
}

@media (min-width: 897px) {
  .c-cms-wysiwyg a:hover {
    text-decoration: underline;
  }
}

.c-cms-wysiwyg h3 {
  font-size: 2.4rem;
  font-weight: 700;
}

@media (max-width: 896px) {
  .c-cms-wysiwyg h3 {
    font-size: 1.6rem;
  }
}

.c-cms-wysiwyg h4 {
  font-size: 1.6rem;
  font-weight: 600;
}

@media (max-width: 896px) {
  .c-cms-wysiwyg h4 {
    font-size: 1.4rem;
  }
}

.c-cms-wysiwyg ol {
  counter-reset: li;
}

.c-cms-wysiwyg ol > li {
  display: table;
  counter-increment: li;
  line-height: 2;
}

@media (max-width: 896px) {
  .c-cms-wysiwyg ol > li {
    line-height: 1.7;
  }
}

.c-cms-wysiwyg ol > li:not(:first-child) {
  margin-top: 14px;
}

@media (max-width: 896px) {
  .c-cms-wysiwyg ol > li:not(:first-child) {
    margin-top: 18px;
  }
}

.c-cms-wysiwyg ol > li::before {
  display: table-cell;
  content: counter(li) ".";
  padding-right: 2px;
}

@media (min-width: 897px) {
  .c-cms-wysiwyg ol > li > .c-list-number-sub {
    margin-top: 2px;
  }
}

.c-cms-wysiwyg ul > li {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding-left: 18px;
}

.c-cms-wysiwyg ul > li:not(:first-child) {
  margin-top: 14px;
}

@media (max-width: 896px) {
  .c-cms-wysiwyg ul > li:not(:first-child) {
    margin-top: 18px;
  }
}

.c-cms-wysiwyg ul > li::before {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  content: '●';
  margin-right: 2px;
  width: 16px;
}

.c-cms-wysiwyg hr {
  border: none;
  width: 100%;
  height: 1px;
  background-color: #979797;
  margin: 28px 0 18px;
}

@media (max-width: 896px) {
  .c-cms-wysiwyg hr {
    margin: 18px 0;
  }
}

.c-cms-wysiwyg img {
  margin-top: 30px;
}

@media (max-width: 896px) {
  .c-cms-wysiwyg img {
    margin-top: 22px;
  }
}

.c-cms-wysiwyg * + h3 {
  margin-top: 84px;
}

@media (max-width: 896px) {
  .c-cms-wysiwyg * + h3 {
    margin-top: 62px;
  }
}

.c-cms-wysiwyg * + h4 {
  margin-top: 45px;
}

@media (max-width: 896px) {
  .c-cms-wysiwyg * + h4 {
    margin-top: 29px;
  }
}

.c-cms-wysiwyg * + ol {
  margin-top: 4px;
}

@media (max-width: 896px) {
  .c-cms-wysiwyg * + ol {
    margin-top: 10px;
  }
}

.c-cms-wysiwyg * + ul {
  margin-top: 4px;
}

@media (max-width: 896px) {
  .c-cms-wysiwyg * + ul {
    margin-top: 10px;
  }
}

@media (max-width: 896px) {
  .c-cms-wysiwyg ul + ol,
  .c-cms-wysiwyg ol + ul {
    margin-top: 0;
  }
}

.c-cms-wysiwyg h3 + p {
  margin-top: 6px;
}

@media (max-width: 896px) {
  .c-cms-wysiwyg h3 + p {
    margin-top: 16px;
  }
}

@media (max-width: 896px) {
  .c-cms-wysiwyg h4 + p {
    margin-top: 4px;
  }
}

.c-cms-wysiwyg p + h3 {
  margin-top: 84px;
}

@media (max-width: 896px) {
  .c-cms-wysiwyg p + h3 {
    margin-top: 65px;
  }
}

.c-cms-wysiwyg * + p {
  margin-top: 8px;
}

@media (max-width: 896px) {
  .c-cms-wysiwyg * + p {
    margin-top: 15px;
  }
}

.c-cms-wysiwyg p + p {
  margin-top: 38px;
}

@media (max-width: 896px) {
  .c-cms-wysiwyg p + p {
    margin-top: 28px;
  }
}

/* c-box-contact
---------------------------------------------------------- */
@media (min-width: 897px) {
  .c-box-contact {
    display: flex;
    background: url(/assets/images/common/bg_footer_pc.png) no-repeat;
    background-size: cover;
    padding: 68px 0 48px;
  }
}

@media (max-width: 896px) {
  .c-box-contact {
    background: url(/assets/images/common/bg_footer_sp.png) no-repeat;
    background-size: cover;
    padding: 43px 24px 33px;
    border-radius: 15px;
  }
}

.c-box-contact > .col {
  text-align: center;
}

@media (min-width: 897px) {
  .c-box-contact > .col {
    width: 50%;
    padding: 22px 0;
  }
}

@media (min-width: 897px) {
  .c-box-contact > .col:first-child {
    border-right: 1px solid #b0aeab;
  }
}

.c-box-contact .title {
  font-size: 2rem;
  color: #fff;
}

@media (max-width: 896px) {
  .c-box-contact .title {
    font-size: 1.6rem;
  }
}

.c-box-contact .box-info {
  margin-right: 23px;
}

@media (max-width: 896px) {
  .c-box-contact .box-info {
    margin-top: 42px;
    margin-right: 0;
  }
}

.c-box-contact .box-info > .tel {
  display: inline-block;
  font-size: 4rem;
  letter-spacing: .1rem;
  line-height: 1.25;
  color: #fff;
  margin-top: 20px;
}

@media (min-width: 897px) {
  .c-box-contact .box-info > .tel {
    pointer-events: none;
  }
}

@media (max-width: 896px) {
  .c-box-contact .box-info > .tel {
    font-size: 3rem;
    margin-top: 5px;
  }
}

.c-box-contact .box-info > .time {
  font-size: 1.6rem;
  color: #fff;
}

@media (max-width: 896px) {
  .c-box-contact .box-info > .time {
    font-size: 1.2rem;
  }
}

.c-box-contact .c-list-button {
  width: 305px;
  margin: 17px auto 0;
  padding-left: 5px;
}

@media (max-width: 896px) {
  .c-box-contact .c-list-button {
    width: 100%;
    padding-left: 0;
    margin-top: 14px;
  }
}

.c-box-contact .c-list-button .c-btn span {
  transition: .25s;
}

@media (min-width: 897px) {
  .c-box-contact .c-list-button .c-btn:hover span {
    color: #fff;
  }
}

/* c-box-branch
---------------------------------------------------------- */
.c-box-branch {
  padding: 105px 0 57px;
}

@media (max-width: 896px) {
  .c-box-branch {
    padding: 45px 0 29px;
  }
}

.c-box-branch .c-heading-line {
  font-weight: 600;
}

@media (min-width: 897px) {
  .c-box-branch .c-list-branch {
    display: flex;
    flex-wrap: wrap;
  }
}

.c-box-branch .c-list-branch > .item {
  margin-top: 45px;
}

@media (min-width: 897px) {
  .c-box-branch .c-list-branch > .item {
    width: 33.33%;
  }
}

@media (max-width: 896px) {
  .c-box-branch .c-list-branch > .item {
    margin-top: 17px;
  }
}

@media (max-width: 896px) {
  .c-box-branch .c-list-branch > .item:not(:first-child) {
    margin-top: 15px;
  }
}

.c-box-branch .detail {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.75;
  color: #7f7f7f;
  margin-top: 4px;
}

@media (max-width: 896px) {
  .c-box-branch .detail {
    font-size: 1.2rem;
    margin-top: 0;
  }
}

.c-box-branch .detail a {
  color: #7f7f7f;
}

/* c-footer
---------------------------------------------------------- */
.c-footer {
  position: relative;
  width: 100%;
  background-color: #fff;
}

@media (min-width: 897px) {
  .c-footer {
    min-width: 1400px;
  }
}

.c-footer-in {
  margin: 0 auto;
  padding-left: 100px;
  padding-right: 100px;
}

@media (min-width: 897px) {
  .c-footer-in {
    width: 1400px;
    min-width: 1400px;
  }
}

@media (max-width: 896px) {
  .c-footer-in {
    padding: 0 15px;
  }
}

.c-footer .pagetop {
  opacity: 0;
  position: fixed;
  bottom: 33px;
  right: 40px;
  z-index: 5;
  backface-visibility: hidden;
  visibility: hidden;
  transition: .3s ease-out;
  background-color: #939393;
  border-radius: 50px;
  overflow: hidden;
}

@media (max-width: 896px) {
  .c-footer .pagetop {
    bottom: 20px;
    right: 20px;
  }
}

.c-footer .pagetop.is-active {
  display: block;
  visibility: visible;
  opacity: 1;
}

.c-footer .pagetop.stop {
  top: -40px;
  right: 40px;
  bottom: auto;
  position: absolute;
  margin: 0;
}

@media (max-width: 896px) {
  .c-footer .pagetop.stop {
    top: -77px;
    right: 20px;
  }
}

.c-footer .pagetop .link {
  position: relative;
  display: block;
  width: 80px;
  height: 80px;
  text-align: center;
}

@media (min-width: 897px) {
  .c-footer .pagetop .link {
    opacity: 1;
    transition: opacity .3s ease-out;
  }
  .c-footer .pagetop .link:hover {
    opacity: 0.6;
    text-decoration: none;
  }
}

@media (max-width: 896px) {
  .c-footer .pagetop .link {
    width: 40px;
    height: 40px;
  }
}

.c-footer .pagetop .link::before {
  content: '';
  position: absolute;
  display: block;
  content: '';
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 32px;
  height: 16px;
  background-image: url(/assets/images/common/ico_pagetop.png);
  background-repeat: no-repeat;
  background-size: 32px 16px;
}

@media (max-width: 896px) {
  .c-footer .pagetop .link::before {
    width: 16px;
    height: 8px;
    top: 47%;
    background-size: 16px 8px;
  }
}

@media (min-width: 897px) {
  .c-footer .footer-content {
    padding: 48px 0 17px;
    background-color: #575757;
  }
}

@media (min-width: 897px) {
  .c-footer .footer-content .c-footer-in {
    display: flex;
  }
  .c-footer .footer-content .c-footer-in > * {
    width: 50%;
  }
}

@media (max-width: 896px) {
  .c-footer .footer-content .c-footer-in {
    padding: 0;
  }
}

.c-footer .footer-detail {
  color: #fff;
}

@media (max-width: 896px) {
  .c-footer .footer-detail {
    padding: 34px 15px 28px;
    background-color: #575757;
  }
}

.c-footer .footer-detail > .logo {
  width: 245px;
  flex: 1 0 auto;
  margin-top: 5px;
}

@media (max-width: 896px) {
  .c-footer .footer-detail > .logo {
    width: 184px;
    margin-top: 2px;
  }
}

.c-footer .footer-detail > .logo > a {
  display: block;
}

.c-footer .footer-detail > .logo .text-logo {
  font-size: 1.5rem;
  letter-spacing: .1rem;
  color: #fff;
  margin-top: 9px;
}

@media (max-width: 896px) {
  .c-footer .footer-detail > .logo .text-logo {
    font-size: 1.1rem;
    margin-top: 0;
  }
}

.c-footer .footer-detail > .detail {
  margin-top: 20px;
}

@media (max-width: 896px) {
  .c-footer .footer-detail > .detail {
    margin-top: 19px;
  }
}

.c-footer .footer-detail > .detail .text {
  font-size: 1.4rem;
  line-height: 1.75;
  letter-spacing: .1rem;
  margin-bottom: 20px;
  opacity: .6;
}

@media (max-width: 896px) {
  .c-footer .footer-detail > .detail .text {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 0;
  }
}

.c-footer .footer-detail > .detail a {
  color: #fff;
}

.c-footer .footer-nav {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}

@media (max-width: 896px) {
  .c-footer .footer-nav {
    background-color: #45b49b;
    margin: 0;
  }
}

.c-footer .footer-nav .list-link {
  width: 50%;
  padding: 0 10px;
}

@media (max-width: 896px) {
  .c-footer .footer-nav .list-link {
    padding: 0;
  }
}

@media (max-width: 896px) {
  .c-footer .footer-nav .list-link:nth-child(odd) {
    border-left: 1px solid rgba(255, 255, 255, 0.5);
  }
  .c-footer .footer-nav .list-link:nth-child(odd) .link-footer {
    padding: 14px 15px 15px 20px;
  }
}

@media (max-width: 896px) {
  .c-footer .footer-nav .list-link:nth-child(even) {
    border-left: 1px solid rgba(255, 255, 255, 0.5);
  }
  .c-footer .footer-nav .list-link:nth-child(even) .item:last-child {
    border-bottom: none;
  }
  .c-footer .footer-nav .list-link:nth-child(even) .link-footer {
    padding: 14px 15px 15px;
  }
}

.c-footer .footer-nav .list-link.-full {
  width: 100%;
  margin-bottom: -17px;
}

@media (max-width: 896px) {
  .c-footer .footer-nav .list-link.-full {
    margin-bottom: 0;
  }
  .c-footer .footer-nav .list-link.-full .link-footer {
    padding: 14px 15px 15px;
  }
}

.c-footer .footer-nav .list-link > .item {
  width: 100%;
  margin-top: 3px;
}

@media (max-width: 896px) {
  .c-footer .footer-nav .list-link > .item {
    margin-top: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }
}

@media (max-width: 896px) {
  .c-footer .footer-nav .link-footer {
    display: block;
    padding-right: 0;
  }
}

@media (max-width: 896px) {
  .c-footer .footer-nav .link-footer > span {
    font-size: 1.2rem;
    letter-spacing: 0;
  }
}

.c-footer .link-footer {
  display: inline-block;
  padding-right: 17px;
}

.c-footer .link-footer span {
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: .2rem;
  color: #fff;
}

@media (max-width: 896px) {
  .c-footer .link-footer span {
    font-size: 1.4rem;
  }
}

.c-footer .link-footer span span:hover {
  text-decoration: underline;
}

.c-footer .link-footer[target="_blank"]::after {
  content: '';
  position: relative;
  display: inline-block;
  content: '';
  top: 3px;
  width: 13px;
  height: 16px;
  background-image: url(/assets/images/common/ico_note_white.png);
  background-repeat: no-repeat;
  background-size: 13px 16px;
  margin-left: 8px;
}

@media (min-width: 897px) {
  .c-footer .link-footer[target="_blank"]::after {
    background-image: url(/assets/images/common/ico_note_white_pc.png);
  }
}

@media (max-width: 896px) {
  .c-footer .link-footer[target="_blank"]::after {
    width: 10px;
    height: 12px;
    background-size: 10px 12px;
  }
}

.c-footer .footer-copy {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #3a3a3a;
  padding: 42px 0 62px;
}

@media (max-width: 896px) {
  .c-footer .footer-copy {
    flex-direction: column;
    background-color: #fff;
    padding: 40px 0 12px;
  }
}

.c-footer .footer-copy .c-footer-in {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.c-footer .footer-copy .logo {
  width: 174px;
  line-height: 1;
}

@media (max-width: 896px) {
  .c-footer .footer-copy .logo {
    width: 166px;
  }
}

.c-footer .footer-copy .logo img {
  width: 100%;
}

.c-footer .footer-copy .list-link {
  display: flex;
  margin-left: 30px;
}

@media (max-width: 896px) {
  .c-footer .footer-copy .list-link {
    margin-top: 19px;
    margin-left: 0;
  }
}

.c-footer .footer-copy .list-link > .item {
  position: relative;
  line-height: 1;
}

.c-footer .footer-copy .list-link > .item::before {
  content: '';
  display: block;
  position: absolute;
  top: 2px;
  left: 0;
  width: 1px;
  height: calc(100% - 6px);
  background-color: #cfcfcf;
}

@media (max-width: 896px) {
  .c-footer .footer-copy .list-link > .item::before {
    height: calc(100% - 8px);
  }
}

@media (max-width: 896px) {
  .c-footer .footer-copy .list-link > .item {
    line-height: 1.3;
  }
}

@media (min-width: 897px) {
  .c-footer .footer-copy .list-link > .item .link-footer > span:hover {
    text-decoration: underline;
  }
}

@media (max-width: 896px) {
  .c-footer .footer-copy .list-link > .item .link-footer > span {
    color: #6d6d6d;
  }
}

.c-footer .footer-copy .list-link > .item:last-child {
  position: relative;
}

@media (max-width: 896px) {
  .c-footer .footer-copy .list-link > .item:last-child::after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    right: 0;
    width: 1px;
    height: calc(100% - 8px);
    background-color: #cfcfcf;
  }
}

.c-footer .footer-copy .list-link > .item .link-footer {
  color: #3a3a3a;
  background: none;
  padding-left: 21px;
  padding-right: 21px;
}

@media (max-width: 896px) {
  .c-footer .footer-copy .list-link > .item .link-footer {
    padding-right: 8px;
    padding-left: 9px;
    position: relative;
    top: -2px;
  }
}

@media (max-width: 896px) {
  .c-footer .footer-copy .list-link > .item .link-footer span {
    font-size: 1.2rem;
    letter-spacing: 0;
  }
}

.c-footer .footer-copy .list-link > .item .link-footer[target="_blank"]::after {
  display: none;
}

.c-footer .footer-copy .copy {
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
  opacity: .6;
  letter-spacing: .15rem;
  margin-top: 16px;
}

@media (max-width: 896px) {
  .c-footer .footer-copy .copy {
    font-size: 1.0rem;
    letter-spacing: 0;
    color: #949494;
    margin-top: 7px;
  }
}

/* form
---------------------------------------------------------- */
@media (max-width: 896px) {
  .c-checkbox {
    font-size: 1.2rem;
  }
}

.c-checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.c-checkbox input:checked + label::before {
  border: 2px solid #5d5d5d;
  background-color: #5d5d5d;
}

.c-checkbox input:checked + label::after {
  content: '';
  display: block;
  position: absolute;
  top: 6px;
  left: 6px;
  width: 3px;
  height: 8px;
  border: solid #fff;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

@media (max-width: 896px) {
  .c-checkbox input:checked + label::after {
    top: 4px;
    left: 6px;
  }
}

.c-checkbox label {
  position: relative;
  cursor: pointer;
  display: inline-block;
}

.c-checkbox label::before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #707070;
  border-radius: 2px;
  padding: 6px;
  display: inline-block;
  position: relative;
  top: 2px;
  cursor: pointer;
  margin-right: 10px;
}

@media (max-width: 896px) {
  .c-checkbox label::before {
    margin-right: 5px;
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .c-checkbox input:checked + label::after {
      top: 5px;
    }
    @media (max-width: 896px) {
      .c-checkbox input:checked + label::after {
        top: 4px;
      }
    }
  }
}

/* c-header
---------------------------------------------------------- */
.c-header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9;
}

@media (min-width: 897px) {
  .c-header {
    min-width: 1400px;
  }
}

.c-header .header-in {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  position: relative;
}

@media (min-width: 897px) {
  .c-header .header-in {
    width: 1200px;
    margin: 0 auto;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    overflow: hidden;
  }
  .c-header .header-in::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: .902;
  }
}

@media (max-width: 896px) {
  .c-header .header-in {
    height: 65px;
    background-color: #fff;
  }
}

.c-header .header-logo {
  position: relative;
  padding-left: 22px;
  padding-top: 24px;
}

@media (max-width: 896px) {
  .c-header .header-logo {
    padding-left: 15px;
    padding-top: 0;
  }
}

.c-header .header-logo > .inner {
  display: flex;
  align-items: center;
}

.c-header .header-logo .logo {
  width: 200px;
}

@media (max-width: 896px) {
  .c-header .header-logo .logo {
    width: 160px;
  }
}

.c-header .header-logo .text-logo {
  display: block;
  font-size: 1.3rem;
  font-weight: 700;
  color: #45b49b;
  letter-spacing: .1rem;
  margin-left: 33px;
}

@media (max-width: 896px) {
  .c-header .header-logo .text-logo {
    font-size: 1rem;
    line-height: 1;
    color: #282828;
    margin-left: 28px;
  }
}

.c-header .header-logo img {
  width: 100%;
}

@media (min-width: 897px) {
  .header-nav {
    display: flex;
    align-items: center;
    padding-right: 190px;
  }
}

@media (max-width: 896px) {
  .header-nav {
    position: fixed;
    display: block;
    width: 100%;
    height: 100% !important;
    top: 65px;
    left: 0;
    padding-bottom: 65px;
    cursor: default;
    opacity: 0;
    overflow-y: auto;
    visibility: hidden;
    transition: opacity .6s ease 0s;
    backface-visibility: hidden;
    background-color: #fff;
    border-top: 1px solid #e8e8e8;
    z-index: 9;
  }
  .header-nav.is-open {
    opacity: 1;
    visibility: visible;
    transition: opacity .6s ease 0s;
  }
}

.header-nav .c-nav-global {
  display: flex;
}

@media (max-width: 896px) {
  .header-nav .c-nav-global {
    flex-wrap: wrap;
    border-bottom: 1px solid #e8e8e8;
  }
}

@media (min-width: 897px) {
  .header-nav .c-nav-global .list-nav {
    display: flex;
    align-items: center;
  }
  .header-nav .c-nav-global .list-nav .item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 16px;
    height: 100%;
  }
  .header-nav .c-nav-global .list-nav .item .link {
    position: relative;
    display: block;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.2;
    color: #282828;
    padding: 42px 0;
    transition: opacity .3s ease-out;
  }
  .header-nav .c-nav-global .list-nav .item .link::before {
    content: '';
    position: absolute;
    left: -10px;
    bottom: 0;
    width: calc(100% + 20px);
    height: 3px;
    background-color: #45b49b;
    transition: opacity .3s ease-out;
    opacity: 0;
    visibility: hidden;
  }
  .header-nav .c-nav-global .list-nav .item .link:hover::before, .header-nav .c-nav-global .list-nav .item .link.is-active::before {
    opacity: 1;
    visibility: visible;
  }
}

@media (max-width: 896px) {
  .header-nav .c-nav-global .list-nav {
    width: 50%;
  }
}

@media (max-width: 896px) and (max-width: 896px) {
  .header-nav .c-nav-global .list-nav:nth-child(odd) {
    border-left: 1px solid #e8e8e8;
  }
  .header-nav .c-nav-global .list-nav:nth-child(odd) .link {
    padding: 14px 15px 13px;
  }
}

@media (max-width: 896px) and (max-width: 896px) {
  .header-nav .c-nav-global .list-nav:nth-child(even) .item:last-child {
    border-bottom: none;
  }
  .header-nav .c-nav-global .list-nav:nth-child(even) .link {
    padding: 14px 15px 13px;
  }
}

@media (max-width: 896px) {
  .header-nav .c-nav-global .list-nav.-full {
    width: 100%;
    margin-bottom: -17px;
  }
}

@media (max-width: 896px) and (max-width: 896px) {
  .header-nav .c-nav-global .list-nav.-full {
    margin-bottom: 0;
    border-left: none;
  }
  .header-nav .c-nav-global .list-nav.-full .link-footer {
    padding: 14px 15px 13px;
  }
}

@media (max-width: 896px) {
  .header-nav .c-nav-global .list-nav .item {
    width: 100%;
    margin-top: 3px;
  }
}

@media (max-width: 896px) and (max-width: 896px) {
  .header-nav .c-nav-global .list-nav .item {
    margin-top: 0;
    border-bottom: 1px solid #e8e8e8;
  }
}

@media (max-width: 896px) and (max-width: 896px) {
  .header-nav .c-nav-global .list-nav .link {
    display: block;
    padding-right: 0;
  }
}

@media (max-width: 896px) and (max-width: 896px) {
  .header-nav .c-nav-global .list-nav .link > span {
    font-size: 1.6rem;
    font-weight: 500;
    color: #707372;
  }
}

@media (max-width: 896px) {
  .header-nav .c-nav-global .list-nav .link[target="_blank"]::after {
    content: '';
    position: relative;
    display: inline-block;
    content: '';
    top: 2px;
    width: 13px;
    height: 16px;
    background-image: url(/assets/images/common/ico_note.png);
    background-repeat: no-repeat;
    background-size: 13px 16px;
    margin-left: 8px;
  }
}

@media (min-width: 897px) {
  .header-nav .btn-contact-wrap {
    display: flex;
    width: 190px;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
  }
}

@media (min-width: 897px) {
  .header-nav .btn-contact {
    display: flex;
    width: 50%;
    height: 100%;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: 700;
    color: #3a3a3a;
  }
}

@media (min-width: 897px) and (min-width: 897px) {
  .header-nav .btn-contact {
    opacity: 1;
    transition: opacity .3s ease-out;
  }
  .header-nav .btn-contact:hover {
    opacity: 0.6;
    text-decoration: none;
  }
}

@media (min-width: 897px) {
  .header-nav .btn-contact.-document {
    background-color: #f8d818;
    letter-spacing: -1px;
  }
  .header-nav .btn-contact.-document span {
    padding-top: 39px;
    margin-top: 17px;
    line-height: 1.2;
  }
  .header-nav .btn-contact.-document span::before {
    width: 26px;
    height: 31px;
    top: 3px;
    background-image: url(/assets/images/common/ico_note_gray.png);
    background-size: contain;
  }
  .header-nav .btn-contact.-contact {
    background-color: #45b49b;
  }
}

@media (min-width: 897px) and (min-width: 897px) {
  .header-nav .btn-contact.-contact {
    border-bottom-right-radius: 6px;
  }
}

@media (min-width: 897px) {
  .header-nav .btn-contact.-contact span {
    padding-top: 30px;
    margin-top: 21px;
  }
  .header-nav .btn-contact.-contact span::before {
    width: 29px;
    height: 21px;
    background-image: url(/assets/images/common/ico_mail.png);
    background-size: contain;
  }
  .header-nav .btn-contact span {
    position: relative;
    padding-top: 28px;
  }
  .header-nav .btn-contact span::before {
    position: absolute;
    content: '';
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-repeat: no-repeat;
  }
}

@media (max-width: 896px) {
  .header-nav .btn-contact {
    display: none;
  }
}

@media (min-width: 897px) {
  .header-nav .btn-menu-close {
    display: none;
  }
}

@media (max-width: 896px) {
  .header-nav .btn-menu-close {
    border-bottom: 1px solid #282828;
  }
}

.header-nav .btn-menu-close-wrap {
  display: flex;
  justify-content: flex-end;
}

@media (min-width: 897px) {
  .header-menu,
  .btn-menu-close {
    display: none;
  }
}

@media (max-width: 896px) {
  .header-menu,
  .btn-menu-close {
    position: relative;
    width: 35px;
    height: 35px;
    border-radius: 37px;
    margin-right: 13px;
    background-color: #282828;
    cursor: pointer;
  }
  .header-menu .hamburger,
  .btn-menu-close .hamburger {
    position: relative;
    top: 10px;
    width: 17px;
    height: 22px;
    left: 9px;
  }
  .header-menu .hamburger > span,
  .btn-menu-close .hamburger > span {
    display: block;
    position: absolute;
    left: 0;
    width: 17px;
    height: 2px;
    background-color: #fff;
    z-index: 1;
  }
  .header-menu .hamburger > span:nth-child(1),
  .btn-menu-close .hamburger > span:nth-child(1) {
    top: 0;
  }
  .header-menu .hamburger > span:nth-child(2),
  .btn-menu-close .hamburger > span:nth-child(2) {
    top: 6px;
  }
  .header-menu .hamburger > span:nth-child(3),
  .btn-menu-close .hamburger > span:nth-child(3) {
    top: 12px;
  }
  .header-menu .text,
  .btn-menu-close .text {
    position: absolute;
    bottom: 8px;
    left: 0;
    width: 100%;
    font-size: 1.2rem;
    font-weight: 400;
    text-align: center;
  }
  .header-menu.is-show .hamburger,
  .btn-menu-close.is-show .hamburger {
    left: 8px;
    top: 8px;
  }
  .header-menu.is-show .hamburger > span,
  .btn-menu-close.is-show .hamburger > span {
    width: 23px;
  }
  .header-menu.is-show .hamburger > span:nth-child(1),
  .btn-menu-close.is-show .hamburger > span:nth-child(1) {
    top: 9px;
    transform: rotate(45deg);
    height: 2px;
    left: -2px;
  }
  .header-menu.is-show .hamburger > span:nth-child(2),
  .btn-menu-close.is-show .hamburger > span:nth-child(2) {
    opacity: 0;
  }
  .header-menu.is-show .hamburger > span:nth-child(3),
  .btn-menu-close.is-show .hamburger > span:nth-child(3) {
    top: 9px;
    transform: rotate(-45deg);
    height: 2px;
    left: -2px;
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    @media (max-width: 896px) {
      .c-nav-global .inner {
        padding-bottom: 160px;
      }
    }
  }
}

/* c-heading-line
---------------------------------------------------------- */
.c-heading-line {
  display: flex;
  align-items: baseline;
  font-size: 2.2rem;
  letter-spacing: .2rem;
  padding-bottom: 5px;
  border-bottom: 1px dotted #7f7f7f;
}

@media (max-width: 896px) {
  .c-heading-line {
    font-size: 1.6rem;
    padding-bottom: 1px;
  }
}

.c-heading-line > span {
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: .1rem;
  color: #575757;
  margin-left: 18px;
}

@media (max-width: 896px) {
  .c-heading-line > span {
    font-size: 1rem;
  }
}

/* c-heading-01
---------------------------------------------------------- */
.c-heading-01 {
  font-size: 3.4rem;
  color: #282828;
  line-height: 1.45;
  letter-spacing: .1rem;
  margin-bottom: 35px;
}

@media (max-width: 896px) {
  .c-heading-01 {
    font-size: 2.2rem;
    margin-bottom: 21px;
  }
}

.c-heading-01 .en,
.c-heading-01 .date {
  display: block;
  font-size: 1.4rem;
  font-weight: 600;
  color: #45b49b;
}

@media (max-width: 896px) {
  .c-heading-01 .en,
  .c-heading-01 .date {
    font-size: 1rem;
    margin-top: 2px;
  }
}

.c-heading-01 .writer {
  display: block;
  font-size: 1.6rem;
  font-weight: 700;
}

@media (max-width: 896px) {
  .c-heading-01 .writer {
    font-size: 1.4rem;
  }
}

.c-heading-01 * + .writer {
  margin-top: 11px;
}

@media (max-width: 896px) {
  .c-heading-01 * + .writer {
    margin-top: 8px;
  }
}

.c-heading-01.-line {
  font-size: 2.4rem;
  font-weight: 700;
  border-top: 1px dotted #979797;
  border-bottom: 1px dotted #979797;
  padding: 66px 0 43px;
  margin-bottom: 20px;
}

@media (max-width: 896px) {
  .c-heading-01.-line {
    font-size: 1.6rem;
    padding: 60px 0 57px;
  }
}

.c-heading-01.-line .date {
  margin-bottom: 16px;
}

@media (max-width: 896px) {
  .c-heading-01.-line .date {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
}

.c-heading-01.-line .en {
  margin-top: 6px;
}

@media (max-width: 896px) {
  .c-heading-01.-line .en {
    font-size: 1.2rem;
  }
}

.c-heading-01.-line .sub-title {
  font-weight: 700;
}

@media (min-width: 897px) {
  .c-heading-01.-lg {
    font-size: 4rem;
  }
}

/* c-heading-02
---------------------------------------------------------- */
.c-heading-02 {
  font-size: 3rem;
  line-height: 1.35;
  letter-spacing: .2em;
  margin: 84px 0 108px;
  font-weight: bold;
}

@media (max-width: 896px) {
  .c-heading-02 {
    font-size: 2.2rem;
    margin: 70px 0 80px;
    text-align: center;
  }
}

.c-heading-02 .en {
  display: block;
  font-size: 1.2rem;
  letter-spacing: .06em;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}

@media (max-width: 896px) {
  .c-heading-02 .en {
    font-size: 1rem;
    margin-top: 2px;
  }
}

.c-heading-02.-line {
  padding-bottom: 97px;
  border-bottom: 1px dotted #979797;
}

@media (max-width: 896px) {
  .c-heading-02.-line {
    padding-bottom: 80px;
    margin-bottom: 58px;
  }
}

/* c-heading-03
---------------------------------------------------------- */
.c-heading-03 {
  font-size: 3rem;
  line-height: 1.45;
  letter-spacing: .15rem;
  margin-bottom: 82px;
  font-weight: bold;
  text-align: center;
}

@media (max-width: 896px) {
  .c-heading-03 {
    font-size: 2.2rem;
    margin-bottom: 40px;
  }
}

.c-heading-03 .en {
  display: block;
  font-size: 1.2rem;
  letter-spacing: .06em;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  color: #25253e;
}

@media (max-width: 896px) {
  .c-heading-03 .en {
    font-size: 1rem;
    margin-top: 2px;
  }
}

.c-heading-03 .sub {
  display: block;
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: 0;
  color: #45b49b;
  margin-top: 6px;
}

@media (max-width: 896px) {
  .c-heading-03 .sub {
    font-size: 1.4rem;
  }
}

.c-heading-03.-line {
  border-top: 1px dotted #979797;
  border-bottom: 1px dotted #979797;
  padding: 56px 0 46px;
  margin-bottom: 74px;
}

@media (max-width: 896px) {
  .c-heading-03.-line {
    padding: 47px 0 40px;
    margin-bottom: 35px;
  }
}

/* c-heading-04
---------------------------------------------------------- */
.c-heading-04 {
  font-size: 2.4rem;
  font-weight: 700;
  margin-bottom: 12px;
}

@media (max-width: 896px) {
  .c-heading-04 {
    font-size: 1.6rem;
    margin-bottom: 21px;
  }
}

.c-heading-04.-line {
  margin-bottom: 38px;
  padding-bottom: 7px;
  border-bottom: 1px dotted #979797;
}

@media (max-width: 896px) {
  .c-heading-04.-line {
    margin-bottom: 28px;
  }
}

.c-heading-04.-lg {
  font-size: 3rem;
}

@media (max-width: 896px) {
  .c-heading-04.-lg {
    font-size: 2.2rem;
  }
}

.c-heading-04.-center {
  text-align: center;
}

/* c-heading-05
---------------------------------------------------------- */
.c-heading-05 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3.4rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 12px;
}

@media (max-width: 896px) {
  .c-heading-05 {
    font-size: 2.2rem;
    margin-bottom: 15px;
  }
}

.c-heading-05::before {
  background: url(/assets/images/common/ico_line_left_01.png) no-repeat;
  margin-right: 15px;
}

@media (max-width: 896px) {
  .c-heading-05::before {
    top: -2px;
    margin-right: 18px;
  }
}

.c-heading-05::after {
  background: url(/assets/images/common/ico_line_right_01.png) no-repeat;
  margin-left: 15px;
}

@media (max-width: 896px) {
  .c-heading-05::after {
    margin-left: 18px;
  }
}

.c-heading-05::before, .c-heading-05::after {
  content: '';
  width: 42px;
  height: 42px;
  background-size: contain;
}

@media (max-width: 896px) {
  .c-heading-05::before, .c-heading-05::after {
    width: 30px;
    height: 30px;
    position: relative;
  }
}

@media (min-width: 897px) {
  .c-heading-05.-sm {
    font-size: 3rem;
  }
}

@media (min-width: 897px) {
  .c-heading-05.-sm::before, .c-heading-05.-sm::after {
    width: 31px;
    height: 31px;
  }
}

.c-heading-05.-line::before {
  margin-right: 23px;
}

@media (max-width: 896px) {
  .c-heading-05.-line::before {
    margin-right: 14px;
  }
}

.c-heading-05.-line::after {
  margin-left: 23px;
}

@media (max-width: 896px) {
  .c-heading-05.-line::after {
    margin-left: 14px;
  }
}

.c-heading-05.-line::before, .c-heading-05.-line::after {
  content: '';
  width: 60px;
  height: 4px;
  background: none;
  background-color: #000;
}

@media (max-width: 896px) {
  .c-heading-05.-line::before, .c-heading-05.-line::after {
    width: 38px;
    height: 2px;
  }
}

/* c-heading-icon
---------------------------------------------------------- */
.c-heading-icon {
  display: flex;
  align-items: center;
}

.c-heading-icon > .icon {
  width: 57px;
  flex: 1 0 auto;
}

@media (max-width: 896px) {
  .c-heading-icon > .icon {
    width: 38px;
    top: 3px;
    left: 2px;
  }
}

.c-heading-icon > .icon img {
  width: 57px;
}

@media (max-width: 896px) {
  .c-heading-icon > .icon img {
    width: 38px;
  }
}

.c-heading-icon > .title {
  width: 100%;
  font-size: 2.4rem;
  font-weight: 700;
  margin-left: 16px;
}

@media (max-width: 896px) {
  .c-heading-icon > .title {
    font-size: 1.6rem;
    line-height: 1.6;
    margin-left: 8px;
  }
}

/* input
---------------------------------------------------------- */
/* c-link-title
---------------------------------------------------------- */
.c-link-title {
  position: relative;
  font-size: 2rem;
  letter-spacing: .2rem;
  padding-right: 27px;
}

@media (min-width: 897px) {
  .c-link-title:hover {
    text-decoration: underline;
  }
}

@media (max-width: 896px) {
  .c-link-title {
    font-size: 1.6rem;
    padding-right: 16px;
  }
}

.c-link-title::after {
  position: absolute;
  content: '';
  top: 44%;
  right: 0;
  transform: translateY(-50%);
  width: 17px;
  height: 17px;
  background-image: url(/assets/images/common/ico_arrow_black.png);
  background-repeat: no-repeat;
  background-size: 17px 17px;
}

@media (max-width: 896px) {
  .c-link-title::after {
    width: 13px;
    height: 13px;
    background-size: 13px 13px;
  }
}

/* c-link-tel
---------------------------------------------------------- */
@media (min-width: 897px) {
  .c-link-tel {
    pointer-events: none;
  }
}

/* c-link
---------------------------------------------------------- */
.c-link {
  color: #0000ff;
}

@media (min-width: 897px) {
  .c-link {
    opacity: 1;
    transition: opacity .3s ease-out;
  }
  .c-link:hover {
    opacity: 0.6;
    text-decoration: none;
  }
}

@media (min-width: 897px) {
  .c-link:hover {
    text-decoration: underline;
  }
}

.c-link.-pdf {
  position: relative;
  padding-right: 29px;
  color: #000;
}

.c-link.-pdf::after {
  content: '';
  display: block;
  position: absolute;
  top: -5px;
  right: 0;
  width: 22px;
  height: 28px;
  background-image: url(/assets/images/common/ico_pdf.png);
  background-repeat: no-repeat;
  background-size: 22px 28px;
  margin-left: 8px;
}

/* c-list-customer
--------------------------------------------------------- */
@media (min-width: 897px) {
  .c-list-customer {
    display: flex;
    flex-wrap: wrap;
    margin-left: -22px;
    margin-right: -22px;
  }
}

.c-list-customer-wrap {
  padding-bottom: 50px;
}

@media (max-width: 896px) {
  .c-list-customer-wrap {
    padding-bottom: 25px;
  }
}

@media (min-width: 897px) {
  .c-list-customer > .item {
    width: 33.33%;
    padding: 0 22px;
  }
}

@media (max-width: 896px) {
  .c-list-customer > .item:not(:first-child) {
    margin-top: 50px;
  }
}

.c-list-customer .link {
  display: block;
}

@media (min-width: 897px) {
  .c-list-customer .link .img-zoom {
    overflow: hidden;
    position: relative;
    transition: transform 1s;
    transform: scale(1);
  }
  .c-list-customer .link .img-zoom::before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #45b49b;
    opacity: 0;
    z-index: 1;
    transition: opacity .2s ease-out;
    border-radius: 10px;
  }
  .c-list-customer .link .img-zoom img {
    transition: transform .2s;
  }
  .c-list-customer .link:hover .img-zoom {
    overflow: hidden;
    transform: scale(1.1);
  }
  .c-list-customer .link:hover .img-zoom::before {
    opacity: .6;
  }
  .c-list-customer .link:hover .img-zoom img {
    transform: scale(1.1);
  }
}

/* c-list-know
--------------------------------------------------------- */
@media (min-width: 897px) {
  .c-list-know {
    display: flex;
    flex-wrap: wrap;
    margin-left: -22px;
    margin-right: -22px;
    margin-top: -75px;
  }
}

.c-list-know-wrap {
  padding-top: 58px;
  padding-bottom: 37px;
}

@media (max-width: 896px) {
  .c-list-know-wrap {
    padding-top: 30px;
    padding-bottom: 27px;
  }
}

@media (min-width: 897px) {
  .c-list-know > .item {
    width: 33.33%;
    padding: 0 22px;
    margin-top: 75px;
  }
}

@media (max-width: 896px) {
  .c-list-know > .item:not(:first-child) {
    margin-top: 38px;
  }
}

.c-list-know .link {
  display: block;
}

@media (min-width: 897px) {
  .c-list-know .link .img-zoom {
    overflow: hidden;
    position: relative;
    transition: transform 1s;
    transform: scale(1);
  }
  .c-list-know .link .img-zoom::before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #45b49b;
    opacity: 0;
    z-index: 1;
    transition: opacity .2s ease-out;
    border-radius: 10px;
  }
  .c-list-know .link .img-zoom img {
    transition: transform .2s;
  }
  .c-list-know .link:hover .img-zoom {
    overflow: hidden;
    transform: scale(1.1);
  }
  .c-list-know .link:hover .img-zoom::before {
    opacity: .6;
  }
  .c-list-know .link:hover .img-zoom img {
    transform: scale(1.1);
  }
}

@media (min-width: 897px) {
  .c-list-know.-top {
    margin-left: -13px;
    margin-right: -13px;
    margin-top: -75px;
  }
}

@media (max-width: 896px) {
  .c-list-know.-top {
    padding-left: 27px;
    padding-right: 16px;
  }
}

@media (min-width: 897px) {
  .c-list-know.-top > .item {
    width: 25%;
    padding: 0 13px;
    margin-top: 75px;
  }
}

/* c-list-voice
--------------------------------------------------------- */
@media (min-width: 897px) {
  .c-list-voice {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media (min-width: 897px) {
  .c-list-voice > .item {
    width: 33.33%;
    padding: 0 15px;
  }
}

@media (max-width: 896px) {
  .c-list-voice > .item:not(:first-child) {
    margin-top: 35px;
  }
}

.c-list-voice .link {
  display: block;
}

@media (min-width: 897px) {
  .c-list-voice .link .img-zoom {
    overflow: hidden;
    position: relative;
    transition: transform 1s;
    transform: scale(1);
  }
  .c-list-voice .link .img-zoom::before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #45b49b;
    opacity: 0;
    z-index: 1;
    transition: opacity .2s ease-out;
    border-radius: 10px;
  }
  .c-list-voice .link .img-zoom img {
    transition: transform .2s;
  }
  .c-list-voice .link:hover .img-zoom {
    overflow: hidden;
    transform: scale(1.1);
  }
  .c-list-voice .link:hover .img-zoom::before {
    opacity: .6;
  }
  .c-list-voice .link:hover .img-zoom img {
    transform: scale(1.1);
  }
}

/* c-list-seminar
--------------------------------------------------------- */
@media (min-width: 897px) {
  .c-list-seminar {
    display: flex;
    flex-wrap: wrap;
    margin-left: -22px;
    margin-right: -22px;
    margin-top: -60px;
  }
}

.c-list-seminar-wrap {
  padding-bottom: 50px;
}

@media (max-width: 896px) {
  .c-list-seminar-wrap {
    padding-bottom: 25px;
  }
}

@media (min-width: 897px) {
  .c-list-seminar > .item {
    width: 33.33%;
    padding: 0 22px;
    margin-top: 60px;
  }
}

@media (max-width: 896px) {
  .c-list-seminar > .item:not(:first-child) {
    margin-top: 37px;
  }
}

.c-list-seminar .link {
  display: block;
}

@media (min-width: 897px) {
  .c-list-seminar .link .img-zoom {
    overflow: hidden;
    position: relative;
    transition: transform 1s;
    transform: scale(1);
  }
  .c-list-seminar .link .img-zoom::before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #45b49b;
    opacity: 0;
    z-index: 1;
    transition: opacity .2s ease-out;
    border-radius: 10px;
  }
  .c-list-seminar .link .img-zoom img {
    transition: transform .2s;
  }
  .c-list-seminar .link:hover .img-zoom {
    overflow: hidden;
    transform: scale(1.1);
  }
  .c-list-seminar .link:hover .img-zoom::before {
    opacity: .6;
  }
  .c-list-seminar .link:hover .img-zoom img {
    transform: scale(1.1);
  }
}

/* c-list-seminar-timeline
--------------------------------------------------------- */
.c-list-seminar-timeline > .item:not(:first-child) {
  margin-top: 70px;
}

@media (max-width: 896px) {
  .c-list-seminar-timeline > .item:not(:first-child) {
    margin-top: 45px;
  }
}

/* c-list-seminar-report
--------------------------------------------------------- */
.c-list-seminar-report-wrap {
  max-width: 800px;
  margin: 0 auto;
}

.c-list-seminar-report > .item:not(:first-child) {
  margin-top: 58px;
}

@media (max-width: 896px) {
  .c-list-seminar-report > .item:not(:first-child) {
    margin-top: 60px;
  }
}

.c-list-seminar-report .link {
  display: block;
}

@media (min-width: 897px) {
  .c-list-seminar-report .link .img-zoom {
    overflow: hidden;
    position: relative;
    transition: transform 1s;
    transform: scale(1);
  }
  .c-list-seminar-report .link .img-zoom::before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #45b49b;
    opacity: 0;
    z-index: 1;
    transition: opacity .2s ease-out;
    border-radius: 10px;
  }
  .c-list-seminar-report .link .img-zoom img {
    transition: transform .2s;
  }
  .c-list-seminar-report .link:hover .img-zoom {
    overflow: hidden;
    transform: scale(1.1);
  }
  .c-list-seminar-report .link:hover .img-zoom::before {
    opacity: .6;
  }
  .c-list-seminar-report .link:hover .img-zoom img {
    transform: scale(1.1);
  }
}

/* c-list-info
---------------------------------------------------------- */
.c-list-info {
  display: table;
  border-spacing: 0 3px;
  margin-top: -5px;
}

.c-list-info > .item {
  display: table-row;
}

.c-list-info > .item:not(:first-child) {
  border-top: 5px solid transparent;
}

.c-list-info .title {
  display: table-cell;
  position: relative;
  padding-right: 16px;
}

@media (max-width: 896px) {
  .c-list-info .title {
    width: 45px;
  }
}

.c-list-info .title::after {
  content: '：';
  position: absolute;
  width: 16px;
  right: 0;
  top: 0;
}

.c-list-info .text {
  display: table-cell;
  line-height: 1.9;
}

/* c-list-dot
---------------------------------------------------------- */
.c-list-dot > .item {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding-left: 18px;
}

.c-list-dot > .item:not(:first-child) {
  margin-top: 14px;
}

@media (max-width: 896px) {
  .c-list-dot > .item:not(:first-child) {
    margin-top: 7px;
  }
}

.c-list-dot > .item::before {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  content: '●';
  margin-right: 2px;
  width: 16px;
}

.c-list-dot.-sm > .item {
  font-size: 1.4rem;
  padding-left: 16px;
}

@media (max-width: 896px) {
  .c-list-dot.-sm > .item {
    font-size: 1.2rem;
    line-height: 1.8;
    padding-left: 14px;
  }
}

.c-list-dot.-sm > .item:not(:first-child) {
  margin-top: 8px;
}

@media (max-width: 896px) {
  .c-list-dot.-sm > .item:not(:first-child) {
    margin-top: 5px;
  }
}

.c-list-dot.-sm > .item::before {
  width: 14px;
}

.c-list-dot.-small-dot li::before {
  content: '・';
  margin-right: 0;
}

/* c-list-number
--------------------------------------------------------- */
.c-list-number {
  counter-reset: li;
}

.c-list-number > li {
  display: table;
  counter-increment: li;
  line-height: 2;
}

@media (max-width: 896px) {
  .c-list-number > li {
    line-height: 1.7;
  }
}

.c-list-number > li::before {
  display: table-cell;
  content: counter(li) ".";
  padding-right: 2px;
}

@media (min-width: 897px) {
  .c-list-number > li > .c-list-number-sub {
    margin-top: 2px;
  }
}

/* c-list-number-sub
--------------------------------------------------------- */
.c-list-number-sub {
  counter-reset: item;
}

.c-list-number-sub > li {
  display: table;
  counter-increment: item;
  line-height: 2.1;
}

@media (max-width: 896px) {
  .c-list-number-sub > li {
    line-height: 1.7;
  }
}

.c-list-number-sub > li::before {
  display: table-cell;
  content: "(" counter(item) ")";
  padding-right: 2px;
}

/* c-list-feature
--------------------------------------------------------- */
@media (min-width: 897px) {
  .c-list-feature {
    display: flex;
    flex-wrap: wrap;
    margin-left: -30px;
    margin-right: -30px;
  }
}

.c-list-feature-wrap {
  padding-top: 47px;
}

@media (max-width: 896px) {
  .c-list-feature-wrap {
    padding-top: 33px;
  }
}

@media (min-width: 897px) {
  .c-list-feature > .item {
    width: 33.33%;
    padding: 0 30px;
  }
}

@media (max-width: 896px) {
  .c-list-feature > .item:not(:first-child) {
    margin-top: 85px;
  }
}

/* c-list-button
--------------------------------------------------------- */
.c-list-button {
  width: 370px;
}

@media (max-width: 896px) {
  .c-list-button {
    width: 100%;
  }
}

.c-list-button > .item:not(:first-child) {
  margin-top: 11px;
}

@media (max-width: 896px) {
  .c-list-button > .item {
    margin-right: 8px;
  }
}

.c-list-button .c-btn {
  width: 100%;
}

.c-list-button .c-btn::before {
  border-radius: 8px;
}

.c-list-button .c-btn span {
  color: #282828;
}

@media (max-width: 896px) {
  .c-list-button .c-btn {
    padding-left: 16px;
  }
}

/* c-list-service-menu
--------------------------------------------------------- */
@media (min-width: 897px) {
  .c-list-service-menu > .item:nth-child(odd) .c-card-service-menu > .image {
    order: 2;
    margin-right: -50px;
  }
}

@media (max-width: 896px) {
  .c-list-service-menu > .item:nth-child(odd) .c-card-service-menu > .image {
    text-align: right;
    margin-right: -15px;
  }
}

@media (min-width: 897px) {
  .c-list-service-menu > .item:nth-child(odd) .c-card-service-menu > .detail {
    order: 1;
    margin-right: -135px;
  }
}

@media (min-width: 897px) {
  .c-list-service-menu > .item:nth-child(even) .c-card-service-menu > .image {
    margin-left: -50px;
  }
}

@media (max-width: 896px) {
  .c-list-service-menu > .item:nth-child(even) .c-card-service-menu > .image {
    margin-left: -15px;
  }
}

@media (min-width: 897px) {
  .c-list-service-menu > .item:nth-child(even) .c-card-service-menu > .detail {
    margin-left: -135px;
  }
}

@media (max-width: 896px) {
  .c-list-service-menu > .item:nth-child(even) .c-card-service-menu > .detail {
    justify-content: flex-end;
  }
}

.c-list-service-menu > .item:not(:first-child) {
  margin-top: 148px;
}

@media (max-width: 896px) {
  .c-list-service-menu > .item:not(:first-child) {
    margin-top: 58px;
  }
}

/* c-list-flow
--------------------------------------------------------- */
@media (min-width: 897px) {
  .c-list-flow {
    padding-left: 100px;
    padding-right: 100px;
  }
}

.c-list-flow > .item:not(:last-child) {
  position: relative;
  padding-bottom: 56px;
}

@media (max-width: 896px) {
  .c-list-flow > .item:not(:last-child) {
    padding-bottom: 36px;
  }
}

.c-list-flow > .item:not(:last-child)::after {
  content: '';
  position: absolute;
  display: block;
  bottom: 21px;
  left: 50%;
  transform: translateX(-50%);
  width: 17px;
  height: 11px;
  background-image: url(/assets/images/common/ico_arrow_down_01_pc.png);
  background-repeat: no-repeat;
  background-size: contain;
}

@media (max-width: 896px) {
  .c-list-flow > .item:not(:last-child)::after {
    background-image: url(/assets/images/common/ico_arrow_down_01.png);
    width: 13px;
    height: 8px;
    bottom: 12px;
  }
}

.c-list-flow > .item:not(:last-child).-is-text {
  padding-bottom: 77px;
}

@media (max-width: 896px) {
  .c-list-flow > .item:not(:last-child).-is-text {
    padding-bottom: 58px;
  }
}

.c-list-flow > .item:not(:last-child).-is-text::before {
  content: 'ご契約';
  position: absolute;
  display: block;
  font-size: 1.6rem;
  font-weight: 600;
  color: #000;
  bottom: 39px;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 896px) {
  .c-list-flow > .item:not(:last-child).-is-text::before {
    bottom: 22px;
  }
}

.c-list-flow > .item:not(:last-child).-is-text::after {
  bottom: 20px;
}

@media (max-width: 896px) {
  .c-list-flow > .item:not(:last-child).-is-text::after {
    bottom: 11px;
  }
}

/* c-list-faq
--------------------------------------------------------- */
@media (min-width: 897px) {
  .c-list-faq {
    padding-left: 100px;
    padding-right: 100px;
  }
}

.c-list-faq > .item:first-child .c-box-faq {
  border-top: none;
}

.c-list-faq > .item:last-child .c-box-faq {
  border-bottom: 1px dotted #979797;
}

/* c-list-quote
--------------------------------------------------------- */
.c-list-quote {
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 897px) {
  .c-list-quote {
    margin-left: -34px;
  }
}

@media (min-width: 897px) {
  .c-list-quote {
    justify-content: center;
  }
}

@media (min-width: 897px) {
  .c-list-quote > .item {
    width: 391px;
    padding-left: 34px;
  }
}

@media (max-width: 896px) {
  .c-list-quote > .item:nth-child(even) .image {
    text-align: right;
  }
}

@media (max-width: 896px) {
  .c-list-quote .image {
    width: calc(100vw - 30px);
  }
}

.c-list-quote .image img {
  width: 100%;
}

@media (max-width: 896px) {
  .c-list-quote .image img {
    width: 72.467%;
  }
}

/* c-list-note
--------------------------------------------------------- */
.c-list-note > .item {
  display: flex;
  align-items: baseline;
}

@media (min-width: 897px) {
  .c-list-note > .item {
    justify-content: center;
  }
}

@media (max-width: 896px) {
  .c-list-note > .item::before {
    content: '・';
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 2.5;
  }
}

@media (min-width: 897px) {
  .c-list-note .c-text-underline span::before {
    content: '・';
    font-size: 2rem;
    font-weight: 700;
    line-height: 3;
  }
}

/* c-list-pagination
---------------------------------------------------------- */
@media (max-width: 896px) {
  .c-list-pagination {
    margin: 0 -15px;
  }
}

.c-list-pagination .list {
  display: flex;
  justify-content: center;
  margin-top: -10px;
}

.c-list-pagination .list .page-numbers {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 3px solid #45b49b;
  font-size: 1.6rem;
  text-align: center;
  color: #fff;
  background-color: #45b49b;
  transition: all .2s;
  margin: 10px 3px 0;
}

@media (max-width: 896px) {
  .c-list-pagination .list .page-numbers {
    border: 2px solid #45b49b;
    width: 37px;
    height: 37px;
    margin: 10px 2px 0;
  }
}

.c-list-pagination .list .page-numbers:hover, .c-list-pagination .list .page-numbers.current {
  background-color: #fff;
  border-color: #000;
  text-decoration: none;
  color: #000;
  font-weight: bold;
}

.c-list-pagination .list .page-numbers.next, .c-list-pagination .list .page-numbers.prev {
  position: relative;
  display: block;
  width: 30px;
  text-indent: -9999px;
  overflow: hidden;
  border: none;
  background: transparent;
  padding: 0;
}

.c-list-pagination .list .page-numbers.next::before, .c-list-pagination .list .page-numbers.prev::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 7px;
  height: 12px;
  transform: translate(-50%, -50%);
}

.c-list-pagination .list .page-numbers.next::before {
  background-image: url(/assets/images/common/ico_next.png);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 100% auto;
}

.c-list-pagination .list .page-numbers.prev::before {
  background-image: url(/assets/images/common/ico_prev.png);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 100% auto;
}

/* c-list-pagination
---------------------------------------------------------- */
.c-box-pagination {
  margin-top: 70px;
  padding: 56px 0;
  background-color: #e3f4f0;
  border-radius: 10px;
}

@media (max-width: 896px) {
  .c-box-pagination {
    margin-top: 46px;
    padding: 32px 0;
    border-radius: 15px;
  }
}

.c-box-pagination.-type-01 {
  margin-top: 100px;
  border-radius: 30px;
}

@media (max-width: 896px) {
  .c-box-pagination.-type-01 {
    margin-top: 82px;
    border-radius: 15px;
  }
}

/* .-box-keyword-search
--------------------------------------------------------- */
.p-box-keyword-search {
  margin-top: 70px;
}

.p-box-keyword-search .l-inner .c-box-keyword-search {
  padding: 44px 50px 30px;
}

@media (max-width: 896px) {
  .p-box-keyword-search .l-inner .c-box-keyword-search {
    padding: 33px 20px 40px;
  }
}

.p-box-keyword-search .l-inner .c-box-keyword-search .c-btn-center {
  display: none;
}

@media (max-width: 896px) {
  .p-box-keyword-search .l-inner .c-box-keyword-search .c-btn-center {
    display: block;
    margin-top: 30px;
  }
}

.p-box-keyword-search .l-inner .c-box-keyword-search .c-btn-center .c-btn {
  position: relative;
}

.p-box-keyword-search .l-inner .c-box-keyword-search .c-btn-center span::after {
  display: none;
}

.p-box-keyword-search .heading {
  text-align: center;
  letter-spacing: .1em;
  font-size: 2.8rem;
  margin-bottom: 34px;
}

@media (max-width: 896px) {
  .p-box-keyword-search .heading {
    letter-spacing: normal;
    margin-bottom: 23px;
  }
}

.c-list-search-keyword {
  text-align: center;
  margin-left: -13px;
  margin-right: -13px;
}

@media (max-width: 896px) {
  .c-list-search-keyword {
    text-align: left;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 896px) {
  .c-list-search-keyword.-is-hidden {
    display: none;
  }
}

.c-list-search-keyword > .item {
  position: relative;
  display: inline-flex;
  line-height: 1.8;
  background: #fff;
  margin: 0 13px 20px;
  border-radius: 20px;
}

@media (max-width: 896px) {
  .c-list-search-keyword > .item {
    margin: 0 13px 10px 0;
  }
}

@media (max-width: 896px) {
  .c-list-search-keyword > .item:first-of-type input[type='radio'] + span {
    padding: 6px 20px 6px 50px;
  }
}

.c-list-search-keyword > .item:hover span::before {
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border-radius: 20px;
  border: 2px solid #45b49b;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.c-list-search-keyword > .item.is-active span {
  box-shadow: unset !important;
}

.c-list-search-keyword > .item.is-active span::before {
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border-radius: 20px;
  border: 2px solid #45b49b;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.c-list-search-keyword > .item.is-active span::after {
  background: url(/assets/images/ico_radio_active.png) center center no-repeat !important;
}

.c-list-search-keyword > .item span {
  transition: all 0.3s cubic-bezier(0.42, 0, 0.3, 1);
}

.c-list-search-keyword > .item input[type='radio'] {
  width: 0;
  opacity: 0;
}

.c-list-search-keyword > .item input[type='radio'] + span {
  font-size: 1.8rem;
  display: inline-block;
  padding: 4px 20px 4px 50px;
  cursor: pointer;
  position: relative;
  border-radius: 20px;
  box-shadow: 0 6px 16.92px 1.08px rgba(51, 51, 51, 0.3);
}

@media (max-width: 896px) {
  .c-list-search-keyword > .item input[type='radio'] + span {
    font-size: 1.6rem;
    padding: 6px 20px 6px 53px;
  }
}

.c-list-search-keyword > .item input[type='radio'] + span::before, .c-list-search-keyword > .item input[type='radio'] + span::after {
  content: '';
}

.c-list-search-keyword > .item input[type='radio'] + span::after {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  display: block;
  width: 20px;
  height: 20px;
  content: '';
  background: url(/assets/images/ico_radio.png) center center no-repeat;
  background-size: 20px;
}

.c-list-search-keyword > .item input[type='radio'].-is-active + span, .c-list-search-keyword > .item input[type='radio']:focus + span, .c-list-search-keyword > .item input[type='radio']:checked + span {
  box-shadow: unset;
}

.c-list-search-keyword > .item input[type='radio'].-is-active + span::before, .c-list-search-keyword > .item input[type='radio']:focus + span::before, .c-list-search-keyword > .item input[type='radio']:checked + span::before {
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border-radius: 20px;
  border: 2px solid #45b49b;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.c-list-search-keyword > .item input[type='radio'].-is-active + span::after, .c-list-search-keyword > .item input[type='radio']:focus + span::after, .c-list-search-keyword > .item input[type='radio']:checked + span::after {
  background: url(/assets/images/ico_radio_active.png) center center no-repeat;
}

.c-list-search-keyword > .item input[type='radio'].focus-visible + span {
  outline: 2px solid #000;
}

/* c-sidebar
---------------------------------------------------------- */
@media (max-width: 896px) {
  .c-sidebar {
    margin-top: 78px;
  }
}

.c-sidebar > .banner {
  display: block;
  margin-bottom: 38px;
}

@media (max-width: 896px) {
  .c-sidebar > .banner {
    margin-bottom: 50px;
  }
}

@media (min-width: 897px) {
  .c-sidebar > a.banner {
    opacity: 1;
    transition: opacity .3s ease-out;
  }
  .c-sidebar > a.banner:hover {
    opacity: 0.6;
    text-decoration: none;
  }
}

.c-sidebar > .title {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 4px;
}

@media (max-width: 896px) {
  .c-sidebar > .title {
    font-size: 1.6rem;
    margin-bottom: 5px;
  }
}

.c-sidebar > .list-pickup > .item:first-child .card-pickup {
  padding-top: 20px;
  border-top: 1px dotted #7f7f7f;
}

@media (max-width: 896px) {
  .c-sidebar > .list-pickup > .item:first-child .card-pickup {
    padding-top: 29px;
  }
}

.c-sidebar > .list-pickup .link {
  display: block;
}

@media (min-width: 897px) {
  .c-sidebar > .list-pickup .link .img-zoom {
    overflow: hidden;
    position: relative;
    transition: transform 1s;
    transform: scale(1);
  }
  .c-sidebar > .list-pickup .link .img-zoom::before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #45b49b;
    opacity: 0;
    z-index: 1;
    transition: opacity .2s ease-out;
    border-radius: 10px;
  }
  .c-sidebar > .list-pickup .link .img-zoom img {
    transition: transform .2s;
  }
  .c-sidebar > .list-pickup .link:hover .img-zoom {
    overflow: hidden;
    transform: scale(1.1);
  }
  .c-sidebar > .list-pickup .link:hover .img-zoom::before {
    opacity: .6;
  }
  .c-sidebar > .list-pickup .link:hover .img-zoom img {
    transform: scale(1.1);
  }
}

.c-sidebar .card-pickup {
  display: flex;
  padding: 28px 0 28px;
  border-bottom: 1px dotted #7f7f7f;
}

@media (max-width: 896px) {
  .c-sidebar .card-pickup {
    padding: 27px 0 20px;
  }
}

.c-sidebar .card-pickup > .image {
  flex: 1 0 auto;
  width: 140px;
  position: relative;
  border-radius: 3px;
  overflow: hidden;
  clip-path: content-box;
}

@media (min-width: 897px) {
  .c-sidebar .card-pickup > .image {
    height: 84px;
  }
}

@media (min-width: 897px) {
  .c-sidebar .card-pickup > .image > * {
    height: 84px;
  }
}

@media (max-width: 896px) {
  .c-sidebar .card-pickup > .image {
    width: 140px;
  }
}

.c-sidebar .card-pickup > .image::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 20px;
  height: 20px;
  background-image: url(/assets/images/common/ico_arrow_right_01.png);
  background-size: 5px 8px;
  background-repeat: no-repeat;
  background-color: #45b49b;
  background-position: center;
}

.c-sidebar .card-pickup > .image img {
  width: 100%;
  border-radius: 3px;
}

.c-sidebar .card-pickup > .detail {
  width: 100%;
  padding-left: 30px;
}

.c-sidebar .card-pickup > .detail .desc {
  font-size: 1.4rem;
}

@media (max-width: 896px) {
  .c-sidebar .card-pickup > .detail .desc {
    font-size: 1.2rem;
  }
}

/* Slider */
/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 32px;
  width: 32px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  z-index: 2;
}

@media (max-width: 896px) {
  .slick-prev,
  .slick-next {
    top: 53%;
    width: 22px;
    height: 22px;
  }
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
}

@media (min-width: 897px) {
  .slick-prev:hover,
  .slick-next:hover {
    opacity: 1;
    transition: opacity .3s ease-out;
  }
  .slick-prev:hover:hover,
  .slick-next:hover:hover {
    opacity: 0.6;
    text-decoration: none;
  }
}

.slick-prev.slick-disabled::before,
.slick-next.slick-disabled::before {
  opacity: 25;
}

.slick-prev::before,
.slick-next::before {
  display: block;
  content: '';
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-size: 32px 32px;
}

@media (max-width: 896px) {
  .slick-prev::before,
  .slick-next::before {
    width: 22px;
    height: 22px;
    background-size: 22px 22px;
  }
}

.slick-prev {
  left: 65px;
}

@media (max-width: 896px) {
  .slick-prev {
    left: 9px;
  }
}

.slick-prev::before {
  background-image: url(/assets/images/common/ico_arrow_black_left.png);
}

.slick-next {
  right: 65px;
}

@media (max-width: 896px) {
  .slick-next {
    right: 9px;
  }
}

.slick-next::before {
  background-image: url(/assets/images/common/ico_arrow_black.png);
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 50px;
}

@media (max-width: 896px) {
  .slick-dotted.slick-slider {
    margin-bottom: 22px;
  }
}

.slick-dots {
  position: absolute;
  bottom: -40px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

@media (max-width: 896px) {
  .slick-dots {
    bottom: -25px;
  }
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 12px;
  width: 12px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

@media (max-width: 896px) {
  .slick-dots li {
    width: 10px;
    height: 10px;
    margin: 0 4px;
  }
}

.slick-dots li button {
  display: block;
  height: 12px;
  width: 12px;
  background-color: #282828;
  outline: none;
  cursor: pointer;
  border-radius: 20px;
  transition: background-color .3s ease-out;
  margin: 0;
}

@media (max-width: 896px) {
  .slick-dots li button {
    width: 10px;
    height: 10px;
  }
}

.slick-dots li button:hover {
  background-color: #45b49b;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button::before {
  display: none;
}

.slick-dots li.slick-active button {
  width: 12px;
  height: 12px;
  background-color: #45b49b;
}

@media (max-width: 896px) {
  .slick-dots li.slick-active button {
    width: 10px;
    height: 10px;
  }
}

/* c-tag
---------------------------------------------------------- */
.c-tag {
  min-width: 120px;
  font-size: 1.2rem;
  text-align: center;
  color: #fff;
  background-color: #000;
  border-radius: 3px;
  padding: 4px 13px 2px;
}

@media (max-width: 896px) {
  .c-tag {
    font-size: 1.2rem;
    min-width: 85px;
    padding: 4px 13px 4px;
  }
}

.c-text-underline {
  text-align: center;
}

@media (max-width: 896px) {
  .c-text-underline {
    text-align: left;
  }
}

.c-text-underline span {
  font-size: 2rem;
  font-weight: 700;
  line-height: 3;
  border-bottom: 1px solid #000;
  padding-bottom: 2px;
}

@media (max-width: 896px) {
  .c-text-underline span {
    font-size: 1.4rem;
    line-height: 2.5;
  }
}

.c-text-black {
  color: #000 !important;
  text-decoration: underline;
}

@media (min-width: 897px) {
  .c-text-black {
    opacity: 1;
    transition: opacity .3s ease-out;
  }
  .c-text-black:hover {
    opacity: 0.6;
    text-decoration: none;
  }
}

/* ==========================================================
 pages
========================================================== */
/* p-notfound
---------------------------------------------------------- */
.p-notfound {
  padding-top: 45px;
  padding-bottom: 57px;
}

@media (max-width: 896px) {
  .p-notfound {
    padding-top: 66px;
    padding-bottom: 35px;
  }
}

.p-notfound .box-notfound {
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 140px 75px;
}

@media (max-width: 896px) {
  .p-notfound .box-notfound {
    border-radius: 5px;
    padding: 55px 35px 85px;
  }
}

.p-notfound .title {
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: .1rem;
  margin-bottom: 18px;
}

@media (max-width: 896px) {
  .p-notfound .title {
    font-size: 2.2rem;
    margin-bottom: 21px;
  }
}

.p-notfound .text {
  font-size: 1.6rem;
  line-height: 2.4;
}

@media (max-width: 896px) {
  .p-notfound .text {
    line-height: 1.6;
  }
  .p-notfound .text + .text {
    margin-top: 26px;
  }
}

/* p-case-detail
--------------------------------------------------------- */
.p-case-detail .mainimg img {
  width: 100%;
}

.p-case-detail .mainimg + * {
  margin-top: 90px;
}

@media (max-width: 896px) {
  .p-case-detail .mainimg + * {
    margin-top: 50px;
  }
}

.p-case-detail .customer-name {
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: .15rem;
  margin-bottom: 9px;
}

@media (max-width: 896px) {
  .p-case-detail .customer-name {
    font-size: 1.6rem;
    letter-spacing: 0;
    margin-bottom: 2px;
  }
}

.p-case-detail .title {
  font-size: 2.8rem;
  font-weight: 700;
  color: #080808;
  letter-spacing: .2rem;
}

@media (max-width: 896px) {
  .p-case-detail .title {
    font-size: 1.6rem;
    letter-spacing: 0;
  }
}

.p-case-detail .text-desc {
  line-height: 1.9;
  color: #080808;
  margin-top: 46px;
}

.p-case-detail .text-desc a {
  color: #0000ff;
}

@media (min-width: 897px) {
  .p-case-detail .text-desc a {
    opacity: 1;
    transition: opacity .3s ease-out;
  }
  .p-case-detail .text-desc a:hover {
    opacity: 0.6;
    text-decoration: none;
  }
}

@media (min-width: 897px) {
  .p-case-detail .text-desc a:hover {
    text-decoration: underline;
  }
}

@media (max-width: 896px) {
  .p-case-detail .text-desc {
    line-height: 2;
    letter-spacing: -.02rem;
    margin-top: 30px;
  }
}

.p-case-detail .box-gallery-wrap {
  width: 800px;
  margin: 56px auto 0;
}

@media (max-width: 896px) {
  .p-case-detail .box-gallery-wrap {
    width: 100%;
    margin-top: 37px;
  }
}

.p-case-detail .box-gallery .image img {
  overflow: hidden;
  border-radius: 8px;
  clip-path: content-box;
}

@media (max-width: 896px) {
  .p-case-detail .box-gallery .image img {
    border-radius: 4px;
  }
}

.p-case-detail .box-gallery .caption {
  font-size: 1.4rem;
  margin-top: 10px;
}

@media (max-width: 896px) {
  .p-case-detail .box-gallery .caption {
    font-size: 1.2rem;
    margin-top: 5px;
  }
}

.p-case-detail .box-gallery + .box-gallery {
  margin-top: 61px;
}

@media (max-width: 896px) {
  .p-case-detail .box-gallery + .box-gallery {
    margin-top: 31px;
  }
}

.p-case-relate {
  padding-top: 115px;
  padding-bottom: 10px;
}

@media (max-width: 896px) {
  .p-case-relate {
    padding-top: 75px;
    padding-bottom: 26px;
  }
}

@media (max-width: 896px) {
  .p-case-relate .p-heading-case-01 {
    margin-bottom: 30px;
  }
}

/* p-list-case
---------------------------------------------------------- */
.p-list-case {
  display: flex;
  flex-wrap: wrap;
  margin: -88px -25px 0;
}

@media (max-width: 896px) {
  .p-list-case {
    margin: -55px -15px 0;
  }
}

.p-list-case > .item {
  margin-top: 88px;
  flex: 0 0 auto;
  width: 33.33%;
  padding: 0 25px;
}

@media (max-width: 896px) {
  .p-list-case > .item {
    margin-top: 55px;
    padding: 0 15px;
    width: 100%;
  }
}

.p-list-case > .item .link {
  display: block;
}

@media (min-width: 897px) {
  .p-list-case > .item .link .img-zoom {
    overflow: hidden;
    position: relative;
    transition: transform 1s;
    transform: scale(1);
  }
  .p-list-case > .item .link .img-zoom::before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #45b49b;
    opacity: 0;
    z-index: 1;
    transition: opacity .2s ease-out;
    border-radius: 10px;
  }
  .p-list-case > .item .link .img-zoom img {
    transition: transform .2s;
  }
  .p-list-case > .item .link:hover .img-zoom {
    overflow: hidden;
    transform: scale(1.1);
  }
  .p-list-case > .item .link:hover .img-zoom::before {
    opacity: .6;
  }
  .p-list-case > .item .link:hover .img-zoom img {
    transform: scale(1.1);
  }
}

.p-list-case > .item .image {
  position: relative;
  border-radius: 2px;
  overflow: hidden;
  clip-path: content-box;
}

@media (min-width: 897px) {
  .p-list-case > .item .image {
    height: 220px;
  }
}

@media (min-width: 897px) {
  .p-list-case > .item .image > * {
    height: 220px;
    border-radius: 2px;
  }
}

@media (max-width: 896px) {
  .p-list-case > .item .image {
    width: 100%;
  }
}

.p-list-case > .item .image::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background-image: url(/assets/images/common/ico_arrow_right_01.png);
  background-size: 7px 12px;
  background-repeat: no-repeat;
  background-color: #45b49b;
  background-position: center;
}

.p-list-case > .item .image img {
  width: 100%;
}

.p-list-case > .item .details .customer-name {
  margin-top: 18px;
  margin-bottom: 10px;
  font-size: 1.8rem;
  font-weight: bold;
}

@media (max-width: 896px) {
  .p-list-case > .item .details .customer-name {
    margin-top: 14px;
    margin-bottom: 5px;
    font-size: 1.6rem;
  }
}

.p-list-case > .item .details .title {
  font-size: 1.2rem;
}

.p-list-case > .item .details .list {
  font-size: 1.2rem;
}

.p-list-case > .item .details .list .item {
  display: flex;
}

.p-list-case > .item .details .list .item .head {
  position: relative;
  padding-right: 10px;
}

.p-list-case > .item .details .list .item .head::after {
  position: absolute;
  content: ':';
  right: 2px;
}

.p-section-case {
  margin-top: 108px;
}

@media (max-width: 896px) {
  .p-section-case {
    margin-top: 55px;
  }
}

.p-heading-case-01 {
  margin-bottom: 48px;
}

@media (max-width: 896px) {
  .p-heading-case-01 {
    margin-bottom: 45px;
  }
}

/* p-cms-detail
--------------------------------------------------------- */
.p-cms-detail .c-heading-01.-line {
  padding: 35px 0 25px;
}

.p-cms-detail .c-box-link {
  margin-top: 60px;
}

@media (max-width: 896px) {
  .p-cms-detail .c-box-link .title {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
}

@media (max-width: 896px) {
  .p-cms-detail .c-box-link {
    margin-top: 50px;
    padding: 32px 0 24px 10px;
  }
}

.p-cms-detail .c-box-link a {
  color: #0000ff;
}

.p-cms-detail .c-box-link a.-pdf {
  color: #000;
}

@media (max-width: 896px) {
  .p-cms-detail .c-box-speaker {
    margin-top: 60px;
  }
}

.p-cms-detail .c-box-speaker .box-detail {
  padding-right: 34px;
}

@media (max-width: 896px) {
  .p-cms-detail .c-box-speaker .box-detail {
    padding-right: 0;
  }
}

@media (min-width: 897px) {
  .p-cms-detail .c-box-speaker .box-detail p {
    font-size: 1.4rem;
  }
}

@media (max-width: 896px) {
  .p-cms-detail .c-box-button {
    margin-top: 44px;
  }
}

/* p-customer-detail
--------------------------------------------------------- */
.p-customer-detail {
  /* box-title */
  /* box-info */
}

.p-customer-detail > .box-title {
  position: relative;
  padding-top: 38px;
}

@media (max-width: 896px) {
  .p-customer-detail > .box-title {
    padding-top: 24px;
  }
}

.p-customer-detail > .box-title::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 170px;
  height: 2px;
  background-color: #ddd;
}

@media (max-width: 896px) {
  .p-customer-detail > .box-title::before {
    width: 85px;
    height: 1px;
  }
}

.p-customer-detail > .box-title > .customer {
  font-weight: 700;
  color: #080808;
}

.p-customer-detail > .box-title > .title {
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.7;
  color: #080808;
  margin-bottom: 6px;
}

@media (max-width: 896px) {
  .p-customer-detail > .box-title > .title {
    font-size: 1.6rem;
    line-height: 1.6;
  }
}

.p-customer-detail > .box-title .sub-title {
  display: block;
}

.p-customer-detail > .box-title > .date {
  font-size: 1.4rem;
  color: #45b49b;
}

@media (max-width: 896px) {
  .p-customer-detail > .box-title > .date {
    font-size: 1.2rem;
  }
}

.p-customer-detail > .box-title * + .title {
  margin-top: 10px;
}

@media (max-width: 896px) {
  .p-customer-detail > .box-title * + .title {
    margin-top: 8px;
  }
}

.p-customer-detail > .box-info {
  margin-top: 42px;
  /* box-detail */
  /* box-image */
}

@media (max-width: 896px) {
  .p-customer-detail > .box-info {
    margin-top: 22px;
  }
}

@media (min-width: 897px) {
  .p-customer-detail > .box-info {
    display: flex;
  }
}

@media (max-width: 896px) {
  .p-customer-detail > .box-info {
    padding-left: 27px;
    padding-right: 16px;
  }
}

@media (max-width: 896px) and (max-width: 896px) {
  .p-customer-detail > .box-info {
    padding-left: 0;
    padding-right: 0;
  }
}

.p-customer-detail > .box-info > .box-detail {
  width: 100%;
}

@media (min-width: 897px) {
  .p-customer-detail > .box-info > .box-detail {
    padding-right: 25px;
  }
}

.p-customer-detail > .box-info > .box-detail > .customer {
  font-weight: 700;
  color: #080808;
}

@media (max-width: 896px) {
  .p-customer-detail > .box-info > .box-detail > .customer {
    font-size: 1.2rem;
  }
}

.p-customer-detail > .box-info > .box-detail > .name {
  font-size: 1.4rem;
  line-height: 1.95;
  color: #080808;
  margin-top: 3px;
}

@media (max-width: 896px) {
  .p-customer-detail > .box-info > .box-detail > .name {
    font-size: 1.2rem;
    line-height: 1.7;
    margin-top: 0;
  }
}

.p-customer-detail > .box-info .list-detail {
  margin-top: 15px;
}

@media (max-width: 896px) {
  .p-customer-detail > .box-info .list-detail {
    margin-top: 7px;
  }
}

.p-customer-detail > .box-info .list-detail > .item {
  display: flex;
}

.p-customer-detail > .box-info .list-detail > .item:not(:first-child) {
  margin-top: 8px;
}

@media (max-width: 896px) {
  .p-customer-detail > .box-info .list-detail > .item:not(:first-child) {
    margin-top: 6px;
  }
}

.p-customer-detail > .box-info .list-detail .title {
  flex: 1 0 auto;
  width: 100px;
}

@media (max-width: 896px) {
  .p-customer-detail > .box-info .list-detail .title {
    width: 87px;
  }
}

.p-customer-detail > .box-info .list-detail .title > span {
  display: block;
  font-size: 1.2rem;
  font-weight: 500;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  background-color: #45b49b;
  padding: 3px 5px 2px;
}

@media (max-width: 896px) {
  .p-customer-detail > .box-info .list-detail .title > span {
    font-size: 1.1rem;
  }
}

.p-customer-detail > .box-info .list-detail .text {
  width: 100%;
  font-size: 1.4rem;
  line-height: 1.7;
  color: #080808;
  padding-left: 15px;
}

@media (max-width: 896px) {
  .p-customer-detail > .box-info .list-detail .text {
    font-size: 1.2rem;
    padding-left: 7px;
  }
}

.p-customer-detail > .box-info .box-sns {
  margin: 13px 0 0 -10px;
}

@media (min-width: 897px) {
  .p-customer-detail > .box-info > .box-image {
    width: 785px;
    flex: 1 0 auto;
  }
}

@media (max-width: 896px) {
  .p-customer-detail > .box-info > .box-image {
    margin-top: 35px;
    margin-right: -15px;
  }
}

.p-customer-detail > .box-info > .box-image > .image {
  border-radius: 8px;
  overflow: hidden;
  clip-path: content-box;
}

@media (max-width: 896px) {
  .p-customer-detail > .box-info > .box-image > .image {
    border-radius: 3px;
  }
}

.p-customer-detail > .box-info > .box-image > .image img {
  width: 100%;
}

.p-customer-detail > .box-info > .box-image > .box-product-detail {
  margin-top: 28px;
}

.p-customer-detail > .box-info > .box-image > .box-product-detail > .title {
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 6px;
}

@media (max-width: 896px) {
  .p-customer-detail > .box-info > .box-image > .box-product-detail > .title {
    font-size: 1.2rem;
    margin-bottom: 0;
  }
}

@media (max-width: 896px) {
  .p-customer-detail > .box-info > .box-image .list-product-detail {
    padding-right: 15px;
  }
}

.p-customer-detail > .box-info > .box-image .list-product-detail > .item {
  display: flex;
  line-height: 1.9;
}

.p-customer-detail > .box-info > .box-image .list-product-detail > .item:not(:first-child) {
  margin-top: 2px;
}

@media (max-width: 896px) {
  .p-customer-detail > .box-info > .box-image .list-product-detail > .item:not(:first-child) {
    margin-top: 0;
  }
}

.p-customer-detail > .box-info > .box-image .list-product-detail > .item > .title {
  display: flex;
  justify-content: space-between;
  width: 72px;
  font-size: 1.4rem;
  flex: 1 0 auto;
}

@media (max-width: 896px) {
  .p-customer-detail > .box-info > .box-image .list-product-detail > .item > .title {
    width: 62px;
    font-size: 1.2rem;
  }
}

.p-customer-detail > .box-info > .box-image .list-product-detail > .item > .title::after {
  position: relative;
  top: -1px;
  content: '：';
  font-size: 1.4rem;
}

@media (max-width: 896px) {
  .p-customer-detail > .box-info > .box-image .list-product-detail > .item > .title::after {
    font-size: 1.2rem;
  }
}

.p-customer-detail > .box-info > .box-image .list-product-detail > .item > .text {
  width: 100%;
  font-size: 1.4rem;
}

@media (max-width: 896px) {
  .p-customer-detail > .box-info > .box-image .list-product-detail > .item > .text {
    font-size: 1.2rem;
  }
}

.p-customer-detail > .box-info > .box-image .list-product-detail > .item a {
  color: #0000ff;
}

@media (min-width: 897px) {
  .p-customer-detail > .box-info > .box-image .list-product-detail > .item a {
    opacity: 1;
    transition: opacity .3s ease-out;
  }
  .p-customer-detail > .box-info > .box-image .list-product-detail > .item a:hover {
    opacity: 0.6;
    text-decoration: none;
  }
}

@media (min-width: 897px) {
  .p-customer-detail > .box-info > .box-image .list-product-detail > .item a:hover {
    text-decoration: underline;
  }
}

/* c-interview
--------------------------------------------------------- */
.c-interview {
  margin-top: 64px;
  padding-top: 63px;
  border-top: 1px dotted #7f7f7f;
}

.c-interview a {
  color: #0000ff;
}

@media (min-width: 897px) {
  .c-interview a {
    opacity: 1;
    transition: opacity .3s ease-out;
  }
  .c-interview a:hover {
    opacity: 0.6;
    text-decoration: none;
  }
}

@media (min-width: 897px) {
  .c-interview a:hover {
    text-decoration: underline;
  }
}

@media (max-width: 896px) {
  .c-interview {
    margin-top: 33px;
    padding-top: 35px;
  }
}

.c-interview + .c-interview {
  margin-top: 140px;
  padding-top: 0;
  border-top: none;
}

@media (max-width: 896px) {
  .c-interview + .c-interview {
    margin-top: 70px;
  }
}

.c-interview > .box-title {
  border-radius: 10px;
  background-color: #e3f4f0;
  padding: 44px 15px 39px 100px;
}

@media (min-width: 897px) {
  .c-interview > .box-title {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 896px) {
  .c-interview > .box-title {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    padding: 32px 15px 25px;
  }
}

.c-interview > .box-title > .title {
  font-size: 2rem;
  font-weight: 700;
  color: #45b49b;
}

@media (min-width: 897px) {
  .c-interview > .box-title > .title {
    width: 300px;
    flex: 1 0 auto;
  }
}

@media (max-width: 896px) {
  .c-interview > .box-title > .title {
    font-size: 1.6rem;
    text-align: center;
  }
}

.c-interview > .box-title > .desc {
  width: 100%;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.6;
  color: #080808;
}

@media (min-width: 897px) {
  .c-interview > .box-title > .desc {
    position: relative;
    padding-left: 65px;
  }
  .c-interview > .box-title > .desc::after {
    content: '';
    position: absolute;
    top: 46%;
    left: 0;
    transform: translateY(-50%);
    width: 1px;
    height: calc(100% + 26px);
    background-color: #45b49b;
  }
}

@media (max-width: 896px) {
  .c-interview > .box-title > .desc {
    font-size: 1.4rem;
    line-height: 1.75;
    margin-top: 7px;
  }
  .c-interview > .box-title > .desc br {
    display: none;
  }
}

@media (min-width: 897px) {
  .c-interview > .box-interview {
    padding-left: 106px;
    margin-top: 67px;
  }
}

@media (max-width: 896px) {
  .c-interview > .box-interview {
    margin-top: 28px;
  }
}

.c-interview > .box-interview + .box-interview {
  margin-top: 90px;
}

@media (max-width: 896px) {
  .c-interview > .box-interview + .box-interview {
    margin-top: 50px;
  }
}

.c-interview > .box-interview > .question {
  position: relative;
  font-size: 2rem;
  font-weight: 700;
  color: #080808;
  padding-left: 40px;
}

@media (max-width: 896px) {
  .c-interview > .box-interview > .question {
    font-size: 1.4rem;
    line-height: 2;
    padding-left: 30px;
  }
}

.c-interview > .box-interview > .question::before {
  content: '';
  position: absolute;
  display: block;
  width: 30px;
  height: 2px;
  left: 0;
  top: 13px;
  background-color: #838383;
}

@media (max-width: 896px) {
  .c-interview > .box-interview > .question::before {
    width: 23px;
    top: 11px;
  }
}

.c-interview > .box-interview > .answer {
  line-height: 2;
  margin-top: 29px;
  padding-left: 36px;
}

@media (max-width: 896px) {
  .c-interview > .box-interview > .answer {
    margin-top: 21px;
    padding-left: 26px;
  }
}

.c-interview > .box-interview > .answer + .answer {
  margin-top: 32px;
}

@media (max-width: 896px) {
  .c-interview > .box-interview > .answer + .answer {
    margin-top: 28px;
  }
}

.c-interview > .box-interview .name {
  font-weight: 600;
  color: #45b49b;
}

.c-interview > .box-interview .response {
  color: #080808;
}

.c-interview > .box-interview .image {
  padding-left: 37px;
  margin-top: 34px;
  border-radius: 10px;
  overflow: hidden;
  clip-path: content-box;
}

@media (max-width: 896px) {
  .c-interview > .box-interview .image {
    padding-left: 0;
    margin-top: 49px;
    margin-left: -15px;
    margin-right: -15px;
    border-radius: 0;
  }
}

.c-interview > .box-interview .image figcaption {
  font-size: 1.4rem;
  color: #444;
  margin-top: 12px;
}

@media (max-width: 896px) {
  .c-interview > .box-interview .image figcaption {
    font-size: 1.2rem;
    margin-top: 7px;
    padding: 0 5px;
  }
}

.c-interview .box-gallery {
  display: flex;
  margin: 119px -100px 140px -100px;
}

@media (max-width: 896px) {
  .c-interview .box-gallery {
    margin: 47px -15px 39px -15px;
  }
}

.c-interview .box-gallery .image > img {
  height: 320px;
  width: 100%;
  clip-path: content-box;
}

@media (max-width: 896px) {
  .c-interview .box-gallery .image > img {
    height: calc((100vw * 90) / 375);
  }
}

.c-interview .box-gallery .image figcaption {
  font-size: 1.4rem;
  color: #444;
  margin-top: 6px;
  margin-left: 6px;
}

@media (max-width: 896px) {
  .c-interview .box-gallery .image figcaption {
    display: none;
  }
}

/* p-customer-list
--------------------------------------------------------- */
.p-customer-list {
  margin-top: 140px;
}

@media (max-width: 896px) {
  .p-customer-list {
    margin-top: 100px;
  }
}

@media (max-width: 896px) {
  .p-customer-list .c-heading-03 {
    margin-bottom: 25px;
  }
}

/* p-customers
---------------------------------------------------------- */
.p-section-customers {
  margin-top: 82px;
}

@media (max-width: 896px) {
  .p-section-customers {
    margin-top: 58px;
  }
}

.p-list-customers {
  padding-bottom: 65px;
  border-bottom: 1px dotted #b2b2b2;
}

@media (max-width: 896px) {
  .p-list-customers {
    padding-bottom: 0;
    border-bottom: none;
  }
}

.p-list-customers > .item:not(:first-child) {
  margin-top: 65px;
  padding-top: 65px;
  border-top: 1px dotted #b2b2b2;
}

@media (max-width: 896px) {
  .p-list-customers > .item:not(:first-child) {
    border-top: none;
    padding-top: 0;
    margin-top: 58px;
  }
}

.p-list-customers > .item > .link {
  display: flex;
  width: 100%;
}

@media (min-width: 897px) {
  .p-list-customers > .item > .link .img-zoom {
    overflow: hidden;
    position: relative;
    transition: transform 1s;
    transform: scale(1);
  }
  .p-list-customers > .item > .link .img-zoom::before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #45b49b;
    opacity: 0;
    z-index: 1;
    transition: opacity .2s ease-out;
    border-radius: 10px;
  }
  .p-list-customers > .item > .link .img-zoom img {
    transition: transform .2s;
  }
  .p-list-customers > .item > .link:hover .img-zoom {
    overflow: hidden;
    transform: scale(1.1);
  }
  .p-list-customers > .item > .link:hover .img-zoom::before {
    opacity: .6;
  }
  .p-list-customers > .item > .link:hover .img-zoom img {
    transform: scale(1.1);
  }
}

@media (max-width: 896px) {
  .p-list-customers > .item > .link {
    display: block;
  }
}

.p-list-customers > .item > .link .image {
  position: relative;
  width: 370px;
  flex: 0 0 auto;
  margin-right: 42px;
  border-radius: 2px;
  overflow: hidden;
  clip-path: content-box;
}

@media (min-width: 897px) {
  .p-list-customers > .item > .link .image {
    height: 250px;
  }
}

@media (min-width: 897px) {
  .p-list-customers > .item > .link .image > * {
    height: 250px;
    border-radius: 2px;
  }
}

@media (max-width: 896px) {
  .p-list-customers > .item > .link .image {
    width: 100%;
    margin-right: 0;
  }
}

.p-list-customers > .item > .link .image::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background-image: url(/assets/images/common/ico_arrow_right_01.png);
  background-size: 7px 12px;
  background-repeat: no-repeat;
  background-color: #45b49b;
  background-position: center;
}

.p-list-customers > .item > .link .image img {
  width: 100%;
}

.p-list-customers > .item > .link .details {
  margin-top: 7px;
  width: 100%;
}

@media (max-width: 896px) {
  .p-list-customers > .item > .link .details {
    margin-top: 20px;
  }
}

.p-list-customers > .item > .link .details .customer-name {
  font-size: 1.6rem;
  font-weight: bold;
}

@media (max-width: 896px) {
  .p-list-customers > .item > .link .details .customer-name {
    font-size: 1.4rem;
  }
}

.p-list-customers > .item > .link .details > .title {
  margin-top: 10px;
  font-size: 2.6rem;
  font-weight: bold;
}

@media (max-width: 896px) {
  .p-list-customers > .item > .link .details > .title {
    font-size: 1.6rem;
  }
}

.p-list-customers > .item > .link .details .sub-title {
  margin-top: 5px;
  font-size: 1.6rem;
}

@media (max-width: 896px) {
  .p-list-customers > .item > .link .details .sub-title {
    margin-top: 0;
    font-size: 1.4rem;
    line-height: 2;
    font-weight: normal;
  }
}

.p-list-customers > .item > .link .details .list {
  margin-top: 28px;
}

@media (max-width: 896px) {
  .p-list-customers > .item > .link .details .list {
    margin-top: 14px;
  }
}

.p-list-customers > .item > .link .details .list .item {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.p-list-customers > .item > .link .details .list .item .title {
  margin-right: 15px;
  padding: 2px 0;
  flex: 0 0 auto;
  width: 120px;
  background-color: #979797;
  text-align: center;
  color: #fff;
  border-radius: 3px;
  font-size: 1.2rem;
}

@media (max-width: 896px) {
  .p-list-customers > .item > .link .details .list .item .title {
    margin-right: 8px;
    width: 85px;
  }
}

.p-list-customers > .item > .link .details .list .item .text {
  font-size: 1.3rem;
}

@media (max-width: 896px) {
  .p-list-customers > .item > .link .details .list .item .text {
    font-size: 1.2rem;
  }
}

/* p-features-problem
---------------------------------------------------------- */
.p-features-problem {
  border-top: 1px dotted #979797;
  padding-top: 60px;
}

@media (max-width: 896px) {
  .p-features-problem {
    padding-top: 43px;
  }
}

.p-features-problem .c-heading-04 {
  margin-bottom: 38px;
}

@media (max-width: 896px) {
  .p-features-problem .c-heading-04 {
    margin-bottom: 31px;
  }
}

.p-features-problem .c-btn {
  padding: 30px 45px 26px 55px;
  margin-top: 40px;
}

.p-features-problem .c-btn:hover::before {
  width: 0px;
}

@media (max-width: 896px) {
  .p-features-problem .c-btn {
    padding: 24px 15px 19px;
    margin-top: 8px;
    border-radius: 5px;
  }
}

.p-features-problem .c-btn span {
  font-size: 3rem;
  font-weight: 700;
}

@media (max-width: 896px) {
  .p-features-problem .c-btn span {
    font-size: 2.2rem;
  }
}

@media (min-width: 897px) {
  .p-features-problem .c-btn {
    width: 800px;
  }
}

.p-features-problem .c-btn::after {
  display: none;
}

/* p-features-feature
---------------------------------------------------------- */
.p-features-feature {
  padding-top: 136px;
}

@media (max-width: 896px) {
  .p-features-feature {
    padding-top: 78px;
  }
}

/* p-features-reason
---------------------------------------------------------- */
.p-features-reason {
  padding-top: 160px;
}

@media (max-width: 896px) {
  .p-features-reason {
    padding-top: 70px;
  }
}

@media (min-width: 897px) {
  .p-features-reason > .box-reason {
    display: flex;
  }
}

.p-features-reason > .box-reason .title {
  font-size: 3rem;
  font-weight: 700;
  color: #282828;
  margin-bottom: 9px;
}

@media (max-width: 896px) {
  .p-features-reason > .box-reason .title {
    font-size: 2.2rem;
    margin-bottom: 4px;
  }
}

.p-features-reason > .box-reason .title > .number {
  display: block;
  font-size: 1.4rem;
  color: #45b49b;
}

.p-features-reason > .box-reason .title > .highlight {
  color: #e5004f;
}

@media (max-width: 896px) {
  .p-features-reason > .box-reason .title > .highlight {
    display: block;
  }
}

.p-features-reason > .box-reason .text {
  line-height: 2;
  color: #282828;
}

.p-features-reason > .box-reason.-full {
  width: 100%;
}

@media (max-width: 896px) {
  .p-features-reason > .box-reason.-column .image {
    margin-top: 30px;
    margin-left: -15px;
    margin-right: -15px;
  }
}

.p-features-reason > .box-reason.-column .text {
  margin-top: 20px;
}

@media (max-width: 896px) {
  .p-features-reason > .box-reason.-column .text {
    margin-top: 8px;
    letter-spacing: -.02rem;
  }
}

@media (min-width: 897px) {
  .p-features-reason > .box-reason.-left .image {
    order: 1;
    margin-left: -45px;
  }
}

@media (min-width: 897px) {
  .p-features-reason > .box-reason.-left .detail {
    order: 2;
    padding-left: 40px;
    padding-top: 16px;
  }
}

@media (min-width: 897px) {
  .p-features-reason > .box-reason.-right .image {
    margin-right: -45px;
  }
}

@media (min-width: 897px) {
  .p-features-reason > .box-reason.-right .detail {
    padding-right: 40px;
    padding-top: 16px;
  }
}

@media (min-width: 897px) {
  .p-features-reason > .box-reason > .image {
    width: 785px;
    flex: 1 0 auto;
  }
}

.p-features-reason > .box-reason > .image img {
  width: 100%;
  border-radius: 10px;
}

@media (max-width: 896px) {
  .p-features-reason > .box-reason > .image img {
    border-radius: 5px;
  }
}

.p-features-reason > .box-reason > .detail {
  width: 100%;
}

.p-features-reason > .box-reason + .box-reason {
  margin-top: 170px;
}

@media (max-width: 896px) {
  .p-features-reason > .box-reason + .box-reason {
    margin-top: 90px;
  }
}

.p-features-reason .list-company {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 88px;
}

@media (min-width: 897px) {
  .p-features-reason .list-company {
    margin-left: -73px;
  }
}

@media (max-width: 896px) {
  .p-features-reason .list-company {
    margin-top: 42px;
    justify-content: space-between;
  }
}

.p-features-reason .list-company > .item {
  width: 245px;
}

@media (max-width: 896px) {
  .p-features-reason .list-company > .item {
    width: inherit;
    max-width: 172px;
  }
}

@media (max-width: 360px) {
  .p-features-reason .list-company > .item {
    max-width: 165px;
  }
}

@media (min-width: 897px) {
  .p-features-reason .list-company > .item {
    margin-left: 73px;
  }
  .p-features-reason .list-company > .item:nth-child(n+5) {
    margin-top: 53px;
  }
}

@media (max-width: 896px) {
  .p-features-reason .list-company > .item:nth-child(n+3) {
    margin-top: 15px;
  }
}

/* p-form
---------------------------------------------------------- */
.p-form .box-info {
  padding: 41px 0 22px;
}

@media (max-width: 896px) {
  .p-form .box-info {
    padding: 46px 0 35px;
  }
}

.p-form .box-info > .inner {
  padding-left: 74px;
}

@media (max-width: 896px) {
  .p-form .box-info > .inner {
    padding-left: 47px;
  }
}

.p-form .c-heading-04 {
  line-height: 1.55;
  margin-top: 5px;
  margin-bottom: 4px;
}

@media (max-width: 896px) {
  .p-form .c-heading-04 {
    margin-bottom: 18px;
  }
}

.p-form .text-desc {
  font-weight: 700;
  line-height: 2.4;
}

@media (max-width: 896px) {
  .p-form .text-desc {
    font-size: 1.2rem;
    margin-top: 4px;
  }
}

.p-form .text-tel {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 4rem;
  letter-spacing: .35rem;
  line-height: 1.45;
}

@media (max-width: 896px) {
  .p-form .text-tel {
    font-size: 3.2rem;
    letter-spacing: .1rem;
    line-height: 1.1;
  }
}

.p-form .text-note {
  color: #282828;
}

@media (max-width: 896px) {
  .p-form .text-note {
    font-size: 1.2rem;
    margin-top: 8px;
  }
}

/* p-knowhow-pickup
---------------------------------------------------------- */
.p-knowhow-pickup {
  margin-top: 82px;
}

@media (max-width: 896px) {
  .p-knowhow-pickup {
    margin-top: 58px;
  }
}

.p-knowhow-pickup .c-heading-03 {
  margin-bottom: 24px;
}

@media (max-width: 896px) {
  .p-knowhow-pickup .c-heading-03 {
    margin-bottom: 28px;
  }
}

.p-knowhow-pickup .box-knowhow {
  padding: 37px 22px 32px;
  border-radius: 14px;
  background-color: #e3f4f0;
}

@media (min-width: 897px) {
  .p-knowhow-pickup .box-knowhow {
    padding: 48px 50px 52px;
    margin-left: -50px;
    margin-right: -50px;
    border-radius: 8px;
  }
}

/* p-knowhow-new
---------------------------------------------------------- */
.p-knowhow-new {
  margin-top: 82px;
}

@media (max-width: 896px) {
  .p-knowhow-new {
    margin-top: 80px;
  }
}

.p-knowhow-new .c-heading-03 {
  margin-bottom: 24px;
}

@media (max-width: 896px) {
  .p-knowhow-new .c-heading-03 {
    margin-bottom: 28px;
  }
}

.p-knowhow-new .box-knowhow {
  padding: 0 22px 32px;
  border-radius: 14px;
}

@media (min-width: 897px) {
  .p-knowhow-new .box-knowhow {
    margin-left: -50px;
    margin-right: -50px;
    padding: 48px 50px 52px;
    border-radius: 8px;
    background-color: #f0f0f0;
  }
}

.p-knowhow-new .c-list-pagination {
  padding: 42px 20px 38px;
  margin-top: 100px;
  background-color: #fff;
  border-radius: 10px;
}

@media (max-width: 896px) {
  .p-knowhow-new .c-list-pagination {
    padding: 31px 10px 32px;
    margin-top: 56px;
    background-color: #e3f4f0;
    border-radius: 15px;
    margin-left: -22px;
    margin-right: -22px;
  }
}

/* p-office-intro
---------------------------------------------------------- */
.p-office-intro {
  padding-top: 90px;
  border-top: 1px dotted #979797;
}

@media (max-width: 896px) {
  .p-office-intro {
    padding-top: 57px;
  }
}

.p-office-intro .title {
  position: relative;
  font-size: 3.8rem;
  font-weight: 700;
  color: #282828;
  line-height: 1.25;
  text-align: center;
  padding-bottom: 16px;
  margin-bottom: 49px;
}

@media (max-width: 896px) {
  .p-office-intro .title {
    font-size: 2.7rem;
    padding-bottom: 8px;
    margin-bottom: 21px;
  }
}

.p-office-intro .title::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  background-color: #282828;
  width: 112px;
  height: 6px;
}

@media (max-width: 896px) {
  .p-office-intro .title::after {
    width: 78px;
    height: 5px;
  }
}

.p-office-intro .title > .highlight {
  font-size: 4rem;
  color: #45b49b;
}

@media (max-width: 896px) {
  .p-office-intro .title > .highlight {
    font-size: 2.8rem;
  }
}

.p-office-intro .title > .en {
  display: block;
  font-size: 5rem;
  font-family: "DINAlternateBold", sans-serif;
}

@media (max-width: 896px) {
  .p-office-intro .title > .en {
    font-size: 3rem;
  }
}

@media (min-width: 897px) {
  .p-office-intro .list-intro {
    display: flex;
    margin: 0 -10px;
  }
}

.p-office-intro .list-intro-wrap {
  padding: 26px 21px;
  background-color: #45b49b;
  border-radius: 5px;
}

@media (min-width: 897px) {
  .p-office-intro .list-intro-wrap {
    margin-top: 41px;
    padding: 41px 40px;
    border-radius: 10px;
  }
}

@media (min-width: 897px) {
  .p-office-intro .list-intro > .item {
    width: 33.33%;
    padding: 0 10px;
  }
}

@media (max-width: 896px) {
  .p-office-intro .list-intro > .item:not(:first-child) {
    margin-top: 18px;
  }
}

.p-office-intro .list-intro > .item img {
  width: 100%;
}

/* p-office-recommend
---------------------------------------------------------- */
.p-office-recommend {
  padding-top: 149px;
}

@media (max-width: 896px) {
  .p-office-recommend {
    padding-top: 74px;
  }
}

.p-office-recommend .box-recommend {
  padding: 33px 20px 27px;
  background-color: #f0f0f0;
  border-radius: 10px;
}

@media (max-width: 896px) {
  .p-office-recommend .box-recommend {
    padding: 26px 22px 23px;
    border-radius: 5px;
  }
}

/* p-office-voice
---------------------------------------------------------- */
.p-office-voice {
  padding-top: 100px;
}

@media (max-width: 896px) {
  .p-office-voice {
    padding-top: 50px;
  }
}

.p-office-voice .c-heading-05 {
  margin-bottom: 45px;
}

@media (max-width: 896px) {
  .p-office-voice .c-heading-05 {
    margin-bottom: 28px;
  }
}

@media (min-width: 897px) {
  .p-office-voice .c-list-quote {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media (min-width: 897px) {
  .p-office-voice .c-list-quote > .item {
    width: 404px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 896px) {
  .p-office-voice .c-list-quote > .item:not(:first-child) {
    margin-top: 13px;
  }
}

@media (max-width: 896px) {
  .p-office-voice .c-list-quote img {
    width: 89.4%;
  }
}

/* p-office-movie
---------------------------------------------------------- */
.p-office-movie {
  padding-top: 90px;
  padding-bottom: 59px;
}

@media (max-width: 896px) {
  .p-office-movie {
    padding-top: 61px;
    padding-bottom: 37px;
  }
}

.p-office-movie.c-bg-full::before {
  height: calc(100% - 351px);
}

@media (max-width: 896px) {
  .p-office-movie.c-bg-full::before {
    height: calc(100% - 235px);
  }
}

.p-office-movie .box-title {
  position: relative;
  padding-top: 91px;
  margin-bottom: 24px;
}

@media (max-width: 896px) {
  .p-office-movie .box-title {
    padding-top: 63px;
    margin-bottom: 16px;
  }
}

.p-office-movie .box-title::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 159px;
  width: 406px;
  height: 65px;
  background-image: url(/assets/images/liveoffice/txt_movie_pc.png);
  background-size: contain;
  background-repeat: no-repeat;
}

@media (max-width: 896px) {
  .p-office-movie .box-title::before {
    right: 0;
    width: 305px;
    height: 48px;
    background-image: url(/assets/images/liveoffice/txt_movie_sp.png);
  }
}

.p-office-movie .box-title > .c-heading-05 {
  margin-bottom: -6px;
}

.p-office-movie .box-title > .title-en {
  font-family: "Roboto", sans-serif;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1.75;
  color: #282828;
  text-align: center;
}

@media (max-width: 896px) {
  .p-office-movie .box-title > .title-en {
    font-size: 1.6rem;
  }
}

.p-office-movie .box-title > .sub-title {
  font-size: 2rem;
  font-weight: 700;
  color: #282828;
  text-align: center;
}

@media (max-width: 896px) {
  .p-office-movie .box-title > .sub-title {
    font-size: 1.4rem;
  }
}

@media (min-width: 897px) {
  .p-office-movie .box-content {
    width: 854px;
    margin: 0 auto;
  }
}

.p-office-movie .box-content > .c-box-youtube {
  margin-top: 0;
}

.p-office-movie .box-content > .banner {
  text-align: center;
  margin-top: 69px;
}

@media (max-width: 896px) {
  .p-office-movie .box-content > .banner {
    margin-top: 38px;
  }
}

.p-office-movie .box-content > .c-btn-center {
  margin-top: 65px;
}

@media (max-width: 896px) {
  .p-office-movie .box-content > .c-btn-center {
    margin-top: 35px;
  }
}

.p-office-movie .box-content .c-btn {
  font-weight: 700;
}

@media (max-width: 896px) {
  .p-office-movie .box-content .c-btn {
    width: calc(100% - 50px);
  }
}

/* p-office-kotae
---------------------------------------------------------- */
.p-office-kotae {
  padding-top: 68px;
}

@media (max-width: 896px) {
  .p-office-kotae {
    padding-top: 70px;
  }
}

.p-office-kotae .l-inner > .title {
  position: relative;
  font-size: 3rem;
  font-weight: 700;
  color: #282828;
  line-height: 1.25;
  text-align: center;
  padding-top: 64px;
  padding-bottom: 58px;
  margin-bottom: 86px;
  border-top: 1px dotted #979797;
  border-bottom: 1px dotted #979797;
}

@media (max-width: 896px) {
  .p-office-kotae .l-inner > .title {
    font-size: 2.2rem;
    padding-top: 47px;
    padding-bottom: 28px;
    margin-bottom: 35px;
    line-height: 1.45;
  }
}

.p-office-kotae .l-inner > .title > .highlight {
  font-size: 3.2rem;
  color: #45b49b;
}

@media (max-width: 896px) {
  .p-office-kotae .l-inner > .title > .highlight {
    font-size: 2.2rem;
  }
}

@media (min-width: 897px) {
  .p-office-kotae .list-kotae {
    display: flex;
    flex-wrap: wrap;
    margin: -55px -30px 0;
  }
}

@media (min-width: 897px) {
  .p-office-kotae .list-kotae > .item {
    width: 33.33%;
    padding: 0 30px;
    margin-top: 55px;
  }
}

@media (max-width: 896px) {
  .p-office-kotae .list-kotae > .item:not(:first-child) {
    margin-top: 28px;
  }
}

.p-office-kotae .list-kotae .image {
  overflow: hidden;
  border-radius: 10px;
  clip-path: content-box;
}

@media (max-width: 896px) {
  .p-office-kotae .list-kotae .image {
    border-radius: 5px;
  }
}

.p-office-kotae .list-kotae .image img {
  width: 100%;
}

.p-office-kotae .list-kotae .box-detail {
  display: flex;
  align-items: center;
}

.p-office-kotae .list-kotae .box-detail > .number {
  font-size: 8rem;
  font-family: "DINAlternateBold", sans-serif;
  font-weight: 700;
  color: #064fa0;
}

@media (max-width: 896px) {
  .p-office-kotae .list-kotae .box-detail > .number {
    font-size: 7rem;
    line-height: 1.2;
  }
}

.p-office-kotae .list-kotae .box-detail > .title {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.4;
  color: #282828;
  margin-left: 17px;
}

@media (max-width: 896px) {
  .p-office-kotae .list-kotae .box-detail > .title {
    font-size: 1.6rem;
    line-height: 1.65;
    margin-left: 13px;
  }
}

/* p-office-procedure
---------------------------------------------------------- */
.p-office-procedure {
  padding-top: 91px;
}

@media (max-width: 896px) {
  .p-office-procedure {
    padding-top: 68px;
  }
}

.p-office-procedure .c-heading-03 {
  letter-spacing: 0;
  padding: 64px 0 42px;
  margin-bottom: 85px;
}

@media (max-width: 896px) {
  .p-office-procedure .c-heading-03 {
    padding: 47px 0 34px;
    margin-bottom: 70px;
  }
}

@media (min-width: 897px) {
  .p-office-procedure .list-procedure {
    display: flex;
    flex-wrap: wrap;
    margin: -55px -11px 0;
    padding: 0 7px;
  }
}

@media (max-width: 896px) {
  .p-office-procedure .list-procedure {
    padding: 0 15px;
  }
}

@media (min-width: 897px) {
  .p-office-procedure .list-procedure > .item {
    width: 25%;
    padding: 0 11px;
    margin-top: 55px;
  }
}

@media (max-width: 896px) {
  .p-office-procedure .list-procedure > .item:nth-child(1) .icon img {
    height: 47px;
  }
  .p-office-procedure .list-procedure > .item:nth-child(2) .icon img {
    height: 57px;
  }
  .p-office-procedure .list-procedure > .item:nth-child(3) .icon img {
    height: 64px;
  }
  .p-office-procedure .list-procedure > .item:nth-child(4) .icon img {
    height: 51px;
  }
  .p-office-procedure .list-procedure > .item:not(:first-child) {
    margin-top: 62px;
  }
}

.p-office-procedure .list-procedure .box-detail {
  position: relative;
  border-radius: 10px;
  background-color: #e3f4f0;
  padding: 62px 20px 14px;
}

@media (min-width: 897px) {
  .p-office-procedure .list-procedure .box-detail {
    min-height: 240px;
    height: 100%;
  }
}

@media (max-width: 896px) {
  .p-office-procedure .list-procedure .box-detail {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    padding-top: 43px;
    padding-bottom: 10px;
  }
}

.p-office-procedure .list-procedure .box-detail > .title {
  position: absolute;
  top: -55px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 100px;
  font-size: 1.8rem;
  font-weight: 700;
  color: #fff;
  line-height: 1.35;
  text-align: center;
  padding: 9px 10px 0;
  background-color: #45b49b;
}

@media (max-width: 896px) {
  .p-office-procedure .list-procedure .box-detail > .title {
    top: -47px;
    width: 85px;
    height: 85px;
    font-size: 1.5rem;
    padding: 8px 10px 0;
  }
}

.p-office-procedure .list-procedure .box-detail > .text {
  font-size: 1.4rem;
  line-height: 1.8;
  color: #282828;
}

@media (max-width: 896px) {
  .p-office-procedure .list-procedure .box-detail > .text {
    line-height: 2;
  }
}

@media (min-width: 897px) {
  .p-office-procedure .list-procedure .box-detail > .icon {
    position: absolute;
    bottom: 14px;
    right: 14px;
  }
}

@media (max-width: 896px) {
  .p-office-procedure .list-procedure .box-detail > .icon {
    display: flex;
    justify-content: flex-end;
  }
}

/* p-office-post
---------------------------------------------------------- */
.p-office-post {
  padding-top: 81px;
}

@media (max-width: 896px) {
  .p-office-post {
    padding-top: 66px;
    padding-bottom: 9px;
  }
}

.p-office-post .c-heading-03 {
  padding: 58px 0 51px;
  margin-bottom: 43px;
}

@media (max-width: 896px) {
  .p-office-post .c-heading-03 {
    padding: 45px 0 28px;
    margin-bottom: 40px;
  }
}

@media (min-width: 897px) {
  .p-office-post .list-post {
    display: flex;
    flex-wrap: wrap;
    margin: -83px -30px 0;
  }
}

@media (max-width: 896px) {
  .p-office-post .list-post {
    padding: 0 15px;
  }
}

@media (min-width: 897px) {
  .p-office-post .list-post > .item {
    width: 33.33%;
    padding: 0 30px;
    margin-top: 83px;
  }
}

@media (max-width: 896px) {
  .p-office-post .list-post > .item:not(:first-child) {
    margin-top: 32px;
  }
}

.p-office-post .list-post .image {
  border-radius: 10px;
  overflow: hidden;
  clip-path: content-box;
}

@media (min-width: 897px) {
  .p-office-post .list-post .image {
    height: 240px;
  }
}

@media (min-width: 897px) {
  .p-office-post .list-post .image > * {
    height: 240px;
    border-radius: 10px;
  }
}

@media (max-width: 896px) {
  .p-office-post .list-post .image {
    border-radius: 5px;
  }
}

.p-office-post .list-post .image img {
  width: 100%;
}

.p-office-post .list-post .box-detail {
  margin-top: 15px;
}

@media (max-width: 896px) {
  .p-office-post .list-post .box-detail {
    margin-top: 8px;
  }
}

.p-office-post .list-post .box-detail > .title {
  font-size: 2rem;
  font-weight: 600;
  color: #282828;
  line-height: 1.4;
  letter-spacing: -.05rem;
}

@media (max-width: 896px) {
  .p-office-post .list-post .box-detail > .title {
    font-size: 1.7rem;
    line-height: 1.3;
  }
}

.p-office-post .list-post .box-detail > .address {
  font-size: 1.6rem;
  color: #6f6f6f;
  line-height: 1.8;
  margin-top: 11px;
}

@media (max-width: 896px) {
  .p-office-post .list-post .box-detail > .address {
    font-size: 1.4rem;
  }
}

.p-office-post .list-post .box-detail > .text {
  font-size: 1.4rem;
  color: #6f6f6f;
  line-height: 1.9;
}

@media (max-width: 896px) {
  .p-office-post .list-post .box-detail > .text {
    font-size: 1.2rem;
  }
}

.p-office-post .list-post .list-button {
  display: flex;
  margin: 7px -2px 0;
}

@media (max-width: 896px) {
  .p-office-post .list-post .list-button {
    margin-top: 5px;
  }
}

.p-office-post .list-post .list-button > .item {
  width: 50%;
  padding: 0 2px;
}

.p-office-post .list-post .list-button .button {
  display: block;
  border: 1px solid #a4a4a4;
  border-radius: 3px;
  background-color: #fff;
  text-align: center;
  padding: 5px;
  transition: background-color .3s ease-out;
}

@media (max-width: 896px) {
  .p-office-post .list-post .list-button .button {
    padding: 4px 5px;
  }
}

.p-office-post .list-post .list-button .button > span {
  position: relative;
  font-size: 1.4rem;
  padding-left: 17px;
  transition: color .3s ease-out;
}

@media (max-width: 896px) {
  .p-office-post .list-post .list-button .button > span {
    font-size: 1.2rem;
  }
}

.p-office-post .list-post .list-button .button > span::before {
  content: '';
  position: absolute;
  display: block;
  width: 14px;
  height: 14px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background-image: url(/assets/images/common/ico_arrow_black.png);
  background-repeat: no-repeat;
  background-size: 14px 14px;
  transition: background-image .3s ease-out;
}

@media (max-width: 896px) {
  .p-office-post .list-post .list-button .button > span::before {
    width: 12px;
    height: 12px;
    background-size: 12px 12px;
  }
}

.p-office-post .list-post .list-button .button:hover {
  background-color: #282828;
}

.p-office-post .list-post .list-button .button:hover > span {
  color: #fff;
}

.p-office-post .list-post .list-button .button:hover > span::before {
  background-image: url(/assets/images/common/ico_arrow_white.png);
}

.p-branch > .c-heading-03 {
  margin-bottom: 60px;
}

@media (max-width: 896px) {
  .p-branch > .c-heading-03 {
    margin-bottom: 40px;
  }
}

/* p-branch-intro
---------------------------------------------------------- */
.p-branch-intro {
  padding-top: 75px;
  padding-bottom: 143px;
  position: relative;
}

@media (max-width: 896px) {
  .p-branch-intro {
    padding-top: 50px;
    padding-bottom: 54px;
  }
}

@media (min-width: 897px) {
  .p-branch-intro.-kyusyu {
    padding-bottom: 310px;
  }
}

@media (min-width: 897px) {
  .p-branch-intro.-hokkaido {
    padding-bottom: 295px;
  }
}

.p-branch-intro > .title {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 18px;
}

@media (max-width: 896px) {
  .p-branch-intro > .title {
    font-size: 1.6rem;
    margin-bottom: 8px;
  }
}

.p-branch-intro > .text {
  color: #282828;
  line-height: 2.375;
}

@media (min-width: 897px) {
  .p-branch-intro > .text {
    width: 790px;
  }
}

@media (max-width: 896px) {
  .p-branch-intro > .text {
    line-height: 2;
  }
}

.p-branch-intro > .image {
  position: absolute;
  top: 0;
  right: -100px;
  z-index: -1;
}

@media (max-width: 896px) {
  .p-branch-intro > .image {
    right: -15px;
  }
}

@media (min-width: 897px) {
  .p-branch-intro .list-gallery {
    display: flex;
    flex-wrap: wrap;
    margin: -40px -15px 0;
  }
}

.p-branch-intro .list-gallery-wrap {
  margin-top: 50px;
}

@media (max-width: 896px) {
  .p-branch-intro .list-gallery-wrap {
    margin-top: 40px;
  }
}

.p-branch-intro .list-gallery > .item {
  margin-top: 15px;
}

@media (min-width: 897px) {
  .p-branch-intro .list-gallery > .item {
    width: 33.33%;
    padding: 0 15px;
    margin-top: 40px;
  }
}

.p-branch-intro .list-gallery .image {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  clip-path: content-box;
}

@media (min-width: 897px) {
  .p-branch-intro .list-gallery .image {
    height: 270px;
  }
}

@media (max-width: 896px) {
  .p-branch-intro .list-gallery .image {
    border-radius: 5px;
  }
}

@media (min-width: 897px) {
  .p-branch-intro .list-gallery .image > * {
    height: 270px;
    border-radius: 10px;
  }
}

.p-branch-intro .list-gallery .image img {
  width: 100%;
}

/* p-branch-tour
---------------------------------------------------------- */
@media (min-width: 897px) {
  .p-branch-tour .c-heading-03.-hokkaido {
    line-height: 2;
    padding: 50px 0 40px;
  }
}

.p-branch-tour > .box-title {
  text-align: center;
}

@media (max-width: 896px) {
  .p-branch-tour > .box-title .c-heading-05.-hokkaido {
    letter-spacing: -.14rem;
  }
}

.p-branch-tour > .box-title > .sub-title {
  font-weight: 700;
  line-height: 1.3;
}

@media (max-width: 896px) {
  .p-branch-tour > .box-title > .sub-title {
    font-size: 1.2rem;
    line-height: 1.9;
  }
}

.p-branch-tour .list-tour-wrap + .box-title {
  margin-top: 45px;
}

.p-branch-tour > .box-note {
  margin-top: 27px;
}

@media (max-width: 896px) {
  .p-branch-tour > .box-note {
    margin-top: 20px;
  }
}

.p-branch-tour > .box-note > .text {
  font-size: 2rem;
  font-weight: 700;
  line-height: 3;
  padding-bottom: 2px;
  text-align: center;
}

@media (max-width: 896px) {
  .p-branch-tour > .box-note > .text {
    font-size: 1.4rem;
    line-height: 2.5;
  }
}

@media (max-width: 896px) {
  .p-branch-tour .c-list-note > .item {
    margin-top: 7px;
    justify-content: center;
  }
}

@media (min-width: 897px) {
  .p-branch-tour .list-tour {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -40px -15px 0;
  }
}

.p-branch-tour .list-tour-wrap {
  margin-top: 56px;
}

@media (max-width: 896px) {
  .p-branch-tour .list-tour-wrap {
    margin-top: 62px;
  }
}

@media (min-width: 897px) {
  .p-branch-tour .list-tour.-center {
    justify-content: center;
  }
}

.p-branch-tour .list-tour > .item {
  margin-top: 24px;
}

@media (min-width: 897px) {
  .p-branch-tour .list-tour > .item {
    width: 33.33%;
    padding: 0 15px;
    margin-top: 40px;
  }
}

.p-branch-tour .list-tour > .item .link {
  display: block;
  overflow: hidden;
}

@media (min-width: 897px) {
  .p-branch-tour .list-tour > .item .link .img-zoom {
    overflow: hidden;
    position: relative;
    transition: transform 1s;
    transform: scale(1);
  }
  .p-branch-tour .list-tour > .item .link .img-zoom::before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #45b49b;
    opacity: 0;
    z-index: 1;
    transition: opacity .2s ease-out;
    border-radius: 10px;
  }
  .p-branch-tour .list-tour > .item .link .img-zoom img {
    transition: transform .2s;
  }
  .p-branch-tour .list-tour > .item .link:hover .img-zoom {
    overflow: hidden;
    transform: scale(1.1);
  }
  .p-branch-tour .list-tour > .item .link:hover .img-zoom::before {
    opacity: .6;
  }
  .p-branch-tour .list-tour > .item .link:hover .img-zoom img {
    transform: scale(1.1);
  }
}

.p-branch-tour .list-tour .image {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  clip-path: content-box;
}

@media (min-width: 897px) {
  .p-branch-tour .list-tour .image {
    height: 270px;
  }
}

@media (max-width: 896px) {
  .p-branch-tour .list-tour .image {
    border-radius: 5px;
  }
}

@media (min-width: 897px) {
  .p-branch-tour .list-tour .image > * {
    height: 270px;
    border-radius: 10px;
  }
}

.p-branch-tour .list-tour .image img {
  width: 100%;
}

.p-branch-tour .list-tour .box-detail {
  margin-top: 18px;
}

@media (max-width: 896px) {
  .p-branch-tour .list-tour .box-detail {
    margin-top: 6px;
  }
}

.p-branch-tour .list-tour .box-detail > .title {
  font-size: 2rem;
  font-weight: 700;
  color: #45b49b;
}

@media (max-width: 896px) {
  .p-branch-tour .list-tour .box-detail > .title {
    font-size: 1.4rem;
  }
}

.p-branch-tour .list-tour .box-detail > .text {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.8;
  color: #282828;
}

.p-branch-tour > .c-box-button {
  width: 100%;
  margin-top: 94px;
  padding: 50px 0 55px;
}

@media (max-width: 896px) {
  .p-branch-tour > .c-box-button {
    margin-top: 28px;
    padding: 26px 0 28px;
  }
}

@media (max-width: 896px) {
  .p-branch-tour > .c-box-button .c-btn {
    width: calc(100% - 44px);
  }
}

.p-branch-tour > .c-box-button > .title {
  font-size: 2rem;
  font-weight: 700;
  color: #282828;
  margin-bottom: 15px;
}

@media (max-width: 896px) {
  .p-branch-tour > .c-box-button > .title {
    font-size: 1.4rem;
    margin-bottom: 10px;
  }
}

/* p-branch-map
---------------------------------------------------------- */
.p-branch-map {
  padding-top: 122px;
}

@media (max-width: 896px) {
  .p-branch-map {
    padding-top: 65px;
  }
}

.p-branch-map .sec-map > .title {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.3;
  text-align: center;
}

@media (max-width: 896px) {
  .p-branch-map .sec-map > .title {
    font-size: 2.2rem;
  }
}

.p-branch-map .sec-map > .text-desc {
  line-height: 1.9;
  text-align: center;
  color: #25253e;
}

.p-branch-map .sec-map > .text-desc span {
  color: #45b49b;
}

.p-branch-map .sec-map > .box-map {
  margin-top: 40px;
}

@media (max-width: 896px) {
  .p-branch-map .sec-map > .box-map {
    margin-top: 13px;
  }
}

.p-branch-map .sec-map > .box-map > iframe {
  width: 100%;
  height: 404px;
}

@media (max-width: 896px) {
  .p-branch-map .sec-map > .box-map > iframe {
    height: 348px;
  }
}

.p-branch-map .sec-map + .sec-map {
  margin-top: 90px;
}

@media (max-width: 896px) {
  .p-branch-map .sec-map + .sec-map {
    margin-top: 50px;
  }
}

/* p-branch-customer
---------------------------------------------------------- */
.p-branch-customer {
  padding-top: 148px;
}

@media (max-width: 896px) {
  .p-branch-customer {
    padding-top: 70px;
  }
}

.p-branch-customer .p-list-customers {
  border-bottom: none;
  padding-bottom: 50px;
}

.p-branch-customer .p-list-customers > .item:not(:first-child) {
  padding-top: 0;
  border-top: none;
}

@media (max-width: 896px) {
  .p-branch-customer .p-list-customers > .item:not(:first-child) {
    margin-top: 40px;
  }
}

/* p-download
---------------------------------------------------------- */
.p-download {
  padding-top: 37px;
  padding-bottom: 95px;
  position: relative;
}

@media (max-width: 896px) {
  .p-download {
    padding-top: 35px;
    padding-bottom: 40px;
  }
}

.p-download > .box-title {
  display: flex;
}

.p-download > .box-title > .icon {
  width: 57px;
  flex: 1 0 auto;
  position: relative;
  top: 4px;
  left: 4px;
}

@media (max-width: 896px) {
  .p-download > .box-title > .icon {
    width: 38px;
    top: 3px;
    left: 2px;
  }
}

.p-download > .box-title > .icon img {
  width: 57px;
}

@media (max-width: 896px) {
  .p-download > .box-title > .icon img {
    width: 38px;
  }
}

.p-download > .box-title > .title {
  width: 100%;
  font-size: 2.4rem;
  font-weight: 700;
  margin-left: 18px;
}

@media (max-width: 896px) {
  .p-download > .box-title > .title {
    font-size: 1.6rem;
    line-height: 1.6;
    margin-left: 10px;
  }
}

.p-download .list-download {
  margin-top: -80px;
}

@media (min-width: 897px) {
  .p-download .list-download {
    display: flex;
    flex-wrap: wrap;
    margin: -137px -29px 0;
  }
}

.p-download .list-download-wrap {
  padding: 93px 66px 123px;
  margin-top: 45px;
  background-color: #f0f0f0;
  border-radius: 10px;
}

@media (max-width: 896px) {
  .p-download .list-download-wrap {
    margin-top: 11px;
    padding: 40px 39px 68px;
    border-right: 5px;
  }
}

.p-download .list-download > .item {
  margin-top: 80px;
}

@media (min-width: 897px) {
  .p-download .list-download > .item {
    width: 33.33%;
    padding: 0 29px;
    margin-top: 137px;
  }
}

.p-download .card-download {
  padding-right: 4px;
}

@media (max-width: 896px) {
  .p-download .card-download {
    padding-right: 0;
  }
}

.p-download .card-download > .box-detail {
  margin-top: 13px;
}

@media (max-width: 896px) {
  .p-download .card-download > .box-detail {
    margin-top: 17px;
  }
}

.p-download .card-download > .box-detail > .text {
  line-height: 1.7;
  text-align: center;
}

.p-download .card-download > .box-detail > .c-btn {
  margin-top: 15px;
}

@media (max-width: 896px) {
  .p-download .card-download > .box-detail > .c-btn {
    width: calc(100% - 10px);
    margin-top: 10px;
  }
}

.p-download .card-download > .title {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 22px;
}

@media (max-width: 896px) {
  .p-download .card-download > .title {
    font-size: 1.6rem;
  }
}

.p-download .card-download > .image {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  clip-path: content-box;
}

@media (min-width: 897px) {
  .p-download .card-download > .image {
    height: 310px;
  }
}

@media (max-width: 896px) {
  .p-download .card-download > .image {
    border-radius: 5px;
  }
}

@media (min-width: 897px) {
  .p-download .card-download > .image > * {
    height: 310px;
    border-radius: 10px;
  }
}

.p-download .card-download > .image img {
  width: 100%;
}

/* p-request-form
---------------------------------------------------------- */
.p-request-form {
  padding-top: 44px;
}

@media (max-width: 896px) {
  .p-request-form {
    padding-top: 20px;
  }
}

.p-request-form .c-heading-04 {
  line-height: 1.55;
  margin-top: 5px;
}

@media (max-width: 896px) {
  .p-request-form .c-heading-04 {
    margin-bottom: 18px;
  }
}

.p-request-form .text-desc {
  font-weight: 700;
}

@media (max-width: 896px) {
  .p-request-form .text-desc {
    font-size: 1.2rem;
  }
}

/* p-seminar-detail
--------------------------------------------------------- */
.p-seminar-detail {
  /* box-detail */
  /* box-seminar-date */
  /* box-seminar-recommended */
}

.p-seminar-detail .box-detail {
  margin-top: 15px;
}

@media (max-width: 896px) {
  .p-seminar-detail .box-detail {
    margin-top: 12px;
  }
}

.p-seminar-detail .box-detail .mainimg + * {
  margin-top: 37px;
}

@media (max-width: 896px) {
  .p-seminar-detail .box-detail .mainimg + * {
    margin-top: 24px;
  }
}

.p-seminar-detail .box-detail p {
  line-height: 2.375;
}

@media (max-width: 896px) {
  .p-seminar-detail .box-detail p {
    line-height: 2;
  }
}

.p-seminar-detail .box-detail p + p {
  margin-top: 37px;
}

.p-seminar-detail .box-detail a {
  color: #0000ff;
}

@media (min-width: 897px) {
  .p-seminar-detail .box-detail a {
    opacity: 1;
    transition: opacity .3s ease-out;
  }
  .p-seminar-detail .box-detail a:hover {
    opacity: 0.6;
    text-decoration: none;
  }
}

@media (min-width: 897px) {
  .p-seminar-detail .box-detail a:hover {
    text-decoration: underline;
  }
}

.p-seminar-detail .box-seminar-date {
  margin-top: 45px;
}

@media (max-width: 896px) {
  .p-seminar-detail .box-seminar-date {
    margin-top: 27px;
  }
}

.p-seminar-detail .box-seminar-date .c-btn-center {
  margin-top: 27px;
}

@media (max-width: 896px) {
  .p-seminar-detail .box-seminar-date .c-btn-center {
    margin-top: 21px;
    margin-left: -11px;
  }
}

.p-seminar-detail .box-seminar-recommended {
  margin-top: 90px;
}

@media (max-width: 896px) {
  .p-seminar-detail .box-seminar-recommended {
    margin-top: 35px;
  }
}

@media (max-width: 896px) {
  .p-seminar-detail .box-seminar-recommended .c-heading-04 {
    margin-bottom: 11px;
  }
}

/* p-seminar-timeline
--------------------------------------------------------- */
.p-seminar-timeline {
  padding-top: 117px;
  padding-bottom: 18px;
}

@media (max-width: 896px) {
  .p-seminar-timeline {
    padding-top: 72px;
  }
}

.p-seminar-timeline .box-link .title {
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 5px;
}

@media (max-width: 896px) {
  .p-seminar-timeline .box-link .title {
    margin-bottom: 2px;
  }
}

@media (max-width: 896px) {
  .p-seminar-timeline .c-box-button {
    margin-top: 30px;
  }
}

.p-seminar-timeline .c-box-line {
  margin-top: 0;
}

.p-seminar-timeline * + .c-box-line {
  margin-top: 70px;
}

@media (max-width: 896px) {
  .p-seminar-timeline * + .c-box-line {
    margin-top: 44px;
  }
}

/* p-seminar-form
--------------------------------------------------------- */
.p-seminar-form .c-heading-04 {
  margin-top: 30px;
  margin-bottom: 10px;
}

@media (max-width: 896px) {
  .p-seminar-form .c-heading-04 {
    margin-top: 16px;
    margin-bottom: 5px;
  }
}

.p-seminar-form .text-desc {
  font-weight: 700;
}

@media (max-width: 896px) {
  .p-seminar-form .text-desc {
    font-size: 1.2rem;
  }
}

/* p-section-seminar
---------------------------------------------------------- */
.p-section-seminar {
  padding-top: 80px;
  border-top: 1px dotted #7f7f7f;
}

@media (max-width: 896px) {
  .p-section-seminar {
    padding-top: 52px;
  }
}

.p-section-seminar-report {
  margin-top: 196px;
}

@media (max-width: 896px) {
  .p-section-seminar-report {
    margin-top: 90px;
  }
}

/* p-service-intro
---------------------------------------------------------- */
.p-service-intro {
  border-top: 1px dotted #979797;
  padding-top: 76px;
}

@media (max-width: 896px) {
  .p-service-intro {
    padding-top: 35px;
  }
}

.p-service-intro > .box-intro {
  position: relative;
}

@media (min-width: 897px) {
  .p-service-intro > .box-intro .detail {
    width: 700px;
  }
}

.p-service-intro > .box-intro .detail > .title {
  font-size: 3rem;
  font-weight: 700;
}

@media (max-width: 896px) {
  .p-service-intro > .box-intro .detail > .title {
    font-size: 1.6rem;
  }
}

.p-service-intro > .box-intro .detail > .text {
  line-height: 2.4;
  margin-top: 20px;
}

@media (min-width: 897px) {
  .p-service-intro > .box-intro .detail > .text {
    width: 600px;
  }
}

@media (max-width: 896px) {
  .p-service-intro > .box-intro .detail > .text {
    line-height: 2;
    margin-top: 9px;
  }
}

@media (min-width: 897px) {
  .p-service-intro > .box-intro .image {
    position: absolute;
    top: -4px;
    right: -2px;
  }
}

@media (max-width: 896px) {
  .p-service-intro > .box-intro .image {
    width: calc(100% - 45px);
    text-align: center;
    margin: 30px auto 0;
  }
}

.p-service-intro > .box-intro .c-list-button {
  margin-top: 40px;
}

@media (max-width: 896px) {
  .p-service-intro > .box-intro .c-list-button {
    background-color: #e3f4f0;
    padding: 36px 24px 37px;
    border-radius: 15px;
    margin-top: 36px;
  }
}

/* p-service-menu
---------------------------------------------------------- */
.p-service-menu {
  padding-top: 104px;
}

@media (max-width: 896px) {
  .p-service-menu {
    padding-top: 43px;
  }
}

.p-service-menu .c-heading-03 {
  margin-bottom: 87px;
}

.p-service-menu .c-box-button {
  width: 100%;
  margin-top: 70px;
}

@media (max-width: 896px) {
  .p-service-menu .c-box-button {
    margin-top: 55px;
  }
}

/* p-service-flow
---------------------------------------------------------- */
.p-service-flow {
  padding-top: 80px;
}

@media (max-width: 896px) {
  .p-service-flow {
    padding-top: 50px;
  }
}

@media (min-width: 897px) {
  .p-service-flow .c-heading-03 {
    border-top: none;
    padding-bottom: 20px;
    margin-bottom: 64px;
  }
}

/* p-service-faq
---------------------------------------------------------- */
.p-service-faq {
  padding-top: 64px;
  padding-bottom: 50px;
}

@media (max-width: 896px) {
  .p-service-faq {
    padding-top: 50px;
    padding-bottom: 27px;
  }
}

@media (min-width: 897px) {
  .p-service-faq .c-heading-03 {
    border-top: none;
    padding-bottom: 20px;
    margin-bottom: 47px;
  }
}

@media (max-width: 896px) {
  .p-service-faq .c-heading-03 {
    margin-bottom: 13px;
  }
}

/* p-top-mainimg
---------------------------------------------------------- */
@media (max-width: 896px) {
  .p-top-mainimg {
    margin-left: -15px;
    margin-right: -15px;
    padding-top: 65px;
  }
}

.p-top-mainimg > .inner {
  position: relative;
}

.p-top-mainimg .box-detail {
  position: absolute;
  left: 24px;
}

@media (min-width: 897px) {
  .p-top-mainimg .box-detail {
    bottom: 46px;
  }
}

@media (max-width: 896px) {
  .p-top-mainimg .box-detail {
    top: 50%;
    left: 18px;
    transform: translateY(-50%);
    z-index: 2;
  }
}

.p-top-mainimg .box-detail > .title {
  font-size: 5.2rem;
  font-weight: 700;
  line-height: 1.45;
  color: #282828;
  margin-top: 14px;
}

@media (min-width: 897px) {
  .p-top-mainimg .box-detail > .title {
    text-shadow: 0px -1px 20px white;
  }
}

@media (max-width: 896px) {
  .p-top-mainimg .box-detail > .title {
    font-size: 3.1rem;
    line-height: 1;
    margin-top: 10px;
  }
}

@media (max-width: 360px) {
  .p-top-mainimg .box-detail > .title {
    letter-spacing: -.1rem;
  }
}

@media (max-width: 896px) {
  .p-top-mainimg .box-detail > .title span {
    display: inline-block;
    padding-top: 3px;
    background-color: rgba(255, 255, 255, 0.7);
  }
  .p-top-mainimg .box-detail > .title span:not(:first-child) {
    margin-top: 10px;
  }
}

.p-top-mainimg .box-detail > .text-desc {
  font-size: 1.6rem;
  font-weight: 600;
}

@media (max-width: 896px) {
  .p-top-mainimg .box-detail > .text-desc {
    display: inline-block;
    font-size: 1rem;
    padding: 0 2px;
    line-height: 1.7;
    background-color: rgba(255, 255, 255, 0.7);
  }
}

.p-top-mainimg .box-detail > .c-btn {
  margin-top: 102px;
}

.p-top-mainimg .box-video {
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
}

@media (min-width: 897px) {
  .p-top-mainimg .box-video {
    margin-right: -100px;
  }
}

.p-top-mainimg .video {
  overflow: hidden;
}

@media (min-width: 897px) {
  .p-top-mainimg .video {
    width: 836px;
    height: 610px;
  }
}

@media (max-width: 896px) {
  .p-top-mainimg .video {
    display: block;
    position: relative;
    height: 440px;
    width: calc((100vw * 331) / 375);
    overflow: hidden;
    margin-right: 0;
    border-bottom-left-radius: calc((100vw * 46) / 375);
    -webkit-border-bottom-left-radius: 46px;
    -webkit-transform: translateZ(0);
  }
}

@media (min-width: 897px) {
  .p-top-mainimg .video video {
    height: 100%;
    border-bottom-left-radius: 95px;
  }
}

@media (max-width: 896px) {
  .p-top-mainimg .video video {
    position: absolute;
    height: 440px;
    width: auto;
    display: block;
    top: 0;
    right: 50%;
    transform: translateX(50%);
  }
}

@media (max-width: 896px) {
  .p-top-mainimg .c-btn {
    margin-top: 24px;
  }
}

/* p-top-service
---------------------------------------------------------- */
.p-top-service {
  padding-top: 120px;
}

@media (min-width: 897px) {
  .p-top-service {
    display: flex;
  }
}

@media (max-width: 896px) {
  .p-top-service {
    padding-top: 53px;
  }
}

@media (min-width: 897px) {
  .p-top-service .box-detail {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 50%;
    padding-left: 140px;
    padding-right: 80px;
  }
}

@media (max-width: 896px) {
  .p-top-service .box-detail {
    margin-top: 48px;
  }
}

.p-top-service .box-detail > .text-desc {
  font-size: 2rem;
  font-weight: 600;
  color: #282828;
  line-height: 1.7;
  letter-spacing: .11rem;
}

@media (max-width: 896px) {
  .p-top-service .box-detail > .text-desc {
    font-size: 1.4rem;
    line-height: 2;
  }
}

.p-top-service .box-detail > .text {
  margin-top: 13px;
  line-height: 1.9;
  color: #505050;
}

@media (max-width: 896px) {
  .p-top-service .box-detail > .text {
    line-height: 2;
    margin-top: 7px;
  }
}

.p-top-service .box-detail .c-btn {
  margin-top: 25px;
}

@media (max-width: 896px) {
  .p-top-service .box-detail .c-btn {
    margin-top: 17px;
  }
}

@media (min-width: 897px) {
  .p-top-service .image {
    width: calc(50% + 100px);
    margin-left: -100px;
  }
}

@media (max-width: 896px) {
  .p-top-service .image {
    margin-left: -15px;
    padding-right: 5px;
  }
}

/* p-top-voice
---------------------------------------------------------- */
.p-top-voice {
  padding-top: 218px;
}

@media (max-width: 896px) {
  .p-top-voice {
    padding-top: 70px;
    padding-bottom: 0;
  }
}

.p-top-voice .c-bg-full::before {
  height: calc(100% - 96px);
}

@media (max-width: 896px) {
  .p-top-voice .c-list-voice-wrap {
    padding-bottom: 51px;
  }
}

@media (min-width: 897px) {
  .p-top-voice .c-list-voice {
    margin-bottom: 63px;
  }
}

@media (min-width: 897px) {
  .p-top-voice .c-btn-center {
    position: relative;
    bottom: -28px;
  }
}

@media (max-width: 896px) {
  .p-top-voice .c-btn-center {
    margin-top: 38px;
  }
}

/* p-top-feature
---------------------------------------------------------- */
.p-top-feature {
  padding-top: 233px;
  padding-bottom: 26px;
}

@media (max-width: 896px) {
  .p-top-feature {
    padding-top: 50px;
    padding-bottom: 0;
  }
}

@media (min-width: 897px) {
  .p-top-feature .box-feature {
    display: flex;
    margin-bottom: -10px;
  }
}

@media (max-width: 896px) {
  .p-top-feature .box-feature {
    padding: 0 10px;
  }
}

@media (min-width: 897px) {
  .p-top-feature .list-feature {
    display: flex;
    flex: 1 0 auto;
    width: 780px;
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media (min-width: 897px) {
  .p-top-feature .list-feature > .item {
    width: 33.33%;
    padding: 0 15px;
  }
}

@media (max-width: 896px) {
  .p-top-feature .list-feature > .item {
    margin-top: 2px;
  }
}

.p-top-feature .list-feature .link {
  display: block;
}

@media (min-width: 897px) {
  .p-top-feature .list-feature .link {
    opacity: 1;
    transition: opacity .3s ease-out;
  }
  .p-top-feature .list-feature .link:hover {
    opacity: 0.6;
    text-decoration: none;
  }
}

.p-top-feature .card-feature {
  /*&::after {
      @include mq-down(md) {
        position: absolute;
        content: '';
        top: 50%;
        right: -10px;
        transform: translateY(-50%);
        background-image: url(/assets/images/common/ico_arrow_black.png);
        background-repeat: no-repeat;
        width: 22px;
        height: 22px;
        background-size: 22px 22px;
      }
    }*/
}

@media (max-width: 896px) {
  .p-top-feature .card-feature {
    position: relative;
    text-align: center;
    background-color: #45b49b;
    border-radius: 10px;
    padding: 33px 5px 26px;
  }
}

@media (max-width: 896px) {
  .p-top-feature .card-feature img {
    height: 66px;
  }
}

.p-top-feature .image {
  width: 100%;
}

@media (min-width: 897px) {
  .p-top-feature .image {
    margin-right: -60px;
  }
}

@media (max-width: 896px) {
  .p-top-feature .image {
    position: relative;
    width: 98%;
    margin-top: 21px;
    margin-left: 7px;
  }
}

@media (min-width: 897px) {
  .p-top-feature .image img {
    position: relative;
    left: 14px;
    top: -50px;
  }
}

@media (max-width: 896px) {
  .p-top-feature .c-btn-center {
    margin-top: 22px;
  }
}

/* p-top-achievement
---------------------------------------------------------- */
.p-top-achievement {
  padding-top: 147px;
}

@media (max-width: 896px) {
  .p-top-achievement {
    padding-top: 88px;
  }
}

.p-top-achievement .box-slider {
  overflow: hidden;
}

.p-top-achievement .image img {
  width: 100%;
}

.p-top-achievement .list-slider .item {
  position: relative;
}

@media (max-width: 896px) {
  .p-top-achievement .list-slider .l-inner {
    display: none;
  }
}

.p-top-achievement .list-slider .box-caption {
  position: relative;
  display: block;
}

.p-top-achievement .list-slider .box-caption .caption {
  position: absolute;
  left: 0;
  bottom: 20px;
  font-weight: 700;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  padding: 29px 39px 26px 34px;
}

.p-top-achievement .list-slider .box-caption .caption > .text {
  letter-spacing: .1rem;
}

.p-top-achievement .list-slider .box-caption .caption > .title {
  font-size: 2rem;
  letter-spacing: .2rem;
  margin-top: 8px;
}

.p-top-achievement .c-btn-center {
  margin-top: 10px;
}

@media (max-width: 896px) {
  .p-top-achievement .c-btn-center {
    margin-top: 15px;
  }
}

/* p-top-seminar
---------------------------------------------------------- */
.p-top-seminar {
  padding-top: 119px;
}

@media (max-width: 896px) {
  .p-top-seminar {
    padding-top: 75px;
  }
}

.p-top-seminar .c-bg-left::before {
  height: calc(100% - 52px);
}

@media (max-width: 896px) {
  .p-top-seminar .c-bg-left::before {
    height: calc(100% - 28px);
  }
}

@media (min-width: 897px) {
  .p-top-seminar .c-btn-center {
    margin-top: 42px;
  }
}

@media (max-width: 896px) {
  .p-top-seminar .c-btn-center {
    margin-top: 25px;
  }
}

/* p-top-know
---------------------------------------------------------- */
.p-top-know {
  padding-top: 162px;
}

@media (max-width: 896px) {
  .p-top-know {
    padding-top: 92px;
  }
}

@media (min-width: 897px) {
  .p-top-know .c-btn-center {
    margin-top: 42px;
  }
}

@media (max-width: 896px) {
  .p-top-know .c-btn-center {
    margin-top: 28px;
  }
}

/* p-top-tour
---------------------------------------------------------- */
.p-top-tour {
  padding-top: 148px;
}

@media (max-width: 896px) {
  .p-top-tour {
    padding-top: 97px;
  }
}

@media (min-width: 897px) {
  .p-top-tour .box-tour {
    display: flex;
  }
}

@media (min-width: 897px) {
  .p-top-tour .box-tour > .image {
    position: relative;
    z-index: 1;
    width: 800px;
    flex: 1 0 auto;
  }
}

@media (max-width: 896px) {
  .p-top-tour .box-tour > .image {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.p-top-tour .box-tour > .detail {
  background-color: #e3f4f0;
  border-radius: 15px;
  margin-top: 60px;
  padding: 61px 10px 52px 127px;
}

@media (max-width: 896px) {
  .p-top-tour .box-tour > .detail {
    position: relative;
    z-index: 1;
    margin-top: -23px;
    padding: 30px 25px 20px 25px;
  }
}

@media (min-width: 897px) {
  .p-top-tour .box-tour > .detail {
    width: 100%;
    margin-left: -100px;
    padding-left: 127px;
    border-radius: 30px;
  }
}

@media (min-width: 897px) {
  .p-top-tour .box-tour > .detail > .c-heading-01 {
    font-size: 3rem;
    margin-bottom: 21px;
  }
}

@media (max-width: 896px) {
  .p-top-tour .box-tour > .detail > .c-heading-01 {
    margin-bottom: 11px;
  }
}

.p-top-tour .box-tour > .detail > .text {
  font-size: 1.5rem;
  line-height: 1.8;
  color: #505050;
}

@media (max-width: 896px) {
  .p-top-tour .box-tour > .detail > .text {
    font-size: 1.2rem;
    line-height: 1.6;
  }
}

.p-top-tour .box-tour > .detail > .c-btn {
  margin-top: 15px;
  padding: 16px 12px 14px 19px;
}

@media (max-width: 896px) {
  .p-top-tour .box-tour > .detail > .c-btn {
    margin-top: 12px;
    padding: 13px 12px 11px 19px;
  }
}

/* ==========================================================
 helpers
========================================================== */
.c-bg-full {
  position: relative;
}

.c-bg-full::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.c-bg-full.-green::before {
  background-color: #e3f4f0;
}

.c-bg-full.-gray::before {
  background-color: #f0f0f0;
}

.c-bg-left {
  position: relative;
}

.c-bg-left::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(((100% - 1400px) / 2) + 1350px);
  height: 100%;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  z-index: -1;
}

@media (max-width: 896px) {
  .c-bg-left::before {
    width: calc(100% - 25px);
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}

.c-bg-left.-green::before {
  background-color: #e3f4f0;
}

.c-bg-right {
  position: relative;
}

.c-bg-right::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: calc(((100% - 1400px) / 2) + 1350px);
  height: 100%;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  z-index: -1;
}

@media (max-width: 896px) {
  .c-bg-right::before {
    width: calc(100% - 15px);
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }
}

.c-bg-right.-green::before {
  background-color: #e3f4f0;
}

.c-bg-right.-gray::before {
  background-color: #f0f0f0;
}

._d-none {
  display: none !important;
}

._d-inline {
  display: inline !important;
}

._d-inline-block {
  display: inline-block !important;
}

._d-block {
  display: block !important;
}

._d-table {
  display: table !important;
}

._d-table-row {
  display: table-row !important;
}

._d-table-cell {
  display: table-cell !important;
}

._d-flex {
  display: flex !important;
}

._d-inline-flex {
  display: inline-flex !important;
}

@media (max-width: 896px) {
  ._d-sm-none {
    display: none !important;
  }
  ._d-sm-inline {
    display: inline !important;
  }
  ._d-sm-inline-block {
    display: inline-block !important;
  }
  ._d-sm-block {
    display: block !important;
  }
  ._d-sm-table {
    display: table !important;
  }
  ._d-sm-table-row {
    display: table-row !important;
  }
  ._d-sm-table-cell {
    display: table-cell !important;
  }
  ._d-sm-flex {
    display: flex !important;
  }
  ._d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 897px) {
  ._d-md-none {
    display: none !important;
  }
  ._d-md-inline {
    display: inline !important;
  }
  ._d-md-inline-block {
    display: inline-block !important;
  }
  ._d-md-block {
    display: block !important;
  }
  ._d-md-table {
    display: table !important;
  }
  ._d-md-table-row {
    display: table-row !important;
  }
  ._d-md-table-cell {
    display: table-cell !important;
  }
  ._d-md-flex {
    display: flex !important;
  }
  ._d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1601px) {
  ._d-lg-none {
    display: none !important;
  }
  ._d-lg-inline {
    display: inline !important;
  }
  ._d-lg-inline-block {
    display: inline-block !important;
  }
  ._d-lg-block {
    display: block !important;
  }
  ._d-lg-table {
    display: table !important;
  }
  ._d-lg-table-row {
    display: table-row !important;
  }
  ._d-lg-table-cell {
    display: table-cell !important;
  }
  ._d-lg-flex {
    display: flex !important;
  }
  ._d-lg-inline-flex {
    display: inline-flex !important;
  }
}

.js-fadein,
.js-fadein-step {
  opacity: 0;
  visibility: hidden;
  transition: opacity .4s ease-out;
}

.js-fadein.is-show,
.js-fadein-step.is-show {
  opacity: 1;
  visibility: visible;
}

._flex-row {
  flex-direction: row !important;
}

._flex-column {
  flex-direction: column !important;
}

._flex-row-reverse {
  flex-direction: row-reverse !important;
}

._flex-column-reverse {
  flex-direction: column-reverse !important;
}

._flex-wrap {
  flex-wrap: wrap !important;
}

._flex-nowrap {
  flex-wrap: nowrap !important;
}

._flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

._flex-fill {
  flex: 1 1 auto !important;
}

._flex-grow-0 {
  flex-grow: 0 !important;
}

._flex-grow-1 {
  flex-grow: 1 !important;
}

._flex-shrink-0 {
  flex-shrink: 0 !important;
}

._flex-shrink-1 {
  flex-shrink: 1 !important;
}

._flex-basis-0 {
  flex-basis: 0% !important;
}

._flex-basis-auto {
  flex-basis: auto !important;
}

._justify-content-start {
  justify-content: flex-start !important;
}

._justify-content-end {
  justify-content: flex-end !important;
}

._justify-content-center {
  justify-content: center !important;
}

._justify-content-between {
  justify-content: space-between !important;
}

._justify-content-around {
  justify-content: space-around !important;
}

._align-items-start {
  align-items: flex-start !important;
}

._align-items-end {
  align-items: flex-end !important;
}

._align-items-center {
  align-items: center !important;
}

._align-items-baseline {
  align-items: baseline !important;
}

._align-items-stretch {
  align-items: stretch !important;
}

._align-content-start {
  align-content: flex-start !important;
}

._align-content-end {
  align-content: flex-end !important;
}

._align-content-center {
  align-content: center !important;
}

._align-content-between {
  align-content: space-between !important;
}

._align-content-around {
  align-content: space-around !important;
}

._align-content-stretch {
  align-content: stretch !important;
}

._align-self-auto {
  align-self: auto !important;
}

._align-self-start {
  align-self: flex-start !important;
}

._align-self-end {
  align-self: flex-end !important;
}

._align-self-center {
  align-self: center !important;
}

._align-self-baseline {
  align-self: baseline !important;
}

._align-self-stretch {
  align-self: stretch !important;
}

._order-1 {
  order: 1;
}

._order-2 {
  order: 2;
}

._order-3 {
  order: 3;
}

._order-4 {
  order: 4;
}

._order-5 {
  order: 5;
}

@media (max-width: 896px) {
  ._flex-sm-row {
    flex-direction: row !important;
  }
  ._flex-sm-column {
    flex-direction: column !important;
  }
  ._flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  ._flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  ._flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  ._flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  ._flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  ._flex-sm-fill {
    flex: 1 1 auto !important;
  }
  ._flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  ._flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  ._flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  ._flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  ._flex-sm-basis-0 {
    flex-basis: 0% !important;
  }
  ._flex-sm-basis-auto {
    flex-basis: auto !important;
  }
  ._justify-content-sm-start {
    justify-content: flex-start !important;
  }
  ._justify-content-sm-end {
    justify-content: flex-end !important;
  }
  ._justify-content-sm-center {
    justify-content: center !important;
  }
  ._justify-content-sm-between {
    justify-content: space-between !important;
  }
  ._justify-content-sm-around {
    justify-content: space-around !important;
  }
  ._align-items-sm-start {
    align-items: flex-start !important;
  }
  ._align-items-sm-end {
    align-items: flex-end !important;
  }
  ._align-items-sm-center {
    align-items: center !important;
  }
  ._align-items-sm-baseline {
    align-items: baseline !important;
  }
  ._align-items-sm-stretch {
    align-items: stretch !important;
  }
  ._align-content-sm-start {
    align-content: flex-start !important;
  }
  ._align-content-sm-end {
    align-content: flex-end !important;
  }
  ._align-content-sm-center {
    align-content: center !important;
  }
  ._align-content-sm-between {
    align-content: space-between !important;
  }
  ._align-content-sm-around {
    align-content: space-around !important;
  }
  ._align-content-sm-stretch {
    align-content: stretch !important;
  }
  ._align-self-sm-auto {
    align-self: auto !important;
  }
  ._align-self-sm-start {
    align-self: flex-start !important;
  }
  ._align-self-sm-end {
    align-self: flex-end !important;
  }
  ._align-self-sm-center {
    align-self: center !important;
  }
  ._align-self-sm-baseline {
    align-self: baseline !important;
  }
  ._align-self-sm-stretch {
    align-self: stretch !important;
  }
  ._flex-grow-sm-auto {
    flex: 0 0 auto !important;
  }
  ._order-sm-1 {
    order: 1;
  }
  ._order-sm-2 {
    order: 2;
  }
  ._order-sm-3 {
    order: 3;
  }
  ._order-sm-4 {
    order: 4;
  }
  ._order-sm-5 {
    order: 5;
  }
}

@media (min-width: 897px) {
  ._flex-md-row {
    flex-direction: row !important;
  }
  ._flex-md-column {
    flex-direction: column !important;
  }
  ._flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  ._flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  ._flex-md-wrap {
    flex-wrap: wrap !important;
  }
  ._flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  ._flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  ._flex-md-fill {
    flex: 1 1 auto !important;
  }
  ._flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  ._flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  ._flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  ._flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  ._flex-md-basis-0 {
    flex-basis: 0% !important;
  }
  ._flex-md-basis-auto {
    flex-basis: auto !important;
  }
  ._justify-content-md-start {
    justify-content: flex-start !important;
  }
  ._justify-content-md-end {
    justify-content: flex-end !important;
  }
  ._justify-content-md-center {
    justify-content: center !important;
  }
  ._justify-content-md-between {
    justify-content: space-between !important;
  }
  ._justify-content-md-around {
    justify-content: space-around !important;
  }
  ._align-items-md-start {
    align-items: flex-start !important;
  }
  ._align-items-md-end {
    align-items: flex-end !important;
  }
  ._align-items-md-center {
    align-items: center !important;
  }
  ._align-items-md-baseline {
    align-items: baseline !important;
  }
  ._align-items-md-stretch {
    align-items: stretch !important;
  }
  ._align-content-md-start {
    align-content: flex-start !important;
  }
  ._align-content-md-end {
    align-content: flex-end !important;
  }
  ._align-content-md-center {
    align-content: center !important;
  }
  ._align-content-md-between {
    align-content: space-between !important;
  }
  ._align-content-md-around {
    align-content: space-around !important;
  }
  ._align-content-md-stretch {
    align-content: stretch !important;
  }
  ._align-self-md-auto {
    align-self: auto !important;
  }
  ._align-self-md-start {
    align-self: flex-start !important;
  }
  ._align-self-md-end {
    align-self: flex-end !important;
  }
  ._align-self-md-center {
    align-self: center !important;
  }
  ._align-self-md-baseline {
    align-self: baseline !important;
  }
  ._align-self-md-stretch {
    align-self: stretch !important;
  }
  ._flex-grow-md-auto {
    flex: 0 0 auto !important;
  }
  ._order-md-1 {
    order: 1;
  }
  ._order-md-2 {
    order: 2;
  }
  ._order-md-3 {
    order: 3;
  }
  ._order-md-4 {
    order: 4;
  }
  ._order-md-5 {
    order: 5;
  }
}

@media (min-width: 1601px) {
  ._flex-lg-row {
    flex-direction: row !important;
  }
  ._flex-lg-column {
    flex-direction: column !important;
  }
  ._flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  ._flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  ._flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  ._flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  ._flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  ._flex-lg-fill {
    flex: 1 1 auto !important;
  }
  ._flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  ._flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  ._flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  ._flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  ._flex-lg-basis-0 {
    flex-basis: 0% !important;
  }
  ._flex-lg-basis-auto {
    flex-basis: auto !important;
  }
  ._justify-content-lg-start {
    justify-content: flex-start !important;
  }
  ._justify-content-lg-end {
    justify-content: flex-end !important;
  }
  ._justify-content-lg-center {
    justify-content: center !important;
  }
  ._justify-content-lg-between {
    justify-content: space-between !important;
  }
  ._justify-content-lg-around {
    justify-content: space-around !important;
  }
  ._align-items-lg-start {
    align-items: flex-start !important;
  }
  ._align-items-lg-end {
    align-items: flex-end !important;
  }
  ._align-items-lg-center {
    align-items: center !important;
  }
  ._align-items-lg-baseline {
    align-items: baseline !important;
  }
  ._align-items-lg-stretch {
    align-items: stretch !important;
  }
  ._align-content-lg-start {
    align-content: flex-start !important;
  }
  ._align-content-lg-end {
    align-content: flex-end !important;
  }
  ._align-content-lg-center {
    align-content: center !important;
  }
  ._align-content-lg-between {
    align-content: space-between !important;
  }
  ._align-content-lg-around {
    align-content: space-around !important;
  }
  ._align-content-lg-stretch {
    align-content: stretch !important;
  }
  ._align-self-lg-auto {
    align-self: auto !important;
  }
  ._align-self-lg-start {
    align-self: flex-start !important;
  }
  ._align-self-lg-end {
    align-self: flex-end !important;
  }
  ._align-self-lg-center {
    align-self: center !important;
  }
  ._align-self-lg-baseline {
    align-self: baseline !important;
  }
  ._align-self-lg-stretch {
    align-self: stretch !important;
  }
  ._flex-grow-lg-auto {
    flex: 0 0 auto !important;
  }
  ._order-lg-1 {
    order: 1;
  }
  ._order-lg-2 {
    order: 2;
  }
  ._order-lg-3 {
    order: 3;
  }
  ._order-lg-4 {
    order: 4;
  }
  ._order-lg-5 {
    order: 5;
  }
}

.-opacity {
  display: block;
}

@media (min-width: 897px) {
  .-opacity {
    opacity: 1;
    transition: opacity .3s ease-out;
  }
  .-opacity:hover {
    opacity: 0.6;
    text-decoration: none;
  }
}

._mt-0 {
  margin-top: 0px !important;
}

._mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

._my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

._m-0 {
  margin: 0px !important;
}

._pt-0 {
  padding-top: 0px !important;
}

._px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

._py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

._p-0 {
  padding: 0px !important;
}

._mb-0 {
  margin-bottom: 0px !important;
}

._mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

._my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

._m-0 {
  margin: 0px !important;
}

._pb-0 {
  padding-bottom: 0px !important;
}

._px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

._py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

._p-0 {
  padding: 0px !important;
}

._ml-0 {
  margin-left: 0px !important;
}

._mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

._my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

._m-0 {
  margin: 0px !important;
}

._pl-0 {
  padding-left: 0px !important;
}

._px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

._py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

._p-0 {
  padding: 0px !important;
}

._mr-0 {
  margin-right: 0px !important;
}

._mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

._my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

._m-0 {
  margin: 0px !important;
}

._pr-0 {
  padding-right: 0px !important;
}

._px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

._py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

._p-0 {
  padding: 0px !important;
}

._mt-5 {
  margin-top: 5px !important;
}

._mx-5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

._my-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

._m-5 {
  margin: 5px !important;
}

._pt-5 {
  padding-top: 5px !important;
}

._px-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

._py-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

._p-5 {
  padding: 5px !important;
}

._mb-5 {
  margin-bottom: 5px !important;
}

._mx-5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

._my-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

._m-5 {
  margin: 5px !important;
}

._pb-5 {
  padding-bottom: 5px !important;
}

._px-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

._py-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

._p-5 {
  padding: 5px !important;
}

._ml-5 {
  margin-left: 5px !important;
}

._mx-5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

._my-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

._m-5 {
  margin: 5px !important;
}

._pl-5 {
  padding-left: 5px !important;
}

._px-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

._py-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

._p-5 {
  padding: 5px !important;
}

._mr-5 {
  margin-right: 5px !important;
}

._mx-5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

._my-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

._m-5 {
  margin: 5px !important;
}

._pr-5 {
  padding-right: 5px !important;
}

._px-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

._py-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

._p-5 {
  padding: 5px !important;
}

._mt-10 {
  margin-top: 10px !important;
}

._mx-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

._my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

._m-10 {
  margin: 10px !important;
}

._pt-10 {
  padding-top: 10px !important;
}

._px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

._py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

._p-10 {
  padding: 10px !important;
}

._mb-10 {
  margin-bottom: 10px !important;
}

._mx-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

._my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

._m-10 {
  margin: 10px !important;
}

._pb-10 {
  padding-bottom: 10px !important;
}

._px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

._py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

._p-10 {
  padding: 10px !important;
}

._ml-10 {
  margin-left: 10px !important;
}

._mx-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

._my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

._m-10 {
  margin: 10px !important;
}

._pl-10 {
  padding-left: 10px !important;
}

._px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

._py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

._p-10 {
  padding: 10px !important;
}

._mr-10 {
  margin-right: 10px !important;
}

._mx-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

._my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

._m-10 {
  margin: 10px !important;
}

._pr-10 {
  padding-right: 10px !important;
}

._px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

._py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

._p-10 {
  padding: 10px !important;
}

._mt-15 {
  margin-top: 15px !important;
}

._mx-15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

._my-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

._m-15 {
  margin: 15px !important;
}

._pt-15 {
  padding-top: 15px !important;
}

._px-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

._py-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

._p-15 {
  padding: 15px !important;
}

._mb-15 {
  margin-bottom: 15px !important;
}

._mx-15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

._my-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

._m-15 {
  margin: 15px !important;
}

._pb-15 {
  padding-bottom: 15px !important;
}

._px-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

._py-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

._p-15 {
  padding: 15px !important;
}

._ml-15 {
  margin-left: 15px !important;
}

._mx-15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

._my-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

._m-15 {
  margin: 15px !important;
}

._pl-15 {
  padding-left: 15px !important;
}

._px-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

._py-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

._p-15 {
  padding: 15px !important;
}

._mr-15 {
  margin-right: 15px !important;
}

._mx-15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

._my-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

._m-15 {
  margin: 15px !important;
}

._pr-15 {
  padding-right: 15px !important;
}

._px-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

._py-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

._p-15 {
  padding: 15px !important;
}

._mt-20 {
  margin-top: 20px !important;
}

._mx-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

._my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

._m-20 {
  margin: 20px !important;
}

._pt-20 {
  padding-top: 20px !important;
}

._px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

._py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

._p-20 {
  padding: 20px !important;
}

._mb-20 {
  margin-bottom: 20px !important;
}

._mx-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

._my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

._m-20 {
  margin: 20px !important;
}

._pb-20 {
  padding-bottom: 20px !important;
}

._px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

._py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

._p-20 {
  padding: 20px !important;
}

._ml-20 {
  margin-left: 20px !important;
}

._mx-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

._my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

._m-20 {
  margin: 20px !important;
}

._pl-20 {
  padding-left: 20px !important;
}

._px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

._py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

._p-20 {
  padding: 20px !important;
}

._mr-20 {
  margin-right: 20px !important;
}

._mx-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

._my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

._m-20 {
  margin: 20px !important;
}

._pr-20 {
  padding-right: 20px !important;
}

._px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

._py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

._p-20 {
  padding: 20px !important;
}

._mt-25 {
  margin-top: 25px !important;
}

._mx-25 {
  margin-left: 25px !important;
  margin-right: 25px !important;
}

._my-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

._m-25 {
  margin: 25px !important;
}

._pt-25 {
  padding-top: 25px !important;
}

._px-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

._py-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

._p-25 {
  padding: 25px !important;
}

._mb-25 {
  margin-bottom: 25px !important;
}

._mx-25 {
  margin-left: 25px !important;
  margin-right: 25px !important;
}

._my-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

._m-25 {
  margin: 25px !important;
}

._pb-25 {
  padding-bottom: 25px !important;
}

._px-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

._py-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

._p-25 {
  padding: 25px !important;
}

._ml-25 {
  margin-left: 25px !important;
}

._mx-25 {
  margin-left: 25px !important;
  margin-right: 25px !important;
}

._my-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

._m-25 {
  margin: 25px !important;
}

._pl-25 {
  padding-left: 25px !important;
}

._px-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

._py-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

._p-25 {
  padding: 25px !important;
}

._mr-25 {
  margin-right: 25px !important;
}

._mx-25 {
  margin-left: 25px !important;
  margin-right: 25px !important;
}

._my-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

._m-25 {
  margin: 25px !important;
}

._pr-25 {
  padding-right: 25px !important;
}

._px-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

._py-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

._p-25 {
  padding: 25px !important;
}

._mt-30 {
  margin-top: 30px !important;
}

._mx-30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

._my-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

._m-30 {
  margin: 30px !important;
}

._pt-30 {
  padding-top: 30px !important;
}

._px-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

._py-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

._p-30 {
  padding: 30px !important;
}

._mb-30 {
  margin-bottom: 30px !important;
}

._mx-30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

._my-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

._m-30 {
  margin: 30px !important;
}

._pb-30 {
  padding-bottom: 30px !important;
}

._px-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

._py-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

._p-30 {
  padding: 30px !important;
}

._ml-30 {
  margin-left: 30px !important;
}

._mx-30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

._my-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

._m-30 {
  margin: 30px !important;
}

._pl-30 {
  padding-left: 30px !important;
}

._px-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

._py-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

._p-30 {
  padding: 30px !important;
}

._mr-30 {
  margin-right: 30px !important;
}

._mx-30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

._my-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

._m-30 {
  margin: 30px !important;
}

._pr-30 {
  padding-right: 30px !important;
}

._px-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

._py-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

._p-30 {
  padding: 30px !important;
}

._mt-35 {
  margin-top: 35px !important;
}

._mx-35 {
  margin-left: 35px !important;
  margin-right: 35px !important;
}

._my-35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

._m-35 {
  margin: 35px !important;
}

._pt-35 {
  padding-top: 35px !important;
}

._px-35 {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

._py-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

._p-35 {
  padding: 35px !important;
}

._mb-35 {
  margin-bottom: 35px !important;
}

._mx-35 {
  margin-left: 35px !important;
  margin-right: 35px !important;
}

._my-35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

._m-35 {
  margin: 35px !important;
}

._pb-35 {
  padding-bottom: 35px !important;
}

._px-35 {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

._py-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

._p-35 {
  padding: 35px !important;
}

._ml-35 {
  margin-left: 35px !important;
}

._mx-35 {
  margin-left: 35px !important;
  margin-right: 35px !important;
}

._my-35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

._m-35 {
  margin: 35px !important;
}

._pl-35 {
  padding-left: 35px !important;
}

._px-35 {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

._py-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

._p-35 {
  padding: 35px !important;
}

._mr-35 {
  margin-right: 35px !important;
}

._mx-35 {
  margin-left: 35px !important;
  margin-right: 35px !important;
}

._my-35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

._m-35 {
  margin: 35px !important;
}

._pr-35 {
  padding-right: 35px !important;
}

._px-35 {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

._py-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

._p-35 {
  padding: 35px !important;
}

._mt-40 {
  margin-top: 40px !important;
}

._mx-40 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

._my-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

._m-40 {
  margin: 40px !important;
}

._pt-40 {
  padding-top: 40px !important;
}

._px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

._py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

._p-40 {
  padding: 40px !important;
}

._mb-40 {
  margin-bottom: 40px !important;
}

._mx-40 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

._my-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

._m-40 {
  margin: 40px !important;
}

._pb-40 {
  padding-bottom: 40px !important;
}

._px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

._py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

._p-40 {
  padding: 40px !important;
}

._ml-40 {
  margin-left: 40px !important;
}

._mx-40 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

._my-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

._m-40 {
  margin: 40px !important;
}

._pl-40 {
  padding-left: 40px !important;
}

._px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

._py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

._p-40 {
  padding: 40px !important;
}

._mr-40 {
  margin-right: 40px !important;
}

._mx-40 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

._my-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

._m-40 {
  margin: 40px !important;
}

._pr-40 {
  padding-right: 40px !important;
}

._px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

._py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

._p-40 {
  padding: 40px !important;
}

._mt-43 {
  margin-top: 43px !important;
}

._mx-43 {
  margin-left: 43px !important;
  margin-right: 43px !important;
}

._my-43 {
  margin-top: 43px !important;
  margin-bottom: 43px !important;
}

._m-43 {
  margin: 43px !important;
}

._pt-43 {
  padding-top: 43px !important;
}

._px-43 {
  padding-left: 43px !important;
  padding-right: 43px !important;
}

._py-43 {
  padding-top: 43px !important;
  padding-bottom: 43px !important;
}

._p-43 {
  padding: 43px !important;
}

._mb-43 {
  margin-bottom: 43px !important;
}

._mx-43 {
  margin-left: 43px !important;
  margin-right: 43px !important;
}

._my-43 {
  margin-top: 43px !important;
  margin-bottom: 43px !important;
}

._m-43 {
  margin: 43px !important;
}

._pb-43 {
  padding-bottom: 43px !important;
}

._px-43 {
  padding-left: 43px !important;
  padding-right: 43px !important;
}

._py-43 {
  padding-top: 43px !important;
  padding-bottom: 43px !important;
}

._p-43 {
  padding: 43px !important;
}

._ml-43 {
  margin-left: 43px !important;
}

._mx-43 {
  margin-left: 43px !important;
  margin-right: 43px !important;
}

._my-43 {
  margin-top: 43px !important;
  margin-bottom: 43px !important;
}

._m-43 {
  margin: 43px !important;
}

._pl-43 {
  padding-left: 43px !important;
}

._px-43 {
  padding-left: 43px !important;
  padding-right: 43px !important;
}

._py-43 {
  padding-top: 43px !important;
  padding-bottom: 43px !important;
}

._p-43 {
  padding: 43px !important;
}

._mr-43 {
  margin-right: 43px !important;
}

._mx-43 {
  margin-left: 43px !important;
  margin-right: 43px !important;
}

._my-43 {
  margin-top: 43px !important;
  margin-bottom: 43px !important;
}

._m-43 {
  margin: 43px !important;
}

._pr-43 {
  padding-right: 43px !important;
}

._px-43 {
  padding-left: 43px !important;
  padding-right: 43px !important;
}

._py-43 {
  padding-top: 43px !important;
  padding-bottom: 43px !important;
}

._p-43 {
  padding: 43px !important;
}

._mt-45 {
  margin-top: 45px !important;
}

._mx-45 {
  margin-left: 45px !important;
  margin-right: 45px !important;
}

._my-45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

._m-45 {
  margin: 45px !important;
}

._pt-45 {
  padding-top: 45px !important;
}

._px-45 {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

._py-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

._p-45 {
  padding: 45px !important;
}

._mb-45 {
  margin-bottom: 45px !important;
}

._mx-45 {
  margin-left: 45px !important;
  margin-right: 45px !important;
}

._my-45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

._m-45 {
  margin: 45px !important;
}

._pb-45 {
  padding-bottom: 45px !important;
}

._px-45 {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

._py-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

._p-45 {
  padding: 45px !important;
}

._ml-45 {
  margin-left: 45px !important;
}

._mx-45 {
  margin-left: 45px !important;
  margin-right: 45px !important;
}

._my-45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

._m-45 {
  margin: 45px !important;
}

._pl-45 {
  padding-left: 45px !important;
}

._px-45 {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

._py-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

._p-45 {
  padding: 45px !important;
}

._mr-45 {
  margin-right: 45px !important;
}

._mx-45 {
  margin-left: 45px !important;
  margin-right: 45px !important;
}

._my-45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

._m-45 {
  margin: 45px !important;
}

._pr-45 {
  padding-right: 45px !important;
}

._px-45 {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

._py-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

._p-45 {
  padding: 45px !important;
}

._mt-46 {
  margin-top: 46px !important;
}

._mx-46 {
  margin-left: 46px !important;
  margin-right: 46px !important;
}

._my-46 {
  margin-top: 46px !important;
  margin-bottom: 46px !important;
}

._m-46 {
  margin: 46px !important;
}

._pt-46 {
  padding-top: 46px !important;
}

._px-46 {
  padding-left: 46px !important;
  padding-right: 46px !important;
}

._py-46 {
  padding-top: 46px !important;
  padding-bottom: 46px !important;
}

._p-46 {
  padding: 46px !important;
}

._mb-46 {
  margin-bottom: 46px !important;
}

._mx-46 {
  margin-left: 46px !important;
  margin-right: 46px !important;
}

._my-46 {
  margin-top: 46px !important;
  margin-bottom: 46px !important;
}

._m-46 {
  margin: 46px !important;
}

._pb-46 {
  padding-bottom: 46px !important;
}

._px-46 {
  padding-left: 46px !important;
  padding-right: 46px !important;
}

._py-46 {
  padding-top: 46px !important;
  padding-bottom: 46px !important;
}

._p-46 {
  padding: 46px !important;
}

._ml-46 {
  margin-left: 46px !important;
}

._mx-46 {
  margin-left: 46px !important;
  margin-right: 46px !important;
}

._my-46 {
  margin-top: 46px !important;
  margin-bottom: 46px !important;
}

._m-46 {
  margin: 46px !important;
}

._pl-46 {
  padding-left: 46px !important;
}

._px-46 {
  padding-left: 46px !important;
  padding-right: 46px !important;
}

._py-46 {
  padding-top: 46px !important;
  padding-bottom: 46px !important;
}

._p-46 {
  padding: 46px !important;
}

._mr-46 {
  margin-right: 46px !important;
}

._mx-46 {
  margin-left: 46px !important;
  margin-right: 46px !important;
}

._my-46 {
  margin-top: 46px !important;
  margin-bottom: 46px !important;
}

._m-46 {
  margin: 46px !important;
}

._pr-46 {
  padding-right: 46px !important;
}

._px-46 {
  padding-left: 46px !important;
  padding-right: 46px !important;
}

._py-46 {
  padding-top: 46px !important;
  padding-bottom: 46px !important;
}

._p-46 {
  padding: 46px !important;
}

._mt-50 {
  margin-top: 50px !important;
}

._mx-50 {
  margin-left: 50px !important;
  margin-right: 50px !important;
}

._my-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

._m-50 {
  margin: 50px !important;
}

._pt-50 {
  padding-top: 50px !important;
}

._px-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

._py-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

._p-50 {
  padding: 50px !important;
}

._mb-50 {
  margin-bottom: 50px !important;
}

._mx-50 {
  margin-left: 50px !important;
  margin-right: 50px !important;
}

._my-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

._m-50 {
  margin: 50px !important;
}

._pb-50 {
  padding-bottom: 50px !important;
}

._px-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

._py-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

._p-50 {
  padding: 50px !important;
}

._ml-50 {
  margin-left: 50px !important;
}

._mx-50 {
  margin-left: 50px !important;
  margin-right: 50px !important;
}

._my-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

._m-50 {
  margin: 50px !important;
}

._pl-50 {
  padding-left: 50px !important;
}

._px-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

._py-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

._p-50 {
  padding: 50px !important;
}

._mr-50 {
  margin-right: 50px !important;
}

._mx-50 {
  margin-left: 50px !important;
  margin-right: 50px !important;
}

._my-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

._m-50 {
  margin: 50px !important;
}

._pr-50 {
  padding-right: 50px !important;
}

._px-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

._py-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

._p-50 {
  padding: 50px !important;
}

._mt-55 {
  margin-top: 55px !important;
}

._mx-55 {
  margin-left: 55px !important;
  margin-right: 55px !important;
}

._my-55 {
  margin-top: 55px !important;
  margin-bottom: 55px !important;
}

._m-55 {
  margin: 55px !important;
}

._pt-55 {
  padding-top: 55px !important;
}

._px-55 {
  padding-left: 55px !important;
  padding-right: 55px !important;
}

._py-55 {
  padding-top: 55px !important;
  padding-bottom: 55px !important;
}

._p-55 {
  padding: 55px !important;
}

._mb-55 {
  margin-bottom: 55px !important;
}

._mx-55 {
  margin-left: 55px !important;
  margin-right: 55px !important;
}

._my-55 {
  margin-top: 55px !important;
  margin-bottom: 55px !important;
}

._m-55 {
  margin: 55px !important;
}

._pb-55 {
  padding-bottom: 55px !important;
}

._px-55 {
  padding-left: 55px !important;
  padding-right: 55px !important;
}

._py-55 {
  padding-top: 55px !important;
  padding-bottom: 55px !important;
}

._p-55 {
  padding: 55px !important;
}

._ml-55 {
  margin-left: 55px !important;
}

._mx-55 {
  margin-left: 55px !important;
  margin-right: 55px !important;
}

._my-55 {
  margin-top: 55px !important;
  margin-bottom: 55px !important;
}

._m-55 {
  margin: 55px !important;
}

._pl-55 {
  padding-left: 55px !important;
}

._px-55 {
  padding-left: 55px !important;
  padding-right: 55px !important;
}

._py-55 {
  padding-top: 55px !important;
  padding-bottom: 55px !important;
}

._p-55 {
  padding: 55px !important;
}

._mr-55 {
  margin-right: 55px !important;
}

._mx-55 {
  margin-left: 55px !important;
  margin-right: 55px !important;
}

._my-55 {
  margin-top: 55px !important;
  margin-bottom: 55px !important;
}

._m-55 {
  margin: 55px !important;
}

._pr-55 {
  padding-right: 55px !important;
}

._px-55 {
  padding-left: 55px !important;
  padding-right: 55px !important;
}

._py-55 {
  padding-top: 55px !important;
  padding-bottom: 55px !important;
}

._p-55 {
  padding: 55px !important;
}

._mt-60 {
  margin-top: 60px !important;
}

._mx-60 {
  margin-left: 60px !important;
  margin-right: 60px !important;
}

._my-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

._m-60 {
  margin: 60px !important;
}

._pt-60 {
  padding-top: 60px !important;
}

._px-60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

._py-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

._p-60 {
  padding: 60px !important;
}

._mb-60 {
  margin-bottom: 60px !important;
}

._mx-60 {
  margin-left: 60px !important;
  margin-right: 60px !important;
}

._my-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

._m-60 {
  margin: 60px !important;
}

._pb-60 {
  padding-bottom: 60px !important;
}

._px-60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

._py-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

._p-60 {
  padding: 60px !important;
}

._ml-60 {
  margin-left: 60px !important;
}

._mx-60 {
  margin-left: 60px !important;
  margin-right: 60px !important;
}

._my-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

._m-60 {
  margin: 60px !important;
}

._pl-60 {
  padding-left: 60px !important;
}

._px-60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

._py-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

._p-60 {
  padding: 60px !important;
}

._mr-60 {
  margin-right: 60px !important;
}

._mx-60 {
  margin-left: 60px !important;
  margin-right: 60px !important;
}

._my-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

._m-60 {
  margin: 60px !important;
}

._pr-60 {
  padding-right: 60px !important;
}

._px-60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

._py-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

._p-60 {
  padding: 60px !important;
}

._mt-65 {
  margin-top: 65px !important;
}

._mx-65 {
  margin-left: 65px !important;
  margin-right: 65px !important;
}

._my-65 {
  margin-top: 65px !important;
  margin-bottom: 65px !important;
}

._m-65 {
  margin: 65px !important;
}

._pt-65 {
  padding-top: 65px !important;
}

._px-65 {
  padding-left: 65px !important;
  padding-right: 65px !important;
}

._py-65 {
  padding-top: 65px !important;
  padding-bottom: 65px !important;
}

._p-65 {
  padding: 65px !important;
}

._mb-65 {
  margin-bottom: 65px !important;
}

._mx-65 {
  margin-left: 65px !important;
  margin-right: 65px !important;
}

._my-65 {
  margin-top: 65px !important;
  margin-bottom: 65px !important;
}

._m-65 {
  margin: 65px !important;
}

._pb-65 {
  padding-bottom: 65px !important;
}

._px-65 {
  padding-left: 65px !important;
  padding-right: 65px !important;
}

._py-65 {
  padding-top: 65px !important;
  padding-bottom: 65px !important;
}

._p-65 {
  padding: 65px !important;
}

._ml-65 {
  margin-left: 65px !important;
}

._mx-65 {
  margin-left: 65px !important;
  margin-right: 65px !important;
}

._my-65 {
  margin-top: 65px !important;
  margin-bottom: 65px !important;
}

._m-65 {
  margin: 65px !important;
}

._pl-65 {
  padding-left: 65px !important;
}

._px-65 {
  padding-left: 65px !important;
  padding-right: 65px !important;
}

._py-65 {
  padding-top: 65px !important;
  padding-bottom: 65px !important;
}

._p-65 {
  padding: 65px !important;
}

._mr-65 {
  margin-right: 65px !important;
}

._mx-65 {
  margin-left: 65px !important;
  margin-right: 65px !important;
}

._my-65 {
  margin-top: 65px !important;
  margin-bottom: 65px !important;
}

._m-65 {
  margin: 65px !important;
}

._pr-65 {
  padding-right: 65px !important;
}

._px-65 {
  padding-left: 65px !important;
  padding-right: 65px !important;
}

._py-65 {
  padding-top: 65px !important;
  padding-bottom: 65px !important;
}

._p-65 {
  padding: 65px !important;
}

._mt-70 {
  margin-top: 70px !important;
}

._mx-70 {
  margin-left: 70px !important;
  margin-right: 70px !important;
}

._my-70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}

._m-70 {
  margin: 70px !important;
}

._pt-70 {
  padding-top: 70px !important;
}

._px-70 {
  padding-left: 70px !important;
  padding-right: 70px !important;
}

._py-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

._p-70 {
  padding: 70px !important;
}

._mb-70 {
  margin-bottom: 70px !important;
}

._mx-70 {
  margin-left: 70px !important;
  margin-right: 70px !important;
}

._my-70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}

._m-70 {
  margin: 70px !important;
}

._pb-70 {
  padding-bottom: 70px !important;
}

._px-70 {
  padding-left: 70px !important;
  padding-right: 70px !important;
}

._py-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

._p-70 {
  padding: 70px !important;
}

._ml-70 {
  margin-left: 70px !important;
}

._mx-70 {
  margin-left: 70px !important;
  margin-right: 70px !important;
}

._my-70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}

._m-70 {
  margin: 70px !important;
}

._pl-70 {
  padding-left: 70px !important;
}

._px-70 {
  padding-left: 70px !important;
  padding-right: 70px !important;
}

._py-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

._p-70 {
  padding: 70px !important;
}

._mr-70 {
  margin-right: 70px !important;
}

._mx-70 {
  margin-left: 70px !important;
  margin-right: 70px !important;
}

._my-70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}

._m-70 {
  margin: 70px !important;
}

._pr-70 {
  padding-right: 70px !important;
}

._px-70 {
  padding-left: 70px !important;
  padding-right: 70px !important;
}

._py-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

._p-70 {
  padding: 70px !important;
}

._mt-75 {
  margin-top: 75px !important;
}

._mx-75 {
  margin-left: 75px !important;
  margin-right: 75px !important;
}

._my-75 {
  margin-top: 75px !important;
  margin-bottom: 75px !important;
}

._m-75 {
  margin: 75px !important;
}

._pt-75 {
  padding-top: 75px !important;
}

._px-75 {
  padding-left: 75px !important;
  padding-right: 75px !important;
}

._py-75 {
  padding-top: 75px !important;
  padding-bottom: 75px !important;
}

._p-75 {
  padding: 75px !important;
}

._mb-75 {
  margin-bottom: 75px !important;
}

._mx-75 {
  margin-left: 75px !important;
  margin-right: 75px !important;
}

._my-75 {
  margin-top: 75px !important;
  margin-bottom: 75px !important;
}

._m-75 {
  margin: 75px !important;
}

._pb-75 {
  padding-bottom: 75px !important;
}

._px-75 {
  padding-left: 75px !important;
  padding-right: 75px !important;
}

._py-75 {
  padding-top: 75px !important;
  padding-bottom: 75px !important;
}

._p-75 {
  padding: 75px !important;
}

._ml-75 {
  margin-left: 75px !important;
}

._mx-75 {
  margin-left: 75px !important;
  margin-right: 75px !important;
}

._my-75 {
  margin-top: 75px !important;
  margin-bottom: 75px !important;
}

._m-75 {
  margin: 75px !important;
}

._pl-75 {
  padding-left: 75px !important;
}

._px-75 {
  padding-left: 75px !important;
  padding-right: 75px !important;
}

._py-75 {
  padding-top: 75px !important;
  padding-bottom: 75px !important;
}

._p-75 {
  padding: 75px !important;
}

._mr-75 {
  margin-right: 75px !important;
}

._mx-75 {
  margin-left: 75px !important;
  margin-right: 75px !important;
}

._my-75 {
  margin-top: 75px !important;
  margin-bottom: 75px !important;
}

._m-75 {
  margin: 75px !important;
}

._pr-75 {
  padding-right: 75px !important;
}

._px-75 {
  padding-left: 75px !important;
  padding-right: 75px !important;
}

._py-75 {
  padding-top: 75px !important;
  padding-bottom: 75px !important;
}

._p-75 {
  padding: 75px !important;
}

._mt-80 {
  margin-top: 80px !important;
}

._mx-80 {
  margin-left: 80px !important;
  margin-right: 80px !important;
}

._my-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

._m-80 {
  margin: 80px !important;
}

._pt-80 {
  padding-top: 80px !important;
}

._px-80 {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

._py-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

._p-80 {
  padding: 80px !important;
}

._mb-80 {
  margin-bottom: 80px !important;
}

._mx-80 {
  margin-left: 80px !important;
  margin-right: 80px !important;
}

._my-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

._m-80 {
  margin: 80px !important;
}

._pb-80 {
  padding-bottom: 80px !important;
}

._px-80 {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

._py-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

._p-80 {
  padding: 80px !important;
}

._ml-80 {
  margin-left: 80px !important;
}

._mx-80 {
  margin-left: 80px !important;
  margin-right: 80px !important;
}

._my-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

._m-80 {
  margin: 80px !important;
}

._pl-80 {
  padding-left: 80px !important;
}

._px-80 {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

._py-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

._p-80 {
  padding: 80px !important;
}

._mr-80 {
  margin-right: 80px !important;
}

._mx-80 {
  margin-left: 80px !important;
  margin-right: 80px !important;
}

._my-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

._m-80 {
  margin: 80px !important;
}

._pr-80 {
  padding-right: 80px !important;
}

._px-80 {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

._py-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

._p-80 {
  padding: 80px !important;
}

._mt-85 {
  margin-top: 85px !important;
}

._mx-85 {
  margin-left: 85px !important;
  margin-right: 85px !important;
}

._my-85 {
  margin-top: 85px !important;
  margin-bottom: 85px !important;
}

._m-85 {
  margin: 85px !important;
}

._pt-85 {
  padding-top: 85px !important;
}

._px-85 {
  padding-left: 85px !important;
  padding-right: 85px !important;
}

._py-85 {
  padding-top: 85px !important;
  padding-bottom: 85px !important;
}

._p-85 {
  padding: 85px !important;
}

._mb-85 {
  margin-bottom: 85px !important;
}

._mx-85 {
  margin-left: 85px !important;
  margin-right: 85px !important;
}

._my-85 {
  margin-top: 85px !important;
  margin-bottom: 85px !important;
}

._m-85 {
  margin: 85px !important;
}

._pb-85 {
  padding-bottom: 85px !important;
}

._px-85 {
  padding-left: 85px !important;
  padding-right: 85px !important;
}

._py-85 {
  padding-top: 85px !important;
  padding-bottom: 85px !important;
}

._p-85 {
  padding: 85px !important;
}

._ml-85 {
  margin-left: 85px !important;
}

._mx-85 {
  margin-left: 85px !important;
  margin-right: 85px !important;
}

._my-85 {
  margin-top: 85px !important;
  margin-bottom: 85px !important;
}

._m-85 {
  margin: 85px !important;
}

._pl-85 {
  padding-left: 85px !important;
}

._px-85 {
  padding-left: 85px !important;
  padding-right: 85px !important;
}

._py-85 {
  padding-top: 85px !important;
  padding-bottom: 85px !important;
}

._p-85 {
  padding: 85px !important;
}

._mr-85 {
  margin-right: 85px !important;
}

._mx-85 {
  margin-left: 85px !important;
  margin-right: 85px !important;
}

._my-85 {
  margin-top: 85px !important;
  margin-bottom: 85px !important;
}

._m-85 {
  margin: 85px !important;
}

._pr-85 {
  padding-right: 85px !important;
}

._px-85 {
  padding-left: 85px !important;
  padding-right: 85px !important;
}

._py-85 {
  padding-top: 85px !important;
  padding-bottom: 85px !important;
}

._p-85 {
  padding: 85px !important;
}

._mt-90 {
  margin-top: 90px !important;
}

._mx-90 {
  margin-left: 90px !important;
  margin-right: 90px !important;
}

._my-90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important;
}

._m-90 {
  margin: 90px !important;
}

._pt-90 {
  padding-top: 90px !important;
}

._px-90 {
  padding-left: 90px !important;
  padding-right: 90px !important;
}

._py-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

._p-90 {
  padding: 90px !important;
}

._mb-90 {
  margin-bottom: 90px !important;
}

._mx-90 {
  margin-left: 90px !important;
  margin-right: 90px !important;
}

._my-90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important;
}

._m-90 {
  margin: 90px !important;
}

._pb-90 {
  padding-bottom: 90px !important;
}

._px-90 {
  padding-left: 90px !important;
  padding-right: 90px !important;
}

._py-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

._p-90 {
  padding: 90px !important;
}

._ml-90 {
  margin-left: 90px !important;
}

._mx-90 {
  margin-left: 90px !important;
  margin-right: 90px !important;
}

._my-90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important;
}

._m-90 {
  margin: 90px !important;
}

._pl-90 {
  padding-left: 90px !important;
}

._px-90 {
  padding-left: 90px !important;
  padding-right: 90px !important;
}

._py-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

._p-90 {
  padding: 90px !important;
}

._mr-90 {
  margin-right: 90px !important;
}

._mx-90 {
  margin-left: 90px !important;
  margin-right: 90px !important;
}

._my-90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important;
}

._m-90 {
  margin: 90px !important;
}

._pr-90 {
  padding-right: 90px !important;
}

._px-90 {
  padding-left: 90px !important;
  padding-right: 90px !important;
}

._py-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

._p-90 {
  padding: 90px !important;
}

._mt-95 {
  margin-top: 95px !important;
}

._mx-95 {
  margin-left: 95px !important;
  margin-right: 95px !important;
}

._my-95 {
  margin-top: 95px !important;
  margin-bottom: 95px !important;
}

._m-95 {
  margin: 95px !important;
}

._pt-95 {
  padding-top: 95px !important;
}

._px-95 {
  padding-left: 95px !important;
  padding-right: 95px !important;
}

._py-95 {
  padding-top: 95px !important;
  padding-bottom: 95px !important;
}

._p-95 {
  padding: 95px !important;
}

._mb-95 {
  margin-bottom: 95px !important;
}

._mx-95 {
  margin-left: 95px !important;
  margin-right: 95px !important;
}

._my-95 {
  margin-top: 95px !important;
  margin-bottom: 95px !important;
}

._m-95 {
  margin: 95px !important;
}

._pb-95 {
  padding-bottom: 95px !important;
}

._px-95 {
  padding-left: 95px !important;
  padding-right: 95px !important;
}

._py-95 {
  padding-top: 95px !important;
  padding-bottom: 95px !important;
}

._p-95 {
  padding: 95px !important;
}

._ml-95 {
  margin-left: 95px !important;
}

._mx-95 {
  margin-left: 95px !important;
  margin-right: 95px !important;
}

._my-95 {
  margin-top: 95px !important;
  margin-bottom: 95px !important;
}

._m-95 {
  margin: 95px !important;
}

._pl-95 {
  padding-left: 95px !important;
}

._px-95 {
  padding-left: 95px !important;
  padding-right: 95px !important;
}

._py-95 {
  padding-top: 95px !important;
  padding-bottom: 95px !important;
}

._p-95 {
  padding: 95px !important;
}

._mr-95 {
  margin-right: 95px !important;
}

._mx-95 {
  margin-left: 95px !important;
  margin-right: 95px !important;
}

._my-95 {
  margin-top: 95px !important;
  margin-bottom: 95px !important;
}

._m-95 {
  margin: 95px !important;
}

._pr-95 {
  padding-right: 95px !important;
}

._px-95 {
  padding-left: 95px !important;
  padding-right: 95px !important;
}

._py-95 {
  padding-top: 95px !important;
  padding-bottom: 95px !important;
}

._p-95 {
  padding: 95px !important;
}

._mt-100 {
  margin-top: 100px !important;
}

._mx-100 {
  margin-left: 100px !important;
  margin-right: 100px !important;
}

._my-100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

._m-100 {
  margin: 100px !important;
}

._pt-100 {
  padding-top: 100px !important;
}

._px-100 {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

._py-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

._p-100 {
  padding: 100px !important;
}

._mb-100 {
  margin-bottom: 100px !important;
}

._mx-100 {
  margin-left: 100px !important;
  margin-right: 100px !important;
}

._my-100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

._m-100 {
  margin: 100px !important;
}

._pb-100 {
  padding-bottom: 100px !important;
}

._px-100 {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

._py-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

._p-100 {
  padding: 100px !important;
}

._ml-100 {
  margin-left: 100px !important;
}

._mx-100 {
  margin-left: 100px !important;
  margin-right: 100px !important;
}

._my-100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

._m-100 {
  margin: 100px !important;
}

._pl-100 {
  padding-left: 100px !important;
}

._px-100 {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

._py-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

._p-100 {
  padding: 100px !important;
}

._mr-100 {
  margin-right: 100px !important;
}

._mx-100 {
  margin-left: 100px !important;
  margin-right: 100px !important;
}

._my-100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

._m-100 {
  margin: 100px !important;
}

._pr-100 {
  padding-right: 100px !important;
}

._px-100 {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

._py-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

._p-100 {
  padding: 100px !important;
}

._mt-105 {
  margin-top: 105px !important;
}

._mx-105 {
  margin-left: 105px !important;
  margin-right: 105px !important;
}

._my-105 {
  margin-top: 105px !important;
  margin-bottom: 105px !important;
}

._m-105 {
  margin: 105px !important;
}

._pt-105 {
  padding-top: 105px !important;
}

._px-105 {
  padding-left: 105px !important;
  padding-right: 105px !important;
}

._py-105 {
  padding-top: 105px !important;
  padding-bottom: 105px !important;
}

._p-105 {
  padding: 105px !important;
}

._mb-105 {
  margin-bottom: 105px !important;
}

._mx-105 {
  margin-left: 105px !important;
  margin-right: 105px !important;
}

._my-105 {
  margin-top: 105px !important;
  margin-bottom: 105px !important;
}

._m-105 {
  margin: 105px !important;
}

._pb-105 {
  padding-bottom: 105px !important;
}

._px-105 {
  padding-left: 105px !important;
  padding-right: 105px !important;
}

._py-105 {
  padding-top: 105px !important;
  padding-bottom: 105px !important;
}

._p-105 {
  padding: 105px !important;
}

._ml-105 {
  margin-left: 105px !important;
}

._mx-105 {
  margin-left: 105px !important;
  margin-right: 105px !important;
}

._my-105 {
  margin-top: 105px !important;
  margin-bottom: 105px !important;
}

._m-105 {
  margin: 105px !important;
}

._pl-105 {
  padding-left: 105px !important;
}

._px-105 {
  padding-left: 105px !important;
  padding-right: 105px !important;
}

._py-105 {
  padding-top: 105px !important;
  padding-bottom: 105px !important;
}

._p-105 {
  padding: 105px !important;
}

._mr-105 {
  margin-right: 105px !important;
}

._mx-105 {
  margin-left: 105px !important;
  margin-right: 105px !important;
}

._my-105 {
  margin-top: 105px !important;
  margin-bottom: 105px !important;
}

._m-105 {
  margin: 105px !important;
}

._pr-105 {
  padding-right: 105px !important;
}

._px-105 {
  padding-left: 105px !important;
  padding-right: 105px !important;
}

._py-105 {
  padding-top: 105px !important;
  padding-bottom: 105px !important;
}

._p-105 {
  padding: 105px !important;
}

._mt-110 {
  margin-top: 110px !important;
}

._mx-110 {
  margin-left: 110px !important;
  margin-right: 110px !important;
}

._my-110 {
  margin-top: 110px !important;
  margin-bottom: 110px !important;
}

._m-110 {
  margin: 110px !important;
}

._pt-110 {
  padding-top: 110px !important;
}

._px-110 {
  padding-left: 110px !important;
  padding-right: 110px !important;
}

._py-110 {
  padding-top: 110px !important;
  padding-bottom: 110px !important;
}

._p-110 {
  padding: 110px !important;
}

._mb-110 {
  margin-bottom: 110px !important;
}

._mx-110 {
  margin-left: 110px !important;
  margin-right: 110px !important;
}

._my-110 {
  margin-top: 110px !important;
  margin-bottom: 110px !important;
}

._m-110 {
  margin: 110px !important;
}

._pb-110 {
  padding-bottom: 110px !important;
}

._px-110 {
  padding-left: 110px !important;
  padding-right: 110px !important;
}

._py-110 {
  padding-top: 110px !important;
  padding-bottom: 110px !important;
}

._p-110 {
  padding: 110px !important;
}

._ml-110 {
  margin-left: 110px !important;
}

._mx-110 {
  margin-left: 110px !important;
  margin-right: 110px !important;
}

._my-110 {
  margin-top: 110px !important;
  margin-bottom: 110px !important;
}

._m-110 {
  margin: 110px !important;
}

._pl-110 {
  padding-left: 110px !important;
}

._px-110 {
  padding-left: 110px !important;
  padding-right: 110px !important;
}

._py-110 {
  padding-top: 110px !important;
  padding-bottom: 110px !important;
}

._p-110 {
  padding: 110px !important;
}

._mr-110 {
  margin-right: 110px !important;
}

._mx-110 {
  margin-left: 110px !important;
  margin-right: 110px !important;
}

._my-110 {
  margin-top: 110px !important;
  margin-bottom: 110px !important;
}

._m-110 {
  margin: 110px !important;
}

._pr-110 {
  padding-right: 110px !important;
}

._px-110 {
  padding-left: 110px !important;
  padding-right: 110px !important;
}

._py-110 {
  padding-top: 110px !important;
  padding-bottom: 110px !important;
}

._p-110 {
  padding: 110px !important;
}

._mt-115 {
  margin-top: 115px !important;
}

._mx-115 {
  margin-left: 115px !important;
  margin-right: 115px !important;
}

._my-115 {
  margin-top: 115px !important;
  margin-bottom: 115px !important;
}

._m-115 {
  margin: 115px !important;
}

._pt-115 {
  padding-top: 115px !important;
}

._px-115 {
  padding-left: 115px !important;
  padding-right: 115px !important;
}

._py-115 {
  padding-top: 115px !important;
  padding-bottom: 115px !important;
}

._p-115 {
  padding: 115px !important;
}

._mb-115 {
  margin-bottom: 115px !important;
}

._mx-115 {
  margin-left: 115px !important;
  margin-right: 115px !important;
}

._my-115 {
  margin-top: 115px !important;
  margin-bottom: 115px !important;
}

._m-115 {
  margin: 115px !important;
}

._pb-115 {
  padding-bottom: 115px !important;
}

._px-115 {
  padding-left: 115px !important;
  padding-right: 115px !important;
}

._py-115 {
  padding-top: 115px !important;
  padding-bottom: 115px !important;
}

._p-115 {
  padding: 115px !important;
}

._ml-115 {
  margin-left: 115px !important;
}

._mx-115 {
  margin-left: 115px !important;
  margin-right: 115px !important;
}

._my-115 {
  margin-top: 115px !important;
  margin-bottom: 115px !important;
}

._m-115 {
  margin: 115px !important;
}

._pl-115 {
  padding-left: 115px !important;
}

._px-115 {
  padding-left: 115px !important;
  padding-right: 115px !important;
}

._py-115 {
  padding-top: 115px !important;
  padding-bottom: 115px !important;
}

._p-115 {
  padding: 115px !important;
}

._mr-115 {
  margin-right: 115px !important;
}

._mx-115 {
  margin-left: 115px !important;
  margin-right: 115px !important;
}

._my-115 {
  margin-top: 115px !important;
  margin-bottom: 115px !important;
}

._m-115 {
  margin: 115px !important;
}

._pr-115 {
  padding-right: 115px !important;
}

._px-115 {
  padding-left: 115px !important;
  padding-right: 115px !important;
}

._py-115 {
  padding-top: 115px !important;
  padding-bottom: 115px !important;
}

._p-115 {
  padding: 115px !important;
}

._mt-120 {
  margin-top: 120px !important;
}

._mx-120 {
  margin-left: 120px !important;
  margin-right: 120px !important;
}

._my-120 {
  margin-top: 120px !important;
  margin-bottom: 120px !important;
}

._m-120 {
  margin: 120px !important;
}

._pt-120 {
  padding-top: 120px !important;
}

._px-120 {
  padding-left: 120px !important;
  padding-right: 120px !important;
}

._py-120 {
  padding-top: 120px !important;
  padding-bottom: 120px !important;
}

._p-120 {
  padding: 120px !important;
}

._mb-120 {
  margin-bottom: 120px !important;
}

._mx-120 {
  margin-left: 120px !important;
  margin-right: 120px !important;
}

._my-120 {
  margin-top: 120px !important;
  margin-bottom: 120px !important;
}

._m-120 {
  margin: 120px !important;
}

._pb-120 {
  padding-bottom: 120px !important;
}

._px-120 {
  padding-left: 120px !important;
  padding-right: 120px !important;
}

._py-120 {
  padding-top: 120px !important;
  padding-bottom: 120px !important;
}

._p-120 {
  padding: 120px !important;
}

._ml-120 {
  margin-left: 120px !important;
}

._mx-120 {
  margin-left: 120px !important;
  margin-right: 120px !important;
}

._my-120 {
  margin-top: 120px !important;
  margin-bottom: 120px !important;
}

._m-120 {
  margin: 120px !important;
}

._pl-120 {
  padding-left: 120px !important;
}

._px-120 {
  padding-left: 120px !important;
  padding-right: 120px !important;
}

._py-120 {
  padding-top: 120px !important;
  padding-bottom: 120px !important;
}

._p-120 {
  padding: 120px !important;
}

._mr-120 {
  margin-right: 120px !important;
}

._mx-120 {
  margin-left: 120px !important;
  margin-right: 120px !important;
}

._my-120 {
  margin-top: 120px !important;
  margin-bottom: 120px !important;
}

._m-120 {
  margin: 120px !important;
}

._pr-120 {
  padding-right: 120px !important;
}

._px-120 {
  padding-left: 120px !important;
  padding-right: 120px !important;
}

._py-120 {
  padding-top: 120px !important;
  padding-bottom: 120px !important;
}

._p-120 {
  padding: 120px !important;
}

._mt-160 {
  margin-top: 160px !important;
}

._mx-160 {
  margin-left: 160px !important;
  margin-right: 160px !important;
}

._my-160 {
  margin-top: 160px !important;
  margin-bottom: 160px !important;
}

._m-160 {
  margin: 160px !important;
}

._pt-160 {
  padding-top: 160px !important;
}

._px-160 {
  padding-left: 160px !important;
  padding-right: 160px !important;
}

._py-160 {
  padding-top: 160px !important;
  padding-bottom: 160px !important;
}

._p-160 {
  padding: 160px !important;
}

._mb-160 {
  margin-bottom: 160px !important;
}

._mx-160 {
  margin-left: 160px !important;
  margin-right: 160px !important;
}

._my-160 {
  margin-top: 160px !important;
  margin-bottom: 160px !important;
}

._m-160 {
  margin: 160px !important;
}

._pb-160 {
  padding-bottom: 160px !important;
}

._px-160 {
  padding-left: 160px !important;
  padding-right: 160px !important;
}

._py-160 {
  padding-top: 160px !important;
  padding-bottom: 160px !important;
}

._p-160 {
  padding: 160px !important;
}

._ml-160 {
  margin-left: 160px !important;
}

._mx-160 {
  margin-left: 160px !important;
  margin-right: 160px !important;
}

._my-160 {
  margin-top: 160px !important;
  margin-bottom: 160px !important;
}

._m-160 {
  margin: 160px !important;
}

._pl-160 {
  padding-left: 160px !important;
}

._px-160 {
  padding-left: 160px !important;
  padding-right: 160px !important;
}

._py-160 {
  padding-top: 160px !important;
  padding-bottom: 160px !important;
}

._p-160 {
  padding: 160px !important;
}

._mr-160 {
  margin-right: 160px !important;
}

._mx-160 {
  margin-left: 160px !important;
  margin-right: 160px !important;
}

._my-160 {
  margin-top: 160px !important;
  margin-bottom: 160px !important;
}

._m-160 {
  margin: 160px !important;
}

._pr-160 {
  padding-right: 160px !important;
}

._px-160 {
  padding-left: 160px !important;
  padding-right: 160px !important;
}

._py-160 {
  padding-top: 160px !important;
  padding-bottom: 160px !important;
}

._p-160 {
  padding: 160px !important;
}

@media (max-width: 896px) {
  ._mt-sm-0 {
    margin-top: 0px !important;
  }
  ._mx-sm-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  ._my-sm-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  ._m-sm-0 {
    margin: 0px !important;
  }
  ._pt-sm-0 {
    padding-top: 0px !important;
  }
  ._px-sm-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  ._py-sm-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  ._p-sm-0 {
    padding: 0px !important;
  }
  ._mb-sm-0 {
    margin-bottom: 0px !important;
  }
  ._mx-sm-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  ._my-sm-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  ._m-sm-0 {
    margin: 0px !important;
  }
  ._pb-sm-0 {
    padding-bottom: 0px !important;
  }
  ._px-sm-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  ._py-sm-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  ._p-sm-0 {
    padding: 0px !important;
  }
  ._ml-sm-0 {
    margin-left: 0px !important;
  }
  ._mx-sm-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  ._my-sm-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  ._m-sm-0 {
    margin: 0px !important;
  }
  ._pl-sm-0 {
    padding-left: 0px !important;
  }
  ._px-sm-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  ._py-sm-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  ._p-sm-0 {
    padding: 0px !important;
  }
  ._mr-sm-0 {
    margin-right: 0px !important;
  }
  ._mx-sm-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  ._my-sm-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  ._m-sm-0 {
    margin: 0px !important;
  }
  ._pr-sm-0 {
    padding-right: 0px !important;
  }
  ._px-sm-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  ._py-sm-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  ._p-sm-0 {
    padding: 0px !important;
  }
  ._mt-sm-5 {
    margin-top: 5px !important;
  }
  ._mx-sm-5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  ._my-sm-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  ._m-sm-5 {
    margin: 5px !important;
  }
  ._pt-sm-5 {
    padding-top: 5px !important;
  }
  ._px-sm-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  ._py-sm-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  ._p-sm-5 {
    padding: 5px !important;
  }
  ._mb-sm-5 {
    margin-bottom: 5px !important;
  }
  ._mx-sm-5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  ._my-sm-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  ._m-sm-5 {
    margin: 5px !important;
  }
  ._pb-sm-5 {
    padding-bottom: 5px !important;
  }
  ._px-sm-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  ._py-sm-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  ._p-sm-5 {
    padding: 5px !important;
  }
  ._ml-sm-5 {
    margin-left: 5px !important;
  }
  ._mx-sm-5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  ._my-sm-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  ._m-sm-5 {
    margin: 5px !important;
  }
  ._pl-sm-5 {
    padding-left: 5px !important;
  }
  ._px-sm-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  ._py-sm-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  ._p-sm-5 {
    padding: 5px !important;
  }
  ._mr-sm-5 {
    margin-right: 5px !important;
  }
  ._mx-sm-5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  ._my-sm-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  ._m-sm-5 {
    margin: 5px !important;
  }
  ._pr-sm-5 {
    padding-right: 5px !important;
  }
  ._px-sm-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  ._py-sm-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  ._p-sm-5 {
    padding: 5px !important;
  }
  ._mt-sm-10 {
    margin-top: 10px !important;
  }
  ._mx-sm-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  ._my-sm-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  ._m-sm-10 {
    margin: 10px !important;
  }
  ._pt-sm-10 {
    padding-top: 10px !important;
  }
  ._px-sm-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  ._py-sm-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  ._p-sm-10 {
    padding: 10px !important;
  }
  ._mb-sm-10 {
    margin-bottom: 10px !important;
  }
  ._mx-sm-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  ._my-sm-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  ._m-sm-10 {
    margin: 10px !important;
  }
  ._pb-sm-10 {
    padding-bottom: 10px !important;
  }
  ._px-sm-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  ._py-sm-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  ._p-sm-10 {
    padding: 10px !important;
  }
  ._ml-sm-10 {
    margin-left: 10px !important;
  }
  ._mx-sm-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  ._my-sm-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  ._m-sm-10 {
    margin: 10px !important;
  }
  ._pl-sm-10 {
    padding-left: 10px !important;
  }
  ._px-sm-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  ._py-sm-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  ._p-sm-10 {
    padding: 10px !important;
  }
  ._mr-sm-10 {
    margin-right: 10px !important;
  }
  ._mx-sm-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  ._my-sm-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  ._m-sm-10 {
    margin: 10px !important;
  }
  ._pr-sm-10 {
    padding-right: 10px !important;
  }
  ._px-sm-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  ._py-sm-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  ._p-sm-10 {
    padding: 10px !important;
  }
  ._mt-sm-15 {
    margin-top: 15px !important;
  }
  ._mx-sm-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  ._my-sm-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  ._m-sm-15 {
    margin: 15px !important;
  }
  ._pt-sm-15 {
    padding-top: 15px !important;
  }
  ._px-sm-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  ._py-sm-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  ._p-sm-15 {
    padding: 15px !important;
  }
  ._mb-sm-15 {
    margin-bottom: 15px !important;
  }
  ._mx-sm-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  ._my-sm-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  ._m-sm-15 {
    margin: 15px !important;
  }
  ._pb-sm-15 {
    padding-bottom: 15px !important;
  }
  ._px-sm-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  ._py-sm-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  ._p-sm-15 {
    padding: 15px !important;
  }
  ._ml-sm-15 {
    margin-left: 15px !important;
  }
  ._mx-sm-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  ._my-sm-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  ._m-sm-15 {
    margin: 15px !important;
  }
  ._pl-sm-15 {
    padding-left: 15px !important;
  }
  ._px-sm-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  ._py-sm-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  ._p-sm-15 {
    padding: 15px !important;
  }
  ._mr-sm-15 {
    margin-right: 15px !important;
  }
  ._mx-sm-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  ._my-sm-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  ._m-sm-15 {
    margin: 15px !important;
  }
  ._pr-sm-15 {
    padding-right: 15px !important;
  }
  ._px-sm-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  ._py-sm-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  ._p-sm-15 {
    padding: 15px !important;
  }
  ._mt-sm-20 {
    margin-top: 20px !important;
  }
  ._mx-sm-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  ._my-sm-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  ._m-sm-20 {
    margin: 20px !important;
  }
  ._pt-sm-20 {
    padding-top: 20px !important;
  }
  ._px-sm-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  ._py-sm-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  ._p-sm-20 {
    padding: 20px !important;
  }
  ._mb-sm-20 {
    margin-bottom: 20px !important;
  }
  ._mx-sm-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  ._my-sm-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  ._m-sm-20 {
    margin: 20px !important;
  }
  ._pb-sm-20 {
    padding-bottom: 20px !important;
  }
  ._px-sm-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  ._py-sm-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  ._p-sm-20 {
    padding: 20px !important;
  }
  ._ml-sm-20 {
    margin-left: 20px !important;
  }
  ._mx-sm-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  ._my-sm-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  ._m-sm-20 {
    margin: 20px !important;
  }
  ._pl-sm-20 {
    padding-left: 20px !important;
  }
  ._px-sm-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  ._py-sm-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  ._p-sm-20 {
    padding: 20px !important;
  }
  ._mr-sm-20 {
    margin-right: 20px !important;
  }
  ._mx-sm-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  ._my-sm-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  ._m-sm-20 {
    margin: 20px !important;
  }
  ._pr-sm-20 {
    padding-right: 20px !important;
  }
  ._px-sm-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  ._py-sm-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  ._p-sm-20 {
    padding: 20px !important;
  }
  ._mt-sm-25 {
    margin-top: 25px !important;
  }
  ._mx-sm-25 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  ._my-sm-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  ._m-sm-25 {
    margin: 25px !important;
  }
  ._pt-sm-25 {
    padding-top: 25px !important;
  }
  ._px-sm-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  ._py-sm-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  ._p-sm-25 {
    padding: 25px !important;
  }
  ._mb-sm-25 {
    margin-bottom: 25px !important;
  }
  ._mx-sm-25 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  ._my-sm-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  ._m-sm-25 {
    margin: 25px !important;
  }
  ._pb-sm-25 {
    padding-bottom: 25px !important;
  }
  ._px-sm-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  ._py-sm-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  ._p-sm-25 {
    padding: 25px !important;
  }
  ._ml-sm-25 {
    margin-left: 25px !important;
  }
  ._mx-sm-25 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  ._my-sm-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  ._m-sm-25 {
    margin: 25px !important;
  }
  ._pl-sm-25 {
    padding-left: 25px !important;
  }
  ._px-sm-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  ._py-sm-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  ._p-sm-25 {
    padding: 25px !important;
  }
  ._mr-sm-25 {
    margin-right: 25px !important;
  }
  ._mx-sm-25 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  ._my-sm-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  ._m-sm-25 {
    margin: 25px !important;
  }
  ._pr-sm-25 {
    padding-right: 25px !important;
  }
  ._px-sm-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  ._py-sm-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  ._p-sm-25 {
    padding: 25px !important;
  }
  ._mt-sm-30 {
    margin-top: 30px !important;
  }
  ._mx-sm-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  ._my-sm-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  ._m-sm-30 {
    margin: 30px !important;
  }
  ._pt-sm-30 {
    padding-top: 30px !important;
  }
  ._px-sm-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  ._py-sm-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  ._p-sm-30 {
    padding: 30px !important;
  }
  ._mb-sm-30 {
    margin-bottom: 30px !important;
  }
  ._mx-sm-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  ._my-sm-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  ._m-sm-30 {
    margin: 30px !important;
  }
  ._pb-sm-30 {
    padding-bottom: 30px !important;
  }
  ._px-sm-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  ._py-sm-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  ._p-sm-30 {
    padding: 30px !important;
  }
  ._ml-sm-30 {
    margin-left: 30px !important;
  }
  ._mx-sm-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  ._my-sm-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  ._m-sm-30 {
    margin: 30px !important;
  }
  ._pl-sm-30 {
    padding-left: 30px !important;
  }
  ._px-sm-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  ._py-sm-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  ._p-sm-30 {
    padding: 30px !important;
  }
  ._mr-sm-30 {
    margin-right: 30px !important;
  }
  ._mx-sm-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  ._my-sm-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  ._m-sm-30 {
    margin: 30px !important;
  }
  ._pr-sm-30 {
    padding-right: 30px !important;
  }
  ._px-sm-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  ._py-sm-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  ._p-sm-30 {
    padding: 30px !important;
  }
  ._mt-sm-35 {
    margin-top: 35px !important;
  }
  ._mx-sm-35 {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  ._my-sm-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  ._m-sm-35 {
    margin: 35px !important;
  }
  ._pt-sm-35 {
    padding-top: 35px !important;
  }
  ._px-sm-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  ._py-sm-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  ._p-sm-35 {
    padding: 35px !important;
  }
  ._mb-sm-35 {
    margin-bottom: 35px !important;
  }
  ._mx-sm-35 {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  ._my-sm-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  ._m-sm-35 {
    margin: 35px !important;
  }
  ._pb-sm-35 {
    padding-bottom: 35px !important;
  }
  ._px-sm-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  ._py-sm-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  ._p-sm-35 {
    padding: 35px !important;
  }
  ._ml-sm-35 {
    margin-left: 35px !important;
  }
  ._mx-sm-35 {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  ._my-sm-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  ._m-sm-35 {
    margin: 35px !important;
  }
  ._pl-sm-35 {
    padding-left: 35px !important;
  }
  ._px-sm-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  ._py-sm-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  ._p-sm-35 {
    padding: 35px !important;
  }
  ._mr-sm-35 {
    margin-right: 35px !important;
  }
  ._mx-sm-35 {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  ._my-sm-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  ._m-sm-35 {
    margin: 35px !important;
  }
  ._pr-sm-35 {
    padding-right: 35px !important;
  }
  ._px-sm-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  ._py-sm-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  ._p-sm-35 {
    padding: 35px !important;
  }
  ._mt-sm-40 {
    margin-top: 40px !important;
  }
  ._mx-sm-40 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  ._my-sm-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  ._m-sm-40 {
    margin: 40px !important;
  }
  ._pt-sm-40 {
    padding-top: 40px !important;
  }
  ._px-sm-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  ._py-sm-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  ._p-sm-40 {
    padding: 40px !important;
  }
  ._mb-sm-40 {
    margin-bottom: 40px !important;
  }
  ._mx-sm-40 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  ._my-sm-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  ._m-sm-40 {
    margin: 40px !important;
  }
  ._pb-sm-40 {
    padding-bottom: 40px !important;
  }
  ._px-sm-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  ._py-sm-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  ._p-sm-40 {
    padding: 40px !important;
  }
  ._ml-sm-40 {
    margin-left: 40px !important;
  }
  ._mx-sm-40 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  ._my-sm-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  ._m-sm-40 {
    margin: 40px !important;
  }
  ._pl-sm-40 {
    padding-left: 40px !important;
  }
  ._px-sm-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  ._py-sm-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  ._p-sm-40 {
    padding: 40px !important;
  }
  ._mr-sm-40 {
    margin-right: 40px !important;
  }
  ._mx-sm-40 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  ._my-sm-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  ._m-sm-40 {
    margin: 40px !important;
  }
  ._pr-sm-40 {
    padding-right: 40px !important;
  }
  ._px-sm-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  ._py-sm-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  ._p-sm-40 {
    padding: 40px !important;
  }
  ._mt-sm-43 {
    margin-top: 43px !important;
  }
  ._mx-sm-43 {
    margin-left: 43px !important;
    margin-right: 43px !important;
  }
  ._my-sm-43 {
    margin-top: 43px !important;
    margin-bottom: 43px !important;
  }
  ._m-sm-43 {
    margin: 43px !important;
  }
  ._pt-sm-43 {
    padding-top: 43px !important;
  }
  ._px-sm-43 {
    padding-left: 43px !important;
    padding-right: 43px !important;
  }
  ._py-sm-43 {
    padding-top: 43px !important;
    padding-bottom: 43px !important;
  }
  ._p-sm-43 {
    padding: 43px !important;
  }
  ._mb-sm-43 {
    margin-bottom: 43px !important;
  }
  ._mx-sm-43 {
    margin-left: 43px !important;
    margin-right: 43px !important;
  }
  ._my-sm-43 {
    margin-top: 43px !important;
    margin-bottom: 43px !important;
  }
  ._m-sm-43 {
    margin: 43px !important;
  }
  ._pb-sm-43 {
    padding-bottom: 43px !important;
  }
  ._px-sm-43 {
    padding-left: 43px !important;
    padding-right: 43px !important;
  }
  ._py-sm-43 {
    padding-top: 43px !important;
    padding-bottom: 43px !important;
  }
  ._p-sm-43 {
    padding: 43px !important;
  }
  ._ml-sm-43 {
    margin-left: 43px !important;
  }
  ._mx-sm-43 {
    margin-left: 43px !important;
    margin-right: 43px !important;
  }
  ._my-sm-43 {
    margin-top: 43px !important;
    margin-bottom: 43px !important;
  }
  ._m-sm-43 {
    margin: 43px !important;
  }
  ._pl-sm-43 {
    padding-left: 43px !important;
  }
  ._px-sm-43 {
    padding-left: 43px !important;
    padding-right: 43px !important;
  }
  ._py-sm-43 {
    padding-top: 43px !important;
    padding-bottom: 43px !important;
  }
  ._p-sm-43 {
    padding: 43px !important;
  }
  ._mr-sm-43 {
    margin-right: 43px !important;
  }
  ._mx-sm-43 {
    margin-left: 43px !important;
    margin-right: 43px !important;
  }
  ._my-sm-43 {
    margin-top: 43px !important;
    margin-bottom: 43px !important;
  }
  ._m-sm-43 {
    margin: 43px !important;
  }
  ._pr-sm-43 {
    padding-right: 43px !important;
  }
  ._px-sm-43 {
    padding-left: 43px !important;
    padding-right: 43px !important;
  }
  ._py-sm-43 {
    padding-top: 43px !important;
    padding-bottom: 43px !important;
  }
  ._p-sm-43 {
    padding: 43px !important;
  }
  ._mt-sm-45 {
    margin-top: 45px !important;
  }
  ._mx-sm-45 {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  ._my-sm-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  ._m-sm-45 {
    margin: 45px !important;
  }
  ._pt-sm-45 {
    padding-top: 45px !important;
  }
  ._px-sm-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  ._py-sm-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  ._p-sm-45 {
    padding: 45px !important;
  }
  ._mb-sm-45 {
    margin-bottom: 45px !important;
  }
  ._mx-sm-45 {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  ._my-sm-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  ._m-sm-45 {
    margin: 45px !important;
  }
  ._pb-sm-45 {
    padding-bottom: 45px !important;
  }
  ._px-sm-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  ._py-sm-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  ._p-sm-45 {
    padding: 45px !important;
  }
  ._ml-sm-45 {
    margin-left: 45px !important;
  }
  ._mx-sm-45 {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  ._my-sm-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  ._m-sm-45 {
    margin: 45px !important;
  }
  ._pl-sm-45 {
    padding-left: 45px !important;
  }
  ._px-sm-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  ._py-sm-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  ._p-sm-45 {
    padding: 45px !important;
  }
  ._mr-sm-45 {
    margin-right: 45px !important;
  }
  ._mx-sm-45 {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  ._my-sm-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  ._m-sm-45 {
    margin: 45px !important;
  }
  ._pr-sm-45 {
    padding-right: 45px !important;
  }
  ._px-sm-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  ._py-sm-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  ._p-sm-45 {
    padding: 45px !important;
  }
  ._mt-sm-46 {
    margin-top: 46px !important;
  }
  ._mx-sm-46 {
    margin-left: 46px !important;
    margin-right: 46px !important;
  }
  ._my-sm-46 {
    margin-top: 46px !important;
    margin-bottom: 46px !important;
  }
  ._m-sm-46 {
    margin: 46px !important;
  }
  ._pt-sm-46 {
    padding-top: 46px !important;
  }
  ._px-sm-46 {
    padding-left: 46px !important;
    padding-right: 46px !important;
  }
  ._py-sm-46 {
    padding-top: 46px !important;
    padding-bottom: 46px !important;
  }
  ._p-sm-46 {
    padding: 46px !important;
  }
  ._mb-sm-46 {
    margin-bottom: 46px !important;
  }
  ._mx-sm-46 {
    margin-left: 46px !important;
    margin-right: 46px !important;
  }
  ._my-sm-46 {
    margin-top: 46px !important;
    margin-bottom: 46px !important;
  }
  ._m-sm-46 {
    margin: 46px !important;
  }
  ._pb-sm-46 {
    padding-bottom: 46px !important;
  }
  ._px-sm-46 {
    padding-left: 46px !important;
    padding-right: 46px !important;
  }
  ._py-sm-46 {
    padding-top: 46px !important;
    padding-bottom: 46px !important;
  }
  ._p-sm-46 {
    padding: 46px !important;
  }
  ._ml-sm-46 {
    margin-left: 46px !important;
  }
  ._mx-sm-46 {
    margin-left: 46px !important;
    margin-right: 46px !important;
  }
  ._my-sm-46 {
    margin-top: 46px !important;
    margin-bottom: 46px !important;
  }
  ._m-sm-46 {
    margin: 46px !important;
  }
  ._pl-sm-46 {
    padding-left: 46px !important;
  }
  ._px-sm-46 {
    padding-left: 46px !important;
    padding-right: 46px !important;
  }
  ._py-sm-46 {
    padding-top: 46px !important;
    padding-bottom: 46px !important;
  }
  ._p-sm-46 {
    padding: 46px !important;
  }
  ._mr-sm-46 {
    margin-right: 46px !important;
  }
  ._mx-sm-46 {
    margin-left: 46px !important;
    margin-right: 46px !important;
  }
  ._my-sm-46 {
    margin-top: 46px !important;
    margin-bottom: 46px !important;
  }
  ._m-sm-46 {
    margin: 46px !important;
  }
  ._pr-sm-46 {
    padding-right: 46px !important;
  }
  ._px-sm-46 {
    padding-left: 46px !important;
    padding-right: 46px !important;
  }
  ._py-sm-46 {
    padding-top: 46px !important;
    padding-bottom: 46px !important;
  }
  ._p-sm-46 {
    padding: 46px !important;
  }
  ._mt-sm-50 {
    margin-top: 50px !important;
  }
  ._mx-sm-50 {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  ._my-sm-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  ._m-sm-50 {
    margin: 50px !important;
  }
  ._pt-sm-50 {
    padding-top: 50px !important;
  }
  ._px-sm-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  ._py-sm-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  ._p-sm-50 {
    padding: 50px !important;
  }
  ._mb-sm-50 {
    margin-bottom: 50px !important;
  }
  ._mx-sm-50 {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  ._my-sm-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  ._m-sm-50 {
    margin: 50px !important;
  }
  ._pb-sm-50 {
    padding-bottom: 50px !important;
  }
  ._px-sm-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  ._py-sm-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  ._p-sm-50 {
    padding: 50px !important;
  }
  ._ml-sm-50 {
    margin-left: 50px !important;
  }
  ._mx-sm-50 {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  ._my-sm-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  ._m-sm-50 {
    margin: 50px !important;
  }
  ._pl-sm-50 {
    padding-left: 50px !important;
  }
  ._px-sm-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  ._py-sm-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  ._p-sm-50 {
    padding: 50px !important;
  }
  ._mr-sm-50 {
    margin-right: 50px !important;
  }
  ._mx-sm-50 {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  ._my-sm-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  ._m-sm-50 {
    margin: 50px !important;
  }
  ._pr-sm-50 {
    padding-right: 50px !important;
  }
  ._px-sm-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  ._py-sm-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  ._p-sm-50 {
    padding: 50px !important;
  }
  ._mt-sm-55 {
    margin-top: 55px !important;
  }
  ._mx-sm-55 {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }
  ._my-sm-55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }
  ._m-sm-55 {
    margin: 55px !important;
  }
  ._pt-sm-55 {
    padding-top: 55px !important;
  }
  ._px-sm-55 {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  ._py-sm-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  ._p-sm-55 {
    padding: 55px !important;
  }
  ._mb-sm-55 {
    margin-bottom: 55px !important;
  }
  ._mx-sm-55 {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }
  ._my-sm-55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }
  ._m-sm-55 {
    margin: 55px !important;
  }
  ._pb-sm-55 {
    padding-bottom: 55px !important;
  }
  ._px-sm-55 {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  ._py-sm-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  ._p-sm-55 {
    padding: 55px !important;
  }
  ._ml-sm-55 {
    margin-left: 55px !important;
  }
  ._mx-sm-55 {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }
  ._my-sm-55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }
  ._m-sm-55 {
    margin: 55px !important;
  }
  ._pl-sm-55 {
    padding-left: 55px !important;
  }
  ._px-sm-55 {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  ._py-sm-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  ._p-sm-55 {
    padding: 55px !important;
  }
  ._mr-sm-55 {
    margin-right: 55px !important;
  }
  ._mx-sm-55 {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }
  ._my-sm-55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }
  ._m-sm-55 {
    margin: 55px !important;
  }
  ._pr-sm-55 {
    padding-right: 55px !important;
  }
  ._px-sm-55 {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  ._py-sm-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  ._p-sm-55 {
    padding: 55px !important;
  }
  ._mt-sm-60 {
    margin-top: 60px !important;
  }
  ._mx-sm-60 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  ._my-sm-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  ._m-sm-60 {
    margin: 60px !important;
  }
  ._pt-sm-60 {
    padding-top: 60px !important;
  }
  ._px-sm-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  ._py-sm-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  ._p-sm-60 {
    padding: 60px !important;
  }
  ._mb-sm-60 {
    margin-bottom: 60px !important;
  }
  ._mx-sm-60 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  ._my-sm-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  ._m-sm-60 {
    margin: 60px !important;
  }
  ._pb-sm-60 {
    padding-bottom: 60px !important;
  }
  ._px-sm-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  ._py-sm-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  ._p-sm-60 {
    padding: 60px !important;
  }
  ._ml-sm-60 {
    margin-left: 60px !important;
  }
  ._mx-sm-60 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  ._my-sm-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  ._m-sm-60 {
    margin: 60px !important;
  }
  ._pl-sm-60 {
    padding-left: 60px !important;
  }
  ._px-sm-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  ._py-sm-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  ._p-sm-60 {
    padding: 60px !important;
  }
  ._mr-sm-60 {
    margin-right: 60px !important;
  }
  ._mx-sm-60 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  ._my-sm-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  ._m-sm-60 {
    margin: 60px !important;
  }
  ._pr-sm-60 {
    padding-right: 60px !important;
  }
  ._px-sm-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  ._py-sm-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  ._p-sm-60 {
    padding: 60px !important;
  }
  ._mt-sm-65 {
    margin-top: 65px !important;
  }
  ._mx-sm-65 {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }
  ._my-sm-65 {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }
  ._m-sm-65 {
    margin: 65px !important;
  }
  ._pt-sm-65 {
    padding-top: 65px !important;
  }
  ._px-sm-65 {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }
  ._py-sm-65 {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  ._p-sm-65 {
    padding: 65px !important;
  }
  ._mb-sm-65 {
    margin-bottom: 65px !important;
  }
  ._mx-sm-65 {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }
  ._my-sm-65 {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }
  ._m-sm-65 {
    margin: 65px !important;
  }
  ._pb-sm-65 {
    padding-bottom: 65px !important;
  }
  ._px-sm-65 {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }
  ._py-sm-65 {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  ._p-sm-65 {
    padding: 65px !important;
  }
  ._ml-sm-65 {
    margin-left: 65px !important;
  }
  ._mx-sm-65 {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }
  ._my-sm-65 {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }
  ._m-sm-65 {
    margin: 65px !important;
  }
  ._pl-sm-65 {
    padding-left: 65px !important;
  }
  ._px-sm-65 {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }
  ._py-sm-65 {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  ._p-sm-65 {
    padding: 65px !important;
  }
  ._mr-sm-65 {
    margin-right: 65px !important;
  }
  ._mx-sm-65 {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }
  ._my-sm-65 {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }
  ._m-sm-65 {
    margin: 65px !important;
  }
  ._pr-sm-65 {
    padding-right: 65px !important;
  }
  ._px-sm-65 {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }
  ._py-sm-65 {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  ._p-sm-65 {
    padding: 65px !important;
  }
  ._mt-sm-70 {
    margin-top: 70px !important;
  }
  ._mx-sm-70 {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }
  ._my-sm-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  ._m-sm-70 {
    margin: 70px !important;
  }
  ._pt-sm-70 {
    padding-top: 70px !important;
  }
  ._px-sm-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  ._py-sm-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  ._p-sm-70 {
    padding: 70px !important;
  }
  ._mb-sm-70 {
    margin-bottom: 70px !important;
  }
  ._mx-sm-70 {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }
  ._my-sm-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  ._m-sm-70 {
    margin: 70px !important;
  }
  ._pb-sm-70 {
    padding-bottom: 70px !important;
  }
  ._px-sm-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  ._py-sm-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  ._p-sm-70 {
    padding: 70px !important;
  }
  ._ml-sm-70 {
    margin-left: 70px !important;
  }
  ._mx-sm-70 {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }
  ._my-sm-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  ._m-sm-70 {
    margin: 70px !important;
  }
  ._pl-sm-70 {
    padding-left: 70px !important;
  }
  ._px-sm-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  ._py-sm-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  ._p-sm-70 {
    padding: 70px !important;
  }
  ._mr-sm-70 {
    margin-right: 70px !important;
  }
  ._mx-sm-70 {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }
  ._my-sm-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  ._m-sm-70 {
    margin: 70px !important;
  }
  ._pr-sm-70 {
    padding-right: 70px !important;
  }
  ._px-sm-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  ._py-sm-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  ._p-sm-70 {
    padding: 70px !important;
  }
  ._mt-sm-75 {
    margin-top: 75px !important;
  }
  ._mx-sm-75 {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }
  ._my-sm-75 {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }
  ._m-sm-75 {
    margin: 75px !important;
  }
  ._pt-sm-75 {
    padding-top: 75px !important;
  }
  ._px-sm-75 {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  ._py-sm-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  ._p-sm-75 {
    padding: 75px !important;
  }
  ._mb-sm-75 {
    margin-bottom: 75px !important;
  }
  ._mx-sm-75 {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }
  ._my-sm-75 {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }
  ._m-sm-75 {
    margin: 75px !important;
  }
  ._pb-sm-75 {
    padding-bottom: 75px !important;
  }
  ._px-sm-75 {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  ._py-sm-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  ._p-sm-75 {
    padding: 75px !important;
  }
  ._ml-sm-75 {
    margin-left: 75px !important;
  }
  ._mx-sm-75 {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }
  ._my-sm-75 {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }
  ._m-sm-75 {
    margin: 75px !important;
  }
  ._pl-sm-75 {
    padding-left: 75px !important;
  }
  ._px-sm-75 {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  ._py-sm-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  ._p-sm-75 {
    padding: 75px !important;
  }
  ._mr-sm-75 {
    margin-right: 75px !important;
  }
  ._mx-sm-75 {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }
  ._my-sm-75 {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }
  ._m-sm-75 {
    margin: 75px !important;
  }
  ._pr-sm-75 {
    padding-right: 75px !important;
  }
  ._px-sm-75 {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  ._py-sm-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  ._p-sm-75 {
    padding: 75px !important;
  }
  ._mt-sm-80 {
    margin-top: 80px !important;
  }
  ._mx-sm-80 {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  ._my-sm-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  ._m-sm-80 {
    margin: 80px !important;
  }
  ._pt-sm-80 {
    padding-top: 80px !important;
  }
  ._px-sm-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  ._py-sm-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  ._p-sm-80 {
    padding: 80px !important;
  }
  ._mb-sm-80 {
    margin-bottom: 80px !important;
  }
  ._mx-sm-80 {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  ._my-sm-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  ._m-sm-80 {
    margin: 80px !important;
  }
  ._pb-sm-80 {
    padding-bottom: 80px !important;
  }
  ._px-sm-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  ._py-sm-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  ._p-sm-80 {
    padding: 80px !important;
  }
  ._ml-sm-80 {
    margin-left: 80px !important;
  }
  ._mx-sm-80 {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  ._my-sm-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  ._m-sm-80 {
    margin: 80px !important;
  }
  ._pl-sm-80 {
    padding-left: 80px !important;
  }
  ._px-sm-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  ._py-sm-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  ._p-sm-80 {
    padding: 80px !important;
  }
  ._mr-sm-80 {
    margin-right: 80px !important;
  }
  ._mx-sm-80 {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  ._my-sm-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  ._m-sm-80 {
    margin: 80px !important;
  }
  ._pr-sm-80 {
    padding-right: 80px !important;
  }
  ._px-sm-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  ._py-sm-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  ._p-sm-80 {
    padding: 80px !important;
  }
  ._mt-sm-85 {
    margin-top: 85px !important;
  }
  ._mx-sm-85 {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }
  ._my-sm-85 {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }
  ._m-sm-85 {
    margin: 85px !important;
  }
  ._pt-sm-85 {
    padding-top: 85px !important;
  }
  ._px-sm-85 {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  ._py-sm-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  ._p-sm-85 {
    padding: 85px !important;
  }
  ._mb-sm-85 {
    margin-bottom: 85px !important;
  }
  ._mx-sm-85 {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }
  ._my-sm-85 {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }
  ._m-sm-85 {
    margin: 85px !important;
  }
  ._pb-sm-85 {
    padding-bottom: 85px !important;
  }
  ._px-sm-85 {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  ._py-sm-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  ._p-sm-85 {
    padding: 85px !important;
  }
  ._ml-sm-85 {
    margin-left: 85px !important;
  }
  ._mx-sm-85 {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }
  ._my-sm-85 {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }
  ._m-sm-85 {
    margin: 85px !important;
  }
  ._pl-sm-85 {
    padding-left: 85px !important;
  }
  ._px-sm-85 {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  ._py-sm-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  ._p-sm-85 {
    padding: 85px !important;
  }
  ._mr-sm-85 {
    margin-right: 85px !important;
  }
  ._mx-sm-85 {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }
  ._my-sm-85 {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }
  ._m-sm-85 {
    margin: 85px !important;
  }
  ._pr-sm-85 {
    padding-right: 85px !important;
  }
  ._px-sm-85 {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  ._py-sm-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  ._p-sm-85 {
    padding: 85px !important;
  }
  ._mt-sm-90 {
    margin-top: 90px !important;
  }
  ._mx-sm-90 {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }
  ._my-sm-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  ._m-sm-90 {
    margin: 90px !important;
  }
  ._pt-sm-90 {
    padding-top: 90px !important;
  }
  ._px-sm-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  ._py-sm-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  ._p-sm-90 {
    padding: 90px !important;
  }
  ._mb-sm-90 {
    margin-bottom: 90px !important;
  }
  ._mx-sm-90 {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }
  ._my-sm-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  ._m-sm-90 {
    margin: 90px !important;
  }
  ._pb-sm-90 {
    padding-bottom: 90px !important;
  }
  ._px-sm-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  ._py-sm-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  ._p-sm-90 {
    padding: 90px !important;
  }
  ._ml-sm-90 {
    margin-left: 90px !important;
  }
  ._mx-sm-90 {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }
  ._my-sm-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  ._m-sm-90 {
    margin: 90px !important;
  }
  ._pl-sm-90 {
    padding-left: 90px !important;
  }
  ._px-sm-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  ._py-sm-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  ._p-sm-90 {
    padding: 90px !important;
  }
  ._mr-sm-90 {
    margin-right: 90px !important;
  }
  ._mx-sm-90 {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }
  ._my-sm-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  ._m-sm-90 {
    margin: 90px !important;
  }
  ._pr-sm-90 {
    padding-right: 90px !important;
  }
  ._px-sm-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  ._py-sm-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  ._p-sm-90 {
    padding: 90px !important;
  }
  ._mt-sm-95 {
    margin-top: 95px !important;
  }
  ._mx-sm-95 {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }
  ._my-sm-95 {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }
  ._m-sm-95 {
    margin: 95px !important;
  }
  ._pt-sm-95 {
    padding-top: 95px !important;
  }
  ._px-sm-95 {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  ._py-sm-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  ._p-sm-95 {
    padding: 95px !important;
  }
  ._mb-sm-95 {
    margin-bottom: 95px !important;
  }
  ._mx-sm-95 {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }
  ._my-sm-95 {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }
  ._m-sm-95 {
    margin: 95px !important;
  }
  ._pb-sm-95 {
    padding-bottom: 95px !important;
  }
  ._px-sm-95 {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  ._py-sm-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  ._p-sm-95 {
    padding: 95px !important;
  }
  ._ml-sm-95 {
    margin-left: 95px !important;
  }
  ._mx-sm-95 {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }
  ._my-sm-95 {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }
  ._m-sm-95 {
    margin: 95px !important;
  }
  ._pl-sm-95 {
    padding-left: 95px !important;
  }
  ._px-sm-95 {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  ._py-sm-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  ._p-sm-95 {
    padding: 95px !important;
  }
  ._mr-sm-95 {
    margin-right: 95px !important;
  }
  ._mx-sm-95 {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }
  ._my-sm-95 {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }
  ._m-sm-95 {
    margin: 95px !important;
  }
  ._pr-sm-95 {
    padding-right: 95px !important;
  }
  ._px-sm-95 {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  ._py-sm-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  ._p-sm-95 {
    padding: 95px !important;
  }
  ._mt-sm-100 {
    margin-top: 100px !important;
  }
  ._mx-sm-100 {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }
  ._my-sm-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  ._m-sm-100 {
    margin: 100px !important;
  }
  ._pt-sm-100 {
    padding-top: 100px !important;
  }
  ._px-sm-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  ._py-sm-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  ._p-sm-100 {
    padding: 100px !important;
  }
  ._mb-sm-100 {
    margin-bottom: 100px !important;
  }
  ._mx-sm-100 {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }
  ._my-sm-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  ._m-sm-100 {
    margin: 100px !important;
  }
  ._pb-sm-100 {
    padding-bottom: 100px !important;
  }
  ._px-sm-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  ._py-sm-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  ._p-sm-100 {
    padding: 100px !important;
  }
  ._ml-sm-100 {
    margin-left: 100px !important;
  }
  ._mx-sm-100 {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }
  ._my-sm-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  ._m-sm-100 {
    margin: 100px !important;
  }
  ._pl-sm-100 {
    padding-left: 100px !important;
  }
  ._px-sm-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  ._py-sm-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  ._p-sm-100 {
    padding: 100px !important;
  }
  ._mr-sm-100 {
    margin-right: 100px !important;
  }
  ._mx-sm-100 {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }
  ._my-sm-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  ._m-sm-100 {
    margin: 100px !important;
  }
  ._pr-sm-100 {
    padding-right: 100px !important;
  }
  ._px-sm-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  ._py-sm-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  ._p-sm-100 {
    padding: 100px !important;
  }
  ._mt-sm-105 {
    margin-top: 105px !important;
  }
  ._mx-sm-105 {
    margin-left: 105px !important;
    margin-right: 105px !important;
  }
  ._my-sm-105 {
    margin-top: 105px !important;
    margin-bottom: 105px !important;
  }
  ._m-sm-105 {
    margin: 105px !important;
  }
  ._pt-sm-105 {
    padding-top: 105px !important;
  }
  ._px-sm-105 {
    padding-left: 105px !important;
    padding-right: 105px !important;
  }
  ._py-sm-105 {
    padding-top: 105px !important;
    padding-bottom: 105px !important;
  }
  ._p-sm-105 {
    padding: 105px !important;
  }
  ._mb-sm-105 {
    margin-bottom: 105px !important;
  }
  ._mx-sm-105 {
    margin-left: 105px !important;
    margin-right: 105px !important;
  }
  ._my-sm-105 {
    margin-top: 105px !important;
    margin-bottom: 105px !important;
  }
  ._m-sm-105 {
    margin: 105px !important;
  }
  ._pb-sm-105 {
    padding-bottom: 105px !important;
  }
  ._px-sm-105 {
    padding-left: 105px !important;
    padding-right: 105px !important;
  }
  ._py-sm-105 {
    padding-top: 105px !important;
    padding-bottom: 105px !important;
  }
  ._p-sm-105 {
    padding: 105px !important;
  }
  ._ml-sm-105 {
    margin-left: 105px !important;
  }
  ._mx-sm-105 {
    margin-left: 105px !important;
    margin-right: 105px !important;
  }
  ._my-sm-105 {
    margin-top: 105px !important;
    margin-bottom: 105px !important;
  }
  ._m-sm-105 {
    margin: 105px !important;
  }
  ._pl-sm-105 {
    padding-left: 105px !important;
  }
  ._px-sm-105 {
    padding-left: 105px !important;
    padding-right: 105px !important;
  }
  ._py-sm-105 {
    padding-top: 105px !important;
    padding-bottom: 105px !important;
  }
  ._p-sm-105 {
    padding: 105px !important;
  }
  ._mr-sm-105 {
    margin-right: 105px !important;
  }
  ._mx-sm-105 {
    margin-left: 105px !important;
    margin-right: 105px !important;
  }
  ._my-sm-105 {
    margin-top: 105px !important;
    margin-bottom: 105px !important;
  }
  ._m-sm-105 {
    margin: 105px !important;
  }
  ._pr-sm-105 {
    padding-right: 105px !important;
  }
  ._px-sm-105 {
    padding-left: 105px !important;
    padding-right: 105px !important;
  }
  ._py-sm-105 {
    padding-top: 105px !important;
    padding-bottom: 105px !important;
  }
  ._p-sm-105 {
    padding: 105px !important;
  }
  ._mt-sm-110 {
    margin-top: 110px !important;
  }
  ._mx-sm-110 {
    margin-left: 110px !important;
    margin-right: 110px !important;
  }
  ._my-sm-110 {
    margin-top: 110px !important;
    margin-bottom: 110px !important;
  }
  ._m-sm-110 {
    margin: 110px !important;
  }
  ._pt-sm-110 {
    padding-top: 110px !important;
  }
  ._px-sm-110 {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }
  ._py-sm-110 {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }
  ._p-sm-110 {
    padding: 110px !important;
  }
  ._mb-sm-110 {
    margin-bottom: 110px !important;
  }
  ._mx-sm-110 {
    margin-left: 110px !important;
    margin-right: 110px !important;
  }
  ._my-sm-110 {
    margin-top: 110px !important;
    margin-bottom: 110px !important;
  }
  ._m-sm-110 {
    margin: 110px !important;
  }
  ._pb-sm-110 {
    padding-bottom: 110px !important;
  }
  ._px-sm-110 {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }
  ._py-sm-110 {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }
  ._p-sm-110 {
    padding: 110px !important;
  }
  ._ml-sm-110 {
    margin-left: 110px !important;
  }
  ._mx-sm-110 {
    margin-left: 110px !important;
    margin-right: 110px !important;
  }
  ._my-sm-110 {
    margin-top: 110px !important;
    margin-bottom: 110px !important;
  }
  ._m-sm-110 {
    margin: 110px !important;
  }
  ._pl-sm-110 {
    padding-left: 110px !important;
  }
  ._px-sm-110 {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }
  ._py-sm-110 {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }
  ._p-sm-110 {
    padding: 110px !important;
  }
  ._mr-sm-110 {
    margin-right: 110px !important;
  }
  ._mx-sm-110 {
    margin-left: 110px !important;
    margin-right: 110px !important;
  }
  ._my-sm-110 {
    margin-top: 110px !important;
    margin-bottom: 110px !important;
  }
  ._m-sm-110 {
    margin: 110px !important;
  }
  ._pr-sm-110 {
    padding-right: 110px !important;
  }
  ._px-sm-110 {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }
  ._py-sm-110 {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }
  ._p-sm-110 {
    padding: 110px !important;
  }
  ._mt-sm-115 {
    margin-top: 115px !important;
  }
  ._mx-sm-115 {
    margin-left: 115px !important;
    margin-right: 115px !important;
  }
  ._my-sm-115 {
    margin-top: 115px !important;
    margin-bottom: 115px !important;
  }
  ._m-sm-115 {
    margin: 115px !important;
  }
  ._pt-sm-115 {
    padding-top: 115px !important;
  }
  ._px-sm-115 {
    padding-left: 115px !important;
    padding-right: 115px !important;
  }
  ._py-sm-115 {
    padding-top: 115px !important;
    padding-bottom: 115px !important;
  }
  ._p-sm-115 {
    padding: 115px !important;
  }
  ._mb-sm-115 {
    margin-bottom: 115px !important;
  }
  ._mx-sm-115 {
    margin-left: 115px !important;
    margin-right: 115px !important;
  }
  ._my-sm-115 {
    margin-top: 115px !important;
    margin-bottom: 115px !important;
  }
  ._m-sm-115 {
    margin: 115px !important;
  }
  ._pb-sm-115 {
    padding-bottom: 115px !important;
  }
  ._px-sm-115 {
    padding-left: 115px !important;
    padding-right: 115px !important;
  }
  ._py-sm-115 {
    padding-top: 115px !important;
    padding-bottom: 115px !important;
  }
  ._p-sm-115 {
    padding: 115px !important;
  }
  ._ml-sm-115 {
    margin-left: 115px !important;
  }
  ._mx-sm-115 {
    margin-left: 115px !important;
    margin-right: 115px !important;
  }
  ._my-sm-115 {
    margin-top: 115px !important;
    margin-bottom: 115px !important;
  }
  ._m-sm-115 {
    margin: 115px !important;
  }
  ._pl-sm-115 {
    padding-left: 115px !important;
  }
  ._px-sm-115 {
    padding-left: 115px !important;
    padding-right: 115px !important;
  }
  ._py-sm-115 {
    padding-top: 115px !important;
    padding-bottom: 115px !important;
  }
  ._p-sm-115 {
    padding: 115px !important;
  }
  ._mr-sm-115 {
    margin-right: 115px !important;
  }
  ._mx-sm-115 {
    margin-left: 115px !important;
    margin-right: 115px !important;
  }
  ._my-sm-115 {
    margin-top: 115px !important;
    margin-bottom: 115px !important;
  }
  ._m-sm-115 {
    margin: 115px !important;
  }
  ._pr-sm-115 {
    padding-right: 115px !important;
  }
  ._px-sm-115 {
    padding-left: 115px !important;
    padding-right: 115px !important;
  }
  ._py-sm-115 {
    padding-top: 115px !important;
    padding-bottom: 115px !important;
  }
  ._p-sm-115 {
    padding: 115px !important;
  }
  ._mt-sm-120 {
    margin-top: 120px !important;
  }
  ._mx-sm-120 {
    margin-left: 120px !important;
    margin-right: 120px !important;
  }
  ._my-sm-120 {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
  }
  ._m-sm-120 {
    margin: 120px !important;
  }
  ._pt-sm-120 {
    padding-top: 120px !important;
  }
  ._px-sm-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  ._py-sm-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  ._p-sm-120 {
    padding: 120px !important;
  }
  ._mb-sm-120 {
    margin-bottom: 120px !important;
  }
  ._mx-sm-120 {
    margin-left: 120px !important;
    margin-right: 120px !important;
  }
  ._my-sm-120 {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
  }
  ._m-sm-120 {
    margin: 120px !important;
  }
  ._pb-sm-120 {
    padding-bottom: 120px !important;
  }
  ._px-sm-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  ._py-sm-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  ._p-sm-120 {
    padding: 120px !important;
  }
  ._ml-sm-120 {
    margin-left: 120px !important;
  }
  ._mx-sm-120 {
    margin-left: 120px !important;
    margin-right: 120px !important;
  }
  ._my-sm-120 {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
  }
  ._m-sm-120 {
    margin: 120px !important;
  }
  ._pl-sm-120 {
    padding-left: 120px !important;
  }
  ._px-sm-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  ._py-sm-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  ._p-sm-120 {
    padding: 120px !important;
  }
  ._mr-sm-120 {
    margin-right: 120px !important;
  }
  ._mx-sm-120 {
    margin-left: 120px !important;
    margin-right: 120px !important;
  }
  ._my-sm-120 {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
  }
  ._m-sm-120 {
    margin: 120px !important;
  }
  ._pr-sm-120 {
    padding-right: 120px !important;
  }
  ._px-sm-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  ._py-sm-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  ._p-sm-120 {
    padding: 120px !important;
  }
  ._mt-sm-160 {
    margin-top: 160px !important;
  }
  ._mx-sm-160 {
    margin-left: 160px !important;
    margin-right: 160px !important;
  }
  ._my-sm-160 {
    margin-top: 160px !important;
    margin-bottom: 160px !important;
  }
  ._m-sm-160 {
    margin: 160px !important;
  }
  ._pt-sm-160 {
    padding-top: 160px !important;
  }
  ._px-sm-160 {
    padding-left: 160px !important;
    padding-right: 160px !important;
  }
  ._py-sm-160 {
    padding-top: 160px !important;
    padding-bottom: 160px !important;
  }
  ._p-sm-160 {
    padding: 160px !important;
  }
  ._mb-sm-160 {
    margin-bottom: 160px !important;
  }
  ._mx-sm-160 {
    margin-left: 160px !important;
    margin-right: 160px !important;
  }
  ._my-sm-160 {
    margin-top: 160px !important;
    margin-bottom: 160px !important;
  }
  ._m-sm-160 {
    margin: 160px !important;
  }
  ._pb-sm-160 {
    padding-bottom: 160px !important;
  }
  ._px-sm-160 {
    padding-left: 160px !important;
    padding-right: 160px !important;
  }
  ._py-sm-160 {
    padding-top: 160px !important;
    padding-bottom: 160px !important;
  }
  ._p-sm-160 {
    padding: 160px !important;
  }
  ._ml-sm-160 {
    margin-left: 160px !important;
  }
  ._mx-sm-160 {
    margin-left: 160px !important;
    margin-right: 160px !important;
  }
  ._my-sm-160 {
    margin-top: 160px !important;
    margin-bottom: 160px !important;
  }
  ._m-sm-160 {
    margin: 160px !important;
  }
  ._pl-sm-160 {
    padding-left: 160px !important;
  }
  ._px-sm-160 {
    padding-left: 160px !important;
    padding-right: 160px !important;
  }
  ._py-sm-160 {
    padding-top: 160px !important;
    padding-bottom: 160px !important;
  }
  ._p-sm-160 {
    padding: 160px !important;
  }
  ._mr-sm-160 {
    margin-right: 160px !important;
  }
  ._mx-sm-160 {
    margin-left: 160px !important;
    margin-right: 160px !important;
  }
  ._my-sm-160 {
    margin-top: 160px !important;
    margin-bottom: 160px !important;
  }
  ._m-sm-160 {
    margin: 160px !important;
  }
  ._pr-sm-160 {
    padding-right: 160px !important;
  }
  ._px-sm-160 {
    padding-left: 160px !important;
    padding-right: 160px !important;
  }
  ._py-sm-160 {
    padding-top: 160px !important;
    padding-bottom: 160px !important;
  }
  ._p-sm-160 {
    padding: 160px !important;
  }
}

@media (min-width: 897px) {
  ._mt-md-0 {
    margin-top: 0px !important;
  }
  ._mx-md-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  ._my-md-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  ._m-md-0 {
    margin: 0px !important;
  }
  ._pt-md-0 {
    padding-top: 0px !important;
  }
  ._px-md-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  ._py-md-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  ._p-md-0 {
    padding: 0px !important;
  }
  ._mb-md-0 {
    margin-bottom: 0px !important;
  }
  ._mx-md-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  ._my-md-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  ._m-md-0 {
    margin: 0px !important;
  }
  ._pb-md-0 {
    padding-bottom: 0px !important;
  }
  ._px-md-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  ._py-md-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  ._p-md-0 {
    padding: 0px !important;
  }
  ._ml-md-0 {
    margin-left: 0px !important;
  }
  ._mx-md-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  ._my-md-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  ._m-md-0 {
    margin: 0px !important;
  }
  ._pl-md-0 {
    padding-left: 0px !important;
  }
  ._px-md-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  ._py-md-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  ._p-md-0 {
    padding: 0px !important;
  }
  ._mr-md-0 {
    margin-right: 0px !important;
  }
  ._mx-md-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  ._my-md-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  ._m-md-0 {
    margin: 0px !important;
  }
  ._pr-md-0 {
    padding-right: 0px !important;
  }
  ._px-md-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  ._py-md-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  ._p-md-0 {
    padding: 0px !important;
  }
  ._mt-md-5 {
    margin-top: 5px !important;
  }
  ._mx-md-5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  ._my-md-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  ._m-md-5 {
    margin: 5px !important;
  }
  ._pt-md-5 {
    padding-top: 5px !important;
  }
  ._px-md-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  ._py-md-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  ._p-md-5 {
    padding: 5px !important;
  }
  ._mb-md-5 {
    margin-bottom: 5px !important;
  }
  ._mx-md-5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  ._my-md-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  ._m-md-5 {
    margin: 5px !important;
  }
  ._pb-md-5 {
    padding-bottom: 5px !important;
  }
  ._px-md-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  ._py-md-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  ._p-md-5 {
    padding: 5px !important;
  }
  ._ml-md-5 {
    margin-left: 5px !important;
  }
  ._mx-md-5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  ._my-md-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  ._m-md-5 {
    margin: 5px !important;
  }
  ._pl-md-5 {
    padding-left: 5px !important;
  }
  ._px-md-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  ._py-md-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  ._p-md-5 {
    padding: 5px !important;
  }
  ._mr-md-5 {
    margin-right: 5px !important;
  }
  ._mx-md-5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  ._my-md-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  ._m-md-5 {
    margin: 5px !important;
  }
  ._pr-md-5 {
    padding-right: 5px !important;
  }
  ._px-md-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  ._py-md-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  ._p-md-5 {
    padding: 5px !important;
  }
  ._mt-md-10 {
    margin-top: 10px !important;
  }
  ._mx-md-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  ._my-md-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  ._m-md-10 {
    margin: 10px !important;
  }
  ._pt-md-10 {
    padding-top: 10px !important;
  }
  ._px-md-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  ._py-md-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  ._p-md-10 {
    padding: 10px !important;
  }
  ._mb-md-10 {
    margin-bottom: 10px !important;
  }
  ._mx-md-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  ._my-md-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  ._m-md-10 {
    margin: 10px !important;
  }
  ._pb-md-10 {
    padding-bottom: 10px !important;
  }
  ._px-md-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  ._py-md-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  ._p-md-10 {
    padding: 10px !important;
  }
  ._ml-md-10 {
    margin-left: 10px !important;
  }
  ._mx-md-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  ._my-md-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  ._m-md-10 {
    margin: 10px !important;
  }
  ._pl-md-10 {
    padding-left: 10px !important;
  }
  ._px-md-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  ._py-md-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  ._p-md-10 {
    padding: 10px !important;
  }
  ._mr-md-10 {
    margin-right: 10px !important;
  }
  ._mx-md-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  ._my-md-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  ._m-md-10 {
    margin: 10px !important;
  }
  ._pr-md-10 {
    padding-right: 10px !important;
  }
  ._px-md-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  ._py-md-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  ._p-md-10 {
    padding: 10px !important;
  }
  ._mt-md-15 {
    margin-top: 15px !important;
  }
  ._mx-md-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  ._my-md-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  ._m-md-15 {
    margin: 15px !important;
  }
  ._pt-md-15 {
    padding-top: 15px !important;
  }
  ._px-md-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  ._py-md-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  ._p-md-15 {
    padding: 15px !important;
  }
  ._mb-md-15 {
    margin-bottom: 15px !important;
  }
  ._mx-md-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  ._my-md-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  ._m-md-15 {
    margin: 15px !important;
  }
  ._pb-md-15 {
    padding-bottom: 15px !important;
  }
  ._px-md-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  ._py-md-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  ._p-md-15 {
    padding: 15px !important;
  }
  ._ml-md-15 {
    margin-left: 15px !important;
  }
  ._mx-md-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  ._my-md-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  ._m-md-15 {
    margin: 15px !important;
  }
  ._pl-md-15 {
    padding-left: 15px !important;
  }
  ._px-md-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  ._py-md-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  ._p-md-15 {
    padding: 15px !important;
  }
  ._mr-md-15 {
    margin-right: 15px !important;
  }
  ._mx-md-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  ._my-md-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  ._m-md-15 {
    margin: 15px !important;
  }
  ._pr-md-15 {
    padding-right: 15px !important;
  }
  ._px-md-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  ._py-md-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  ._p-md-15 {
    padding: 15px !important;
  }
  ._mt-md-20 {
    margin-top: 20px !important;
  }
  ._mx-md-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  ._my-md-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  ._m-md-20 {
    margin: 20px !important;
  }
  ._pt-md-20 {
    padding-top: 20px !important;
  }
  ._px-md-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  ._py-md-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  ._p-md-20 {
    padding: 20px !important;
  }
  ._mb-md-20 {
    margin-bottom: 20px !important;
  }
  ._mx-md-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  ._my-md-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  ._m-md-20 {
    margin: 20px !important;
  }
  ._pb-md-20 {
    padding-bottom: 20px !important;
  }
  ._px-md-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  ._py-md-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  ._p-md-20 {
    padding: 20px !important;
  }
  ._ml-md-20 {
    margin-left: 20px !important;
  }
  ._mx-md-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  ._my-md-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  ._m-md-20 {
    margin: 20px !important;
  }
  ._pl-md-20 {
    padding-left: 20px !important;
  }
  ._px-md-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  ._py-md-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  ._p-md-20 {
    padding: 20px !important;
  }
  ._mr-md-20 {
    margin-right: 20px !important;
  }
  ._mx-md-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  ._my-md-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  ._m-md-20 {
    margin: 20px !important;
  }
  ._pr-md-20 {
    padding-right: 20px !important;
  }
  ._px-md-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  ._py-md-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  ._p-md-20 {
    padding: 20px !important;
  }
  ._mt-md-25 {
    margin-top: 25px !important;
  }
  ._mx-md-25 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  ._my-md-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  ._m-md-25 {
    margin: 25px !important;
  }
  ._pt-md-25 {
    padding-top: 25px !important;
  }
  ._px-md-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  ._py-md-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  ._p-md-25 {
    padding: 25px !important;
  }
  ._mb-md-25 {
    margin-bottom: 25px !important;
  }
  ._mx-md-25 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  ._my-md-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  ._m-md-25 {
    margin: 25px !important;
  }
  ._pb-md-25 {
    padding-bottom: 25px !important;
  }
  ._px-md-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  ._py-md-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  ._p-md-25 {
    padding: 25px !important;
  }
  ._ml-md-25 {
    margin-left: 25px !important;
  }
  ._mx-md-25 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  ._my-md-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  ._m-md-25 {
    margin: 25px !important;
  }
  ._pl-md-25 {
    padding-left: 25px !important;
  }
  ._px-md-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  ._py-md-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  ._p-md-25 {
    padding: 25px !important;
  }
  ._mr-md-25 {
    margin-right: 25px !important;
  }
  ._mx-md-25 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  ._my-md-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  ._m-md-25 {
    margin: 25px !important;
  }
  ._pr-md-25 {
    padding-right: 25px !important;
  }
  ._px-md-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  ._py-md-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  ._p-md-25 {
    padding: 25px !important;
  }
  ._mt-md-30 {
    margin-top: 30px !important;
  }
  ._mx-md-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  ._my-md-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  ._m-md-30 {
    margin: 30px !important;
  }
  ._pt-md-30 {
    padding-top: 30px !important;
  }
  ._px-md-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  ._py-md-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  ._p-md-30 {
    padding: 30px !important;
  }
  ._mb-md-30 {
    margin-bottom: 30px !important;
  }
  ._mx-md-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  ._my-md-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  ._m-md-30 {
    margin: 30px !important;
  }
  ._pb-md-30 {
    padding-bottom: 30px !important;
  }
  ._px-md-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  ._py-md-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  ._p-md-30 {
    padding: 30px !important;
  }
  ._ml-md-30 {
    margin-left: 30px !important;
  }
  ._mx-md-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  ._my-md-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  ._m-md-30 {
    margin: 30px !important;
  }
  ._pl-md-30 {
    padding-left: 30px !important;
  }
  ._px-md-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  ._py-md-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  ._p-md-30 {
    padding: 30px !important;
  }
  ._mr-md-30 {
    margin-right: 30px !important;
  }
  ._mx-md-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  ._my-md-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  ._m-md-30 {
    margin: 30px !important;
  }
  ._pr-md-30 {
    padding-right: 30px !important;
  }
  ._px-md-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  ._py-md-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  ._p-md-30 {
    padding: 30px !important;
  }
  ._mt-md-35 {
    margin-top: 35px !important;
  }
  ._mx-md-35 {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  ._my-md-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  ._m-md-35 {
    margin: 35px !important;
  }
  ._pt-md-35 {
    padding-top: 35px !important;
  }
  ._px-md-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  ._py-md-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  ._p-md-35 {
    padding: 35px !important;
  }
  ._mb-md-35 {
    margin-bottom: 35px !important;
  }
  ._mx-md-35 {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  ._my-md-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  ._m-md-35 {
    margin: 35px !important;
  }
  ._pb-md-35 {
    padding-bottom: 35px !important;
  }
  ._px-md-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  ._py-md-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  ._p-md-35 {
    padding: 35px !important;
  }
  ._ml-md-35 {
    margin-left: 35px !important;
  }
  ._mx-md-35 {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  ._my-md-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  ._m-md-35 {
    margin: 35px !important;
  }
  ._pl-md-35 {
    padding-left: 35px !important;
  }
  ._px-md-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  ._py-md-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  ._p-md-35 {
    padding: 35px !important;
  }
  ._mr-md-35 {
    margin-right: 35px !important;
  }
  ._mx-md-35 {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  ._my-md-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  ._m-md-35 {
    margin: 35px !important;
  }
  ._pr-md-35 {
    padding-right: 35px !important;
  }
  ._px-md-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  ._py-md-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  ._p-md-35 {
    padding: 35px !important;
  }
  ._mt-md-40 {
    margin-top: 40px !important;
  }
  ._mx-md-40 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  ._my-md-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  ._m-md-40 {
    margin: 40px !important;
  }
  ._pt-md-40 {
    padding-top: 40px !important;
  }
  ._px-md-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  ._py-md-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  ._p-md-40 {
    padding: 40px !important;
  }
  ._mb-md-40 {
    margin-bottom: 40px !important;
  }
  ._mx-md-40 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  ._my-md-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  ._m-md-40 {
    margin: 40px !important;
  }
  ._pb-md-40 {
    padding-bottom: 40px !important;
  }
  ._px-md-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  ._py-md-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  ._p-md-40 {
    padding: 40px !important;
  }
  ._ml-md-40 {
    margin-left: 40px !important;
  }
  ._mx-md-40 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  ._my-md-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  ._m-md-40 {
    margin: 40px !important;
  }
  ._pl-md-40 {
    padding-left: 40px !important;
  }
  ._px-md-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  ._py-md-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  ._p-md-40 {
    padding: 40px !important;
  }
  ._mr-md-40 {
    margin-right: 40px !important;
  }
  ._mx-md-40 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  ._my-md-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  ._m-md-40 {
    margin: 40px !important;
  }
  ._pr-md-40 {
    padding-right: 40px !important;
  }
  ._px-md-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  ._py-md-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  ._p-md-40 {
    padding: 40px !important;
  }
  ._mt-md-43 {
    margin-top: 43px !important;
  }
  ._mx-md-43 {
    margin-left: 43px !important;
    margin-right: 43px !important;
  }
  ._my-md-43 {
    margin-top: 43px !important;
    margin-bottom: 43px !important;
  }
  ._m-md-43 {
    margin: 43px !important;
  }
  ._pt-md-43 {
    padding-top: 43px !important;
  }
  ._px-md-43 {
    padding-left: 43px !important;
    padding-right: 43px !important;
  }
  ._py-md-43 {
    padding-top: 43px !important;
    padding-bottom: 43px !important;
  }
  ._p-md-43 {
    padding: 43px !important;
  }
  ._mb-md-43 {
    margin-bottom: 43px !important;
  }
  ._mx-md-43 {
    margin-left: 43px !important;
    margin-right: 43px !important;
  }
  ._my-md-43 {
    margin-top: 43px !important;
    margin-bottom: 43px !important;
  }
  ._m-md-43 {
    margin: 43px !important;
  }
  ._pb-md-43 {
    padding-bottom: 43px !important;
  }
  ._px-md-43 {
    padding-left: 43px !important;
    padding-right: 43px !important;
  }
  ._py-md-43 {
    padding-top: 43px !important;
    padding-bottom: 43px !important;
  }
  ._p-md-43 {
    padding: 43px !important;
  }
  ._ml-md-43 {
    margin-left: 43px !important;
  }
  ._mx-md-43 {
    margin-left: 43px !important;
    margin-right: 43px !important;
  }
  ._my-md-43 {
    margin-top: 43px !important;
    margin-bottom: 43px !important;
  }
  ._m-md-43 {
    margin: 43px !important;
  }
  ._pl-md-43 {
    padding-left: 43px !important;
  }
  ._px-md-43 {
    padding-left: 43px !important;
    padding-right: 43px !important;
  }
  ._py-md-43 {
    padding-top: 43px !important;
    padding-bottom: 43px !important;
  }
  ._p-md-43 {
    padding: 43px !important;
  }
  ._mr-md-43 {
    margin-right: 43px !important;
  }
  ._mx-md-43 {
    margin-left: 43px !important;
    margin-right: 43px !important;
  }
  ._my-md-43 {
    margin-top: 43px !important;
    margin-bottom: 43px !important;
  }
  ._m-md-43 {
    margin: 43px !important;
  }
  ._pr-md-43 {
    padding-right: 43px !important;
  }
  ._px-md-43 {
    padding-left: 43px !important;
    padding-right: 43px !important;
  }
  ._py-md-43 {
    padding-top: 43px !important;
    padding-bottom: 43px !important;
  }
  ._p-md-43 {
    padding: 43px !important;
  }
  ._mt-md-45 {
    margin-top: 45px !important;
  }
  ._mx-md-45 {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  ._my-md-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  ._m-md-45 {
    margin: 45px !important;
  }
  ._pt-md-45 {
    padding-top: 45px !important;
  }
  ._px-md-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  ._py-md-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  ._p-md-45 {
    padding: 45px !important;
  }
  ._mb-md-45 {
    margin-bottom: 45px !important;
  }
  ._mx-md-45 {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  ._my-md-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  ._m-md-45 {
    margin: 45px !important;
  }
  ._pb-md-45 {
    padding-bottom: 45px !important;
  }
  ._px-md-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  ._py-md-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  ._p-md-45 {
    padding: 45px !important;
  }
  ._ml-md-45 {
    margin-left: 45px !important;
  }
  ._mx-md-45 {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  ._my-md-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  ._m-md-45 {
    margin: 45px !important;
  }
  ._pl-md-45 {
    padding-left: 45px !important;
  }
  ._px-md-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  ._py-md-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  ._p-md-45 {
    padding: 45px !important;
  }
  ._mr-md-45 {
    margin-right: 45px !important;
  }
  ._mx-md-45 {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  ._my-md-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  ._m-md-45 {
    margin: 45px !important;
  }
  ._pr-md-45 {
    padding-right: 45px !important;
  }
  ._px-md-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  ._py-md-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  ._p-md-45 {
    padding: 45px !important;
  }
  ._mt-md-46 {
    margin-top: 46px !important;
  }
  ._mx-md-46 {
    margin-left: 46px !important;
    margin-right: 46px !important;
  }
  ._my-md-46 {
    margin-top: 46px !important;
    margin-bottom: 46px !important;
  }
  ._m-md-46 {
    margin: 46px !important;
  }
  ._pt-md-46 {
    padding-top: 46px !important;
  }
  ._px-md-46 {
    padding-left: 46px !important;
    padding-right: 46px !important;
  }
  ._py-md-46 {
    padding-top: 46px !important;
    padding-bottom: 46px !important;
  }
  ._p-md-46 {
    padding: 46px !important;
  }
  ._mb-md-46 {
    margin-bottom: 46px !important;
  }
  ._mx-md-46 {
    margin-left: 46px !important;
    margin-right: 46px !important;
  }
  ._my-md-46 {
    margin-top: 46px !important;
    margin-bottom: 46px !important;
  }
  ._m-md-46 {
    margin: 46px !important;
  }
  ._pb-md-46 {
    padding-bottom: 46px !important;
  }
  ._px-md-46 {
    padding-left: 46px !important;
    padding-right: 46px !important;
  }
  ._py-md-46 {
    padding-top: 46px !important;
    padding-bottom: 46px !important;
  }
  ._p-md-46 {
    padding: 46px !important;
  }
  ._ml-md-46 {
    margin-left: 46px !important;
  }
  ._mx-md-46 {
    margin-left: 46px !important;
    margin-right: 46px !important;
  }
  ._my-md-46 {
    margin-top: 46px !important;
    margin-bottom: 46px !important;
  }
  ._m-md-46 {
    margin: 46px !important;
  }
  ._pl-md-46 {
    padding-left: 46px !important;
  }
  ._px-md-46 {
    padding-left: 46px !important;
    padding-right: 46px !important;
  }
  ._py-md-46 {
    padding-top: 46px !important;
    padding-bottom: 46px !important;
  }
  ._p-md-46 {
    padding: 46px !important;
  }
  ._mr-md-46 {
    margin-right: 46px !important;
  }
  ._mx-md-46 {
    margin-left: 46px !important;
    margin-right: 46px !important;
  }
  ._my-md-46 {
    margin-top: 46px !important;
    margin-bottom: 46px !important;
  }
  ._m-md-46 {
    margin: 46px !important;
  }
  ._pr-md-46 {
    padding-right: 46px !important;
  }
  ._px-md-46 {
    padding-left: 46px !important;
    padding-right: 46px !important;
  }
  ._py-md-46 {
    padding-top: 46px !important;
    padding-bottom: 46px !important;
  }
  ._p-md-46 {
    padding: 46px !important;
  }
  ._mt-md-50 {
    margin-top: 50px !important;
  }
  ._mx-md-50 {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  ._my-md-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  ._m-md-50 {
    margin: 50px !important;
  }
  ._pt-md-50 {
    padding-top: 50px !important;
  }
  ._px-md-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  ._py-md-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  ._p-md-50 {
    padding: 50px !important;
  }
  ._mb-md-50 {
    margin-bottom: 50px !important;
  }
  ._mx-md-50 {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  ._my-md-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  ._m-md-50 {
    margin: 50px !important;
  }
  ._pb-md-50 {
    padding-bottom: 50px !important;
  }
  ._px-md-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  ._py-md-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  ._p-md-50 {
    padding: 50px !important;
  }
  ._ml-md-50 {
    margin-left: 50px !important;
  }
  ._mx-md-50 {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  ._my-md-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  ._m-md-50 {
    margin: 50px !important;
  }
  ._pl-md-50 {
    padding-left: 50px !important;
  }
  ._px-md-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  ._py-md-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  ._p-md-50 {
    padding: 50px !important;
  }
  ._mr-md-50 {
    margin-right: 50px !important;
  }
  ._mx-md-50 {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  ._my-md-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  ._m-md-50 {
    margin: 50px !important;
  }
  ._pr-md-50 {
    padding-right: 50px !important;
  }
  ._px-md-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  ._py-md-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  ._p-md-50 {
    padding: 50px !important;
  }
  ._mt-md-55 {
    margin-top: 55px !important;
  }
  ._mx-md-55 {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }
  ._my-md-55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }
  ._m-md-55 {
    margin: 55px !important;
  }
  ._pt-md-55 {
    padding-top: 55px !important;
  }
  ._px-md-55 {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  ._py-md-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  ._p-md-55 {
    padding: 55px !important;
  }
  ._mb-md-55 {
    margin-bottom: 55px !important;
  }
  ._mx-md-55 {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }
  ._my-md-55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }
  ._m-md-55 {
    margin: 55px !important;
  }
  ._pb-md-55 {
    padding-bottom: 55px !important;
  }
  ._px-md-55 {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  ._py-md-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  ._p-md-55 {
    padding: 55px !important;
  }
  ._ml-md-55 {
    margin-left: 55px !important;
  }
  ._mx-md-55 {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }
  ._my-md-55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }
  ._m-md-55 {
    margin: 55px !important;
  }
  ._pl-md-55 {
    padding-left: 55px !important;
  }
  ._px-md-55 {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  ._py-md-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  ._p-md-55 {
    padding: 55px !important;
  }
  ._mr-md-55 {
    margin-right: 55px !important;
  }
  ._mx-md-55 {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }
  ._my-md-55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }
  ._m-md-55 {
    margin: 55px !important;
  }
  ._pr-md-55 {
    padding-right: 55px !important;
  }
  ._px-md-55 {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  ._py-md-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  ._p-md-55 {
    padding: 55px !important;
  }
  ._mt-md-60 {
    margin-top: 60px !important;
  }
  ._mx-md-60 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  ._my-md-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  ._m-md-60 {
    margin: 60px !important;
  }
  ._pt-md-60 {
    padding-top: 60px !important;
  }
  ._px-md-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  ._py-md-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  ._p-md-60 {
    padding: 60px !important;
  }
  ._mb-md-60 {
    margin-bottom: 60px !important;
  }
  ._mx-md-60 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  ._my-md-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  ._m-md-60 {
    margin: 60px !important;
  }
  ._pb-md-60 {
    padding-bottom: 60px !important;
  }
  ._px-md-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  ._py-md-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  ._p-md-60 {
    padding: 60px !important;
  }
  ._ml-md-60 {
    margin-left: 60px !important;
  }
  ._mx-md-60 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  ._my-md-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  ._m-md-60 {
    margin: 60px !important;
  }
  ._pl-md-60 {
    padding-left: 60px !important;
  }
  ._px-md-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  ._py-md-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  ._p-md-60 {
    padding: 60px !important;
  }
  ._mr-md-60 {
    margin-right: 60px !important;
  }
  ._mx-md-60 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  ._my-md-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  ._m-md-60 {
    margin: 60px !important;
  }
  ._pr-md-60 {
    padding-right: 60px !important;
  }
  ._px-md-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  ._py-md-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  ._p-md-60 {
    padding: 60px !important;
  }
  ._mt-md-65 {
    margin-top: 65px !important;
  }
  ._mx-md-65 {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }
  ._my-md-65 {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }
  ._m-md-65 {
    margin: 65px !important;
  }
  ._pt-md-65 {
    padding-top: 65px !important;
  }
  ._px-md-65 {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }
  ._py-md-65 {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  ._p-md-65 {
    padding: 65px !important;
  }
  ._mb-md-65 {
    margin-bottom: 65px !important;
  }
  ._mx-md-65 {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }
  ._my-md-65 {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }
  ._m-md-65 {
    margin: 65px !important;
  }
  ._pb-md-65 {
    padding-bottom: 65px !important;
  }
  ._px-md-65 {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }
  ._py-md-65 {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  ._p-md-65 {
    padding: 65px !important;
  }
  ._ml-md-65 {
    margin-left: 65px !important;
  }
  ._mx-md-65 {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }
  ._my-md-65 {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }
  ._m-md-65 {
    margin: 65px !important;
  }
  ._pl-md-65 {
    padding-left: 65px !important;
  }
  ._px-md-65 {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }
  ._py-md-65 {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  ._p-md-65 {
    padding: 65px !important;
  }
  ._mr-md-65 {
    margin-right: 65px !important;
  }
  ._mx-md-65 {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }
  ._my-md-65 {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }
  ._m-md-65 {
    margin: 65px !important;
  }
  ._pr-md-65 {
    padding-right: 65px !important;
  }
  ._px-md-65 {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }
  ._py-md-65 {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  ._p-md-65 {
    padding: 65px !important;
  }
  ._mt-md-70 {
    margin-top: 70px !important;
  }
  ._mx-md-70 {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }
  ._my-md-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  ._m-md-70 {
    margin: 70px !important;
  }
  ._pt-md-70 {
    padding-top: 70px !important;
  }
  ._px-md-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  ._py-md-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  ._p-md-70 {
    padding: 70px !important;
  }
  ._mb-md-70 {
    margin-bottom: 70px !important;
  }
  ._mx-md-70 {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }
  ._my-md-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  ._m-md-70 {
    margin: 70px !important;
  }
  ._pb-md-70 {
    padding-bottom: 70px !important;
  }
  ._px-md-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  ._py-md-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  ._p-md-70 {
    padding: 70px !important;
  }
  ._ml-md-70 {
    margin-left: 70px !important;
  }
  ._mx-md-70 {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }
  ._my-md-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  ._m-md-70 {
    margin: 70px !important;
  }
  ._pl-md-70 {
    padding-left: 70px !important;
  }
  ._px-md-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  ._py-md-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  ._p-md-70 {
    padding: 70px !important;
  }
  ._mr-md-70 {
    margin-right: 70px !important;
  }
  ._mx-md-70 {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }
  ._my-md-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  ._m-md-70 {
    margin: 70px !important;
  }
  ._pr-md-70 {
    padding-right: 70px !important;
  }
  ._px-md-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  ._py-md-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  ._p-md-70 {
    padding: 70px !important;
  }
  ._mt-md-75 {
    margin-top: 75px !important;
  }
  ._mx-md-75 {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }
  ._my-md-75 {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }
  ._m-md-75 {
    margin: 75px !important;
  }
  ._pt-md-75 {
    padding-top: 75px !important;
  }
  ._px-md-75 {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  ._py-md-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  ._p-md-75 {
    padding: 75px !important;
  }
  ._mb-md-75 {
    margin-bottom: 75px !important;
  }
  ._mx-md-75 {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }
  ._my-md-75 {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }
  ._m-md-75 {
    margin: 75px !important;
  }
  ._pb-md-75 {
    padding-bottom: 75px !important;
  }
  ._px-md-75 {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  ._py-md-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  ._p-md-75 {
    padding: 75px !important;
  }
  ._ml-md-75 {
    margin-left: 75px !important;
  }
  ._mx-md-75 {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }
  ._my-md-75 {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }
  ._m-md-75 {
    margin: 75px !important;
  }
  ._pl-md-75 {
    padding-left: 75px !important;
  }
  ._px-md-75 {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  ._py-md-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  ._p-md-75 {
    padding: 75px !important;
  }
  ._mr-md-75 {
    margin-right: 75px !important;
  }
  ._mx-md-75 {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }
  ._my-md-75 {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }
  ._m-md-75 {
    margin: 75px !important;
  }
  ._pr-md-75 {
    padding-right: 75px !important;
  }
  ._px-md-75 {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  ._py-md-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  ._p-md-75 {
    padding: 75px !important;
  }
  ._mt-md-80 {
    margin-top: 80px !important;
  }
  ._mx-md-80 {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  ._my-md-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  ._m-md-80 {
    margin: 80px !important;
  }
  ._pt-md-80 {
    padding-top: 80px !important;
  }
  ._px-md-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  ._py-md-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  ._p-md-80 {
    padding: 80px !important;
  }
  ._mb-md-80 {
    margin-bottom: 80px !important;
  }
  ._mx-md-80 {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  ._my-md-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  ._m-md-80 {
    margin: 80px !important;
  }
  ._pb-md-80 {
    padding-bottom: 80px !important;
  }
  ._px-md-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  ._py-md-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  ._p-md-80 {
    padding: 80px !important;
  }
  ._ml-md-80 {
    margin-left: 80px !important;
  }
  ._mx-md-80 {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  ._my-md-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  ._m-md-80 {
    margin: 80px !important;
  }
  ._pl-md-80 {
    padding-left: 80px !important;
  }
  ._px-md-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  ._py-md-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  ._p-md-80 {
    padding: 80px !important;
  }
  ._mr-md-80 {
    margin-right: 80px !important;
  }
  ._mx-md-80 {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  ._my-md-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  ._m-md-80 {
    margin: 80px !important;
  }
  ._pr-md-80 {
    padding-right: 80px !important;
  }
  ._px-md-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  ._py-md-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  ._p-md-80 {
    padding: 80px !important;
  }
  ._mt-md-85 {
    margin-top: 85px !important;
  }
  ._mx-md-85 {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }
  ._my-md-85 {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }
  ._m-md-85 {
    margin: 85px !important;
  }
  ._pt-md-85 {
    padding-top: 85px !important;
  }
  ._px-md-85 {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  ._py-md-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  ._p-md-85 {
    padding: 85px !important;
  }
  ._mb-md-85 {
    margin-bottom: 85px !important;
  }
  ._mx-md-85 {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }
  ._my-md-85 {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }
  ._m-md-85 {
    margin: 85px !important;
  }
  ._pb-md-85 {
    padding-bottom: 85px !important;
  }
  ._px-md-85 {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  ._py-md-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  ._p-md-85 {
    padding: 85px !important;
  }
  ._ml-md-85 {
    margin-left: 85px !important;
  }
  ._mx-md-85 {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }
  ._my-md-85 {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }
  ._m-md-85 {
    margin: 85px !important;
  }
  ._pl-md-85 {
    padding-left: 85px !important;
  }
  ._px-md-85 {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  ._py-md-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  ._p-md-85 {
    padding: 85px !important;
  }
  ._mr-md-85 {
    margin-right: 85px !important;
  }
  ._mx-md-85 {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }
  ._my-md-85 {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }
  ._m-md-85 {
    margin: 85px !important;
  }
  ._pr-md-85 {
    padding-right: 85px !important;
  }
  ._px-md-85 {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  ._py-md-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  ._p-md-85 {
    padding: 85px !important;
  }
  ._mt-md-90 {
    margin-top: 90px !important;
  }
  ._mx-md-90 {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }
  ._my-md-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  ._m-md-90 {
    margin: 90px !important;
  }
  ._pt-md-90 {
    padding-top: 90px !important;
  }
  ._px-md-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  ._py-md-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  ._p-md-90 {
    padding: 90px !important;
  }
  ._mb-md-90 {
    margin-bottom: 90px !important;
  }
  ._mx-md-90 {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }
  ._my-md-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  ._m-md-90 {
    margin: 90px !important;
  }
  ._pb-md-90 {
    padding-bottom: 90px !important;
  }
  ._px-md-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  ._py-md-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  ._p-md-90 {
    padding: 90px !important;
  }
  ._ml-md-90 {
    margin-left: 90px !important;
  }
  ._mx-md-90 {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }
  ._my-md-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  ._m-md-90 {
    margin: 90px !important;
  }
  ._pl-md-90 {
    padding-left: 90px !important;
  }
  ._px-md-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  ._py-md-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  ._p-md-90 {
    padding: 90px !important;
  }
  ._mr-md-90 {
    margin-right: 90px !important;
  }
  ._mx-md-90 {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }
  ._my-md-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  ._m-md-90 {
    margin: 90px !important;
  }
  ._pr-md-90 {
    padding-right: 90px !important;
  }
  ._px-md-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  ._py-md-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  ._p-md-90 {
    padding: 90px !important;
  }
  ._mt-md-95 {
    margin-top: 95px !important;
  }
  ._mx-md-95 {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }
  ._my-md-95 {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }
  ._m-md-95 {
    margin: 95px !important;
  }
  ._pt-md-95 {
    padding-top: 95px !important;
  }
  ._px-md-95 {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  ._py-md-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  ._p-md-95 {
    padding: 95px !important;
  }
  ._mb-md-95 {
    margin-bottom: 95px !important;
  }
  ._mx-md-95 {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }
  ._my-md-95 {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }
  ._m-md-95 {
    margin: 95px !important;
  }
  ._pb-md-95 {
    padding-bottom: 95px !important;
  }
  ._px-md-95 {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  ._py-md-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  ._p-md-95 {
    padding: 95px !important;
  }
  ._ml-md-95 {
    margin-left: 95px !important;
  }
  ._mx-md-95 {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }
  ._my-md-95 {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }
  ._m-md-95 {
    margin: 95px !important;
  }
  ._pl-md-95 {
    padding-left: 95px !important;
  }
  ._px-md-95 {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  ._py-md-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  ._p-md-95 {
    padding: 95px !important;
  }
  ._mr-md-95 {
    margin-right: 95px !important;
  }
  ._mx-md-95 {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }
  ._my-md-95 {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }
  ._m-md-95 {
    margin: 95px !important;
  }
  ._pr-md-95 {
    padding-right: 95px !important;
  }
  ._px-md-95 {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  ._py-md-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  ._p-md-95 {
    padding: 95px !important;
  }
  ._mt-md-100 {
    margin-top: 100px !important;
  }
  ._mx-md-100 {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }
  ._my-md-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  ._m-md-100 {
    margin: 100px !important;
  }
  ._pt-md-100 {
    padding-top: 100px !important;
  }
  ._px-md-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  ._py-md-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  ._p-md-100 {
    padding: 100px !important;
  }
  ._mb-md-100 {
    margin-bottom: 100px !important;
  }
  ._mx-md-100 {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }
  ._my-md-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  ._m-md-100 {
    margin: 100px !important;
  }
  ._pb-md-100 {
    padding-bottom: 100px !important;
  }
  ._px-md-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  ._py-md-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  ._p-md-100 {
    padding: 100px !important;
  }
  ._ml-md-100 {
    margin-left: 100px !important;
  }
  ._mx-md-100 {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }
  ._my-md-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  ._m-md-100 {
    margin: 100px !important;
  }
  ._pl-md-100 {
    padding-left: 100px !important;
  }
  ._px-md-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  ._py-md-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  ._p-md-100 {
    padding: 100px !important;
  }
  ._mr-md-100 {
    margin-right: 100px !important;
  }
  ._mx-md-100 {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }
  ._my-md-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  ._m-md-100 {
    margin: 100px !important;
  }
  ._pr-md-100 {
    padding-right: 100px !important;
  }
  ._px-md-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  ._py-md-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  ._p-md-100 {
    padding: 100px !important;
  }
  ._mt-md-105 {
    margin-top: 105px !important;
  }
  ._mx-md-105 {
    margin-left: 105px !important;
    margin-right: 105px !important;
  }
  ._my-md-105 {
    margin-top: 105px !important;
    margin-bottom: 105px !important;
  }
  ._m-md-105 {
    margin: 105px !important;
  }
  ._pt-md-105 {
    padding-top: 105px !important;
  }
  ._px-md-105 {
    padding-left: 105px !important;
    padding-right: 105px !important;
  }
  ._py-md-105 {
    padding-top: 105px !important;
    padding-bottom: 105px !important;
  }
  ._p-md-105 {
    padding: 105px !important;
  }
  ._mb-md-105 {
    margin-bottom: 105px !important;
  }
  ._mx-md-105 {
    margin-left: 105px !important;
    margin-right: 105px !important;
  }
  ._my-md-105 {
    margin-top: 105px !important;
    margin-bottom: 105px !important;
  }
  ._m-md-105 {
    margin: 105px !important;
  }
  ._pb-md-105 {
    padding-bottom: 105px !important;
  }
  ._px-md-105 {
    padding-left: 105px !important;
    padding-right: 105px !important;
  }
  ._py-md-105 {
    padding-top: 105px !important;
    padding-bottom: 105px !important;
  }
  ._p-md-105 {
    padding: 105px !important;
  }
  ._ml-md-105 {
    margin-left: 105px !important;
  }
  ._mx-md-105 {
    margin-left: 105px !important;
    margin-right: 105px !important;
  }
  ._my-md-105 {
    margin-top: 105px !important;
    margin-bottom: 105px !important;
  }
  ._m-md-105 {
    margin: 105px !important;
  }
  ._pl-md-105 {
    padding-left: 105px !important;
  }
  ._px-md-105 {
    padding-left: 105px !important;
    padding-right: 105px !important;
  }
  ._py-md-105 {
    padding-top: 105px !important;
    padding-bottom: 105px !important;
  }
  ._p-md-105 {
    padding: 105px !important;
  }
  ._mr-md-105 {
    margin-right: 105px !important;
  }
  ._mx-md-105 {
    margin-left: 105px !important;
    margin-right: 105px !important;
  }
  ._my-md-105 {
    margin-top: 105px !important;
    margin-bottom: 105px !important;
  }
  ._m-md-105 {
    margin: 105px !important;
  }
  ._pr-md-105 {
    padding-right: 105px !important;
  }
  ._px-md-105 {
    padding-left: 105px !important;
    padding-right: 105px !important;
  }
  ._py-md-105 {
    padding-top: 105px !important;
    padding-bottom: 105px !important;
  }
  ._p-md-105 {
    padding: 105px !important;
  }
  ._mt-md-110 {
    margin-top: 110px !important;
  }
  ._mx-md-110 {
    margin-left: 110px !important;
    margin-right: 110px !important;
  }
  ._my-md-110 {
    margin-top: 110px !important;
    margin-bottom: 110px !important;
  }
  ._m-md-110 {
    margin: 110px !important;
  }
  ._pt-md-110 {
    padding-top: 110px !important;
  }
  ._px-md-110 {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }
  ._py-md-110 {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }
  ._p-md-110 {
    padding: 110px !important;
  }
  ._mb-md-110 {
    margin-bottom: 110px !important;
  }
  ._mx-md-110 {
    margin-left: 110px !important;
    margin-right: 110px !important;
  }
  ._my-md-110 {
    margin-top: 110px !important;
    margin-bottom: 110px !important;
  }
  ._m-md-110 {
    margin: 110px !important;
  }
  ._pb-md-110 {
    padding-bottom: 110px !important;
  }
  ._px-md-110 {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }
  ._py-md-110 {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }
  ._p-md-110 {
    padding: 110px !important;
  }
  ._ml-md-110 {
    margin-left: 110px !important;
  }
  ._mx-md-110 {
    margin-left: 110px !important;
    margin-right: 110px !important;
  }
  ._my-md-110 {
    margin-top: 110px !important;
    margin-bottom: 110px !important;
  }
  ._m-md-110 {
    margin: 110px !important;
  }
  ._pl-md-110 {
    padding-left: 110px !important;
  }
  ._px-md-110 {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }
  ._py-md-110 {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }
  ._p-md-110 {
    padding: 110px !important;
  }
  ._mr-md-110 {
    margin-right: 110px !important;
  }
  ._mx-md-110 {
    margin-left: 110px !important;
    margin-right: 110px !important;
  }
  ._my-md-110 {
    margin-top: 110px !important;
    margin-bottom: 110px !important;
  }
  ._m-md-110 {
    margin: 110px !important;
  }
  ._pr-md-110 {
    padding-right: 110px !important;
  }
  ._px-md-110 {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }
  ._py-md-110 {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }
  ._p-md-110 {
    padding: 110px !important;
  }
  ._mt-md-115 {
    margin-top: 115px !important;
  }
  ._mx-md-115 {
    margin-left: 115px !important;
    margin-right: 115px !important;
  }
  ._my-md-115 {
    margin-top: 115px !important;
    margin-bottom: 115px !important;
  }
  ._m-md-115 {
    margin: 115px !important;
  }
  ._pt-md-115 {
    padding-top: 115px !important;
  }
  ._px-md-115 {
    padding-left: 115px !important;
    padding-right: 115px !important;
  }
  ._py-md-115 {
    padding-top: 115px !important;
    padding-bottom: 115px !important;
  }
  ._p-md-115 {
    padding: 115px !important;
  }
  ._mb-md-115 {
    margin-bottom: 115px !important;
  }
  ._mx-md-115 {
    margin-left: 115px !important;
    margin-right: 115px !important;
  }
  ._my-md-115 {
    margin-top: 115px !important;
    margin-bottom: 115px !important;
  }
  ._m-md-115 {
    margin: 115px !important;
  }
  ._pb-md-115 {
    padding-bottom: 115px !important;
  }
  ._px-md-115 {
    padding-left: 115px !important;
    padding-right: 115px !important;
  }
  ._py-md-115 {
    padding-top: 115px !important;
    padding-bottom: 115px !important;
  }
  ._p-md-115 {
    padding: 115px !important;
  }
  ._ml-md-115 {
    margin-left: 115px !important;
  }
  ._mx-md-115 {
    margin-left: 115px !important;
    margin-right: 115px !important;
  }
  ._my-md-115 {
    margin-top: 115px !important;
    margin-bottom: 115px !important;
  }
  ._m-md-115 {
    margin: 115px !important;
  }
  ._pl-md-115 {
    padding-left: 115px !important;
  }
  ._px-md-115 {
    padding-left: 115px !important;
    padding-right: 115px !important;
  }
  ._py-md-115 {
    padding-top: 115px !important;
    padding-bottom: 115px !important;
  }
  ._p-md-115 {
    padding: 115px !important;
  }
  ._mr-md-115 {
    margin-right: 115px !important;
  }
  ._mx-md-115 {
    margin-left: 115px !important;
    margin-right: 115px !important;
  }
  ._my-md-115 {
    margin-top: 115px !important;
    margin-bottom: 115px !important;
  }
  ._m-md-115 {
    margin: 115px !important;
  }
  ._pr-md-115 {
    padding-right: 115px !important;
  }
  ._px-md-115 {
    padding-left: 115px !important;
    padding-right: 115px !important;
  }
  ._py-md-115 {
    padding-top: 115px !important;
    padding-bottom: 115px !important;
  }
  ._p-md-115 {
    padding: 115px !important;
  }
  ._mt-md-120 {
    margin-top: 120px !important;
  }
  ._mx-md-120 {
    margin-left: 120px !important;
    margin-right: 120px !important;
  }
  ._my-md-120 {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
  }
  ._m-md-120 {
    margin: 120px !important;
  }
  ._pt-md-120 {
    padding-top: 120px !important;
  }
  ._px-md-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  ._py-md-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  ._p-md-120 {
    padding: 120px !important;
  }
  ._mb-md-120 {
    margin-bottom: 120px !important;
  }
  ._mx-md-120 {
    margin-left: 120px !important;
    margin-right: 120px !important;
  }
  ._my-md-120 {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
  }
  ._m-md-120 {
    margin: 120px !important;
  }
  ._pb-md-120 {
    padding-bottom: 120px !important;
  }
  ._px-md-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  ._py-md-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  ._p-md-120 {
    padding: 120px !important;
  }
  ._ml-md-120 {
    margin-left: 120px !important;
  }
  ._mx-md-120 {
    margin-left: 120px !important;
    margin-right: 120px !important;
  }
  ._my-md-120 {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
  }
  ._m-md-120 {
    margin: 120px !important;
  }
  ._pl-md-120 {
    padding-left: 120px !important;
  }
  ._px-md-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  ._py-md-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  ._p-md-120 {
    padding: 120px !important;
  }
  ._mr-md-120 {
    margin-right: 120px !important;
  }
  ._mx-md-120 {
    margin-left: 120px !important;
    margin-right: 120px !important;
  }
  ._my-md-120 {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
  }
  ._m-md-120 {
    margin: 120px !important;
  }
  ._pr-md-120 {
    padding-right: 120px !important;
  }
  ._px-md-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  ._py-md-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  ._p-md-120 {
    padding: 120px !important;
  }
  ._mt-md-160 {
    margin-top: 160px !important;
  }
  ._mx-md-160 {
    margin-left: 160px !important;
    margin-right: 160px !important;
  }
  ._my-md-160 {
    margin-top: 160px !important;
    margin-bottom: 160px !important;
  }
  ._m-md-160 {
    margin: 160px !important;
  }
  ._pt-md-160 {
    padding-top: 160px !important;
  }
  ._px-md-160 {
    padding-left: 160px !important;
    padding-right: 160px !important;
  }
  ._py-md-160 {
    padding-top: 160px !important;
    padding-bottom: 160px !important;
  }
  ._p-md-160 {
    padding: 160px !important;
  }
  ._mb-md-160 {
    margin-bottom: 160px !important;
  }
  ._mx-md-160 {
    margin-left: 160px !important;
    margin-right: 160px !important;
  }
  ._my-md-160 {
    margin-top: 160px !important;
    margin-bottom: 160px !important;
  }
  ._m-md-160 {
    margin: 160px !important;
  }
  ._pb-md-160 {
    padding-bottom: 160px !important;
  }
  ._px-md-160 {
    padding-left: 160px !important;
    padding-right: 160px !important;
  }
  ._py-md-160 {
    padding-top: 160px !important;
    padding-bottom: 160px !important;
  }
  ._p-md-160 {
    padding: 160px !important;
  }
  ._ml-md-160 {
    margin-left: 160px !important;
  }
  ._mx-md-160 {
    margin-left: 160px !important;
    margin-right: 160px !important;
  }
  ._my-md-160 {
    margin-top: 160px !important;
    margin-bottom: 160px !important;
  }
  ._m-md-160 {
    margin: 160px !important;
  }
  ._pl-md-160 {
    padding-left: 160px !important;
  }
  ._px-md-160 {
    padding-left: 160px !important;
    padding-right: 160px !important;
  }
  ._py-md-160 {
    padding-top: 160px !important;
    padding-bottom: 160px !important;
  }
  ._p-md-160 {
    padding: 160px !important;
  }
  ._mr-md-160 {
    margin-right: 160px !important;
  }
  ._mx-md-160 {
    margin-left: 160px !important;
    margin-right: 160px !important;
  }
  ._my-md-160 {
    margin-top: 160px !important;
    margin-bottom: 160px !important;
  }
  ._m-md-160 {
    margin: 160px !important;
  }
  ._pr-md-160 {
    padding-right: 160px !important;
  }
  ._px-md-160 {
    padding-left: 160px !important;
    padding-right: 160px !important;
  }
  ._py-md-160 {
    padding-top: 160px !important;
    padding-bottom: 160px !important;
  }
  ._p-md-160 {
    padding: 160px !important;
  }
}

@media (min-width: 1601px) {
  ._mt-lg-0 {
    margin-top: 0px !important;
  }
  ._m-lg-0 {
    margin: 0px !important;
  }
  ._mx-lg-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  ._my-lg-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  ._pt-lg-0 {
    padding-top: 0px !important;
  }
  ._px-lg-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  ._py-lg-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  ._p-lg-0 {
    padding: 0px !important;
  }
  ._mb-lg-0 {
    margin-bottom: 0px !important;
  }
  ._m-lg-0 {
    margin: 0px !important;
  }
  ._mx-lg-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  ._my-lg-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  ._pb-lg-0 {
    padding-bottom: 0px !important;
  }
  ._px-lg-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  ._py-lg-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  ._p-lg-0 {
    padding: 0px !important;
  }
  ._ml-lg-0 {
    margin-left: 0px !important;
  }
  ._m-lg-0 {
    margin: 0px !important;
  }
  ._mx-lg-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  ._my-lg-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  ._pl-lg-0 {
    padding-left: 0px !important;
  }
  ._px-lg-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  ._py-lg-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  ._p-lg-0 {
    padding: 0px !important;
  }
  ._mr-lg-0 {
    margin-right: 0px !important;
  }
  ._m-lg-0 {
    margin: 0px !important;
  }
  ._mx-lg-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  ._my-lg-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  ._pr-lg-0 {
    padding-right: 0px !important;
  }
  ._px-lg-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  ._py-lg-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  ._p-lg-0 {
    padding: 0px !important;
  }
  ._mt-lg-5 {
    margin-top: 5px !important;
  }
  ._m-lg-5 {
    margin: 5px !important;
  }
  ._mx-lg-5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  ._my-lg-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  ._pt-lg-5 {
    padding-top: 5px !important;
  }
  ._px-lg-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  ._py-lg-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  ._p-lg-5 {
    padding: 5px !important;
  }
  ._mb-lg-5 {
    margin-bottom: 5px !important;
  }
  ._m-lg-5 {
    margin: 5px !important;
  }
  ._mx-lg-5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  ._my-lg-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  ._pb-lg-5 {
    padding-bottom: 5px !important;
  }
  ._px-lg-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  ._py-lg-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  ._p-lg-5 {
    padding: 5px !important;
  }
  ._ml-lg-5 {
    margin-left: 5px !important;
  }
  ._m-lg-5 {
    margin: 5px !important;
  }
  ._mx-lg-5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  ._my-lg-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  ._pl-lg-5 {
    padding-left: 5px !important;
  }
  ._px-lg-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  ._py-lg-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  ._p-lg-5 {
    padding: 5px !important;
  }
  ._mr-lg-5 {
    margin-right: 5px !important;
  }
  ._m-lg-5 {
    margin: 5px !important;
  }
  ._mx-lg-5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  ._my-lg-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  ._pr-lg-5 {
    padding-right: 5px !important;
  }
  ._px-lg-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  ._py-lg-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  ._p-lg-5 {
    padding: 5px !important;
  }
  ._mt-lg-10 {
    margin-top: 10px !important;
  }
  ._m-lg-10 {
    margin: 10px !important;
  }
  ._mx-lg-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  ._my-lg-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  ._pt-lg-10 {
    padding-top: 10px !important;
  }
  ._px-lg-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  ._py-lg-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  ._p-lg-10 {
    padding: 10px !important;
  }
  ._mb-lg-10 {
    margin-bottom: 10px !important;
  }
  ._m-lg-10 {
    margin: 10px !important;
  }
  ._mx-lg-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  ._my-lg-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  ._pb-lg-10 {
    padding-bottom: 10px !important;
  }
  ._px-lg-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  ._py-lg-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  ._p-lg-10 {
    padding: 10px !important;
  }
  ._ml-lg-10 {
    margin-left: 10px !important;
  }
  ._m-lg-10 {
    margin: 10px !important;
  }
  ._mx-lg-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  ._my-lg-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  ._pl-lg-10 {
    padding-left: 10px !important;
  }
  ._px-lg-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  ._py-lg-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  ._p-lg-10 {
    padding: 10px !important;
  }
  ._mr-lg-10 {
    margin-right: 10px !important;
  }
  ._m-lg-10 {
    margin: 10px !important;
  }
  ._mx-lg-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  ._my-lg-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  ._pr-lg-10 {
    padding-right: 10px !important;
  }
  ._px-lg-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  ._py-lg-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  ._p-lg-10 {
    padding: 10px !important;
  }
  ._mt-lg-15 {
    margin-top: 15px !important;
  }
  ._m-lg-15 {
    margin: 15px !important;
  }
  ._mx-lg-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  ._my-lg-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  ._pt-lg-15 {
    padding-top: 15px !important;
  }
  ._px-lg-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  ._py-lg-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  ._p-lg-15 {
    padding: 15px !important;
  }
  ._mb-lg-15 {
    margin-bottom: 15px !important;
  }
  ._m-lg-15 {
    margin: 15px !important;
  }
  ._mx-lg-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  ._my-lg-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  ._pb-lg-15 {
    padding-bottom: 15px !important;
  }
  ._px-lg-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  ._py-lg-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  ._p-lg-15 {
    padding: 15px !important;
  }
  ._ml-lg-15 {
    margin-left: 15px !important;
  }
  ._m-lg-15 {
    margin: 15px !important;
  }
  ._mx-lg-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  ._my-lg-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  ._pl-lg-15 {
    padding-left: 15px !important;
  }
  ._px-lg-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  ._py-lg-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  ._p-lg-15 {
    padding: 15px !important;
  }
  ._mr-lg-15 {
    margin-right: 15px !important;
  }
  ._m-lg-15 {
    margin: 15px !important;
  }
  ._mx-lg-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  ._my-lg-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  ._pr-lg-15 {
    padding-right: 15px !important;
  }
  ._px-lg-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  ._py-lg-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  ._p-lg-15 {
    padding: 15px !important;
  }
  ._mt-lg-20 {
    margin-top: 20px !important;
  }
  ._m-lg-20 {
    margin: 20px !important;
  }
  ._mx-lg-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  ._my-lg-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  ._pt-lg-20 {
    padding-top: 20px !important;
  }
  ._px-lg-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  ._py-lg-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  ._p-lg-20 {
    padding: 20px !important;
  }
  ._mb-lg-20 {
    margin-bottom: 20px !important;
  }
  ._m-lg-20 {
    margin: 20px !important;
  }
  ._mx-lg-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  ._my-lg-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  ._pb-lg-20 {
    padding-bottom: 20px !important;
  }
  ._px-lg-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  ._py-lg-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  ._p-lg-20 {
    padding: 20px !important;
  }
  ._ml-lg-20 {
    margin-left: 20px !important;
  }
  ._m-lg-20 {
    margin: 20px !important;
  }
  ._mx-lg-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  ._my-lg-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  ._pl-lg-20 {
    padding-left: 20px !important;
  }
  ._px-lg-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  ._py-lg-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  ._p-lg-20 {
    padding: 20px !important;
  }
  ._mr-lg-20 {
    margin-right: 20px !important;
  }
  ._m-lg-20 {
    margin: 20px !important;
  }
  ._mx-lg-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  ._my-lg-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  ._pr-lg-20 {
    padding-right: 20px !important;
  }
  ._px-lg-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  ._py-lg-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  ._p-lg-20 {
    padding: 20px !important;
  }
  ._mt-lg-25 {
    margin-top: 25px !important;
  }
  ._m-lg-25 {
    margin: 25px !important;
  }
  ._mx-lg-25 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  ._my-lg-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  ._pt-lg-25 {
    padding-top: 25px !important;
  }
  ._px-lg-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  ._py-lg-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  ._p-lg-25 {
    padding: 25px !important;
  }
  ._mb-lg-25 {
    margin-bottom: 25px !important;
  }
  ._m-lg-25 {
    margin: 25px !important;
  }
  ._mx-lg-25 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  ._my-lg-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  ._pb-lg-25 {
    padding-bottom: 25px !important;
  }
  ._px-lg-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  ._py-lg-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  ._p-lg-25 {
    padding: 25px !important;
  }
  ._ml-lg-25 {
    margin-left: 25px !important;
  }
  ._m-lg-25 {
    margin: 25px !important;
  }
  ._mx-lg-25 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  ._my-lg-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  ._pl-lg-25 {
    padding-left: 25px !important;
  }
  ._px-lg-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  ._py-lg-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  ._p-lg-25 {
    padding: 25px !important;
  }
  ._mr-lg-25 {
    margin-right: 25px !important;
  }
  ._m-lg-25 {
    margin: 25px !important;
  }
  ._mx-lg-25 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  ._my-lg-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  ._pr-lg-25 {
    padding-right: 25px !important;
  }
  ._px-lg-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  ._py-lg-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  ._p-lg-25 {
    padding: 25px !important;
  }
  ._mt-lg-30 {
    margin-top: 30px !important;
  }
  ._m-lg-30 {
    margin: 30px !important;
  }
  ._mx-lg-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  ._my-lg-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  ._pt-lg-30 {
    padding-top: 30px !important;
  }
  ._px-lg-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  ._py-lg-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  ._p-lg-30 {
    padding: 30px !important;
  }
  ._mb-lg-30 {
    margin-bottom: 30px !important;
  }
  ._m-lg-30 {
    margin: 30px !important;
  }
  ._mx-lg-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  ._my-lg-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  ._pb-lg-30 {
    padding-bottom: 30px !important;
  }
  ._px-lg-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  ._py-lg-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  ._p-lg-30 {
    padding: 30px !important;
  }
  ._ml-lg-30 {
    margin-left: 30px !important;
  }
  ._m-lg-30 {
    margin: 30px !important;
  }
  ._mx-lg-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  ._my-lg-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  ._pl-lg-30 {
    padding-left: 30px !important;
  }
  ._px-lg-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  ._py-lg-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  ._p-lg-30 {
    padding: 30px !important;
  }
  ._mr-lg-30 {
    margin-right: 30px !important;
  }
  ._m-lg-30 {
    margin: 30px !important;
  }
  ._mx-lg-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  ._my-lg-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  ._pr-lg-30 {
    padding-right: 30px !important;
  }
  ._px-lg-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  ._py-lg-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  ._p-lg-30 {
    padding: 30px !important;
  }
  ._mt-lg-35 {
    margin-top: 35px !important;
  }
  ._m-lg-35 {
    margin: 35px !important;
  }
  ._mx-lg-35 {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  ._my-lg-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  ._pt-lg-35 {
    padding-top: 35px !important;
  }
  ._px-lg-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  ._py-lg-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  ._p-lg-35 {
    padding: 35px !important;
  }
  ._mb-lg-35 {
    margin-bottom: 35px !important;
  }
  ._m-lg-35 {
    margin: 35px !important;
  }
  ._mx-lg-35 {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  ._my-lg-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  ._pb-lg-35 {
    padding-bottom: 35px !important;
  }
  ._px-lg-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  ._py-lg-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  ._p-lg-35 {
    padding: 35px !important;
  }
  ._ml-lg-35 {
    margin-left: 35px !important;
  }
  ._m-lg-35 {
    margin: 35px !important;
  }
  ._mx-lg-35 {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  ._my-lg-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  ._pl-lg-35 {
    padding-left: 35px !important;
  }
  ._px-lg-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  ._py-lg-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  ._p-lg-35 {
    padding: 35px !important;
  }
  ._mr-lg-35 {
    margin-right: 35px !important;
  }
  ._m-lg-35 {
    margin: 35px !important;
  }
  ._mx-lg-35 {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  ._my-lg-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  ._pr-lg-35 {
    padding-right: 35px !important;
  }
  ._px-lg-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  ._py-lg-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  ._p-lg-35 {
    padding: 35px !important;
  }
  ._mt-lg-40 {
    margin-top: 40px !important;
  }
  ._m-lg-40 {
    margin: 40px !important;
  }
  ._mx-lg-40 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  ._my-lg-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  ._pt-lg-40 {
    padding-top: 40px !important;
  }
  ._px-lg-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  ._py-lg-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  ._p-lg-40 {
    padding: 40px !important;
  }
  ._mb-lg-40 {
    margin-bottom: 40px !important;
  }
  ._m-lg-40 {
    margin: 40px !important;
  }
  ._mx-lg-40 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  ._my-lg-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  ._pb-lg-40 {
    padding-bottom: 40px !important;
  }
  ._px-lg-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  ._py-lg-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  ._p-lg-40 {
    padding: 40px !important;
  }
  ._ml-lg-40 {
    margin-left: 40px !important;
  }
  ._m-lg-40 {
    margin: 40px !important;
  }
  ._mx-lg-40 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  ._my-lg-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  ._pl-lg-40 {
    padding-left: 40px !important;
  }
  ._px-lg-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  ._py-lg-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  ._p-lg-40 {
    padding: 40px !important;
  }
  ._mr-lg-40 {
    margin-right: 40px !important;
  }
  ._m-lg-40 {
    margin: 40px !important;
  }
  ._mx-lg-40 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  ._my-lg-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  ._pr-lg-40 {
    padding-right: 40px !important;
  }
  ._px-lg-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  ._py-lg-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  ._p-lg-40 {
    padding: 40px !important;
  }
  ._mt-lg-43 {
    margin-top: 43px !important;
  }
  ._m-lg-43 {
    margin: 43px !important;
  }
  ._mx-lg-43 {
    margin-left: 43px !important;
    margin-right: 43px !important;
  }
  ._my-lg-43 {
    margin-top: 43px !important;
    margin-bottom: 43px !important;
  }
  ._pt-lg-43 {
    padding-top: 43px !important;
  }
  ._px-lg-43 {
    padding-left: 43px !important;
    padding-right: 43px !important;
  }
  ._py-lg-43 {
    padding-top: 43px !important;
    padding-bottom: 43px !important;
  }
  ._p-lg-43 {
    padding: 43px !important;
  }
  ._mb-lg-43 {
    margin-bottom: 43px !important;
  }
  ._m-lg-43 {
    margin: 43px !important;
  }
  ._mx-lg-43 {
    margin-left: 43px !important;
    margin-right: 43px !important;
  }
  ._my-lg-43 {
    margin-top: 43px !important;
    margin-bottom: 43px !important;
  }
  ._pb-lg-43 {
    padding-bottom: 43px !important;
  }
  ._px-lg-43 {
    padding-left: 43px !important;
    padding-right: 43px !important;
  }
  ._py-lg-43 {
    padding-top: 43px !important;
    padding-bottom: 43px !important;
  }
  ._p-lg-43 {
    padding: 43px !important;
  }
  ._ml-lg-43 {
    margin-left: 43px !important;
  }
  ._m-lg-43 {
    margin: 43px !important;
  }
  ._mx-lg-43 {
    margin-left: 43px !important;
    margin-right: 43px !important;
  }
  ._my-lg-43 {
    margin-top: 43px !important;
    margin-bottom: 43px !important;
  }
  ._pl-lg-43 {
    padding-left: 43px !important;
  }
  ._px-lg-43 {
    padding-left: 43px !important;
    padding-right: 43px !important;
  }
  ._py-lg-43 {
    padding-top: 43px !important;
    padding-bottom: 43px !important;
  }
  ._p-lg-43 {
    padding: 43px !important;
  }
  ._mr-lg-43 {
    margin-right: 43px !important;
  }
  ._m-lg-43 {
    margin: 43px !important;
  }
  ._mx-lg-43 {
    margin-left: 43px !important;
    margin-right: 43px !important;
  }
  ._my-lg-43 {
    margin-top: 43px !important;
    margin-bottom: 43px !important;
  }
  ._pr-lg-43 {
    padding-right: 43px !important;
  }
  ._px-lg-43 {
    padding-left: 43px !important;
    padding-right: 43px !important;
  }
  ._py-lg-43 {
    padding-top: 43px !important;
    padding-bottom: 43px !important;
  }
  ._p-lg-43 {
    padding: 43px !important;
  }
  ._mt-lg-45 {
    margin-top: 45px !important;
  }
  ._m-lg-45 {
    margin: 45px !important;
  }
  ._mx-lg-45 {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  ._my-lg-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  ._pt-lg-45 {
    padding-top: 45px !important;
  }
  ._px-lg-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  ._py-lg-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  ._p-lg-45 {
    padding: 45px !important;
  }
  ._mb-lg-45 {
    margin-bottom: 45px !important;
  }
  ._m-lg-45 {
    margin: 45px !important;
  }
  ._mx-lg-45 {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  ._my-lg-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  ._pb-lg-45 {
    padding-bottom: 45px !important;
  }
  ._px-lg-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  ._py-lg-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  ._p-lg-45 {
    padding: 45px !important;
  }
  ._ml-lg-45 {
    margin-left: 45px !important;
  }
  ._m-lg-45 {
    margin: 45px !important;
  }
  ._mx-lg-45 {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  ._my-lg-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  ._pl-lg-45 {
    padding-left: 45px !important;
  }
  ._px-lg-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  ._py-lg-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  ._p-lg-45 {
    padding: 45px !important;
  }
  ._mr-lg-45 {
    margin-right: 45px !important;
  }
  ._m-lg-45 {
    margin: 45px !important;
  }
  ._mx-lg-45 {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  ._my-lg-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  ._pr-lg-45 {
    padding-right: 45px !important;
  }
  ._px-lg-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  ._py-lg-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  ._p-lg-45 {
    padding: 45px !important;
  }
  ._mt-lg-46 {
    margin-top: 46px !important;
  }
  ._m-lg-46 {
    margin: 46px !important;
  }
  ._mx-lg-46 {
    margin-left: 46px !important;
    margin-right: 46px !important;
  }
  ._my-lg-46 {
    margin-top: 46px !important;
    margin-bottom: 46px !important;
  }
  ._pt-lg-46 {
    padding-top: 46px !important;
  }
  ._px-lg-46 {
    padding-left: 46px !important;
    padding-right: 46px !important;
  }
  ._py-lg-46 {
    padding-top: 46px !important;
    padding-bottom: 46px !important;
  }
  ._p-lg-46 {
    padding: 46px !important;
  }
  ._mb-lg-46 {
    margin-bottom: 46px !important;
  }
  ._m-lg-46 {
    margin: 46px !important;
  }
  ._mx-lg-46 {
    margin-left: 46px !important;
    margin-right: 46px !important;
  }
  ._my-lg-46 {
    margin-top: 46px !important;
    margin-bottom: 46px !important;
  }
  ._pb-lg-46 {
    padding-bottom: 46px !important;
  }
  ._px-lg-46 {
    padding-left: 46px !important;
    padding-right: 46px !important;
  }
  ._py-lg-46 {
    padding-top: 46px !important;
    padding-bottom: 46px !important;
  }
  ._p-lg-46 {
    padding: 46px !important;
  }
  ._ml-lg-46 {
    margin-left: 46px !important;
  }
  ._m-lg-46 {
    margin: 46px !important;
  }
  ._mx-lg-46 {
    margin-left: 46px !important;
    margin-right: 46px !important;
  }
  ._my-lg-46 {
    margin-top: 46px !important;
    margin-bottom: 46px !important;
  }
  ._pl-lg-46 {
    padding-left: 46px !important;
  }
  ._px-lg-46 {
    padding-left: 46px !important;
    padding-right: 46px !important;
  }
  ._py-lg-46 {
    padding-top: 46px !important;
    padding-bottom: 46px !important;
  }
  ._p-lg-46 {
    padding: 46px !important;
  }
  ._mr-lg-46 {
    margin-right: 46px !important;
  }
  ._m-lg-46 {
    margin: 46px !important;
  }
  ._mx-lg-46 {
    margin-left: 46px !important;
    margin-right: 46px !important;
  }
  ._my-lg-46 {
    margin-top: 46px !important;
    margin-bottom: 46px !important;
  }
  ._pr-lg-46 {
    padding-right: 46px !important;
  }
  ._px-lg-46 {
    padding-left: 46px !important;
    padding-right: 46px !important;
  }
  ._py-lg-46 {
    padding-top: 46px !important;
    padding-bottom: 46px !important;
  }
  ._p-lg-46 {
    padding: 46px !important;
  }
  ._mt-lg-50 {
    margin-top: 50px !important;
  }
  ._m-lg-50 {
    margin: 50px !important;
  }
  ._mx-lg-50 {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  ._my-lg-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  ._pt-lg-50 {
    padding-top: 50px !important;
  }
  ._px-lg-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  ._py-lg-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  ._p-lg-50 {
    padding: 50px !important;
  }
  ._mb-lg-50 {
    margin-bottom: 50px !important;
  }
  ._m-lg-50 {
    margin: 50px !important;
  }
  ._mx-lg-50 {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  ._my-lg-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  ._pb-lg-50 {
    padding-bottom: 50px !important;
  }
  ._px-lg-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  ._py-lg-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  ._p-lg-50 {
    padding: 50px !important;
  }
  ._ml-lg-50 {
    margin-left: 50px !important;
  }
  ._m-lg-50 {
    margin: 50px !important;
  }
  ._mx-lg-50 {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  ._my-lg-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  ._pl-lg-50 {
    padding-left: 50px !important;
  }
  ._px-lg-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  ._py-lg-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  ._p-lg-50 {
    padding: 50px !important;
  }
  ._mr-lg-50 {
    margin-right: 50px !important;
  }
  ._m-lg-50 {
    margin: 50px !important;
  }
  ._mx-lg-50 {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  ._my-lg-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  ._pr-lg-50 {
    padding-right: 50px !important;
  }
  ._px-lg-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  ._py-lg-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  ._p-lg-50 {
    padding: 50px !important;
  }
  ._mt-lg-55 {
    margin-top: 55px !important;
  }
  ._m-lg-55 {
    margin: 55px !important;
  }
  ._mx-lg-55 {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }
  ._my-lg-55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }
  ._pt-lg-55 {
    padding-top: 55px !important;
  }
  ._px-lg-55 {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  ._py-lg-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  ._p-lg-55 {
    padding: 55px !important;
  }
  ._mb-lg-55 {
    margin-bottom: 55px !important;
  }
  ._m-lg-55 {
    margin: 55px !important;
  }
  ._mx-lg-55 {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }
  ._my-lg-55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }
  ._pb-lg-55 {
    padding-bottom: 55px !important;
  }
  ._px-lg-55 {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  ._py-lg-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  ._p-lg-55 {
    padding: 55px !important;
  }
  ._ml-lg-55 {
    margin-left: 55px !important;
  }
  ._m-lg-55 {
    margin: 55px !important;
  }
  ._mx-lg-55 {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }
  ._my-lg-55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }
  ._pl-lg-55 {
    padding-left: 55px !important;
  }
  ._px-lg-55 {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  ._py-lg-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  ._p-lg-55 {
    padding: 55px !important;
  }
  ._mr-lg-55 {
    margin-right: 55px !important;
  }
  ._m-lg-55 {
    margin: 55px !important;
  }
  ._mx-lg-55 {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }
  ._my-lg-55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }
  ._pr-lg-55 {
    padding-right: 55px !important;
  }
  ._px-lg-55 {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  ._py-lg-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  ._p-lg-55 {
    padding: 55px !important;
  }
  ._mt-lg-60 {
    margin-top: 60px !important;
  }
  ._m-lg-60 {
    margin: 60px !important;
  }
  ._mx-lg-60 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  ._my-lg-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  ._pt-lg-60 {
    padding-top: 60px !important;
  }
  ._px-lg-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  ._py-lg-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  ._p-lg-60 {
    padding: 60px !important;
  }
  ._mb-lg-60 {
    margin-bottom: 60px !important;
  }
  ._m-lg-60 {
    margin: 60px !important;
  }
  ._mx-lg-60 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  ._my-lg-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  ._pb-lg-60 {
    padding-bottom: 60px !important;
  }
  ._px-lg-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  ._py-lg-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  ._p-lg-60 {
    padding: 60px !important;
  }
  ._ml-lg-60 {
    margin-left: 60px !important;
  }
  ._m-lg-60 {
    margin: 60px !important;
  }
  ._mx-lg-60 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  ._my-lg-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  ._pl-lg-60 {
    padding-left: 60px !important;
  }
  ._px-lg-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  ._py-lg-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  ._p-lg-60 {
    padding: 60px !important;
  }
  ._mr-lg-60 {
    margin-right: 60px !important;
  }
  ._m-lg-60 {
    margin: 60px !important;
  }
  ._mx-lg-60 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  ._my-lg-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  ._pr-lg-60 {
    padding-right: 60px !important;
  }
  ._px-lg-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  ._py-lg-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  ._p-lg-60 {
    padding: 60px !important;
  }
  ._mt-lg-65 {
    margin-top: 65px !important;
  }
  ._m-lg-65 {
    margin: 65px !important;
  }
  ._mx-lg-65 {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }
  ._my-lg-65 {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }
  ._pt-lg-65 {
    padding-top: 65px !important;
  }
  ._px-lg-65 {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }
  ._py-lg-65 {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  ._p-lg-65 {
    padding: 65px !important;
  }
  ._mb-lg-65 {
    margin-bottom: 65px !important;
  }
  ._m-lg-65 {
    margin: 65px !important;
  }
  ._mx-lg-65 {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }
  ._my-lg-65 {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }
  ._pb-lg-65 {
    padding-bottom: 65px !important;
  }
  ._px-lg-65 {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }
  ._py-lg-65 {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  ._p-lg-65 {
    padding: 65px !important;
  }
  ._ml-lg-65 {
    margin-left: 65px !important;
  }
  ._m-lg-65 {
    margin: 65px !important;
  }
  ._mx-lg-65 {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }
  ._my-lg-65 {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }
  ._pl-lg-65 {
    padding-left: 65px !important;
  }
  ._px-lg-65 {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }
  ._py-lg-65 {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  ._p-lg-65 {
    padding: 65px !important;
  }
  ._mr-lg-65 {
    margin-right: 65px !important;
  }
  ._m-lg-65 {
    margin: 65px !important;
  }
  ._mx-lg-65 {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }
  ._my-lg-65 {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }
  ._pr-lg-65 {
    padding-right: 65px !important;
  }
  ._px-lg-65 {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }
  ._py-lg-65 {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  ._p-lg-65 {
    padding: 65px !important;
  }
  ._mt-lg-70 {
    margin-top: 70px !important;
  }
  ._m-lg-70 {
    margin: 70px !important;
  }
  ._mx-lg-70 {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }
  ._my-lg-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  ._pt-lg-70 {
    padding-top: 70px !important;
  }
  ._px-lg-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  ._py-lg-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  ._p-lg-70 {
    padding: 70px !important;
  }
  ._mb-lg-70 {
    margin-bottom: 70px !important;
  }
  ._m-lg-70 {
    margin: 70px !important;
  }
  ._mx-lg-70 {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }
  ._my-lg-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  ._pb-lg-70 {
    padding-bottom: 70px !important;
  }
  ._px-lg-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  ._py-lg-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  ._p-lg-70 {
    padding: 70px !important;
  }
  ._ml-lg-70 {
    margin-left: 70px !important;
  }
  ._m-lg-70 {
    margin: 70px !important;
  }
  ._mx-lg-70 {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }
  ._my-lg-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  ._pl-lg-70 {
    padding-left: 70px !important;
  }
  ._px-lg-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  ._py-lg-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  ._p-lg-70 {
    padding: 70px !important;
  }
  ._mr-lg-70 {
    margin-right: 70px !important;
  }
  ._m-lg-70 {
    margin: 70px !important;
  }
  ._mx-lg-70 {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }
  ._my-lg-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  ._pr-lg-70 {
    padding-right: 70px !important;
  }
  ._px-lg-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  ._py-lg-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  ._p-lg-70 {
    padding: 70px !important;
  }
  ._mt-lg-75 {
    margin-top: 75px !important;
  }
  ._m-lg-75 {
    margin: 75px !important;
  }
  ._mx-lg-75 {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }
  ._my-lg-75 {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }
  ._pt-lg-75 {
    padding-top: 75px !important;
  }
  ._px-lg-75 {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  ._py-lg-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  ._p-lg-75 {
    padding: 75px !important;
  }
  ._mb-lg-75 {
    margin-bottom: 75px !important;
  }
  ._m-lg-75 {
    margin: 75px !important;
  }
  ._mx-lg-75 {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }
  ._my-lg-75 {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }
  ._pb-lg-75 {
    padding-bottom: 75px !important;
  }
  ._px-lg-75 {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  ._py-lg-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  ._p-lg-75 {
    padding: 75px !important;
  }
  ._ml-lg-75 {
    margin-left: 75px !important;
  }
  ._m-lg-75 {
    margin: 75px !important;
  }
  ._mx-lg-75 {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }
  ._my-lg-75 {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }
  ._pl-lg-75 {
    padding-left: 75px !important;
  }
  ._px-lg-75 {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  ._py-lg-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  ._p-lg-75 {
    padding: 75px !important;
  }
  ._mr-lg-75 {
    margin-right: 75px !important;
  }
  ._m-lg-75 {
    margin: 75px !important;
  }
  ._mx-lg-75 {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }
  ._my-lg-75 {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }
  ._pr-lg-75 {
    padding-right: 75px !important;
  }
  ._px-lg-75 {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  ._py-lg-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  ._p-lg-75 {
    padding: 75px !important;
  }
  ._mt-lg-80 {
    margin-top: 80px !important;
  }
  ._m-lg-80 {
    margin: 80px !important;
  }
  ._mx-lg-80 {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  ._my-lg-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  ._pt-lg-80 {
    padding-top: 80px !important;
  }
  ._px-lg-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  ._py-lg-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  ._p-lg-80 {
    padding: 80px !important;
  }
  ._mb-lg-80 {
    margin-bottom: 80px !important;
  }
  ._m-lg-80 {
    margin: 80px !important;
  }
  ._mx-lg-80 {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  ._my-lg-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  ._pb-lg-80 {
    padding-bottom: 80px !important;
  }
  ._px-lg-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  ._py-lg-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  ._p-lg-80 {
    padding: 80px !important;
  }
  ._ml-lg-80 {
    margin-left: 80px !important;
  }
  ._m-lg-80 {
    margin: 80px !important;
  }
  ._mx-lg-80 {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  ._my-lg-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  ._pl-lg-80 {
    padding-left: 80px !important;
  }
  ._px-lg-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  ._py-lg-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  ._p-lg-80 {
    padding: 80px !important;
  }
  ._mr-lg-80 {
    margin-right: 80px !important;
  }
  ._m-lg-80 {
    margin: 80px !important;
  }
  ._mx-lg-80 {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  ._my-lg-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  ._pr-lg-80 {
    padding-right: 80px !important;
  }
  ._px-lg-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  ._py-lg-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  ._p-lg-80 {
    padding: 80px !important;
  }
  ._mt-lg-85 {
    margin-top: 85px !important;
  }
  ._m-lg-85 {
    margin: 85px !important;
  }
  ._mx-lg-85 {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }
  ._my-lg-85 {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }
  ._pt-lg-85 {
    padding-top: 85px !important;
  }
  ._px-lg-85 {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  ._py-lg-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  ._p-lg-85 {
    padding: 85px !important;
  }
  ._mb-lg-85 {
    margin-bottom: 85px !important;
  }
  ._m-lg-85 {
    margin: 85px !important;
  }
  ._mx-lg-85 {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }
  ._my-lg-85 {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }
  ._pb-lg-85 {
    padding-bottom: 85px !important;
  }
  ._px-lg-85 {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  ._py-lg-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  ._p-lg-85 {
    padding: 85px !important;
  }
  ._ml-lg-85 {
    margin-left: 85px !important;
  }
  ._m-lg-85 {
    margin: 85px !important;
  }
  ._mx-lg-85 {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }
  ._my-lg-85 {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }
  ._pl-lg-85 {
    padding-left: 85px !important;
  }
  ._px-lg-85 {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  ._py-lg-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  ._p-lg-85 {
    padding: 85px !important;
  }
  ._mr-lg-85 {
    margin-right: 85px !important;
  }
  ._m-lg-85 {
    margin: 85px !important;
  }
  ._mx-lg-85 {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }
  ._my-lg-85 {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }
  ._pr-lg-85 {
    padding-right: 85px !important;
  }
  ._px-lg-85 {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  ._py-lg-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  ._p-lg-85 {
    padding: 85px !important;
  }
  ._mt-lg-90 {
    margin-top: 90px !important;
  }
  ._m-lg-90 {
    margin: 90px !important;
  }
  ._mx-lg-90 {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }
  ._my-lg-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  ._pt-lg-90 {
    padding-top: 90px !important;
  }
  ._px-lg-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  ._py-lg-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  ._p-lg-90 {
    padding: 90px !important;
  }
  ._mb-lg-90 {
    margin-bottom: 90px !important;
  }
  ._m-lg-90 {
    margin: 90px !important;
  }
  ._mx-lg-90 {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }
  ._my-lg-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  ._pb-lg-90 {
    padding-bottom: 90px !important;
  }
  ._px-lg-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  ._py-lg-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  ._p-lg-90 {
    padding: 90px !important;
  }
  ._ml-lg-90 {
    margin-left: 90px !important;
  }
  ._m-lg-90 {
    margin: 90px !important;
  }
  ._mx-lg-90 {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }
  ._my-lg-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  ._pl-lg-90 {
    padding-left: 90px !important;
  }
  ._px-lg-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  ._py-lg-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  ._p-lg-90 {
    padding: 90px !important;
  }
  ._mr-lg-90 {
    margin-right: 90px !important;
  }
  ._m-lg-90 {
    margin: 90px !important;
  }
  ._mx-lg-90 {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }
  ._my-lg-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  ._pr-lg-90 {
    padding-right: 90px !important;
  }
  ._px-lg-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  ._py-lg-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  ._p-lg-90 {
    padding: 90px !important;
  }
  ._mt-lg-95 {
    margin-top: 95px !important;
  }
  ._m-lg-95 {
    margin: 95px !important;
  }
  ._mx-lg-95 {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }
  ._my-lg-95 {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }
  ._pt-lg-95 {
    padding-top: 95px !important;
  }
  ._px-lg-95 {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  ._py-lg-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  ._p-lg-95 {
    padding: 95px !important;
  }
  ._mb-lg-95 {
    margin-bottom: 95px !important;
  }
  ._m-lg-95 {
    margin: 95px !important;
  }
  ._mx-lg-95 {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }
  ._my-lg-95 {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }
  ._pb-lg-95 {
    padding-bottom: 95px !important;
  }
  ._px-lg-95 {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  ._py-lg-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  ._p-lg-95 {
    padding: 95px !important;
  }
  ._ml-lg-95 {
    margin-left: 95px !important;
  }
  ._m-lg-95 {
    margin: 95px !important;
  }
  ._mx-lg-95 {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }
  ._my-lg-95 {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }
  ._pl-lg-95 {
    padding-left: 95px !important;
  }
  ._px-lg-95 {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  ._py-lg-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  ._p-lg-95 {
    padding: 95px !important;
  }
  ._mr-lg-95 {
    margin-right: 95px !important;
  }
  ._m-lg-95 {
    margin: 95px !important;
  }
  ._mx-lg-95 {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }
  ._my-lg-95 {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }
  ._pr-lg-95 {
    padding-right: 95px !important;
  }
  ._px-lg-95 {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  ._py-lg-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  ._p-lg-95 {
    padding: 95px !important;
  }
  ._mt-lg-100 {
    margin-top: 100px !important;
  }
  ._m-lg-100 {
    margin: 100px !important;
  }
  ._mx-lg-100 {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }
  ._my-lg-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  ._pt-lg-100 {
    padding-top: 100px !important;
  }
  ._px-lg-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  ._py-lg-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  ._p-lg-100 {
    padding: 100px !important;
  }
  ._mb-lg-100 {
    margin-bottom: 100px !important;
  }
  ._m-lg-100 {
    margin: 100px !important;
  }
  ._mx-lg-100 {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }
  ._my-lg-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  ._pb-lg-100 {
    padding-bottom: 100px !important;
  }
  ._px-lg-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  ._py-lg-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  ._p-lg-100 {
    padding: 100px !important;
  }
  ._ml-lg-100 {
    margin-left: 100px !important;
  }
  ._m-lg-100 {
    margin: 100px !important;
  }
  ._mx-lg-100 {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }
  ._my-lg-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  ._pl-lg-100 {
    padding-left: 100px !important;
  }
  ._px-lg-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  ._py-lg-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  ._p-lg-100 {
    padding: 100px !important;
  }
  ._mr-lg-100 {
    margin-right: 100px !important;
  }
  ._m-lg-100 {
    margin: 100px !important;
  }
  ._mx-lg-100 {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }
  ._my-lg-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  ._pr-lg-100 {
    padding-right: 100px !important;
  }
  ._px-lg-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  ._py-lg-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  ._p-lg-100 {
    padding: 100px !important;
  }
  ._mt-lg-105 {
    margin-top: 105px !important;
  }
  ._m-lg-105 {
    margin: 105px !important;
  }
  ._mx-lg-105 {
    margin-left: 105px !important;
    margin-right: 105px !important;
  }
  ._my-lg-105 {
    margin-top: 105px !important;
    margin-bottom: 105px !important;
  }
  ._pt-lg-105 {
    padding-top: 105px !important;
  }
  ._px-lg-105 {
    padding-left: 105px !important;
    padding-right: 105px !important;
  }
  ._py-lg-105 {
    padding-top: 105px !important;
    padding-bottom: 105px !important;
  }
  ._p-lg-105 {
    padding: 105px !important;
  }
  ._mb-lg-105 {
    margin-bottom: 105px !important;
  }
  ._m-lg-105 {
    margin: 105px !important;
  }
  ._mx-lg-105 {
    margin-left: 105px !important;
    margin-right: 105px !important;
  }
  ._my-lg-105 {
    margin-top: 105px !important;
    margin-bottom: 105px !important;
  }
  ._pb-lg-105 {
    padding-bottom: 105px !important;
  }
  ._px-lg-105 {
    padding-left: 105px !important;
    padding-right: 105px !important;
  }
  ._py-lg-105 {
    padding-top: 105px !important;
    padding-bottom: 105px !important;
  }
  ._p-lg-105 {
    padding: 105px !important;
  }
  ._ml-lg-105 {
    margin-left: 105px !important;
  }
  ._m-lg-105 {
    margin: 105px !important;
  }
  ._mx-lg-105 {
    margin-left: 105px !important;
    margin-right: 105px !important;
  }
  ._my-lg-105 {
    margin-top: 105px !important;
    margin-bottom: 105px !important;
  }
  ._pl-lg-105 {
    padding-left: 105px !important;
  }
  ._px-lg-105 {
    padding-left: 105px !important;
    padding-right: 105px !important;
  }
  ._py-lg-105 {
    padding-top: 105px !important;
    padding-bottom: 105px !important;
  }
  ._p-lg-105 {
    padding: 105px !important;
  }
  ._mr-lg-105 {
    margin-right: 105px !important;
  }
  ._m-lg-105 {
    margin: 105px !important;
  }
  ._mx-lg-105 {
    margin-left: 105px !important;
    margin-right: 105px !important;
  }
  ._my-lg-105 {
    margin-top: 105px !important;
    margin-bottom: 105px !important;
  }
  ._pr-lg-105 {
    padding-right: 105px !important;
  }
  ._px-lg-105 {
    padding-left: 105px !important;
    padding-right: 105px !important;
  }
  ._py-lg-105 {
    padding-top: 105px !important;
    padding-bottom: 105px !important;
  }
  ._p-lg-105 {
    padding: 105px !important;
  }
  ._mt-lg-110 {
    margin-top: 110px !important;
  }
  ._m-lg-110 {
    margin: 110px !important;
  }
  ._mx-lg-110 {
    margin-left: 110px !important;
    margin-right: 110px !important;
  }
  ._my-lg-110 {
    margin-top: 110px !important;
    margin-bottom: 110px !important;
  }
  ._pt-lg-110 {
    padding-top: 110px !important;
  }
  ._px-lg-110 {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }
  ._py-lg-110 {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }
  ._p-lg-110 {
    padding: 110px !important;
  }
  ._mb-lg-110 {
    margin-bottom: 110px !important;
  }
  ._m-lg-110 {
    margin: 110px !important;
  }
  ._mx-lg-110 {
    margin-left: 110px !important;
    margin-right: 110px !important;
  }
  ._my-lg-110 {
    margin-top: 110px !important;
    margin-bottom: 110px !important;
  }
  ._pb-lg-110 {
    padding-bottom: 110px !important;
  }
  ._px-lg-110 {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }
  ._py-lg-110 {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }
  ._p-lg-110 {
    padding: 110px !important;
  }
  ._ml-lg-110 {
    margin-left: 110px !important;
  }
  ._m-lg-110 {
    margin: 110px !important;
  }
  ._mx-lg-110 {
    margin-left: 110px !important;
    margin-right: 110px !important;
  }
  ._my-lg-110 {
    margin-top: 110px !important;
    margin-bottom: 110px !important;
  }
  ._pl-lg-110 {
    padding-left: 110px !important;
  }
  ._px-lg-110 {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }
  ._py-lg-110 {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }
  ._p-lg-110 {
    padding: 110px !important;
  }
  ._mr-lg-110 {
    margin-right: 110px !important;
  }
  ._m-lg-110 {
    margin: 110px !important;
  }
  ._mx-lg-110 {
    margin-left: 110px !important;
    margin-right: 110px !important;
  }
  ._my-lg-110 {
    margin-top: 110px !important;
    margin-bottom: 110px !important;
  }
  ._pr-lg-110 {
    padding-right: 110px !important;
  }
  ._px-lg-110 {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }
  ._py-lg-110 {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }
  ._p-lg-110 {
    padding: 110px !important;
  }
  ._mt-lg-115 {
    margin-top: 115px !important;
  }
  ._m-lg-115 {
    margin: 115px !important;
  }
  ._mx-lg-115 {
    margin-left: 115px !important;
    margin-right: 115px !important;
  }
  ._my-lg-115 {
    margin-top: 115px !important;
    margin-bottom: 115px !important;
  }
  ._pt-lg-115 {
    padding-top: 115px !important;
  }
  ._px-lg-115 {
    padding-left: 115px !important;
    padding-right: 115px !important;
  }
  ._py-lg-115 {
    padding-top: 115px !important;
    padding-bottom: 115px !important;
  }
  ._p-lg-115 {
    padding: 115px !important;
  }
  ._mb-lg-115 {
    margin-bottom: 115px !important;
  }
  ._m-lg-115 {
    margin: 115px !important;
  }
  ._mx-lg-115 {
    margin-left: 115px !important;
    margin-right: 115px !important;
  }
  ._my-lg-115 {
    margin-top: 115px !important;
    margin-bottom: 115px !important;
  }
  ._pb-lg-115 {
    padding-bottom: 115px !important;
  }
  ._px-lg-115 {
    padding-left: 115px !important;
    padding-right: 115px !important;
  }
  ._py-lg-115 {
    padding-top: 115px !important;
    padding-bottom: 115px !important;
  }
  ._p-lg-115 {
    padding: 115px !important;
  }
  ._ml-lg-115 {
    margin-left: 115px !important;
  }
  ._m-lg-115 {
    margin: 115px !important;
  }
  ._mx-lg-115 {
    margin-left: 115px !important;
    margin-right: 115px !important;
  }
  ._my-lg-115 {
    margin-top: 115px !important;
    margin-bottom: 115px !important;
  }
  ._pl-lg-115 {
    padding-left: 115px !important;
  }
  ._px-lg-115 {
    padding-left: 115px !important;
    padding-right: 115px !important;
  }
  ._py-lg-115 {
    padding-top: 115px !important;
    padding-bottom: 115px !important;
  }
  ._p-lg-115 {
    padding: 115px !important;
  }
  ._mr-lg-115 {
    margin-right: 115px !important;
  }
  ._m-lg-115 {
    margin: 115px !important;
  }
  ._mx-lg-115 {
    margin-left: 115px !important;
    margin-right: 115px !important;
  }
  ._my-lg-115 {
    margin-top: 115px !important;
    margin-bottom: 115px !important;
  }
  ._pr-lg-115 {
    padding-right: 115px !important;
  }
  ._px-lg-115 {
    padding-left: 115px !important;
    padding-right: 115px !important;
  }
  ._py-lg-115 {
    padding-top: 115px !important;
    padding-bottom: 115px !important;
  }
  ._p-lg-115 {
    padding: 115px !important;
  }
  ._mt-lg-120 {
    margin-top: 120px !important;
  }
  ._m-lg-120 {
    margin: 120px !important;
  }
  ._mx-lg-120 {
    margin-left: 120px !important;
    margin-right: 120px !important;
  }
  ._my-lg-120 {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
  }
  ._pt-lg-120 {
    padding-top: 120px !important;
  }
  ._px-lg-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  ._py-lg-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  ._p-lg-120 {
    padding: 120px !important;
  }
  ._mb-lg-120 {
    margin-bottom: 120px !important;
  }
  ._m-lg-120 {
    margin: 120px !important;
  }
  ._mx-lg-120 {
    margin-left: 120px !important;
    margin-right: 120px !important;
  }
  ._my-lg-120 {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
  }
  ._pb-lg-120 {
    padding-bottom: 120px !important;
  }
  ._px-lg-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  ._py-lg-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  ._p-lg-120 {
    padding: 120px !important;
  }
  ._ml-lg-120 {
    margin-left: 120px !important;
  }
  ._m-lg-120 {
    margin: 120px !important;
  }
  ._mx-lg-120 {
    margin-left: 120px !important;
    margin-right: 120px !important;
  }
  ._my-lg-120 {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
  }
  ._pl-lg-120 {
    padding-left: 120px !important;
  }
  ._px-lg-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  ._py-lg-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  ._p-lg-120 {
    padding: 120px !important;
  }
  ._mr-lg-120 {
    margin-right: 120px !important;
  }
  ._m-lg-120 {
    margin: 120px !important;
  }
  ._mx-lg-120 {
    margin-left: 120px !important;
    margin-right: 120px !important;
  }
  ._my-lg-120 {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
  }
  ._pr-lg-120 {
    padding-right: 120px !important;
  }
  ._px-lg-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  ._py-lg-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  ._p-lg-120 {
    padding: 120px !important;
  }
  ._mt-lg-160 {
    margin-top: 160px !important;
  }
  ._m-lg-160 {
    margin: 160px !important;
  }
  ._mx-lg-160 {
    margin-left: 160px !important;
    margin-right: 160px !important;
  }
  ._my-lg-160 {
    margin-top: 160px !important;
    margin-bottom: 160px !important;
  }
  ._pt-lg-160 {
    padding-top: 160px !important;
  }
  ._px-lg-160 {
    padding-left: 160px !important;
    padding-right: 160px !important;
  }
  ._py-lg-160 {
    padding-top: 160px !important;
    padding-bottom: 160px !important;
  }
  ._p-lg-160 {
    padding: 160px !important;
  }
  ._mb-lg-160 {
    margin-bottom: 160px !important;
  }
  ._m-lg-160 {
    margin: 160px !important;
  }
  ._mx-lg-160 {
    margin-left: 160px !important;
    margin-right: 160px !important;
  }
  ._my-lg-160 {
    margin-top: 160px !important;
    margin-bottom: 160px !important;
  }
  ._pb-lg-160 {
    padding-bottom: 160px !important;
  }
  ._px-lg-160 {
    padding-left: 160px !important;
    padding-right: 160px !important;
  }
  ._py-lg-160 {
    padding-top: 160px !important;
    padding-bottom: 160px !important;
  }
  ._p-lg-160 {
    padding: 160px !important;
  }
  ._ml-lg-160 {
    margin-left: 160px !important;
  }
  ._m-lg-160 {
    margin: 160px !important;
  }
  ._mx-lg-160 {
    margin-left: 160px !important;
    margin-right: 160px !important;
  }
  ._my-lg-160 {
    margin-top: 160px !important;
    margin-bottom: 160px !important;
  }
  ._pl-lg-160 {
    padding-left: 160px !important;
  }
  ._px-lg-160 {
    padding-left: 160px !important;
    padding-right: 160px !important;
  }
  ._py-lg-160 {
    padding-top: 160px !important;
    padding-bottom: 160px !important;
  }
  ._p-lg-160 {
    padding: 160px !important;
  }
  ._mr-lg-160 {
    margin-right: 160px !important;
  }
  ._m-lg-160 {
    margin: 160px !important;
  }
  ._mx-lg-160 {
    margin-left: 160px !important;
    margin-right: 160px !important;
  }
  ._my-lg-160 {
    margin-top: 160px !important;
    margin-bottom: 160px !important;
  }
  ._pr-lg-160 {
    padding-right: 160px !important;
  }
  ._px-lg-160 {
    padding-left: 160px !important;
    padding-right: 160px !important;
  }
  ._py-lg-160 {
    padding-top: 160px !important;
    padding-bottom: 160px !important;
  }
  ._p-lg-160 {
    padding: 160px !important;
  }
}

._m-0 {
  margin: 0 !important;
}

._mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

@media (max-width: 896px) {
  ._m-sm-0 {
    margin: 0 !important;
  }
  ._mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media (min-width: 897px) {
  ._m-md-0 {
    margin: 0 !important;
  }
  ._mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media (min-width: 1601px) {
  ._m-lg-0 {
    margin: 0 !important;
  }
  ._mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

/* ==========================================================
 Text align
========================================================== */
._txt-left {
  text-align: left !important;
}

._txt-center {
  text-align: center !important;
}

._txt-right {
  text-align: right !important;
}

@media (max-width: 896px) {
  ._txt-sm-left {
    text-align: left !important;
  }
  ._txt-sm-center {
    text-align: center !important;
  }
  ._txt-sm-right {
    text-align: right !important;
  }
}

@media (min-width: 897px) {
  ._txt-md-left {
    text-align: left !important;
  }
  ._txt-md-center {
    text-align: center !important;
  }
  ._txt-md-right {
    text-align: right !important;
  }
}

@media (min-width: 1601px) {
  ._txt-lg-left {
    text-align: left !important;
  }
  ._txt-lg-center {
    text-align: center !important;
  }
  ._txt-lg-right {
    text-align: right !important;
  }
}

/* ==========================================================
 Text weight
========================================================== */
._txt-normal {
  font-weight: normal !important;
}

._txt-bold {
  font-weight: bold !important;
}

/* ==========================================================
 Text color
========================================================== */
/* ==========================================================
 Text style
========================================================== */
/* ==========================================================
 Text size
========================================================== */
._txt-size-8 {
  font-size: 0.8rem !important;
}

._txt-size-9 {
  font-size: 0.9rem !important;
}

._txt-size-10 {
  font-size: 1rem !important;
}

._txt-size-11 {
  font-size: 1.1rem !important;
}

._txt-size-12 {
  font-size: 1.2rem !important;
}

._txt-size-13 {
  font-size: 1.3rem !important;
}

._txt-size-14 {
  font-size: 1.4rem !important;
}

._txt-size-15 {
  font-size: 1.5rem !important;
}

._txt-size-16 {
  font-size: 1.6rem !important;
}

._txt-size-17 {
  font-size: 1.7rem !important;
}

._txt-size-18 {
  font-size: 1.8rem !important;
}

._txt-size-19 {
  font-size: 1.9rem !important;
}

._txt-size-20 {
  font-size: 2rem !important;
}

._txt-size-21 {
  font-size: 2.1rem !important;
}

._txt-size-22 {
  font-size: 2.2rem !important;
}

._txt-size-23 {
  font-size: 2.3rem !important;
}

._txt-size-24 {
  font-size: 2.4rem !important;
}

._txt-size-25 {
  font-size: 2.5rem !important;
}

._txt-size-26 {
  font-size: 2.6rem !important;
}

._txt-size-27 {
  font-size: 2.7rem !important;
}

._txt-size-28 {
  font-size: 2.8rem !important;
}

._txt-size-29 {
  font-size: 2.9rem !important;
}

._txt-size-30 {
  font-size: 3rem !important;
}

._txt-size-31 {
  font-size: 3.1rem !important;
}

._txt-size-32 {
  font-size: 3.2rem !important;
}

._txt-size-33 {
  font-size: 3.3rem !important;
}

._txt-size-34 {
  font-size: 3.4rem !important;
}

._txt-size-35 {
  font-size: 3.5rem !important;
}

._txt-size-36 {
  font-size: 3.6rem !important;
}

@media (max-width: 896px) {
  ._txt-sm-size-8 {
    font-size: 0.8rem !important;
  }
  ._txt-sm-size-9 {
    font-size: 0.9rem !important;
  }
  ._txt-sm-size-10 {
    font-size: 1rem !important;
  }
  ._txt-sm-size-11 {
    font-size: 1.1rem !important;
  }
  ._txt-sm-size-12 {
    font-size: 1.2rem !important;
  }
  ._txt-sm-size-13 {
    font-size: 1.3rem !important;
  }
  ._txt-sm-size-14 {
    font-size: 1.4rem !important;
  }
  ._txt-sm-size-15 {
    font-size: 1.5rem !important;
  }
  ._txt-sm-size-16 {
    font-size: 1.6rem !important;
  }
  ._txt-sm-size-17 {
    font-size: 1.7rem !important;
  }
  ._txt-sm-size-18 {
    font-size: 1.8rem !important;
  }
  ._txt-sm-size-19 {
    font-size: 1.9rem !important;
  }
  ._txt-sm-size-20 {
    font-size: 2rem !important;
  }
  ._txt-sm-size-21 {
    font-size: 2.1rem !important;
  }
  ._txt-sm-size-22 {
    font-size: 2.2rem !important;
  }
  ._txt-sm-size-23 {
    font-size: 2.3rem !important;
  }
  ._txt-sm-size-24 {
    font-size: 2.4rem !important;
  }
  ._txt-sm-size-25 {
    font-size: 2.5rem !important;
  }
  ._txt-sm-size-26 {
    font-size: 2.6rem !important;
  }
  ._txt-sm-size-27 {
    font-size: 2.7rem !important;
  }
  ._txt-sm-size-28 {
    font-size: 2.8rem !important;
  }
  ._txt-sm-size-29 {
    font-size: 2.9rem !important;
  }
  ._txt-sm-size-30 {
    font-size: 3rem !important;
  }
}

@media (min-width: 897px) {
  ._txt-md-size-8 {
    font-size: 0.8rem !important;
  }
  ._txt-md-size-9 {
    font-size: 0.9rem !important;
  }
  ._txt-md-size-10 {
    font-size: 1rem !important;
  }
  ._txt-md-size-11 {
    font-size: 1.1rem !important;
  }
  ._txt-md-size-12 {
    font-size: 1.2rem !important;
  }
  ._txt-md-size-13 {
    font-size: 1.3rem !important;
  }
  ._txt-md-size-14 {
    font-size: 1.4rem !important;
  }
  ._txt-md-size-15 {
    font-size: 1.5rem !important;
  }
  ._txt-md-size-16 {
    font-size: 1.6rem !important;
  }
  ._txt-md-size-17 {
    font-size: 1.7rem !important;
  }
  ._txt-md-size-18 {
    font-size: 1.8rem !important;
  }
  ._txt-md-size-19 {
    font-size: 1.9rem !important;
  }
  ._txt-md-size-20 {
    font-size: 2rem !important;
  }
  ._txt-md-size-21 {
    font-size: 2.1rem !important;
  }
  ._txt-md-size-22 {
    font-size: 2.2rem !important;
  }
  ._txt-md-size-23 {
    font-size: 2.3rem !important;
  }
  ._txt-md-size-24 {
    font-size: 2.4rem !important;
  }
  ._txt-md-size-25 {
    font-size: 2.5rem !important;
  }
  ._txt-md-size-26 {
    font-size: 2.6rem !important;
  }
  ._txt-md-size-27 {
    font-size: 2.7rem !important;
  }
  ._txt-md-size-28 {
    font-size: 2.8rem !important;
  }
  ._txt-md-size-29 {
    font-size: 2.9rem !important;
  }
  ._txt-md-size-30 {
    font-size: 3rem !important;
  }
}

@media (min-width: 1601px) {
  ._txt-lg-size-8 {
    font-size: 0.8rem !important;
  }
  ._txt-lg-size-9 {
    font-size: 0.9rem !important;
  }
  ._txt-lg-size-10 {
    font-size: 1rem !important;
  }
  ._txt-lg-size-11 {
    font-size: 1.1rem !important;
  }
  ._txt-lg-size-12 {
    font-size: 1.2rem !important;
  }
  ._txt-lg-size-13 {
    font-size: 1.3rem !important;
  }
  ._txt-lg-size-14 {
    font-size: 1.4rem !important;
  }
  ._txt-lg-size-15 {
    font-size: 1.5rem !important;
  }
  ._txt-lg-size-16 {
    font-size: 1.6rem !important;
  }
  ._txt-lg-size-17 {
    font-size: 1.7rem !important;
  }
  ._txt-lg-size-18 {
    font-size: 1.8rem !important;
  }
  ._txt-lg-size-19 {
    font-size: 1.9rem !important;
  }
  ._txt-lg-size-20 {
    font-size: 2rem !important;
  }
  ._txt-lg-size-21 {
    font-size: 2.1rem !important;
  }
  ._txt-lg-size-22 {
    font-size: 2.2rem !important;
  }
  ._txt-lg-size-23 {
    font-size: 2.3rem !important;
  }
  ._txt-lg-size-24 {
    font-size: 2.4rem !important;
  }
  ._txt-lg-size-25 {
    font-size: 2.5rem !important;
  }
  ._txt-lg-size-26 {
    font-size: 2.6rem !important;
  }
  ._txt-lg-size-27 {
    font-size: 2.7rem !important;
  }
  ._txt-lg-size-28 {
    font-size: 2.8rem !important;
  }
  ._txt-lg-size-29 {
    font-size: 2.9rem !important;
  }
  ._txt-lg-size-30 {
    font-size: 3rem !important;
  }
}
