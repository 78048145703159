/* ==========================================================
 layout
========================================================== */
html {
  font-size: 62.5%;

  &.is-locked {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    overflow-y: scroll;
  }
}

body {
  background-color: $white;
  font-family: $font-base;
  font-size: 1.6rem;
  font-weight: 400;
  color: $color-main;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;

  @include mq-down(md) {
    font-size: 1.4rem;
  }
}

/* contents
---------------------------------------------------------- */
.l-contents {
  @include minWidth1200;
  position: relative;
  margin-bottom: 143px;

  @include mq-down(md) {
    margin-bottom: 80px;
  }

  &.-lower-page {
    padding-top: 120px;
    margin-bottom: 75px;

    @include mq-down(md) {
      padding-top: 58px;
      margin-bottom: 50px;
    }
  }
}

/* container
---------------------------------------------------------- */
.l-container {
  @include minWidth1200;
}

.l-inner {
  @include width1200;

  @include mq-down(md) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

#top {
  .l-container {
    padding: 0;
  }
}

/* container-column
---------------------------------------------------------- */
.l-container-column {
  display: flex;

  @include mq-down(md) {
    flex-wrap: wrap;
  }

  &.-detail {
    > .l-container-left {
      @include mq-down(md) {
        order: 1;
      }
    }

    > .l-container-right {
      @include mq-down(md) {
        order: 3;
      }
    }

    > .p-journal-relate {
      @include mq-down(md) {
        order: 2;
      }
    }
  }

  > .l-container-left {
    @include mq-up(md) {
      flex: 1 0 auto;
      width: 860px;
      padding-right: 75px;
    }

    @include mq-down(md) {
      width: 100%;
      padding-right: 0;
    }
  }

  > .l-container-right {
    width: 100%;
  }
}


.c-line-top {
  border-top: 1px dotted $color-gray-10;
}