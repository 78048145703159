@mixin is-opacity($opacity: .6) {
  @include mq-up(md) {
    opacity: 1;
    transition: opacity .3s ease-out;

    &:hover {
      opacity: $opacity;
      text-decoration: none;
    }
  }
}

@mixin is-underline {
  @include mq-up(md) {
    &:hover {
      text-decoration: underline;
    }
  }
}

@mixin is-underline-none {
  text-decoration: underline;
    
  &:hover {
    text-decoration: none;
  }
}

@mixin is-img {
  @include mq-up(md) {
    .img-zoom {
      overflow: hidden;
      position: relative;
      transition: transform 1s;
      transform: scale(1);
      

      &::before {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        background-color: $color-green-01;
        opacity: 0;
        z-index: 1;
        transition: opacity .2s ease-out;
        border-radius: $border-radius-pc;
      }

      img {
        transition: transform .2s;
      }
    }

    &:hover {
      .img-zoom {
        overflow: hidden;
        transform: scale(1.1);

        &::before {
          opacity: .6;
        }
        
        img {
          transform: scale(1.1);
        }
      }
    }
  }
}
