/* c-box-customers-filters
---------------------------------------------------------- */
.c-box-customers-filters {
  padding: 62px 0 50px;
  background-color: #e3f4f0;

  @include mq-down(md) {
    padding:  32px 0 45px;
  }
}

/* c-box-filters
---------------------------------------------------------- */
.c-box-filters {
  @include mq-down(md) {
    padding: 0 10px;
  }

  .box-title {
    position: relative;
    border-radius: $border-radius-pc;
    padding: 28px 0;
    height: 80px;
    background-color: $color-green-01;
    text-align: center;
    cursor: pointer;

    @include mq-down(md) {
      padding: 14px 0;
      height: 44px;
    }

    &::before {
      content: '';
      width: 0;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: $color-gray-12;
      border-radius: $border-radius-pc;
      transition: .25s;
    }

    &:hover {
      &::before {
        width: 100%;
      }
    }

    .title {
      position: relative;
      display: inline-block;
      padding-left: 30px;
      font-size: 2rem;
      letter-spacing: .1em;
      font-weight: bold;
      color: $white;

      @include mq-down(md) {
        font-size: 1.4rem;
      }

      &::before {
        content: '';
        position: absolute;
        top: 5px;
        left: 0;
        width: 19px;
        height: 19px;
        background: url('/assets/images/common/ico_search.png') 0 0 no-repeat;

        @include mq-down(md) {
          top: 2px;
          width: 15px;
          height: 15px;
          background-size: 15px 15px;
        }
      }
    }

    &.is-open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .box-content {
    border-left: 4px solid $color-green-01;
    border-right: 4px solid $color-green-01;
    border-bottom: 4px solid $color-green-01;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: $white;
    padding: 25px 42px 28px;
    display: none;

    @include mq-down(md) {
      border-left: 2px solid $color-green-01;
      border-right: 2px solid $color-green-01;
      border-bottom: 2px solid $color-green-01;
      padding: 12px 15px 38px;
    }

    .item-content {
      display: block;

      .item-row {
        display: flex;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid #d4d4d4;

        @include mq-down(md) {
          align-items: flex-start;
        }

        .title {
          width: 176px;
          flex: 0 0 auto;
          text-align: center;

          @include mq-down(md) {
            padding-top: 5px;
            width: 60px;
            text-align: left;
          }
        }

        .details {
          padding: 21px 0 20px;
          border-left: 1px solid #d4d4d4;

          @include mq-down(md) {
            padding: 10px 0;
          }

          .list {
            display: flex;
            margin: -15px 0 0 -50px;
            padding-left: 45px;
            flex-wrap: wrap;

            @include mq-down(md) {
              display: block;
              margin-left: 0;
              padding-left: 0;
            }

            .item {
              margin: 15px 0 0 50px;

              @include mq-down(md) {
                margin-top: 10px;
                margin-left: 8px;
              }

              &:not(:first-child) {
                @include mq-down(md) {
                  margin-top: 3px;
                }
              }
            }
          }
        }

        &.-row-01 {
          .details {
            padding: 17px 0;

            @include mq-down(md) {
              padding: 10px 0;
            }
          }
        }
      }
    }
  }

  .wrap-button {
    position: relative;
    margin-top: 25px;
    display: flex;
    justify-content: center;

    @include mq-down(md) {
      margin-right: 10px;
      margin-top: 36px;
      flex-wrap: wrap;
    }
  }
}

/* c-box-button
---------------------------------------------------------- */
.c-box-button {
  margin-top: 50px;
  padding: 48px 0;
  text-align: center;
  background-color: $color-lightgreen-01;
  border-radius: $border-radius-pc;

  @include mq-down(md) {
    padding: 28px 18px;
    border-radius: 14px;
  }

  .c-btn {
    font-weight: 700;

    @include mq-up(md) {
      padding: 17px 45px 13px 55px;
    }
  }

  &.-lg {
    padding: 40px 0;

    @include mq-up(md) {
      width: calc(100% - 15px);
      margin: 124px auto 0;
    }

    @include mq-down(md) {
      padding: 35px 15px;
    }

    .c-btn {
      @include mq-up(md) {
        min-width: 500px;
        padding: 23px 45px 23px 55px;

        &::after {
          right: -8px;
        }
      }
    }
  }

  &.-full.-lg {
    @include mq-up(md) {
      width: 100%;
      margin-top: 45px;
      padding: 69px 0 60px;
    }
  }
}

/* c-box-info
---------------------------------------------------------- */
/* c-box-info */
.c-box-info {
  margin-top: 43px;
  padding: 22px 0 24px 2px;
  border-top: 2px solid $color-black-01;
  border-bottom: 2px solid $color-black-01;

  @include mq-down(md) {
    margin-top: 44px;
    border-top: 1px solid $color-black-01;
    border-bottom: 1px solid $color-black-01;
  }

  @include mq-up(md) {
    display: flex;
  }

  @include mq-down(md) {
    padding-left: 27px;
    padding-right: 16px;

    @include mq-down(md) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  /* box-contact */
  > .box-contact {
    width: 100%;

    @include mq-up(md) {
      width: 50%;
      padding-right: 25px;
    }

    @include mq-down(md) {
      padding-left: 5px;
    }

    > .title {
      font-size: 1.4rem;
      font-weight: 700;
      color: $color-black-01;
    }

    > .customer {
      font-size: 1.4rem;
      font-weight: 700;
      color: $color-black-01;
      margin-top: 6px;

      @include mq-down(md) {
        margin-top: 3px;
      }
    }

    > .name {
      font-size: 1.4rem;
      line-height: 1.95;
      color: $color-black-01;
      margin-top: 3px;

      @include mq-down(md) {
        font-size: 1.2rem;
        line-height: 1.7;
        margin-top: 0;
      }
    }
  }

  .list-detail {
    margin-top: 5px;

    @include mq-down(md) {
      padding-right: 8px;
    }

    > .item {
      display: flex;
      line-height: 1.9;

      &:not(:first-child) {
        margin-top: 2px;

        @include mq-down(md) {
          margin-top: 0;
        }
      }

      > .title {
        display: flex;
        justify-content: space-between;
        width: 80px;
        font-size: 1.4rem;
        flex: 1 0 auto;

        @include mq-down(md) {
          width: 60px;
          font-size: 1.2rem;
        }

        &::after {
          position: relative;
          top: -1px;
          content: '：';
          font-size: 1.4rem;

          @include mq-down(md) {
            font-size: 1.2rem;
          }
        }
      }

      > .text {
        width: 100%;
        font-size: 1.4rem;

        @include mq-down(md) {
          font-size: 1.2rem;
        }
      }

      a {
        color: $color-blue-01;
        @include is-opacity;
        @include is-underline;
      }
    }
  }

  .box-sns {
    margin: 6px 0 0 -16px;
  }

  .box-product-detail {
    @include mq-up(md) {
      width: 50%;
    }
  }

  .list-product-detail {
    margin-top: 30px;

    @include mq-down(md) {
      margin-top: 7px;
    }

    > .item {
      display: flex;

      &:not(:first-child) {
        margin-top: 6px;

        @include mq-down(md) {
          margin-top: 4px;
        }
      }
    }

    .title {
      flex: 1 0 auto;
      width: 100px;

      @include mq-down(md) {
        width: 75px;
      }

      > span {
        display: block;
        font-size: 1.2rem;
        font-weight: 500;
        color: $white;
        text-align: center;
        border-radius: 3px;
        background-color: $color-gray-10;
        padding: 3px 5px 2px;

        @include mq-down(md) {
          font-size: 1.2rem;
          border-radius: 2px;
          padding: 1px 5px;
        }
      }
    }

    .text {
      width: 100%;
      font-size: 1.4rem;
      line-height: 1.7;
      color: $color-black-01;
      padding-left: 10px;
      letter-spacing: -.05rem;

      @include mq-down(md) {
        font-size: 1.2rem;
        padding-left: 7px;
      }
    }
  }
}

/* c-box-green
---------------------------------------------------------- */
.c-box-green {
  padding: 48px 52px 38px;
  background-color: $color-lightgreen-01;
  border-radius: $border-radius-pc;

  @include mq-down(md) {
    padding: 35px 20px 26px 25px;
    border-radius: 13px;
  }
}

/* c-box-line
---------------------------------------------------------- */
.c-box-line {
  margin-top: 70px;
  padding: 42px 0 43px 3px;
  border-top: 2px solid $color-black-01;
  border-bottom: 2px solid $color-black-01;

  @include mq-down(md) {
    margin-top: 44px;
    padding: 31px 0 30px 7px;
    border-top: 1px solid $color-black-01;
    border-bottom: 1px solid $color-black-01;
  }
}

/* c-box-link
---------------------------------------------------------- */
.c-box-link  {
  margin-top: 74px;
  padding: 41px 0 42px 2px;

  .title {
    font-size: 1.4rem;
    font-weight: 700;
    margin-bottom: 8px;
  }
}

/* c-box-detail-info
---------------------------------------------------------- */
.c-box-detail-info {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .c-tag {
    position: relative;
    top: -4px;

    @include mq-down(md) {
      top: -7px;
    }
  }

  margin-bottom: 15px;

  @include mq-down(md) {
    margin-bottom: 26px;
  }
}

/* c-box-sns
---------------------------------------------------------- */
.c-box-sns  {
  &.-right {
    text-align: right;
  }

  .twitter {
    position: relative;
    top: -1px;

    img {
      height: 21px;
    }
  }
}

/* c-box-speaker
---------------------------------------------------------- */
.c-box-speaker  {
  display: flex;
  margin-top: 68px;
  padding: 57px 50px 36px 60px;

  @include mq-down(md) {
    flex-wrap: wrap;
    margin-top: 33px;
    padding: 45px 23px 36px 23px;
  }

  > .box-detail {
    padding-right: 50px;

    @include mq-up(md) {
      flex: 1 0 auto;
      width: 514px;
    }

    @include mq-down(md) {
      width: 100%;
    }

    .c-heading-04 {
      margin-bottom: 5px;

      @include mq-down(md) {
        margin-bottom: 14px;
      }
    }

    p {
      line-height: 2;

      @include mq-down(md) {
        font-size: 1.2rem;
        line-height: 1.7;
      }
    }

    ul {
      margin-top: 4px;

      @include mq-down(md) {
        margin-top: 0;
      }

      li:not(:first-child) {
        margin-top: 9px;

        @include mq-down(md) {
          margin-top: 0;
        }
      }

      li {
        @include mq-down(md) {
          font-size: 1.2rem;
          line-height: 1.7;
          padding-left: 12px;
        }

        &::before {
          @include mq-down(md) {
            width: 12px;
          }
        }
      }
    }
  }

  > .box-image {
    width: 100%;

    @include mq-down(md) {
      margin-top: 26px;
    }

    .image {
      margin-top: 9px;
      clip-path: content-box;

      @include mq-down(md) {
        width: 150px;
        margin: 0 auto;
      }

      img {
        width: 100%;
        border-radius: 200px;
      }
    }

    .caption {
      font-size: 2rem;
      text-align: center;

      @include mq-down(md) {
        font-size: 1.4rem;
        margin-top: 10px;
      }

      > span {
        display: block;
        font-size: 1.2rem;
        margin-top: 8px;
      }
    }
  }
}

/* c-box-post-relate
---------------------------------------------------------- */
.c-box-post-relate {
  padding-top: 128px;

  @include mq-down(md) {
    padding-top: 132px;
  }

  .c-heading-03 {
    @include mq-up(md) {
      margin-bottom: 45px;
    }
  }

  > ul > li {
    @include mq-up(md) {
      width: 50% !important;
    }
  }

  .c-list-know {
    > .item {
      @include mq-down(md) {
        padding-left: 24px;
        padding-right: 24px;
      }

      &:not(:first-child) {
        @include mq-down(md) {
          margin-top: 45px;
        }
      }
    }
  }
}

/* c-box-youtube
---------------------------------------------------------- */
.c-box-youtube {
  .c-heading-04 {
    margin-bottom: 15px;
  }

  .video {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;

    >  iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

* + .c-box-youtube {
  margin-top: 203px;

  @include mq-down(md) {
    margin-top: 82px;
  }
}

/* c-box-flow
---------------------------------------------------------- */
.c-box-flow {
  border-radius: $border-radius-pc;
  background-color: $color-lightgreen-01;

  @include mq-up(md) {
    display: flex;
    align-items: center;
    min-height: 120px;
    padding-top: 37px;
    padding-bottom: 27px;
  }

  @include mq-down(md) {
    display: flex;
    flex-direction: column;
    border-radius: $border-radius-sp;
    padding: 25px 15px 32px;

  }

  > .title {
    font-size: 1.8rem;
    font-weight: 700;
    color: $color-green-01;

    @include mq-up(md) {
      width: 250px;
      flex: 1 0 auto;
      text-align: center;
      padding: 0px 36px 0 48px;
    }

    @include mq-down(md) {
      font-size: 1.6rem;
      text-align: center;
    }
  }

  > .desc {
    width: 100%;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.8;
    color: $color-black-01;

    @include mq-up(md) {
      position: relative;
      padding-left: 39px;
      padding: 0 35px 0 38px;

      &::after {
        content: '';
        position: absolute;
        top: 48%;
        left: 0;
        transform: translateY(-50%);
        width: 1px;
        height: calc(100% + 13px);
        min-height: 69px;
        background-color: $color-green-01;
      }
    }

    @include mq-down(md) {
      font-size: 1.4rem;
      line-height: 2;
      margin-top: 4px;
    }

    a {
      @include is-opacity;
    }
  }
}

/* c-box-faq
---------------------------------------------------------- */
.c-box-faq {
  position: relative;
  border-top: 1px dotted $color-gray-10;

  .box-text {
    position: relative;

    @include mq-up(md) {
      display: flex;
      align-items: baseline;
    }
  }

  .text-cmn {
    font-size: 1.8rem;

    @include mq-down(md) {
      display: block;
      font-size: 1.6rem;
      margin-top: 4px;
    }
  }

  .box-question {
    display: block;
    position: relative;
    padding: 21px 0 16px 0;
    cursor: pointer;
    @include is-opacity;

    @include mq-down(md) {
      padding: 25px 32px 17px 0;
    }

    &.is-open {
      &::before {
        background-image: url(/assets/images/common/ico_accordion_open_pc.png);

        @include mq-down(md) {
          background-image: url(/assets/images/common/ico_accordion_open.png);
        }
      }
    }

    &::before {
      position: absolute;
      content: '';
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      background-image: url(/assets/images/common/ico_accordion_close_pc.png);
      background-size: 24px 24px;
      background-repeat: no-repeat;

      @include mq-down(md) {
        top: 35px;
        right: 2px;
        width: 18px;
        height: 18px;
        background-image: url(/assets/images/common/ico_accordion_close.png);
        background-size: 18px 18px;
      }
    }

    .box-text {
      font-size: 2rem;
      font-weight: 700;
      padding-left: 52px;

      @include mq-down(md) {
        font-size: 1.6rem;
        padding-left: 36px;
      }

      &::before {
        content: '';
        position: absolute;
        display: block;
        top: -5px;
        left: 4px;
        width: 36px;
        height: 36px;
        background-image: url(/assets/images/common/txt_faq_pc.png);
        background-size: 36px 36px;
        background-repeat: no-repeat;

        @include mq-down(md) {
          top: -4px;
          left: 0px;
          width: 28px;
          height: 27px;
          background-image: url(/assets/images/common/txt_faq_sp.png);
          background-size: 28px 27px;
        }
      }
    }
  }

  .box-answer {
    display: none;
    position: relative;
    padding: 0 20px 20px 53px;
    line-height: 1.85;

    @include mq-down(md) {
      padding: 0px 10px 19px 36px
    }

    &::before {
      position: absolute;
      content: '';
      top: 0;
      right: 20px;
      width: calc(100% - 40px);
      height: 2px;

      @include mq-down(md) {
        height: 1px;
      }
    }
  }
}

/* c-box-form
---------------------------------------------------------- */
.c-box-form {
  border: 1px solid #b9b9b9;
  border-radius: $border-radius-pc;
  margin-top: 19px;
  padding: 42px 30px 37px;

  @include mq-down(md) {
    border-radius: $border-radius-sp;
    margin-top: 20px;
    padding: 23px 15px 37px;
  }

  .title {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 18px;

    @include mq-down(md) {
      font-size: 1.6rem;
      margin-bottom: 4px;
    }
  }

  ol,
  ul,
  p {
    font-size: 1.2rem;
  }

  ol {
    > li {
      font-weight: 600;
    }
  }

  ul {
    > li {
      font-weight: 400;
    }
  }

  p {
    line-height: 2.1;

    @include mq-down(md) {
      line-height: 1.7;
    }
  }

  strong {
    font-weight: 600;
  }
}

/* c-box-form-check
---------------------------------------------------------- */
.c-box-form-check {
  margin-top: 39px;
  margin-left: 54px;

  @include mq-down(md) {
    margin-top: 27px;
    margin-left: 7px;
  }

  .c-checkbox {
    input {
      &:checked {
        + label {
          &::after {
            @include mq-up(md) {
              top: 8px;
              left: 10px;
              width: 5px;
              height: 13px;
              border-width: 0 2px 2px 0;
            }

            @include mq-down(md) {
              top: 5px;
              left: 6px;
            }

            @include mq-down(sm) {
              top: 5px;
            }
          }
        }
      }
    }

    label {
      font-size: 2.4rem;
      font-weight: 700;

      @include mq-down(md) {
        font-size: 1.6rem;
        display: flex;
        align-items: flex-start;
      }

      &::before {
        @include mq-up(md) {
          padding: 11px;
          top: 4px;
          margin-right: 10px;
        }

        @include mq-down(md) {
          top: 3px;
        }
      }

      &::after {
        @include mq-down(md) {
          top: 5px;
        }
      }
    }
  }

  .text-note {
    font-weight: 700;
    margin-top: 11px;
    margin-left: 38px;

    @include mq-down(md) {
      font-size: 1.2rem;
      line-height: 1.7;
      margin-top: 4px;
      margin-left: 20px;
    }
  }
}

@media not all and (min-resolution:.001dpcm){
  @supports (-webkit-appearance:none) {
    .c-box-form-check .c-checkbox {
      input {
        &:checked {
          + label {
            &::after {
              top: 9px;

              @include mq-down(md) {
                top: 5px;
              }
            }
          }
        }
      }
    }
  }
}


/* c-box-form-external
---------------------------------------------------------- */
.c-box-form-external {
  display: none;
  margin-top: 35px;

  @include mq-down(md) {
    margin-top: 25px;
  }
}
