/* .-box-keyword-search
--------------------------------------------------------- */
.p-box-keyword-search {
  margin-top: 70px;

  .l-inner {
    .c-box-keyword-search {
      padding: 44px 50px 30px;

      @include mq-down(md) {
        padding: 33px 20px 40px;
      }

      .c-btn-center {
        display: none;

        @include mq-down(md) {
          display: block;
          margin-top: 30px;
        }

        .c-btn {
          position: relative;
        }

        span {
          &::after {
            display: none;
          }
        }
      }
    }
  }

  .heading {
    text-align: center;
    letter-spacing: .1em;
    font: {
      size: 2.8rem;
    }
    margin: {
      bottom: 34px;
    }

    @include mq-down(md) {
      letter-spacing: normal;
      margin: {
        bottom: 23px;
      }
    }
  }
}

.c-list-search-keyword {
  text-align: center;
  margin: {
    left: -13px;
    right: -13px;
  }

  @include mq-down(md) {
    text-align: left;

    margin: {
      left: 0;
      right: 0;
    }
  }

  &.-is-hidden {
    @include mq-down(md) {
      display: none;
    }
  }

  > .item {
    position: relative;
    display: inline-flex;
    line-height: 1.8;
    background: $white;
    margin: 0 13px 20px;
    border-radius: 20px;

    @include mq-down(md) {
      margin: 0 13px 10px 0;
    }

    &:first-of-type {
      input[type='radio'] {
        & + span {
          @include mq-down(md) {
            padding: 6px 20px 6px 50px;
          }
        }
      }
    }

    &:hover{
      span {
        &::before {
          width: calc(100% - 4px);
          height: calc(100% - 4px);
          border-radius: 20px;
          border: 2px solid $color-green-01;
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
        }
      }
    }

    &.is-active {
      span {
        box-shadow: unset !important;

        &::before {
          width: calc(100% - 4px);
          height: calc(100% - 4px);
          border-radius: 20px;
          border: 2px solid $color-green-01;
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
        }

        &::after {
          background: url(/assets/images/ico_radio_active.png) center center no-repeat !important;
        }
      }
    }

    span {
      transition: all .3s cubic-bezier(.420, .000, .3, 1.000);
    }

    input[type='radio'] {
      width: 0;
      opacity: 0;

      & + span {
        font-size: 1.8rem;
        display: inline-block;
        padding: 4px 20px 4px 50px;
        cursor: pointer;
        position: relative;
        border-radius: 20px;
        box-shadow: 0 6px 16.92px 1.08px rgba(51, 51, 51, .3);

        @include mq-down(md) {
          font-size: 1.6rem;
          padding: 6px 20px 6px 53px;
        }

        &::before,
        &::after {
          content: '';
        }

        &::after {
          position: absolute;
          top: 50%;
          left: 20px;
          transform: translateY(-50%);
          display: block;
          width: 20px;
          height: 20px;
          content: '';
          background: url(/assets/images/ico_radio.png) center center no-repeat;
          background-size: 20px;
        }
      }

      &.-is-active,
      &:focus,
      &:checked {
        & + span {
          box-shadow: unset;

          &::before {
            width: calc(100% - 4px);
            height: calc(100% - 4px);
            border-radius: 20px;
            border: 2px solid $color-green-01;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
          }

          &::after {
            background: url(/assets/images/ico_radio_active.png) center center no-repeat;
          }
        }
      }
    }

    input[type='radio'].focus-visible + span {
      outline: 2px solid $color-main;
    }
  }
}