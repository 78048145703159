
/* c-heading-line
---------------------------------------------------------- */
.c-heading-line {
  display: flex;
  align-items: baseline;
  font-size: 2.2rem;
  letter-spacing: .2rem;
  padding-bottom: 5px;
  border-bottom: 1px dotted $color-gray-04;

  @include mq-down(md) {
    font-size: 1.6rem;
    padding-bottom: 1px;
  }

  > span {
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: .1rem;
    color: $color-gray-02;
    margin-left: 18px;

    @include mq-down(md) {
      font-size: 1rem;
    }
  }
}

/* c-heading-01
---------------------------------------------------------- */
.c-heading-01 {
  font-size: 3.4rem;
  color: $color-gray-01;
  line-height: 1.45;
  letter-spacing: .1rem;
  margin-bottom: 35px;

  @include mq-down(md) {
    font-size: 2.2rem;
    margin-bottom: 21px;
  }

  .en,
  .date {
    display: block;
    font-size: 1.4rem;
    font-weight: 600;
    color: $color-green-01;

    @include mq-down(md) {
      font-size: 1rem;
      margin-top: 2px;
    }
  }

  .writer {
    display: block;
    font-size: 1.6rem;
    font-weight: 700;

    @include mq-down(md) {
      font-size: 1.4rem;
    }
  }

  * + .writer {
    margin-top: 11px;

    @include mq-down(md) {
      margin-top: 8px;
    }
  }

  &.-line {
    font-size: 2.4rem;
    font-weight: 700;
    border-top: 1px dotted $color-gray-10;
    border-bottom: 1px dotted $color-gray-10;
    padding: 66px 0 43px;
    margin-bottom: 20px;

    @include mq-down(md) {
      font-size: 1.6rem;
      padding: 60px 0 57px;
    }

    .date {
      margin-bottom: 16px;

      @include mq-down(md) {
        font-size: 1.2rem;
        margin-bottom: 10px;
      }
    }

    .en {
      margin-top: 6px;

      @include mq-down(md) {
        font-size: 1.2rem;
      }
    }

    .sub-title {
      font-weight: 700;
    }
  }

  &.-lg {
    @include mq-up(md) {
      font-size: 4rem;
    }
  
  }
}

/* c-heading-02
---------------------------------------------------------- */
.c-heading-02 {
  font-size: 3rem;
  line-height: 1.35;
  letter-spacing: .2em;
  margin: 84px 0 108px;
  font-weight: bold;

  @include mq-down(md) {
    font-size: 2.2rem;
    margin: 70px 0 80px;
    text-align: center;
  }

  .en {
    display: block;
    font-size: 1.2rem;
    letter-spacing: .06em;
    font-weight: bold;
    font-family: $font-roboto;

    @include mq-down(md) {
      font-size: 1rem;
      margin-top: 2px;
    }
  }

  &.-line {
    padding-bottom: 97px;
    border-bottom: 1px dotted $color-gray-10;

    @include mq-down(md) {
      padding-bottom: 80px;
      margin-bottom: 58px;
    }
  }
}

/* c-heading-03
---------------------------------------------------------- */
.c-heading-03 {
  font-size: 3rem;
  line-height: 1.45;
  letter-spacing: .15rem;
  margin-bottom: 82px;
  font-weight: bold;
  text-align: center;

  @include mq-down(md) {
    font-size: 2.2rem;
    margin-bottom: 40px;
  }

  .en {
    display: block;
    font-size: 1.2rem;
    letter-spacing: .06em;
    font-weight: bold;
    font-family: $font-roboto;
    color: #25253e;

    @include mq-down(md) {
      font-size: 1rem;
      margin-top: 2px;
    }
  }

  .sub {
    display: block;
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: 0;
    color: $color-green-01;
    margin-top: 6px;

    @include mq-down(md) {
      font-size: 1.4rem;
    }
  }

  &.-line {
    border-top: 1px dotted $color-gray-10;
    border-bottom: 1px dotted $color-gray-10;
    padding: 56px 0 46px;
    margin-bottom: 74px;

    @include mq-down(md) {
      padding: 47px 0 40px;
      margin-bottom: 35px;
    }
  }
}

/* c-heading-04
---------------------------------------------------------- */
.c-heading-04 {
  font-size: 2.4rem;
  font-weight: 700;
  margin-bottom: 12px;

  @include mq-down(md) {
    font-size: 1.6rem;
    margin-bottom: 21px;
  }

  &.-line {
    margin-bottom: 38px;
    padding-bottom: 7px;
    border-bottom: 1px dotted $color-gray-10;

    @include mq-down(md) {
      margin-bottom: 28px;
    }
  }

  &.-lg {
    font-size: 3rem;

    @include mq-down(md) {
      font-size: 2.2rem;
    }
  }

  &.-center {
    text-align: center;
  }
}

/* c-heading-05
---------------------------------------------------------- */
.c-heading-05 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3.4rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 12px;

  @include mq-down(md) {
    font-size: 2.2rem;
    margin-bottom: 15px;
  }
  
  &::before {
    background: url(/assets/images/common/ico_line_left_01.png) no-repeat;
    margin-right: 15px;

    @include mq-down(md) {
      top: -2px;
      margin-right: 18px;
    }
  }

  &::after {
    background: url(/assets/images/common/ico_line_right_01.png)  no-repeat;
    margin-left: 15px;

    @include mq-down(md) {
      margin-left: 18px;
    }
  }

  &::before,
  &::after {
    content: '';
    width: 42px;
    height: 42px;
    background-size: contain;

    @include mq-down(md) {
      width: 30px;
      height: 30px;
      position: relative;
    }
  }

  &.-sm {
    @include mq-up(md) {
      font-size: 3rem;
    }

    &::before,
    &::after {
      @include mq-up(md) {
        width: 31px;
        height: 31px;
      }
    }
  }

  &.-line {
    &::before {
      margin-right: 23px;

      @include mq-down(md) {
        margin-right: 14px;
      }
    }
  
    &::after {
      margin-left: 23px;

      @include mq-down(md) {
        margin-left: 14px;
      }
    }
  
    &::before,
    &::after {
      content: '';
      width: 60px;
      height: 4px;
      background: none;
      background-color: $black;

      @include mq-down(md) {
        width: 38px;
        height: 2px;
      }
    }
  }
}

/* c-heading-icon
---------------------------------------------------------- */
.c-heading-icon {
  display: flex;
  align-items: center;

  > .icon {
    width: 57px;
    flex: 1 0 auto;

    @include mq-down(md) {
      width: 38px;
      top: 3px;
      left: 2px;
    }

    img {
      width: 57px;

      @include mq-down(md) {
        width: 38px;
      }
    }
  }

  > .title {
    width: 100%;
    font-size: 2.4rem;
    font-weight: 700;
    margin-left: 16px;

    @include mq-down(md) {
      font-size: 1.6rem;
      line-height: 1.6;
      margin-left: 8px;
    }
  }
}