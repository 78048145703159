/* p-seminar-form
--------------------------------------------------------- */
.p-seminar-form {
  .c-heading-04 {
    margin-top: 30px;
    margin-bottom: 10px;

    @include mq-down(md) {
      margin-top: 16px;
      margin-bottom: 5px;
    }
  }

  .text-desc {
    font-weight: 700;

    @include mq-down(md) {
      font-size: 1.2rem;
    }
  }
}
