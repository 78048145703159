
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes progress {
  0% {
    transform: translateX(-100%);
  }
  21% {
    transform:  translateX(0%);
  }
  27% {
    transform:  translateX(0%);
  }
  48% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}