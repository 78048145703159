.c-breadcrumb {
  display: flex;
  padding-left: 15px;

  @include mq-down(md) {
    display: none;
  }

  > .item {
    position: relative;
    color: $color-gray-11;
    font-size: 1.1rem;

    &:not(:last-child) {
      margin-right: 20px;

      &::after {
        width: 10px;
        height: 14px;
        content: '>';
        display: block;
        position: absolute;
        top: 0;
        right: -14px;
        color: $color-gray-11;
      }
    }

    > a {
      display: block;
      color: #808080;
      @include is-opacity();
    }
  }
}
