/* p-download
---------------------------------------------------------- */
.p-download {
  padding-top: 37px;
  padding-bottom: 95px;
  position: relative;

  @include mq-down(md) {
    padding-top: 35px;
    padding-bottom: 40px;
  }

  > .box-title {
    display: flex;

    > .icon {
      width: 57px;
      flex: 1 0 auto;
      position: relative;
      top: 4px;
      left: 4px;

      @include mq-down(md) {
        width: 38px;
        top: 3px;
        left: 2px;
      }

      img {
        width: 57px;

        @include mq-down(md) {
          width: 38px;
        }
      }
    }

    > .title {
      width: 100%;
      font-size: 2.4rem;
      font-weight: 700;
      margin-left: 18px;

      @include mq-down(md) {
        font-size: 1.6rem;
        line-height: 1.6;
        margin-left: 10px;
      }
    }
  } 

  .list-download {
    margin-top: -80px;

    @include mq-up(md) {
      display: flex;
      flex-wrap: wrap;
      margin: -137px -29px 0;
    }

    &-wrap {
      padding: 93px 66px 123px;
      margin-top: 45px;
      background-color: $color-gray-08;
      border-radius: $border-radius-pc;

      @include mq-down(md) {
        margin-top: 11px;
        padding: 40px 39px 68px;
        border-right: $border-radius-sp;
      }
    }

    > .item {
      margin-top: 80px;

      @include mq-up(md) {
        width: 33.33%;
        padding: 0 29px;
        margin-top: 137px;
      }
    }
  }

  .card-download {
    padding-right: 4px;

    @include mq-down(md) {
      padding-right: 0;
    }

    > .box-detail {
      margin-top: 13px;

      @include mq-down(md) {
        margin-top: 17px;
      }

      > .text {
        line-height: 1.7;
        text-align: center;
      }

      > .c-btn {
        margin-top: 15px;

        @include mq-down(md) {
          width: calc(100% - 10px);
          margin-top: 10px;
        }
      }
    }

    > .title {
      font-size: 2rem;
      text-align: center;
      margin-bottom: 22px;

      @include mq-down(md) {
        font-size: 1.6rem;
      }
    }

    > .image {
      position: relative;
      border-radius: $border-radius-pc;
      overflow: hidden;
      clip-path: content-box;

      @include mq-up(md) {
        height: 310px;
      }

      @include mq-down(md) {
        border-radius: $border-radius-sp;
      }

      > * {
        @include mq-up(md) {
          height: 310px;
          border-radius: $border-radius-pc;
        }
      }

      img {
        width: 100%;
      }
    }
  }
}

/* p-request-form
---------------------------------------------------------- */
.p-request-form {
  padding-top: 44px;

  @include mq-down(md) {
    padding-top: 20px;
  }

  .c-heading-04 {
    line-height: 1.55;
    margin-top: 5px;

    @include mq-down(md) {
      margin-bottom: 18px;
    }
  }

  .text-desc {
    font-weight: 700;

    @include mq-down(md) {
      font-size: 1.2rem;
    }
  }
}