$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 25 !default;

@function slick-image-url($url) {
  @if function-exists(image-url) {
    @return image-url($url);
  }

  @else {
    @return url($slick-loader-path + $url);
  }
}

@function slick-font-url($url) {
  @if function-exists(font-url) {
    @return font-url($url);
  }

  @else {
    @return url($slick-font-path + $url);
  }
}

/* Slider */

/* Icons */
@if $slick-font-family=="slick" {
  @font-face {
    font-family: "slick";
    src: slick-font-url("slick.eot");
    src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
    font-weight: normal;
    font-style: normal;
  }
}

/* Arrows */

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 32px;
  width: 32px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  z-index: 2;

  @include mq-down(md) {
    top: 53%;
    width: 22px;
    height: 22px;
  }

  &:hover,
  &:focus {
    outline: none;
  }

  &:hover {
    @include is-opacity;
  }

  &.slick-disabled::before {
    opacity: $slick-opacity-not-active;
  }

  &::before {
    display: block;
    content: '';
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-size: 32px 32px;

    @include mq-down(md) {
      width: 22px;
      height: 22px;
      background-size: 22px 22px;
    }
  }
}

.slick-prev {
  left: 65px;

  @include mq-down(md) {
    left: 9px;
  }

  &::before {
    background-image: url(/assets/images/common/ico_arrow_black_left.png);
  }
}

.slick-next {
  right: 65px;

  @include mq-down(md) {
    right: 9px;
  }

  &::before {
    background-image: url(/assets/images/common/ico_arrow_black.png);
  }
}

/* Dots */

.slick-dotted.slick-slider {
  margin-bottom: 50px;

  @include mq-down(md) {
    margin-bottom: 22px;
  }
}

.slick-dots {
  position: absolute;
  bottom: -40px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;

  @include mq-down(md) {
    bottom: -25px;
  }

  li {
    position: relative;
    display: inline-block;
    height: 12px;
    width: 12px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;

    @include mq-down(md) {
      width: 10px;
      height: 10px;
      margin: 0 4px;
    }

    button {
      display: block;
      height: 12px;
      width: 12px;
      background-color: $color-gray-01;
      outline: none;
      cursor: pointer;
      border-radius: 20px;
      transition: background-color .3s ease-out;
      margin: 0;

      @include mq-down(md) {
        width: 10px;
        height: 10px;
      }

      &:hover {
        background-color: $color-green-01;
      }

      &:hover,
      &:focus {
        outline: none;
      }

      &::before {
        display: none;
      }
    }

    &.slick-active button {
      width: 12px;
      height: 12px;
      background-color: $color-green-01;

      @include mq-down(md) {
        width: 10px;
        height: 10px;
      }
    }
  }
}
