/* p-list-case
---------------------------------------------------------- */
.p-list-case {
  display: flex;
  flex-wrap: wrap;
  margin: -88px -25px 0;

  @include mq-down(md) {
    margin: -55px -15px 0;
  }

  > .item {
    margin-top: 88px;
    flex: 0 0 auto;
    width: 33.33%;
    padding: 0 25px;

    @include mq-down(md) {
      margin-top: 55px;
      padding: 0 15px;
      width: 100%;
    }

    .link {
      display: block;
      @include is-img;
    }

    .image {
      position: relative;
      border-radius: 2px;
      overflow: hidden;
      clip-path: content-box;

      @include mq-up(md) {
        height: 220px;
      }

      > * {
        @include mq-up(md) {
          height: 220px;
          border-radius: 2px;
        }
      }

      @include mq-down(md) {
        width: 100%;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 40px;
        height: 40px;
        background-image: url(/assets/images/common/ico_arrow_right_01.png);
        background-size: 7px 12px;
        background-repeat: no-repeat;
        background-color: $color-green-01;
        background-position: center;
      }

      img {
        width: 100%;
      }
    }

    .details {
      .customer-name {
        margin-top: 18px;
        margin-bottom: 10px;
        font-size: 1.8rem;
        font-weight: bold;

        @include mq-down(md) {
          margin-top: 14px;
          margin-bottom: 5px;
          font-size: 1.6rem;
        }
      }

      .title {
        font-size: 1.2rem;
      }

      .list {
        font-size: 1.2rem;

        .item {
          display: flex;

          .head {
            position: relative;
            padding-right: 10px;

            &::after {
              position: absolute;
              content: ':';
              right: 2px;
            }
          }
        }
      }
    }
  }
}

.p-section-case {
  margin-top: 108px;

  @include mq-down(md) {
    margin-top: 55px;
  }
}

.p-heading-case-01 {
  margin-bottom: 48px;

  @include mq-down(md) {
    margin-bottom: 45px;
  }
}
