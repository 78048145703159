@mixin width1200 {
  margin: 0 auto;
  padding-left: 100px;
  padding-right: 100px;

  @include mq-up(md) {
    width: 1400px;
    min-width: 1400px;
  }

  @include mq-down(md) {
    padding: 0 15px;
  }
}

@mixin width970 {
  @include mq-up(md) {
    width: 970px;
    margin: 0 auto;
  }
}

@mixin minWidth1200 {
  @include mq-up(md) {
    min-width: 1400px;
  }
}