/* p-knowhow-pickup
---------------------------------------------------------- */
.p-knowhow-pickup {
  margin-top: 82px;

  @include mq-down(md) {
    margin-top: 58px;
  }

  .c-heading-03 {
    margin-bottom: 24px;

    @include mq-down(md) {
      margin-bottom: 28px;
    }
  }

  .box-knowhow {
    padding: 37px 22px 32px;
    border-radius: 14px;
    background-color: $color-lightgreen-01;

    @include mq-up(md) {
      padding: 48px 50px 52px;
      margin-left: -50px;
      margin-right: -50px;
      border-radius: 8px;
    }
  }
}

/* p-knowhow-new
---------------------------------------------------------- */
.p-knowhow-new {
  margin-top: 82px;

  @include mq-down(md) {
    margin-top: 80px;
  }

  .c-heading-03 {
    margin-bottom: 24px;

    @include mq-down(md) {
      margin-bottom: 28px;
    }
  }

  .box-knowhow {
    padding: 0 22px 32px;
    border-radius: 14px;

    @include mq-up(md) {
      margin-left: -50px;
      margin-right: -50px;
      padding: 48px 50px 52px;
      border-radius: 8px;
      background-color: $color-gray-08;
    }
  }

  .c-list-pagination {
    padding: 42px 20px 38px;
    margin-top: 100px;
    background-color: $white;
    border-radius: $border-radius-pc;

    @include mq-down(md) {
      padding: 31px 10px 32px;
      margin-top: 56px;
      background-color: $color-lightgreen-01;
      border-radius: 15px;
      margin-left: -22px;
      margin-right: -22px;
    }
  }
}