/* p-seminar-detail
--------------------------------------------------------- */
.p-seminar-detail {
  /* box-detail */
  .box-detail {
    margin-top: 15px;

    @include mq-down(md) {
      margin-top: 12px;
    }

    .mainimg {
      & + * {
        margin-top: 37px;

        @include mq-down(md) {
          margin-top: 24px;
        }
      }
    }

    p {
      line-height: 2.375;

      @include mq-down(md) {
        line-height: 2;
      }

      & + p {
        margin-top: 37px;
      }
    }

    a{
      color: $color-blue-01;
      @include is-opacity;
      @include is-underline; 
    }
  }

  /* box-seminar-date */
  .box-seminar-date {
    margin-top: 45px;

    @include mq-down(md) {
      margin-top: 27px;
    }

    .c-btn-center {
      margin-top: 27px;

      @include mq-down(md) {
        margin-top: 21px;
        margin-left: -11px;
      }
    }
  }

  /* box-seminar-recommended */
  .box-seminar-recommended {
    margin-top: 90px;

    @include mq-down(md) {
      margin-top: 35px;
    }

    .c-heading-04 {
      @include mq-down(md) {
        margin-bottom: 11px;
      }
    }
  }
}

/* p-seminar-timeline
--------------------------------------------------------- */
.p-seminar-timeline {
  padding-top: 117px;
  padding-bottom: 18px;

  @include mq-down(md) {
    padding-top: 72px;
  }

  .box-link {
    .title {
      font-size: 1.4rem;
      font-weight: 700;
      margin-bottom: 5px;

      @include mq-down(md) {
        margin-bottom: 2px;
      }
    }
  }

  .c-box-button {
    @include mq-down(md) {
      margin-top: 30px;
    }
  }

  .c-box-line {
    margin-top: 0;
  }

  * + .c-box-line {
    margin-top: 70px;

    @include mq-down(md) {
      margin-top: 44px;
    }
  }
}

