.p-branch {
  > .c-heading-03 {
    margin-bottom: 60px;

    @include mq-down(md) {
      margin-bottom: 40px;
    }
  }
}

/* p-branch-intro
---------------------------------------------------------- */
.p-branch-intro {
  padding-top: 75px;
  padding-bottom: 143px;
  position: relative;

  @include mq-down(md) {
    padding-top: 50px;
    padding-bottom: 54px;
  }

  &.-kyusyu {
    @include mq-up(md) {
      padding-bottom: 310px;
    }
  }

  &.-hokkaido {
    @include mq-up(md) {
      padding-bottom: 295px;
    }
  }

  > .title {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 18px;

    @include mq-down(md) {
      font-size: 1.6rem;
      margin-bottom: 8px;
    }
  }

  > .text {
    color: $color-gray-01;
    line-height: 2.375;

    @include mq-up(md) {
      width: 790px;
    }

    @include mq-down(md) {
      line-height: 2;
    }
  }

  > .image {
    position: absolute;
    top: 0;
    right: -100px;
    z-index: -1;

    @include mq-down(md) {
      right: -15px;
    }
  } 

  .list-gallery {
    @include mq-up(md) {
      display: flex;
      flex-wrap: wrap;
      margin: -40px -15px 0;
    }

    &-wrap {
      margin-top: 50px;

      @include mq-down(md) {
        margin-top: 40px;
      }
    }

    > .item {
      margin-top: 15px;

      @include mq-up(md) {
        width: 33.33%;
        padding: 0 15px;
        margin-top: 40px;
      }
    }

    .image {
      position: relative;
      border-radius: $border-radius-pc;
      overflow: hidden;
      clip-path: content-box;

      @include mq-up(md) {
        height: 270px;
      }

      @include mq-down(md) {
        border-radius: $border-radius-sp;
      }

      > * {
        @include mq-up(md) {
          height: 270px;
          border-radius: $border-radius-pc;
        }
      }

      img {
        width: 100%;
      }
    }
  }
}

/* p-branch-tour
---------------------------------------------------------- */
.p-branch-tour {
  .c-heading-03 {
    &.-hokkaido {
      @include mq-up(md) {
        line-height: 2;
        padding: 50px 0 40px;
      }
    }
  }

  > .box-title {
    text-align: center;

    .c-heading-05 {
      &.-hokkaido {
        @include mq-down(md) {
          letter-spacing: -.14rem;
        }
      }
    }

    > .sub-title {
      font-weight: 700;
      line-height: 1.3;

      @include mq-down(md) {
        font-size: 1.2rem;
        line-height: 1.9;
      }
    }
  }

  .list-tour-wrap + .box-title {
    margin-top: 45px;
  }

  > .box-note {
    margin-top: 27px;

    @include mq-down(md) {
      margin-top: 20px;
    }

    > .text {
      font-size: 2rem;
      font-weight: 700;
      line-height: 3;
      padding-bottom: 2px;
      text-align: center;

      @include mq-down(md) {
        font-size: 1.4rem;
        line-height: 2.5;
      }
    }
  }
  
  .c-list-note {
    > .item {
      @include mq-down(md) {
        margin-top: 7px;
        justify-content: center;
      }
    }
  }

  .list-tour {
    @include mq-up(md) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: -40px -15px 0;
    }

    &-wrap {
      margin-top: 56px;

      @include mq-down(md) {
        margin-top: 62px;
      }
    }

    &.-center {
      @include mq-up(md) {
        justify-content: center;
      }
    }

    > .item {
      margin-top: 24px;

      @include mq-up(md) {
        width: 33.33%;
        padding: 0 15px;
        margin-top: 40px;
      }

      .link {
        display: block;
        @include is-img;
        overflow: hidden;
      }
    }

    .image {
      position: relative;
      border-radius: $border-radius-pc;
      overflow: hidden;
      clip-path: content-box;

      @include mq-up(md) {
        height: 270px;
      }

      @include mq-down(md) {
        border-radius: $border-radius-sp;
      }

      > * {
        @include mq-up(md) {
          height: 270px;
          border-radius: $border-radius-pc;
        }
      }

      img {
        width: 100%;
      }
    }

    .box-detail {
      margin-top: 18px;

      @include mq-down(md) {
        margin-top: 6px;
      }

      > .title {
        font-size: 2rem;
        font-weight: 700;
        color: $color-green-01;

        @include mq-down(md) {
          font-size: 1.4rem;
        }
      }

      > .text {
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 1.8;
        color: $color-gray-01;
      }
    }
  }

  > .c-box-button {
    width: 100%;
    margin-top: 94px;
    padding: 50px 0 55px;

    @include mq-down(md) {
      margin-top: 28px;
      padding: 26px 0 28px;
    }

    .c-btn {
      @include mq-down(md) {
        width: calc(100% - 44px);
      }
    }

    > .title {
      font-size: 2rem;
      font-weight: 700;
      color: $color-gray-01;
      margin-bottom: 15px;

      @include mq-down(md) {
        font-size: 1.4rem;
        margin-bottom: 10px;
      }
    }
  }
}

/* p-branch-map
---------------------------------------------------------- */
.p-branch-map {
  padding-top: 122px;

  @include mq-down(md) {
    padding-top: 65px;
  }

  .sec-map {
    > .title {
      font-size: 3rem;
      font-weight: 700;
      line-height: 1.3;
      text-align: center;
  
      @include mq-down(md) {
        font-size: 2.2rem;
      }
    }
  
    > .text-desc {
      line-height: 1.9;
      text-align: center;
      color: #25253e;
  
      span {
        color: $color-green-01;
      }
    }
  
    > .box-map {
      margin-top: 40px;

      @include mq-down(md) {
        margin-top: 13px;
      }
  
      > iframe {
        width: 100%;
        height: 404px;

        @include mq-down(md) {
          height: 348px;
        }
      }
    }

    & + .sec-map {
      margin-top: 90px;

      @include mq-down(md) {
        margin-top: 50px;
      }
    }
  }
}


/* p-branch-customer
---------------------------------------------------------- */
.p-branch-customer {
  padding-top: 148px;

  @include mq-down(md) {
    padding-top: 70px;
  }

  .p-list-customers {
    border-bottom: none;
    padding-bottom: 50px;

    > .item {
      &:not(:first-child) {
        padding-top: 0;
        border-top: none;

        @include mq-down(md) {
          margin-top: 40px;
        }
      }
    }
  }
}