/* p-form
---------------------------------------------------------- */
.p-form {
  .box-info {
    padding: 41px 0 22px;

    @include mq-down(md) {
      padding: 46px 0 35px;
    }

    > .inner {
      padding-left: 74px;

      @include mq-down(md) {
        padding-left: 47px;
      }
    }
  }

  .c-heading-04 {
    line-height: 1.55;
    margin-top: 5px;
    margin-bottom: 4px;

    @include mq-down(md) {
      margin-bottom: 18px;
    }
  }

  .text-desc {
    font-weight: 700;
    line-height: 2.4;

    @include mq-down(md) {
      font-size: 1.2rem;
      margin-top: 4px;
    }
  }

  .text-tel {
    font-family: $font-roboto;
    font-weight: 500;
    font-size: 4rem;
    letter-spacing: .35rem;
    line-height: 1.45;

    @include mq-down(md) {
      font-size: 3.2rem;
      letter-spacing: .1rem;
      line-height: 1.1;
    }
  }

  .text-note {
    color: $color-gray-01;

    @include mq-down(md) {
      font-size: 1.2rem;
      margin-top: 8px;
    }
  }
}