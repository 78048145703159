/* p-service-intro
---------------------------------------------------------- */
.p-service-intro {
  border-top: 1px dotted $color-gray-10;
  padding-top: 76px;

  @include mq-down(md) {
    padding-top: 35px;
  }

  > .box-intro {
    position: relative;

    .detail {
      @include mq-up(md) {
        width: 700px;
      }

      > .title {
        font-size: 3rem;
        font-weight: 700;

        @include mq-down(md) {
          font-size: 1.6rem;
        }
      }

      > .text {
        line-height: 2.4;
        margin-top: 20px;

        @include mq-up(md) {
          width: 600px;
        }

        @include mq-down(md) {
          line-height: 2;
          margin-top: 9px;
        }
      }
    }

    .image {
      @include mq-up(md) {
        position: absolute;
        top: -4px;
        right: -2px;
      }

      @include mq-down(md) {
        width: calc(100% - 45px);
        text-align: center;
        margin: 30px auto 0;
      }
    }

    .c-list-button {
      margin-top: 40px;

      @include mq-down(md) {
        background-color: $color-lightgreen-01;
        padding: 36px 24px 37px;
        border-radius: 15px;
        margin-top: 36px;
      }
    }
  }
}

/* p-service-menu
---------------------------------------------------------- */
.p-service-menu {
  padding-top: 104px;

  @include mq-down(md) {
    padding-top: 43px;
  }

  .c-heading-03 {
    margin-bottom: 87px;
  }

  .c-box-button {
    width: 100%;
    margin-top: 70px;

    @include mq-down(md) {
      margin-top: 55px;
    }
  }
}

/* p-service-flow
---------------------------------------------------------- */
.p-service-flow {
  padding-top: 80px;

  @include mq-down(md) {
    padding-top: 50px;
  }

  .c-heading-03 {
    @include mq-up(md) {
      border-top: none;
      padding-bottom: 20px;
      margin-bottom: 64px;
    }
  }
}

/* p-service-faq
---------------------------------------------------------- */
.p-service-faq {
  padding-top: 64px;
  padding-bottom: 50px;

  @include mq-down(md) {
    padding-top: 50px;
    padding-bottom: 27px;
  }

  .c-heading-03 {
    @include mq-up(md) {
      border-top: none;
      padding-bottom: 20px;
      margin-bottom: 47px;
    }

    @include mq-down(md) {
      margin-bottom: 13px;
    }
  }
}