/* c-header
---------------------------------------------------------- */
.c-header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9;

  @include mq-up(md) {
    min-width: 1400px;
  }

  .header-in {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    position: relative;

    @include mq-up(md) {
      width: 1200px;
      margin: 0 auto;
      box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, .1);
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $white;
        opacity: .902;
      }
    }

    @include mq-down(md) {
      height: 65px;
      background-color: $white;
    }
  }

  .header-logo {
    position: relative;
    padding-left: 22px;
    padding-top: 24px;

    @include mq-down(md) {
      padding-left: 15px;
      padding-top: 0;
    }

    > .inner {
      display: flex;
      align-items: center;
    }

    .logo {
      width: 200px;

      @include mq-down(md) {
        width: 160px;
      }
    }

    .text-logo {
      display: block;
      font-size: 1.3rem;
      font-weight: 700;
      color: $color-green-01;
      letter-spacing: .1rem;
      margin-left: 33px;

      @include mq-down(md) {
        font-size: 1rem;
        line-height: 1;
        color: $color-gray-01;
        margin-left: 28px;
      }
    }

    img {
      width: 100%;
    }
  }
}

.header-nav {
  @include mq-up(md) {
    display: flex;
    align-items: center;
    padding-right: 190px;
  }

  @include mq-down(md) {
    position: fixed;
    display: block;
    width: 100%;
    height: 100% !important;
    top: 65px;
    left: 0;
    padding-bottom: 65px;
    cursor: default;
    opacity: 0;
    overflow-y: auto;
    visibility: hidden;
    transition: opacity .6s ease 0s;
    backface-visibility: hidden;
    background-color: $white;
    border-top: 1px solid #e8e8e8;
    z-index: 9;

    &.is-open {
      opacity: 1;
      visibility: visible;
      transition: opacity .6s ease 0s;
    }
  }

  .c-nav-global {
    display: flex;

    @include mq-down(md) {
      flex-wrap: wrap;
      border-bottom: 1px solid #e8e8e8;
    }
    
    .list-nav {
      @include mq-up(md) {
        display: flex;
        align-items: center;
  
        .item {
          position: relative;
          display: flex;
          align-items: center;
          padding: 0 16px;
          height: 100%;
          
          .link {
            position: relative;
            display: block;
            font-size: 1.4rem;
            font-weight: 600;
            line-height: 1.2;
            color: $color-gray-01;
            padding: 42px 0;
            transition: opacity .3s ease-out;

            &::before {
              content: '';
              position: absolute;
              left: -10px;
              bottom: 0;
              width: calc(100% + 20px);
              height: 3px;
              background-color: $color-green-01;
              transition: opacity .3s ease-out;
              opacity: 0;
              visibility: hidden;
            } 

            &:hover,
            &.is-active {
              &::before {
                opacity: 1;
                visibility: visible;
              } 
            }
          }
        }
      }
  
      @include mq-down(md) {
        width: 50%;
        
        &:nth-child(odd){
          @include mq-down(md) {
            border-left: 1px solid #e8e8e8;
  
            .link {
              padding: 14px 15px 13px;
            }
          }
        }
  
        &:nth-child(even){
          @include mq-down(md) {
            .item:last-child {
              border-bottom: none;
            }
            
            .link {
              padding: 14px 15px 13px;
            }
          }
        }

        &.-full {
          width: 100%;
          margin-bottom: -17px;
  
          @include mq-down(md) {
            margin-bottom: 0;
            border-left: none;
  
            .link-footer {
              padding: 14px 15px 13px;
            }
          }
        }

        .item {
          width: 100%;
          margin-top: 3px;

          @include mq-down(md) {
            margin-top: 0;
            border-bottom: 1px solid #e8e8e8;
          }
        }

        .link {
          @include mq-down(md) {
            display: block;
            padding-right: 0;
          }
    
          > span {
            @include mq-down(md) {
              font-size: 1.6rem;
              font-weight: 500;
              color: #707372;
            }
          }

          &[target="_blank"] {
            &::after {
              content: '';
              position: relative;
              display: inline-block;
              content: '';
              top: 2px;
              width: 13px;
              height: 16px;
              background-image: url(/assets/images/common/ico_note.png);
              background-repeat: no-repeat;
              background-size: 13px 16px;
              margin-left: 8px;
            }
          }
        }
      }
    }
  }

  .btn-contact {
    &-wrap {
      @include mq-up(md) {
        display: flex;
        width: 190px;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
      }
    }

    @include mq-up(md) {
      display: flex;
      width: 50%;
      height: 100%;
      align-items: center;
      text-align: center;
      justify-content: center;
      font-size: 1.2rem;
      font-weight: 700;
      color: $color-gray-03;
    
      @include is-opacity;

      &.-document {
        background-color: $color-yellow-02;
        letter-spacing: -1px;

        span {
          padding-top: 39px;
          margin-top: 17px;
          line-height: 1.2;

          &::before {
            width: 26px;
            height: 31px;
            top: 3px;
            background-image: url(/assets/images/common/ico_note_gray.png);
            background-size: contain;
          }
          
        }
      }

      &.-contact {
        background-color: $color-green-01;

        @include mq-up(md) {
          border-bottom-right-radius: 6px;
        }

        span {
          padding-top: 30px;
          margin-top: 21px;

          &::before {
            width: 29px;
            height: 21px;
            background-image: url(/assets/images/common/ico_mail.png);
            background-size: contain;
          }
        }
      }

      span {
        position: relative;
        padding-top: 28px;

        &::before {
          position: absolute;
          content: '';
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          background-repeat: no-repeat;
        }
      }
    }

    @include mq-down(md) {
      display: none;
    }
  }

  .btn-menu-close {
    @include mq-up(md) {
      display: none;
    }

    @include mq-down(md) {
      border-bottom: 1px solid $color-gray-01;
    }

    &-wrap {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.header-menu,
.btn-menu-close {
  @include mq-up(md) {
    display: none;
  }

  @include mq-down(md) {
    position: relative;
    width: 35px;
    height: 35px;
    border-radius: 37px;
    margin-right: 13px;
    background-color: $color-gray-01;
    cursor: pointer;

    .hamburger {
      position: relative;
      top: 10px;
      width: 17px;
      height: 22px;
      left: 9px;

      > span {
        display: block;
        position: absolute;
        left: 0;
        width: 17px;
        height: 2px;
        background-color: $white;
        z-index: 1;
    
        &:nth-child(1) {
          top: 0;
        }
    
        &:nth-child(2) {
          top: 6px;
        }
    
        &:nth-child(3) {
          top: 12px;
        }
      }
    }

    .text {
      position: absolute;
      bottom: 8px;
      left: 0;
      width: 100%;
      font-size: 1.2rem;
      font-weight: 400;
      text-align: center;
    }
    
    &.is-show {
      .hamburger {
        left: 8px;
        top: 8px;

        > span {
          width: 23px;

          &:nth-child(1) {
            top: 9px;
            transform: rotate(45deg);
            height: 2px;
            left: -2px;
          }
    
          &:nth-child(2) {
            opacity: 0;
          }
    
          &:nth-child(3) {
            top: 9px;
            transform: rotate(-45deg);
            height: 2px;
            left: -2px;
          }
        }
      }
    }
  }
}

@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    .c-nav-global .inner {
      @include mq-down(md) {
        padding-bottom: 160px;
      }
    }
  }
}
