/* c-link-title
---------------------------------------------------------- */
.c-link-title {
  position: relative;
  font-size: 2rem;
  letter-spacing: .2rem;
  padding-right: 27px;
  @include is-underline;

  @include mq-down(md) {
    font-size: 1.6rem;
    padding-right: 16px;
  }

  &::after {
    position: absolute;
    content: '';
    top: 44%;
    right: 0;
    transform: translateY(-50%);
    width: 17px;
    height: 17px;
    background-image: url(/assets/images/common/ico_arrow_black.png);
    background-repeat: no-repeat;
    background-size: 17px 17px;

    @include mq-down(md) {
      width: 13px;
      height: 13px;
      background-size: 13px 13px;
    }
  }
}

/* c-link-tel
---------------------------------------------------------- */
.c-link-tel {
  @include mq-up(md) {
    pointer-events: none;
  }
}

/* c-link
---------------------------------------------------------- */
.c-link {
  @include is-opacity;
  @include is-underline; 
  color: $color-blue-01;

  &.-pdf {
    position: relative;
    padding-right: 29px;
    color: $black;
    
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: -5px;
      right: 0;
      width: 22px;
      height: 28px;
      background-image: url(/assets/images/common/ico_pdf.png);
      background-repeat: no-repeat;
      background-size: 22px 28px;
      margin-left: 8px;
    }
  }
}
