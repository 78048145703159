/* form
---------------------------------------------------------- */
.c-checkbox {
  @include mq-down(md) {
    font-size: 1.2rem;
  }

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;

    &:checked {
      + label {

        &::before {
          border: 2px solid $color-gray-12;
          background-color: $color-gray-12;
        }

        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 6px;
          left: 6px;
          width: 3px;
          height: 8px;
          border: solid $white;
          border-width: 0 1px 1px 0;
          transform: rotate(45deg);

          @include mq-down(md) {
            top: 4px;
            left: 6px;
          }
        }
      }
    }
  }

  label {
    position: relative;
    cursor: pointer;
    display: inline-block;

    &::before {
      content:'';
      -webkit-appearance: none;
      background-color: transparent;
      border: 2px solid $color-gray-07;
      border-radius: 2px;
      padding: 6px;
      display: inline-block;
      position: relative;
      top: 2px;
      cursor: pointer;
      margin-right: 10px;

      @include mq-down(md) {
        margin-right: 5px;
      }
    }
  }
}

@media not all and (min-resolution:.001dpcm){ 
  @supports (-webkit-appearance:none) {
    .c-checkbox {
      input {
        &:checked {
          + label {
            &::after {
              top: 5px;
    
              @include mq-down(md) {
                top: 4px;
              }
            }
          }
        }
      }
    }
  }
}
