/* Slider */
.slick-slider {
  position: relative;

  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;

  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track::before,
.slick-track::after {
  display: table;

  content: '';
}

.slick-track::after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Dots */

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0;
  margin-top: 30px;
  margin-left: -25px;
  width: 100%;

  li {
    position: relative;
    padding: 0;
    cursor: pointer;

    button {
      border: 0;
      display: block;
      height: 5px;
      width: 5px;
      outline: none;
      color: transparent;
      margin: 0 7px;
      cursor: pointer;

      &:hover,
      &:focus {
        outline: none;
      }

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        margin: 0 7px;
        width: 5px;
        height: 5px;
        background-color: #E2E6F2;
        border-radius: 100%;
      }
    }

    &.slick-active button {
      width: 10px;
      height: 10px;
    }

    &.slick-active button::before {
      width: 10px;
      height: 10px;
      background-color: #9FA8AF;
    }
  }
}
