/* p-features-problem
---------------------------------------------------------- */
.p-features-problem {
  border-top: 1px dotted $color-gray-10;
  padding-top: 60px;

  @include mq-down(md) {
    padding-top: 43px;
  }

  .c-heading-04 {
    margin-bottom: 38px;

    @include mq-down(md) {
      margin-bottom: 31px;
    }
  }

  .c-btn {
    padding: 30px 45px 26px 55px;
    margin-top: 40px;

    &:hover {
      &::before {
        width: 0px;
      }
    }

    @include mq-down(md) {
      padding: 24px 15px 19px;
      margin-top: 8px;
      border-radius: $border-radius-sp;
    }

    span {
      font-size: 3rem;
      font-weight: 700;

      @include mq-down(md) {
        font-size: 2.2rem;
      }
    }

    @include mq-up(md) {
      width: 800px;
    }

    &::after {
      display: none;
    }
  }
}

/* p-features-feature
---------------------------------------------------------- */
.p-features-feature {
  padding-top: 136px;

  @include mq-down(md) {
    padding-top: 78px;
  }
}

/* p-features-reason
---------------------------------------------------------- */
.p-features-reason {
  padding-top: 160px;

  @include mq-down(md) {
    padding-top: 70px;
  }

  > .box-reason {
    @include mq-up(md) {
      display: flex;
    }

    .title {
      font-size: 3rem;
      font-weight: 700;
      color: $color-gray-01;
      margin-bottom: 9px;

      @include mq-down(md) {
        font-size: 2.2rem;
        margin-bottom: 4px;
      }
    }

    .title > .number {
      display: block;
      font-size: 1.4rem;
      color: $color-green-01;
    }

    .title > .highlight {
      color: $color-pink-01;

      @include mq-down(md) {
        display: block;
      }
    }

    .text {
      line-height: 2;
      color: $color-gray-01;
    }

    &.-full {
      width: 100%;
    }

    &.-column {
      .image {
        @include mq-down(md) {
          margin-top: 30px;
          margin-left: -15px;
          margin-right: -15px;
        }
      }

      .text {
        margin-top: 20px;

        @include mq-down(md) {
          margin-top: 8px;
          letter-spacing: -.02rem;
        }
      }
    }

    &.-left {
      .image {
        @include mq-up(md) {
          order: 1;
          margin-left: -45px;
        }
      }

      .detail {
        @include mq-up(md) {
          order: 2;
          padding-left: 40px;
          padding-top: 16px;
        }
      }
    }

    &.-right {
      .image {
        @include mq-up(md) {
          margin-right: -45px;
        }
      }

      .detail {
        @include mq-up(md) {
          padding-right: 40px;
          padding-top: 16px;
        }
      }
    }

    > .image {
      @include mq-up(md) {
        width: 785px;
        flex: 1 0 auto;
      }

      img {
        width: 100%;
        border-radius: $border-radius-pc;

        @include mq-down(md) {
          border-radius: $border-radius-sp;
        }
      }
    }

    > .detail {
      width: 100%;
    }

    + .box-reason {
      margin-top: 170px;

      @include mq-down(md) {
        margin-top: 90px;
      }
    }
  }

  .list-company {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 88px;

    @include mq-up(md) {
      margin-left: -73px;
    }

    @include mq-down(md) {
      margin-top: 42px;
      justify-content: space-between;
    }

    > .item {
      width: 245px;

      @include mq-down(md) {
        width: inherit;
        max-width: 172px;
      }

      @include mq-down(sm) {
        max-width: 165px;
      }

      @include mq-up(md) {
        margin-left: 73px;

        &:nth-child(n+5) {
          margin-top: 53px;
        }
      }

      @include mq-down(md) {
        &:nth-child(n+3) {
          margin-top: 15px;
        }
      }
    }
  }
}
