/* p-notfound
---------------------------------------------------------- */
.p-notfound {
  padding-top: 45px;
  padding-bottom: 57px;

  @include mq-down(md) {
    padding-top: 66px;
    padding-bottom: 35px;
  }

  .box-notfound {
    background-color: $color-gray-08;
    border-radius: $border-radius-pc;
    padding: 140px 75px;

    @include mq-down(md) {
      border-radius: $border-radius-sp;
      padding: 55px 35px 85px;
    }
  }

  .title {
    font-size: 3rem;
    font-weight: 700;
    letter-spacing: .1rem;
    margin-bottom: 18px;

    @include mq-down(md) {
      font-size: 2.2rem;
      margin-bottom: 21px;
    }
  }

  .text {
    font-size: 1.6rem;
    line-height: 2.4;

    @include mq-down(md) {
      line-height: 1.6;

      & + .text {
        margin-top: 26px;
      }
    }
  }
}