
/* c-sidebar
---------------------------------------------------------- */
.c-sidebar {
  @include mq-down(md) {
    margin-top: 78px;
  }

  > .banner {
    display: block;
    margin-bottom: 38px;

    @include mq-down(md) {
      margin-bottom: 50px;
    }
  }

  > a.banner {
    @include is-opacity;
  }

  > .title {
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 4px;

    @include mq-down(md) {
      font-size: 1.6rem;
      margin-bottom: 5px;
    }
  }

  > .list-pickup {
    > .item {
      &:first-child {
        .card-pickup  {
          padding-top: 20px;
          border-top: 1px dotted $color-gray-04;

          @include mq-down(md) {
            padding-top: 29px;
          }
        }
      }
    }

    .link {
      display: block;
      @include is-img;
    }
  }

  .card-pickup {
    display: flex;
    padding: 28px 0 28px;
    border-bottom: 1px dotted $color-gray-04;

    @include mq-down(md) {
      padding: 27px 0 20px;
    }

    > .image {
      flex: 1 0 auto;
      width: 140px;
      position: relative;
      border-radius: 3px;
      overflow: hidden;
      clip-path: content-box;

      @include mq-up(md) {
        height: 84px;
      }

      > * {
        @include mq-up(md) {
          height: 84px;
        }
      }

      @include mq-down(md) {
        width: 140px;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 20px;
        height: 20px;
        background-image: url(/assets/images/common/ico_arrow_right_01.png);
        background-size: 5px 8px;
        background-repeat: no-repeat;
        background-color: $color-green-01;
        background-position: center;
      }

      img {
        width: 100%;
        border-radius: 3px;
      }
    }

    > .detail {
      width: 100%;
      padding-left: 30px;

      .desc {
        font-size: 1.4rem;

        @include mq-down(md) {
          font-size: 1.2rem;
        }
      }
    }
  }
}
