/* p-customer-detail
--------------------------------------------------------- */
.p-customer-detail {
  
  /* box-title */
  > .box-title {
    position: relative;
    padding-top: 38px;

    @include mq-down(md) {
      padding-top: 24px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 170px;
      height: 2px;
      background-color: $color-gray-09;

      @include mq-down(md) {
        width: 85px;
        height: 1px;
      }
    }

    > .customer {
      font-weight: 700;
      color: $color-black-01;
    }

    > .title {
      font-size: 2.4rem;
      font-weight: 700;
      line-height: 1.7;
      color: $color-black-01;
      margin-bottom: 6px;

      @include mq-down(md) {
        font-size: 1.6rem;
        line-height: 1.6;
      }
    }

    .sub-title {
      display: block;
    }

    > .date {
      font-size: 1.4rem;
      color: $color-green-01;

      @include mq-down(md) {
        font-size: 1.2rem;
      }
    }

    * + .title {
      margin-top: 10px;

      @include mq-down(md) {
        margin-top: 8px;
      }
    }
  }

  /* box-info */
  > .box-info {
    margin-top: 42px;

    @include mq-down(md) {
      margin-top: 22px;
    }

    @include mq-up(md) {
      display: flex;
    }

    @include mq-down(md) {
      padding-left: 27px;
      padding-right: 16px;

      @include mq-down(md) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    /* box-detail */
    > .box-detail {
      width: 100%;

      @include mq-up(md) {
        padding-right: 25px;
      }

      > .customer {
        font-weight: 700;
        color: $color-black-01;

        @include mq-down(md) {
          font-size: 1.2rem;
        }
      }

      > .name {
        font-size: 1.4rem;
        line-height: 1.95;
        color: $color-black-01;
        margin-top: 3px;

        @include mq-down(md) {
          font-size: 1.2rem;
          line-height: 1.7;
          margin-top: 0;
        }
      }
    }

    .list-detail {
      margin-top: 15px;

      @include mq-down(md) {
        margin-top: 7px;
      }

      > .item {
        display: flex;

        &:not(:first-child) {
          margin-top: 8px;

          @include mq-down(md) {
            margin-top: 6px;
          }
        }
      }

      .title {
        flex: 1 0 auto;
        width: 100px;

        @include mq-down(md) {
          width: 87px;
        }

        > span {
          display: block;
          font-size: 1.2rem;
          font-weight: 500;
          color: $white;
          text-align: center;
          border-radius: $border-radius-pc;
          background-color: $color-green-01;
          padding: 3px 5px 2px;

          @include mq-down(md) {
            font-size: 1.1rem;
          }
        }
      }

      .text {
        width: 100%;
        font-size: 1.4rem;
        line-height: 1.7;
        color: $color-black-01;
        padding-left: 15px;

        @include mq-down(md) {
          font-size: 1.2rem;
          padding-left: 7px;
        }
      }
    }

    .box-sns {
      margin: 13px 0 0 -10px;
    }

    /* box-image */
    > .box-image {
      @include mq-up(md) {
        width: 785px;
        flex: 1 0 auto;
      }

      @include mq-down(md) {
        margin-top: 35px;
        margin-right: -15px;
      }

      > .image {
        border-radius: 8px;
        overflow: hidden;
        clip-path: content-box;

        @include mq-down(md) {
          border-radius: 3px;
        }

        img {
          width: 100%;
        }
      }

      > .box-product-detail {
        margin-top: 28px;

        > .title {
          font-size: 1.4rem;
          font-weight: 700;
          margin-bottom: 6px;

          @include mq-down(md) {
            font-size: 1.2rem;
            margin-bottom: 0;
          }
        }
      }

      .list-product-detail {
        @include mq-down(md) {
          padding-right: 15px;
        }

        > .item {
          display: flex;
          line-height: 1.9;

          &:not(:first-child) {
            margin-top: 2px;

            @include mq-down(md) {
              margin-top: 0;
            }
          }

          > .title {
            display: flex;
            justify-content: space-between;
            width: 72px;
            font-size: 1.4rem;
            flex: 1 0 auto;

            @include mq-down(md) {
              width: 62px;
              font-size: 1.2rem;
            }

            &::after {
              position: relative;
              top: -1px;
              content: '：';
              font-size: 1.4rem;

              @include mq-down(md) {
                font-size: 1.2rem;
              }
            }
          }

          > .text {
            width: 100%;
            font-size: 1.4rem;

            @include mq-down(md) {
              font-size: 1.2rem;
            }
          }

          a {
            color: $color-blue-01;
            @include is-opacity;
            @include is-underline;
          }
        }
      }
    }
  }
}

/* c-interview
--------------------------------------------------------- */
.c-interview {
  margin-top: 64px;
  padding-top: 63px;
  border-top: 1px dotted $color-gray-04;

  a{
    color: $color-blue-01;
    @include is-opacity;
    @include is-underline;
  }

  @include mq-down(md) {
    margin-top: 33px;
    padding-top: 35px;
  }

  + .c-interview {
    margin-top: 140px;
    padding-top: 0;
    border-top: none;

    @include mq-down(md) {
      margin-top: 70px;
    }

  }

  > .box-title {
    border-radius: $border-radius-pc;
    background-color: $color-lightgreen-01;
    padding: 44px 15px 39px 100px;

    @include mq-up(md) {
      display: flex;
      align-items: center;
    }

    @include mq-down(md) {
      display: flex;
      flex-direction: column;
      border-radius: $border-radius-sp;
      padding: 32px 15px 25px;
    }

    > .title {
      font-size: 2rem;
      font-weight: 700;
      color: $color-green-01;

      @include mq-up(md) {
        width: 300px;
        flex: 1 0 auto;
      }

      @include mq-down(md) {
        font-size: 1.6rem;
        text-align: center;
      }
    }

    > .desc {
      width: 100%;
      font-size: 2rem;
      font-weight: 600;
      line-height: 1.6;
      color: $color-black-01;

      @include mq-up(md) {
        position: relative;
        padding-left: 65px;

        &::after {
          content: '';
          position: absolute;
          top: 46%;
          left: 0;
          transform: translateY(-50%);
          width: 1px;
          height: calc(100% + 26px);
          background-color: $color-green-01;
        }
      }

      @include mq-down(md) {
        font-size: 1.4rem;
        line-height: 1.75;
        margin-top: 7px;

        br {
          display: none;
        }
      }
    }
  }

  > .box-interview {
    @include mq-up(md) {
      padding-left: 106px;
      margin-top: 67px;
    }

    @include mq-down(md) {
      margin-top: 28px;
    }

    + .box-interview {
      margin-top: 90px;

      @include mq-down(md) {
        margin-top: 50px;
      }
    }

    > .question {
      position: relative;
      font-size: 2rem;
      font-weight: 700;
      color: $color-black-01;
      padding-left: 40px;

      @include mq-down(md) {
        font-size: 1.4rem;
        line-height: 2;
        padding-left: 30px;
      }

      &::before {
        content: '';
        position: absolute;
        display: block;
        width: 30px;
        height: 2px;
        left: 0;
        top: 13px;
        background-color: #838383;

        @include mq-down(md) {
          width: 23px;
          top: 11px;
        }
      }
    }

    > .answer {
      line-height: 2;
      margin-top: 29px;
      padding-left: 36px;

      @include mq-down(md) {
        margin-top: 21px;
        padding-left: 26px;
      }

      + .answer {
        margin-top: 32px;

        @include mq-down(md) {
          margin-top: 28px;
        }
      }
    }

    .name {
      font-weight: 600;
      color: $color-green-01;
    }

    .response {
      color: $color-black-01;
    }

    .image {
      padding-left: 37px;
      margin-top: 34px;
      border-radius: $border-radius-pc;
      overflow: hidden;
      clip-path: content-box;

      @include mq-down(md) {
        padding-left: 0;
        margin-top: 49px;
        margin-left: -15px;
        margin-right: -15px;
        border-radius: 0;
      }

      figcaption {
        font-size: 1.4rem;
        color: #444;
        margin-top: 12px;

        @include mq-down(md) {
          font-size: 1.2rem;
          margin-top: 7px;
          padding: 0 5px;
        }
      }
    }
  }

  .box-gallery {
    display: flex;
    margin: 119px -100px 140px -100px;

    @include mq-down(md) {
      margin: 47px -15px 39px -15px;
    }

    .image {
      > img {
        height: 320px;
        width: 100%;
        clip-path: content-box;

        @include mq-down(md) {
          height: calc-vw375(90);
        }
      }

      figcaption {
        font-size: 1.4rem;
        color: #444;
        margin-top: 6px;
        margin-left: 6px;

        @include mq-down(md) {
          display: none;
        }
      }
    }
  }
}

/* p-customer-list
--------------------------------------------------------- */
.p-customer-list  {
  margin-top: 140px;

  @include mq-down(md) {
    margin-top: 100px;
  }

  .c-heading-03 {
    @include mq-down(md) {
      margin-bottom: 25px;
    }
  }
}
