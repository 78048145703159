/* c-btn
---------------------------------------------------------- */
.c-btn {
  width: 300px;
  position: relative;
  display: inline-block;
  padding: 15px 45px 12px 55px;
  border-radius: $border-radius-pc;
  text-align: center;
  color: $white;
  background-color: $color-green-01;
  transition: .25s;

  &:hover {
    &::before {
      width: 100%;
    }
  }

  @include mq-down(md) {
    width: calc(100% - 20px);
    padding: 12px 5px 11px;
  }

  &::before {
    content: '';
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $color-gray-12;
    border-radius: $border-radius-pc;
    transition: .25s;
  }

  &::after {
    position: absolute;
    content: '';
    top: 50%;
    right: -13px;
    transform: translateY(-50%);
    width: 26px;
    height: 26px;
    background-image: url(/assets/images/common/ico_arrow_black.png);
    background-repeat: no-repeat;
    background-size: 26px 26px;

    @include mq-down(md) {
      width: 22px;
      height: 22px;
      right: -10px;
      background-size: 22px 22px;
    }
  }

  span {
    display: inline-block;
    position: relative;
    font-size: 1.6rem;
    color: $white;
    margin-left: -6px;

    @include mq-down(md) {
      font-size: 1.4rem;
    }
  }

  &.-full {
    width: 100%;
  }

  &.-full-pc {
    @include mq-up(md) {
      width: 100%;
    }
  }

  &.-full-sp {
    @include mq-down(md) {
      width: 100%;
    }
  }

  &.-lg {
    @include mq-up(md) {
      width: 500px;
      padding: 25px 45px 21px 55px;

      &::after {
        right: -8px;
      }
    }
  }

  &.-icon {
    > span {
      padding-right: 22px;

      &::after {
        position: absolute;
        content: '';
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        background-repeat: no-repeat;
      }
    }

    &.-note {
      span {
        padding-right: 18px;
        transition: .25s;

        &::after {
          width: 14px;
          height: 17px;
          background-image: url(/assets/images/common/ico_note.png);
          background-size: 14px 17px;
        }
      }

      &:hover {
        span {
          color: $white;

          &::after {
            background-image: url(/assets/images/common/ico_note_white.png);
          }
        }
      }
    }

    &.-document {
      &:hover {
        > .text-wrap {
          &::after {
            background-image: url(/assets/images/common/ico_note_white.png);
          }
          > .jp {
            color: $white;
          }
        }
      }
    }

    &.-office {
      width: 390px;
      padding: 22px 20px 17px 28px;

      @include mq-down(md) {
        width: calc(100% - 40px);
        padding: 19px 13px 13px 28px;
        margin-left: 15px;
      }

      > .text-wrap {
        position: relative;
        padding-left: 65px;
        padding-right: 0;
        text-align: left;

        @include mq-down(md) {
          padding-left: 53px;
        }

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 47px;
          height: 47px;
          background-image: url(/assets/images/common/ico_office.png);
          background-size: 47px 47px;

          @include mq-down(md) {
            width: 33px;
            height: 33px;
            background-size: 33px 33px;
          }
        }

        > .en {
          display: block;
          font-family: $font-roboto;
          font-size: 2.5rem;
          font-weight: 700;
          line-height: 1.3;

          @include mq-down(md) {
            font-size: 1.8rem;
            line-height: 1;
          }
        }
        > .jp {
          display: block;
          font-size: 2.5rem;
          font-weight: 400;
          line-height: 1.45;
          margin-bottom: -3px;

          @include mq-down(md) {
            font-size: 2rem;
          }
        }

        > span {
          font-size: 1.4rem;
          font-weight: 700;

          @include mq-down(md) {
            font-size: 1.2rem;
          }
        }
      }
    }

    &.-document {
      width: 390px;
      padding: 35px 50px 34px 60px;
      background-color: $color-yellow-02;

      @include mq-down(md) {
        width: calc(100% - 40px);
        padding: 25px 20px 22px 60px;
        margin-left: 15px;
      }

      > .text-wrap {
        position: relative;
        padding-left: 42px;
        padding-right: 0;
        text-align: left;

        @include mq-down(md) {
          padding-left: 39px;
        }

        &::after {
          content: '';
          position: absolute;
          top: 49%;
          left: 0;
          transform: translateY(-50%);
          width: 26px;
          height: 31px;
          background-image: url(/assets/images/common/ico_note_gray.png);
          background-size: 26px 31px;

          @include mq-down(md) {
            right: 42px;
            width: 21px;
            height: 26px;
            background-size: 21px 26px;
          }
        }
  
        > .jp {
          display: block;
          font-size: 2.0rem;
          font-weight: 600;
          line-height: 1.45;
          margin-bottom: -3px;
          color: $color-gray-03;

          @include mq-down(md) {
            font-size: 1.6rem;
          }
        }
      }
    }



  }

  &.-yellow {
    background-color: $color-yellow-01;
  }

  &.-uncheck {
    width: 150px;
    font-size: 1.6rem;
    font-weight: bold;
    text-align: center;
    background-color: $color-gray-13;
    border: 3px solid $color-gray-14;
    padding: 12px 5px 9px;
    cursor: pointer;
    @include is-opacity;

    @include mq-up(md) {
      position: absolute;
      top: 0;
      right: 50%;
      transform: translateX(calc(50% + 265px));
    }

    @include mq-down(md) {
      width: calc(100% - 50px);
      border: 2px solid $color-gray-14;
      padding: 8px 5px;

      .c-btn-search + & {
        margin-top: 18px;
      }
    }

    span {
      color: $color-gray-01;

      @include mq-up(md) {
        width: 146px;
      }
    }

    &::before {
      display: none;
    }

    &::after {
      display: none;
    }
  }

  &-center {
    text-align: center;
  }

  &-wrap {
    margin-top: 116px;
    padding: 43px 0 40px;
    background-color: #e3f4f0;
    border-radius: $border-radius-pc;
    text-align: center;

    @include mq-down(md) {
      padding: 32px 15px;
      margin-top: 34px;
    }

    .-xl {
      @include mq-up(md) {
        width: 500px;
        padding: 25px 45px 21px 55px;
      }
    }
  }

  &-search {
    width: 300px;
    position: relative;
    display: inline-block;
    padding: 15px 45px 12px 55px;
    border-radius: $border-radius-pc;
    text-align: center;
    background-color: $color-gray-12;

    &::before {
      content: '';
      width: 0;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: $black;
      border-radius: $border-radius-pc;
      transition: .25s;
    }

    &:hover {
      &::before {
        width: 100%;
      }
    }

    @include mq-down(md) {
      width: calc(100% - 50px);
      padding: 12px 5px 11px;
    }

    &::after {
      position: absolute;
      content: '';
      top: 50%;
      right: -13px;
      transform: translateY(-50%);
      width: 26px;
      height: 26px;
      background-image: url(/assets/images/common/ico_arrow_black.png);
      background-repeat: no-repeat;
      background-size: 26px 26px;

      @include mq-down(md) {
        width: 22px;
        height: 22px;
        right: -10px;
        background-size: 22px 22px;
      }
    }

    span {
      display: inline-block;
      margin-left: -10px;
      position: relative;
      font-size: 1.6rem;
      letter-spacing: .1em;
      font-weight: bold;
      color: $white;
      padding-left: 24px;

      @include mq-down(md) {
        font-size: 1.4rem;
      }

      &::after {
        position: absolute;
        content: '';
        top: 3px;
        left: 0;
        width: 17px;
        height: 17px;
        background-image: url(/assets/images/common/ico_search.png);
        background-repeat: no-repeat;
        background-size: 17px 17px;
      }
    }
  }
}
