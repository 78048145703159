/* c-cms-wysiwyg
---------------------------------------------------------- */
.c-cms-wysiwyg {
  *:first-child {
    margin-top: 0;
  }

  *:first-child + h3 {
    margin-top: 63px;

    @include mq-down(md) {
      margin-top: 35px;
    }
  }

  p {
    line-height: 2.4;

    @include mq-down(md) {
      line-height: 2;
    }

    .sm {
      color: $black;

      @include mq-down(md) {
        font-size: 1.2rem;
      }
    }
  }

  span {
    color: $color-green-01;
  }

  a {
    color: $color-blue-01;
    @include is-opacity;
    @include is-underline; 
  }

  h3 {
    font-size: 2.4rem;
    font-weight: 700;

    @include mq-down(md) {
      font-size: 1.6rem;
    }
  }

  h4 {
    font-size: 1.6rem;
    font-weight: 600;

    @include mq-down(md) {
      font-size: 1.4rem;
    }
  }

  ol {
    counter-reset: li;

    > li {
      display: table;
      counter-increment: li;
      line-height: 2;

      @include mq-down(md) {
        line-height: 1.7;
      }

      &:not(:first-child) {
        margin-top: 14px;

        @include mq-down(md) {
          margin-top: 18px;
        }
      }

      &::before {
        display: table-cell;
        content: counter(li)".";
        padding-right: 2px;
      }

      > .c-list-number-sub {
        @include mq-up(md) {
          margin-top: 2px;
        }
      }
    }
  }

  ul {
    > li {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      padding-left: 18px;

      &:not(:first-child) {
        margin-top: 14px;

        @include mq-down(md) {
          margin-top: 18px;
        }
      }

      &::before {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        content: '●';
        margin-right: 2px;
        width: 16px;
      }
    }
  }

  hr {
    border: none;
    width: 100%;
    height: 1px;
    background-color: $color-gray-10;
    margin: 28px 0 18px;

    @include mq-down(md) {
      margin: 18px 0;
    }
  }

  img {
    margin-top: 30px;

    @include mq-down(md) {
      margin-top: 22px;
    }
  }

  * + h3 {
    margin-top: 84px;

    @include mq-down(md) {
      margin-top: 62px;
    }
  }

  * + h4 {
    margin-top: 45px;

    @include mq-down(md) {
      margin-top: 29px;
    }
  }

  * + ol {
    margin-top: 4px;

    @include mq-down(md) {
      margin-top: 10px;
    }
  }

  * + ul {
    margin-top: 4px;

    @include mq-down(md) {
      margin-top: 10px;
    }
  }

  ul + ol,
  ol + ul {
    @include mq-down(md) {
      margin-top: 0;
    }
  }

  h3 + p {
    margin-top: 6px;

    @include mq-down(md) {
      margin-top: 16px;
    }
  }

  h4 + p {
    @include mq-down(md) {
      margin-top: 4px;
    }
  }

  p + h3 {
    margin-top: 84px;

    @include mq-down(md) {
      margin-top: 65px;
    }
  }

  * + p {
    margin-top: 8px;

    @include mq-down(md) {
      margin-top: 15px;
    }
  }

  p + p {
    margin-top: 38px;

    @include mq-down(md) {
      margin-top: 28px;
    }
  }
}
