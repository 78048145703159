/* Font
---------------------------------------------------------- */
// Fonts
$font-base: 'NotoSansCJKjp', sans-serif;
$font-roboto: 'Roboto', sans-serif;
$font-yugothic: 'YuGothic', 'Hiragino Kaku Gothic Pro', 'Meiryo', sans-serif;
$font-DINAlternateBold: 'DINAlternateBold', sans-serif;
// font-weight
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-bold: 700;
$fw-bold: 800;

/* Breakpoint
---------------------------------------------------------- */
$breakpoint-sm  : 360;
$breakpoint-md  : 896;
$breakpoint-slg : 1400;
$breakpoint-lg  : 1600;
$breakpoint-xlg : 1920;

/* Color
---------------------------------------------------------- */
// Base Color
$white: #fff !default;
$black: #000 !default;
$color-black-01: #080808;
$color-main: $black;
$color-gray-01: #282828;
$color-gray-02: #575757;
$color-gray-03: #3a3a3a;
$color-gray-04: #7f7f7f;
$color-gray-05: #6d6d6d;
$color-gray-06: #6f6f6f;
$color-gray-07: #707070;
$color-gray-08: #f0f0f0;
$color-gray-09: #ddd;
$color-gray-10: #979797;
$color-gray-11: #505050;
$color-gray-12: #5d5d5d;
$color-gray-13: #efefef;
$color-gray-14: #dedede;
$color-green-01: #45b49b;
$color-lightgreen-01: #e3f4f0;
$color-yellow-01: #f5d006;
$color-yellow-02: #f8d818;
$color-orange-01: #eb6100;
$color-pink-01: #e5004f;
$color-blue-01: #0000ff;


/* line-height
---------------------------------------------------------- */
$lh-narrow: 1.5;
$lh-middle: 1.7;
$lh-wide: 2;

/* border-radius
---------------------------------------------------------- */
$border-radius-pc: 10px;
$border-radius-sp: 5px;

