/* calc-vw1800
---------------------------------------------------------- */
@function calc-vw1800($value) {
  @return calc((100vw * #{$value}) / 1800);
}

/* calc-vw1280
---------------------------------------------------------- */
@function calc-vw1280($value) {
  @return calc((100vw * #{$value}) / 1280);
}

/* calc-vw1280
---------------------------------------------------------- */
@function calc-vw1280($value) {
  @return calc((100vw * #{$value}) / 1280);
}

/* calc-vw375
---------------------------------------------------------- */
@function calc-vw375($value) {
  @return calc((100vw * #{$value}) / 375);
}