/* c-list-customer
--------------------------------------------------------- */
.c-list-customer {
  @include mq-up(md) {
    display: flex;
    flex-wrap: wrap;
    margin-left: -22px;
    margin-right: -22px;
  }

  &-wrap {
    padding-bottom: 50px;

    @include mq-down(md) {
      padding-bottom: 25px;
    }
  }

  > .item {
    @include mq-up(md) {
      width: 33.33%;
      padding: 0 22px;
    }

    &:not(:first-child) {
      @include mq-down(md) {
        margin-top: 50px;
      }
    }
  }

  .link {
    display: block;
    @include is-img;
  }
}


/* c-list-know
--------------------------------------------------------- */
.c-list-know {
  @include mq-up(md) {
    display: flex;
    flex-wrap: wrap;
    margin-left: -22px;
    margin-right: -22px;
    margin-top: -75px;
  }

  &-wrap {
    padding-top: 58px;
    padding-bottom: 37px;

    @include mq-down(md) {
      padding-top: 30px;
      padding-bottom: 27px;
    }
  }

  > .item {
    @include mq-up(md) {
      width: 33.33%;
      padding: 0 22px;
      margin-top: 75px;
    }

    &:not(:first-child) {
      @include mq-down(md) {
        margin-top: 38px;
      }
    }
  }

  .link {
    display: block;
    @include is-img;
  }

  &.-top {
    @include mq-up(md) {
      margin-left: -13px;
      margin-right: -13px;
      margin-top: -75px;
    }

    @include mq-down(md) {
      padding-left: 27px;
      padding-right: 16px;
    }

    > .item {
      @include mq-up(md) {
        width: 25%;
        padding: 0 13px;
        margin-top: 75px;
      }
    }
  }
}


/* c-list-voice
--------------------------------------------------------- */
.c-list-voice {
  @include mq-up(md) {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
  }

  > .item {
    @include mq-up(md) {
      width: 33.33%;
      padding: 0 15px;
    }

    &:not(:first-child) {
      @include mq-down(md) {
        margin-top: 35px;
      }
    }
  }

  .link {
    display: block;
    @include is-img;
  }
}

/* c-list-seminar
--------------------------------------------------------- */
.c-list-seminar {
  @include mq-up(md) {
    display: flex;
    flex-wrap: wrap;
    margin-left: -22px;
    margin-right: -22px;
    margin-top: -60px;
  }

  &-wrap {
    padding-bottom: 50px;

    @include mq-down(md) {
      padding-bottom: 25px;
    }
  }

  > .item {
    @include mq-up(md) {
      width: 33.33%;
      padding: 0 22px;
      margin-top: 60px;
    }

    &:not(:first-child) {
      @include mq-down(md) {
        margin-top: 37px;
      }
    }
  }

  .link {
    display: block;
    @include is-img;
  }
}

/* c-list-seminar-timeline
--------------------------------------------------------- */
.c-list-seminar-timeline {
  > .item {
    &:not(:first-child) {
      margin-top: 70px;

      @include mq-down(md) {
        margin-top: 45px;
      }
    }
  }
}

/* c-list-seminar-report
--------------------------------------------------------- */
.c-list-seminar-report {
  &-wrap {
    max-width: 800px;
    margin: 0 auto;
  }

  > .item {
    &:not(:first-child) {
      margin-top: 58px;

      @include mq-down(md) {
        margin-top: 60px;
      }
    }
  }

  .link {
    display: block;
    @include is-img;
  }
}

/* c-list-info
---------------------------------------------------------- */
.c-list-info {
  display: table;
  border-spacing: 0 3px;
  margin-top: -5px;

  > .item {
    display: table-row;

    &:not(:first-child) {
      border-top: 5px solid transparent;
    }
  }

  .title {
    display: table-cell;
    position: relative;
    padding-right: 16px;

    @include mq-down(md) {
      width: 45px;
    }

    &::after {
      content: '：';
      position: absolute;
      width: 16px;
      right: 0;
      top: 0;
    } 
  }

  .text {
    display: table-cell;
    line-height: 1.9;
  }
}

/* c-list-dot
---------------------------------------------------------- */
.c-list-dot {
  > .item {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding-left: 18px;

    &:not(:first-child) {
      margin-top: 14px;

      @include mq-down(md) {
        margin-top: 7px;
      }
    }

    &::before {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      content: '●';
      margin-right: 2px;
      width: 16px;
    }
  }

  &.-sm {
    > .item {
      font-size: 1.4rem;
      padding-left: 16px;

      @include mq-down(md) {
        font-size: 1.2rem;
        line-height: 1.8;
        padding-left: 14px;
      }

      &:not(:first-child) {
        margin-top: 8px;

        @include mq-down(md) {
          margin-top: 5px;
        }
      }

      &::before {
        width: 14px;
      }
    }
  }

  &.-small-dot {
    li::before {
      content: '・';
      margin-right: 0;
    }
  }
}

/* c-list-number
--------------------------------------------------------- */
.c-list-number {
  counter-reset: li;  

  > li {
    display: table;
    counter-increment: li;
    line-height: 2;

    @include mq-down(md) {
      line-height: 1.7;
    }

    &::before {
      display: table-cell;
      content: counter(li)".";  
      padding-right: 2px;
    }

    > .c-list-number-sub {
      @include mq-up(md) {
        margin-top: 2px;
      }
    }
  }
}

/* c-list-number-sub
--------------------------------------------------------- */
.c-list-number-sub {
  counter-reset: item;  

  > li {
    display: table;
    counter-increment: item;
    line-height: 2.1;

    @include mq-down(md) {
      line-height: 1.7;
    }

    &::before {
      display: table-cell;
      content: "("counter(item)")"; 
      padding-right: 2px;
    }
  }
}

/* c-list-feature
--------------------------------------------------------- */
.c-list-feature {
  @include mq-up(md) {
    display: flex;
    flex-wrap: wrap;
    margin-left: -30px;
    margin-right: -30px;
  }

  &-wrap {
    padding-top: 47px;

    @include mq-down(md) {
      padding-top: 33px;
    }
  }

  > .item {
    @include mq-up(md) {
      width: 33.33%;
      padding: 0 30px;
    }

    &:not(:first-child) {
      @include mq-down(md) {
        margin-top: 85px;
      }
    }
  }
}

/* c-list-button
--------------------------------------------------------- */
.c-list-button {
  width: 370px;
  
  @include mq-down(md) {
    width: 100%;
  }

  > .item {
    &:not(:first-child) {
      margin-top: 11px;
    }

    @include mq-down(md) {
      margin-right: 8px;
    }
  }

  .c-btn {
    width: 100%;

    &::before {
      border-radius: 8px;
    }

    span {
      color: $color-gray-01;
    }

    @include mq-down(md) {
      padding-left: 16px;
    }
  }
}

/* c-list-service-menu
--------------------------------------------------------- */
.c-list-service-menu {
  > .item {
    &:nth-child(odd) {
      .c-card-service-menu {
        > .image {
          @include mq-up(md) {
            order: 2;
            margin-right: -50px;
          }

          @include mq-down(md) {
            text-align: right;
            margin-right: -15px;
          }
        }

        > .detail {
          @include mq-up(md) {
            order: 1;
            margin-right: -135px;
          }
        }
      }
    }

    &:nth-child(even) {
      .c-card-service-menu {
        > .image {
          @include mq-up(md) {
            margin-left: -50px;
          }

          @include mq-down(md) {
            margin-left: -15px;
          }
        }

        > .detail {
          @include mq-up(md) {
            margin-left: -135px;
          }

          @include mq-down(md) {
            justify-content: flex-end;
          }
        }
      }
    }

    &:not(:first-child) {
      margin-top: 148px;

      @include mq-down(md) {
        margin-top: 58px;
      }
    }
  }
}

/* c-list-flow
--------------------------------------------------------- */
.c-list-flow {
  @include mq-up(md) {
    padding-left: 100px;
    padding-right: 100px;
  }

  > .item:not(:last-child) {
    position: relative;
    padding-bottom: 56px;

    @include mq-down(md) {
      padding-bottom: 36px;
    }

    &::after {
      content: '';
      position: absolute;
      display: block;
      bottom: 21px;
      left: 50%;
      transform: translateX(-50%);
      width: 17px;
      height: 11px;
      background-image: url(/assets/images/common/ico_arrow_down_01_pc.png);
      background-repeat: no-repeat;
      background-size: contain;

      @include mq-down(md) {
        background-image: url(/assets/images/common/ico_arrow_down_01.png);
        width: 13px;
        height: 8px;
        bottom: 12px;
      }
    }

    &.-is-text {
      padding-bottom: 77px;

      @include mq-down(md) {
        padding-bottom: 58px;
      }

      &::before {
        content: 'ご契約';
        position: absolute;
        display: block;
        font-size: 1.6rem;
        font-weight: 600;
        color: $black;
        bottom: 39px;
        left: 50%;
        transform: translateX(-50%);

        @include mq-down(md) {
          bottom: 22px;
        }
      }

      &::after {
        bottom: 20px;

        @include mq-down(md) {
          bottom: 11px;
        }
      }
    }
  }
}

/* c-list-faq
--------------------------------------------------------- */
.c-list-faq {
  @include mq-up(md) {
    padding-left: 100px;
    padding-right: 100px;
  }

  > .item {
    &:first-child {
      .c-box-faq {
        border-top: none;
      }
    }

    &:last-child {
      .c-box-faq {
        border-bottom: 1px dotted $color-gray-10;
      }
    }
  }
}


/* c-list-quote
--------------------------------------------------------- */
.c-list-quote {
  display: flex;
  flex-wrap: wrap;

  @include mq-up(md) {
    margin-left: -34px;
  }

  @include mq-up(md) {
    justify-content: center;
  }

  > .item {
    @include mq-up(md) {
      width: 391px;
      padding-left: 34px;
    }

    &:nth-child(even) {
      @include mq-down(md) {
        .image {
          text-align: right;
        }
      }
    }
  }

  .image {
    @include mq-down(md) {
      width: calc(100vw - 30px)
    }

    img {
      width: 100%;

      @include mq-down(md) {
        width: 72.467%
      }
    }
  }
}

/* c-list-note
--------------------------------------------------------- */
.c-list-note {
  > .item {
    display: flex;
    align-items: baseline;

    @include mq-up(md) {
      justify-content: center;
    }

    &::before {
      @include mq-down(md) {
        content: '・';
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 2.5;
      }
    }
  }

  .c-text-underline span {
    @include mq-up(md) {
      &::before {
        content: '・';
        font-size: 2rem;
        font-weight: 700;
        line-height: 3;
      }
    }
  }
}
