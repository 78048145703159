/* c-card-customer
---------------------------------------------------------- */
.c-card-customer {
  .image {
    position: relative;
    border-radius: 3px;
    overflow: hidden;
    clip-path: content-box;

    @include mq-up(md) {
      height: 220px;
    }

    > * {
      @include mq-up(md) {
        height: 220px;
        border-radius: 3px;
      }
    }

    img {
      width: 100%;
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 1.3rem; 
      font-weight: 700;
      color: $white;
      text-align: center;
      padding: 6px 21px 5px;

      @include mq-down(md) {
        padding: 5px 17px 4px;
      }
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 40px;
      height: 40px;
      background-image: url(/assets/images/common/ico_arrow_right_01.png);
      background-size: 7px 12px;
      background-repeat: no-repeat;
      background-color: $color-green-01;
      background-position: center;
    }
  }

  .box-detail {
    position: relative;
    margin-top: 22px;

    @include mq-down(md) {
      margin-top: 16px;
    }

    > .name {
      font-size: 1.4rem;
      font-weight: 700;

      @include mq-down(md) {
        font-size: 1.4rem;
      }
    }

    > .title {
      font-size: 1.8rem;
      font-weight: 600;
      line-height: 1.7;
      margin-top: 8px;

      @include mq-down(md) {
        font-size: 1.6rem;
        line-height: 1.6;
        margin-top: 5px;
      }
    }

    > .text {
      font-size: 1.4rem;
      line-height: 2;

      @include mq-down(md) {
        font-size: 1.2rem;
        line-height: 1.65;
        margin-top: 4px;
      }
    }
  }
}

/* c-card-know
---------------------------------------------------------- */
.c-card-know {
  .image {
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    clip-path: content-box;

    @include mq-up(md) {
      height: 220px;
    }

    > * {
      @include mq-up(md) {
        height: 220px;
        border-radius: 5px;
      }
    }

    @include mq-down(md) {
      border-radius: 2px;
    }

    img {
      width: 100%;
    }

    &.-arrow {
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 40px;
        height: 40px;
        background-image: url(/assets/images/common/ico_arrow_right_01.png);
        background-size: 7px 12px;
        background-repeat: no-repeat;
        background-color: $color-green-01;
        background-position: center;

        @include mq-down(md) {
          width: 37px;
          height: 37px;
        }
      }
    }
  }

  .box-detail {
    position: relative;
    margin-top: 14px;

    @include mq-down(md) {
      margin-top: 10px;
    }

    .box-category {
      display: flex;
      align-items: center;
      justify-content: space-between;

      > .date {
        font-size: 1.4rem;
        color: $color-green-01;

        @include mq-down(md) {
          font-size: 1.2rem;
        }
      }
    }

    .category {
      display: inline-block;
      min-width: 120px;
      font-size: 1.2rem;
      text-align: center;
      color: $white;
      background-color: $black;
      border-radius: 3px;
      padding: 4px 13px 2px;

      @include mq-down(md) {
        font-size: 1.2rem;
        min-width: 130px;
        padding: 4px 13px 4px;
      }
    }

    > .title {
      font-weight: 700;
      line-height: 1.7;
      margin-top: 15px;

      @include mq-down(md) {
        font-size: 1.4rem;
        line-height: 1.6;
        margin-top: 7px;
      }

      &.-md {
        @include mq-down(md) {
          font-size: 1.6rem;
          line-height: 1.6;
          margin-top: 14px;
        }
      }
    }

    > .date {
      font-size: 1.4rem;
      color: $color-gray-01;
      margin-top: 3px;
    }

    > .text {
      font-size: 1.4rem;
      line-height: 2;
      margin-top: 2px;

      @include mq-down(md) {
        font-size: 1.2rem;
        line-height: 1.6;
        margin-top: 5px;
      }
    }

    > .link-detail {
      font-size: 1.4rem;
      text-align: right;
      margin-top: 3px;

      &.-sm {
        @include mq-down(md) {
          font-size: 1.2rem;
          margin-top: 0;
        }
      }
    }
  }

  &.-top {
    .image {
      border-radius: 3px;
      clip-path: content-box;

      @include mq-up(md) {
        height: 165px;
      }

      > * {
        @include mq-up(md) {
          height: 165px;
          border-radius: 3px;
        }
      }
    }

    .box-detail {
      margin-top: 11px;
  
      @include mq-down(md) {
        margin-top: 10px;
      }
    }
  }
}

/* c-card-voice
---------------------------------------------------------- */
.c-card-voice {
  .image {
    border-radius: 30px;
    overflow: hidden;
    clip-path: content-box;

    @include mq-up(md) {
      height: 255px;
    }

    > * {
      @include mq-up(md) {
        height: 255px;
        border-radius: 30px;
      }
    }

    @include mq-down(md) {
      border-radius: 14px;
    }

    img {
      width: 100%;
    }
  }

  .box-detail {
    position: relative;
    width: calc(100% - 20px);
    margin: 0 auto;
    padding: 32px 30px 17px;
    background-color: $white;
    border-radius: 6px;
    box-shadow: 0px 8px 8px 0px rgba(142, 142, 142, .2);
    margin-top: -32px;
    z-index: 2;

    @include mq-down(md) {
      padding: 28px 26px 18px;
    }

    >.text-desc-en {
      font-size: 1.2rem;
      font-weight: 700;
      color: $color-green-01;
    }

    >.text-desc {
      font-size: 1.4rem;
      font-weight: 700;
      color: $color-gray-01;
      margin-top: 4px;

      @include mq-down(md) {
        font-size: 1.2rem;
      }
    }

    >.title {
      font-size: 1.8rem;
      font-weight: 700;
      color: $color-gray-01;
      margin-top: 15px;
      letter-spacing: -.05rem;

      @include mq-down(md) {
        font-size: 1.6rem;
        margin-top: 10px;
      }
    }
  }

  .list-voice {
    margin-top: 7px;

    @include mq-down(md) {
      margin-top: 12px;
    }

    >.item {
      display: flex;

      &:not(:first-child) {
        margin-top: 4px;

        @include mq-down(md) {
          margin-top: 1px;
        }
      }

      >* {
        font-size: 1.2rem;
        color: $color-gray-06;
      }

      >.title {
        position: relative;
        width: 50px;
        flex: 1 0 auto;

        &::after {
          position: absolute;
          right: 0;
          top: 0;
          content: '：';
          display: inline-block;
        }
      }

      >.text {
        width: 100%;
      }
    }
  }
}

/* c-card-seminar
---------------------------------------------------------- */
.c-card-seminar {
  .image {
    position: relative;
    border-radius: 3px;
    overflow: hidden;
    clip-path: content-box;

    @include mq-up(md) {
      height: 220px;
    }

    > * {
      @include mq-up(md) {
        height: 220px;
        border-radius: 3px;
      }
    }

    img {
      width: 100%;
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 1.3rem;
      font-weight: 700;
      color: $white;
      text-align: center;
      padding: 6px 21px 5px;
      z-index: 2;

      @include mq-down(md) {
        padding: 5px 17px 4px;
      }
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 40px;
      height: 40px;
      background-image: url(/assets/images/common/ico_arrow_right_01.png);
      background-size: 7px 12px;
      background-repeat: no-repeat;
      background-color: $color-green-01;
      background-position: center;
      z-index: 2;
    }

    &.-accepting {
      &::before {
        content: '受付中';
        background-color: $color-orange-01;
      }
    }

    &.-accep-end {
      &::before {
        content: '終了';
        background-color: $color-gray-07;
      }
    }
  }

  .box-detail {
    position: relative;
    margin-top: 20px;

    @include mq-down(md) {
      margin-top: 16px;
    }

    > .date {
      font-size: 1.4rem;
      color: $color-green-01;

      @include mq-down(md) {
        font-size: 1.2rem;
      }
    }

    > .place {
      font-size: 1.4rem;
      color: $color-gray-07;
      margin-top: 5px;

      @include mq-down(md) {
        font-size: 1.2rem;
      }
    }

    > .title {
      font-weight: 700;
      line-height: 1.7;
      margin-top: 4px;

      @include mq-down(md) {
        font-size: 1.6rem;
        line-height: 1.6;
        margin-top: 5px;
      }
    }

    > .box-text {
      font-size: 1.3rem;
      color: $color-green-01;
      text-align: center;
      border: 1px solid $color-green-01;
      border-radius: 3px;
      padding: 8px 10px 5px;
      margin-top: 25px;

      @include mq-down(md) {
        font-size: 1.2rem;
        padding: 6px 10px 6px;
        margin-top: 13px;
      }

      &.-grey {
        color: $color-main;
        border: 1px solid #969696;
      }
    }

    > .link-detail {
      font-size: 1.4rem;
      text-align: right;
      margin-top: 7px;

      @include mq-down(md) {
        font-size: 1.2rem;
        margin-top: 2px;
      }
    }
  }

  .list-recommend {
    margin-top: 15px;
    margin-left: 6px;

    @include mq-down(md) {
      margin-top: 10px;
      margin-left: 7px;
    }

    > .item {
      position: relative;
      display: flex;
      font-size: 1.4rem;
      padding-left: 20px;
      color: $color-gray-11;

      @include mq-down(md) {
        font-size: 1.2rem;
        padding-left: 12px;
      }

      &::before {
        content: '';
        position: absolute;
        top: 7px;
        left: 7px;
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 6px;
        background-color: $color-main;

        @include mq-down(md) {
          width: 5px;
          height: 5px;
          top: 6px;
          left: 0;
        }
      }

      &:not(:first-child) {
        margin-top: 5px;

        @include mq-down(md) {
          margin-top: 1px;
        }
      }
    }
  }
}

/* c-card-seminar-timeline
---------------------------------------------------------- */
.c-card-seminar-timeline {
  display: flex;

  > .box-detail {
    padding-right: 36px;

    @include mq-up(md) {
      flex: 1 0 auto;
      width: 680px;
    }

    @include mq-down(md) {
      width: 100%;
      padding-right: 20px;
    }

    .title {
      line-height: 1.9;
    }

    .detail {
      font-size: 1.4rem;
      line-height: 2;
      margin-top: 6px;

      @include mq-down(md) {
        font-size: 1.2rem;
        line-height: 1.6;
        margin-top: 10px;
      }
    }

    .speaker {
      font-size: 1.4rem;
      line-height: 2;
      margin-top: 3px;

      @include mq-down(md) {
        font-size: 1.2rem;
        line-height: 1.6;
        margin-top: 20px;
      }
    }
  }

  > .image {
    width: 100%;
    margin-top: 3px;
    clip-path: content-box;

    @include mq-down(md) {
      flex: 1 0 auto;
      width: 85px;
    }
  }
}

/* c-card-seminar-report
---------------------------------------------------------- */
.c-card-seminar-report {
  display: flex;

  @include mq-down(md) {
    display: block;
  }

  .image {
    flex: 0 0 auto;
    overflow: hidden;
    width: 370px;
    position: relative;
    margin-right: 42px;
    clip-path: content-box;
    
    @include mq-up(md) {
      height: 220px;
    }

    > * {
      @include mq-up(md) {
        height: 220px;
      }
    }

    @include mq-down(md) {
      width: 100%;
      margin-right: 0;
    }

    img {
      width: 100%;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 40px;
      height: 40px;
      background-image: url(/assets/images/common/ico_arrow_right_01.png);
      background-size: 7px 12px;
      background-repeat: no-repeat;
      background-color: $color-green-01;
      background-position: center;
    }
  }

  .box-detail {
    position: relative;
    margin-top: 3px;

    @include mq-down(md) {
      margin-top: 15px;
    }

    > .date {
      font-size: 1.4rem;
      color: $color-green-01;

      @include mq-down(md) {
        font-size: 1.2rem;
      }
    }

    > .title {
      font-size: 2rem;
      font-weight: 500;
      line-height: 1.7;
      margin-top: 4px;

      @include mq-down(md) {
        font-size: 1.6rem;
        line-height: 1.6;
        margin-top: 5px;
      }
    }

    > .detail {
      font-size: 1.4rem;
      color: $color-main;
      margin-top: 10px;

      @include mq-down(md) {
        font-size: 1.2rem;
        margin-top: 3px;
      }
    }

    > .link-detail {
      font-size: 1.4rem;
      text-align: right;
      margin-top: 7px;

      @include mq-down(md) {
        font-size: 1.2rem;
        margin-top: 2px;
      }
    }
  }
}

/* c-card-feature
---------------------------------------------------------- */
.c-card-feature {
  position: relative;
  height: 100%;
  padding: 91px 30px 30px;
  background-color: $color-lightgreen-01;
  border-radius: $border-radius-pc;

  @include mq-down(md) {
    padding: 70px 24px 23px 26px;
    border-radius: $border-radius-sp;
  }

  .number {
    position: absolute;
    width: 100%;
    text-align: center;
    top: -33px;
    left: 0;

    @include mq-down(md) {
      top: -24px;
    }

    img {
      @include mq-down(md) {
        height: 71px;
      }
    }
  }

  .title {
    font-size: 2.6rem;
    font-weight: 700;

    @include mq-down(md) {
      font-size: 2.2rem;
    }
  }

  .text {
    line-height: 2;
    margin-top: 9px;
  }

  > .icon {
    display: block;
    text-align: center;
    margin-top: 20px;

    @include mq-down(md) {
      margin-top: 34px;
    }

    &.-icon-01 {
      img {
        @include mq-down(md) {
          height: 60px;
        }
      }
    }

    &.-icon-02 {
      img {
        @include mq-down(md) {
          height: 63px;
        }
      }
    }

    &.-icon-03 {
      img {
        @include mq-down(md) {
          height: 68px;
        }
      }
    }
  }
}

/* c-card-service-menu
---------------------------------------------------------- */
.c-card-service-menu {
  @include mq-up(md) {
    display: flex;
    align-items: flex-start;
  }

  > .image {
    @include mq-up(md) {
      width: 785px;
      flex: 1 0 auto;
      border-radius: $border-radius-pc;
      clip-path: content-box;
    }

    img {
      @include mq-down(md) {
        width: 76%;
        border-radius: $border-radius-sp;
      }
    }
  } 

  > .detail {
    position: relative;
    margin-top: 60px;

    @include mq-down(md) {
      display: flex;
      z-index: 1;
      margin-top: -23px;
    }

    @include mq-up(md) {
      width: 100%;
      z-index: 1;
      margin-top: 253px;
    }

    > .inner {
      position: relative;
      background-color: $color-gray-08;
      padding: 87px 24px 42px 46px;
      border-radius: $border-radius-pc;

      @include mq-down(md) {
        width: 87%;
        padding: 67px 20px 25px 20px;
        border-radius: $border-radius-sp;
      }
    }

    .number {
      position: absolute;
      top: -33px;
      left: 45px;

      @include mq-down(md) {
        top: -24px;
        left: 24px;

        img {
          height: 71px;
        }
      }
    }

    .c-heading-01 {
      letter-spacing: 0;

      @include mq-up(md) {
        font-size: 2.8rem;
        margin-bottom: 13px;
      }

      @include mq-down(md) {
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 1.6;
        margin-bottom: 13px;
      }
    }

    .text {
      line-height: 2;
      color: $color-gray-11;

      @include mq-down(md) {
        font-size: 1.4rem;
        line-height: 2;
      }
    }

    .c-btn {
      margin-top: 15px;
      padding: 16px 12px 14px 19px;

      @include mq-down(md) {
        margin-top: 12px;
        padding: 13px 12px 11px 19px;
      }
    }
  } 
}
