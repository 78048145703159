/* p-cms-detail
--------------------------------------------------------- */
.p-cms-detail {
  .c-heading-01.-line {
    padding: 35px 0 25px;
  }

  .c-box-link {
    margin-top: 60px;

    .title {
      @include mq-down(md) {
        font-size: 1.2rem;
        margin-bottom: 5px;
      }
    }

    @include mq-down(md) {
      margin-top: 50px;
      padding: 32px 0 24px 10px;
    }

    a {
      color: $color-blue-01;
    }
    a.-pdf {
      color: $black;
    }    
  }

  .c-box-speaker {
    @include mq-down(md) {
      margin-top: 60px;
    }

    .box-detail {
      padding-right: 34px;

      @include mq-down(md) {
        padding-right: 0;
      }

      p {
        @include mq-up(md) {
          font-size: 1.4rem;
        }
      }
    }
  }

  .c-box-button {
    @include mq-down(md) {
      margin-top: 44px;
    }
  }
}
