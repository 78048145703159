/* p-office-intro
---------------------------------------------------------- */
.p-office-intro {
  padding-top: 90px;
  border-top: 1px dotted $color-gray-10;

  @include mq-down(md) {
    padding-top: 57px;
  }

  .title {
    position: relative;
    font-size: 3.8rem;
    font-weight: 700;
    color: $color-gray-01;
    line-height: 1.25;
    text-align: center;
    padding-bottom: 16px;
    margin-bottom: 49px;

    @include mq-down(md) {
      font-size: 2.7rem;
      padding-bottom: 8px;
      margin-bottom: 21px;
    }
  

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      background-color: $color-gray-01; 
      width: 112px;
      height: 6px;

      @include mq-down(md) {
        width: 78px;
        height: 5px;
      }
    }
    
    > .highlight {
      font-size: 4rem;
      color: $color-green-01;

      @include mq-down(md) {
        font-size: 2.8rem;
      }
    
    }

    > .en {
      display: block;
      font-size: 5rem;
      font-family: $font-DINAlternateBold;

      @include mq-down(md) {
        font-size: 3rem;
      }
    }
  }

  .list-intro {
    @include mq-up(md) {
      display: flex;
      margin: 0 -10px;
    }

    &-wrap {
      padding: 26px 21px;
      background-color: $color-green-01;
      border-radius: $border-radius-sp;

      @include mq-up(md) {
        margin-top: 41px;
        padding: 41px 40px;
        border-radius: $border-radius-pc;
      }
    }

    > .item {
      @include mq-up(md) {
        width: 33.33%;
        padding: 0 10px;
      }

      &:not(:first-child) {
        @include mq-down(md) {
          margin-top: 18px;
        }
      }

      img {
        width: 100%;
      }
    }
  }
}

/* p-office-recommend
---------------------------------------------------------- */
.p-office-recommend {
  padding-top: 149px;

  @include mq-down(md) {
    padding-top: 74px;
  }

  .box-recommend {
    padding: 33px 20px 27px;
    background-color: $color-gray-08;
    border-radius: $border-radius-pc;

    @include mq-down(md) {
      padding: 26px 22px 23px;
      border-radius: $border-radius-sp;
    }
  }
}

/* p-office-voice
---------------------------------------------------------- */
.p-office-voice {
  padding-top: 100px;

  @include mq-down(md) {
    padding-top: 50px;
  }

  .c-heading-05 {
    margin-bottom: 45px;

    @include mq-down(md) {
      margin-bottom: 28px;
    }
  }

  .c-list-quote {
    @include mq-up(md) {
      margin-left: -15px;
      margin-right: -15px;
    }

    > .item {
      @include mq-up(md) {
        width: 404px;
        padding-left: 15px;
        padding-right: 15px;
      }

      &:not(:first-child) {
        @include mq-down(md) {
          margin-top: 13px;
        }
      }
    }

    img {
      @include mq-down(md) {
        width: 89.4%;
      }
    }
  }
}

/* p-office-movie
---------------------------------------------------------- */
.p-office-movie {
  padding-top: 90px;
  padding-bottom: 59px;

  @include mq-down(md) {
    padding-top: 61px;
    padding-bottom: 37px;
  }

  &.c-bg-full { 
    &::before {
      height: calc(100% - 351px);

      @include mq-down(md) {
        height: calc(100% - 235px);
      }
    }
  }

  .box-title {
    position: relative;
    padding-top: 91px;
    margin-bottom: 24px;

    @include mq-down(md) {
      padding-top: 63px;
      margin-bottom: 16px;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 159px;
      width: 406px;
      height: 65px;
      background-image: url(/assets/images/liveoffice/txt_movie_pc.png);
      background-size: contain;
      background-repeat: no-repeat;

      @include mq-down(md) {
        right: 0;
        width: 305px;
        height: 48px;
        background-image: url(/assets/images/liveoffice/txt_movie_sp.png);
      }
    }

    > .c-heading-05 {
      margin-bottom: -6px;
    }
  
    > .title-en {
      font-family: $font-roboto;
      font-size: 2.4rem;
      font-weight: 500;
      line-height: 1.75;
      color: $color-gray-01;
      text-align: center;

      @include mq-down(md) {
        font-size: 1.6rem;
      }
    } 
  
    > .sub-title {
      font-size: 2rem;
      font-weight: 700;
      color: $color-gray-01;
      text-align: center;

      @include mq-down(md) {
        font-size: 1.4rem;
      }
    }
  }

  .box-content {
    @include mq-up(md) {
      width: 854px;
      margin: 0 auto;
    }

    > .c-box-youtube {
      margin-top: 0;
    }

    > .banner {
      text-align: center;
      margin-top: 69px;

      @include mq-down(md) {
        margin-top: 38px;
      }
    }

    > .c-btn-center {
      margin-top: 65px;

      @include mq-down(md) {
        margin-top: 35px;
      }
    }

    .c-btn {
      font-weight: 700;

      @include mq-down(md) {
        width: calc(100% - 50px);
      }
    }
  }
}

/* p-office-kotae
---------------------------------------------------------- */
.p-office-kotae {
  padding-top: 68px;

  @include mq-down(md) {
    padding-top: 70px;
  } 

  .l-inner {
    > .title {
      position: relative;
      font-size: 3rem;
      font-weight: 700;
      color: $color-gray-01;
      line-height: 1.25;
      text-align: center;
      padding-top: 64px;
      padding-bottom: 58px;
      margin-bottom: 86px;
      border-top: 1px dotted $color-gray-10;
      border-bottom: 1px dotted $color-gray-10;
  
      @include mq-down(md) {
        font-size: 2.2rem;
        padding-top: 47px;
        padding-bottom: 28px;
        margin-bottom: 35px;
        line-height: 1.45;
      }
      
      > .highlight {
        font-size: 3.2rem;
        color: $color-green-01;
  
        @include mq-down(md) {
          font-size: 2.2rem;
        }
      
      }
    }
  }

  .list-kotae {
    @include mq-up(md) {
      display: flex;
      flex-wrap: wrap;
      margin: -55px -30px 0;
    }

    > .item {
      @include mq-up(md) {
        width: 33.33%;
        padding: 0 30px;
        margin-top: 55px;
      }

      &:not(:first-child) {
        @include mq-down(md) {
          margin-top: 28px;
        }
      }
    }

    .image {
      overflow: hidden;
      border-radius: $border-radius-pc;
      clip-path: content-box;

      @include mq-down(md) {
        border-radius: $border-radius-sp;
      }

      img {
        width: 100%;
      }
    }

    .box-detail {
      display: flex;
      align-items: center;

      > .number {
        font-size: 8rem;
        font-family: $font-DINAlternateBold;
        font-weight: 700;
        color: #064fa0;

        @include mq-down(md) {
          font-size: 7rem;
          line-height: 1.2;
        }
      }

      > .title {
        font-size: 2rem;
        font-weight: 700;
        line-height: 1.4;
        color: $color-gray-01;
        margin-left: 17px;

        @include mq-down(md) {
          font-size: 1.6rem;
          line-height: 1.65;
          margin-left: 13px;
        }
      }
    }
  }
}

/* p-office-procedure
---------------------------------------------------------- */
.p-office-procedure {
  padding-top: 91px;

  @include mq-down(md) {
    padding-top: 68px;
  }

  .c-heading-03 {
    letter-spacing: 0;
    padding: 64px 0 42px;
    margin-bottom: 85px;

    @include mq-down(md) {
      padding: 47px 0 34px;
      margin-bottom: 70px;
    }
  }

  .list-procedure {
    @include mq-up(md) {
      display: flex;
      flex-wrap: wrap;
      margin: -55px -11px 0;
      padding: 0 7px;
    }

    @include mq-down(md) {
      padding: 0 15px;
    }

    > .item {
      @include mq-up(md) {
        width: 25%;
        padding: 0 11px;
        margin-top: 55px;
      }

      @include mq-down(md) {
        &:nth-child(1) {
          .icon img {
            height: 47px;
          }
        }

        &:nth-child(2) {
          .icon img {
            height: 57px;
          }
        }

        &:nth-child(3) {
          .icon img {
            height: 64px;
          }
        }

        &:nth-child(4) {
          .icon img {
            height: 51px;
          }
        }

        &:not(:first-child) {
          margin-top: 62px;
        } 
      }
    }

    .box-detail {
      position: relative;
      border-radius: $border-radius-pc;
      background-color: $color-lightgreen-01;
      padding: 62px 20px 14px;

      @include mq-up(md) {
        min-height: 240px;
        height: 100%;
      }

      @include mq-down(md) {
        display: flex;
        flex-direction: column;
        border-radius: $border-radius-sp;
        padding-top: 43px;
        padding-bottom: 10px;
      }

      > .title {
        position: absolute;
        top: -55px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 100px;
        border-radius: 100px;
        font-size: 1.8rem;
        font-weight: 700;
        color: $white;
        line-height: 1.35;
        text-align: center;
        padding: 9px 10px 0;
        background-color: $color-green-01;

        @include mq-down(md) {
          top: -47px;
          width: 85px;
          height: 85px;
          font-size: 1.5rem;
          padding: 8px 10px 0;
        }
      }

      > .text {
        font-size: 1.4rem;
        line-height: 1.8;
        color: $color-gray-01;

        @include mq-down(md) {
          line-height: 2;
        }
      }

      > .icon {
        @include mq-up(md) {
          position: absolute;
          bottom: 14px;
          right: 14px;
        }

        @include mq-down(md) {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}

/* p-office-post
---------------------------------------------------------- */
.p-office-post {
  padding-top: 81px;

  @include mq-down(md) {
    padding-top: 66px;
    padding-bottom: 9px;
  }

  .c-heading-03 {
    padding: 58px 0 51px;
    margin-bottom: 43px;

    @include mq-down(md) {
      padding: 45px 0 28px;
      margin-bottom: 40px;
    }
  }

  .list-post {
    @include mq-up(md) {
      display: flex;
      flex-wrap: wrap;
      margin: -83px -30px 0;
    }

    @include mq-down(md) {
      padding: 0 15px;
    }

    > .item {
      @include mq-up(md) {
        width: 33.33%;
        padding: 0 30px;
        margin-top: 83px;
      }

      &:not(:first-child) {
        @include mq-down(md) {
          margin-top: 32px;
        }
      }
    }

    .image {
      border-radius: $border-radius-pc;
      overflow: hidden;
      clip-path: content-box;
  
      @include mq-up(md) {
        height: 240px;
      }
  
      > * {
        @include mq-up(md) {
          height: 240px;
          border-radius: $border-radius-pc;
        }
      }
  
      @include mq-down(md) {
        border-radius: $border-radius-sp;
      }
  
      img {
        width: 100%;
      }
    }

    .box-detail {
      margin-top: 15px;

      @include mq-down(md) {
        margin-top: 8px;
      }

      > .title {
        font-size: 2rem;
        font-weight: 600;
        color: $color-gray-01;
        line-height: 1.4;
        letter-spacing: -.05rem;

        @include mq-down(md) {
          font-size: 1.7rem;
          line-height: 1.3;
        }
      }

      > .address {
        font-size: 1.6rem;
        color: $color-gray-06;
        line-height: 1.8;
        margin-top: 11px;

        @include mq-down(md) {
          font-size: 1.4rem;
        }
      }

      > .text {
        font-size: 1.4rem;
        color: $color-gray-06;
        line-height: 1.9;

        @include mq-down(md) {
          font-size: 1.2rem;
        }
      }
    }

    .list-button {
      display: flex;
      margin: 7px -2px 0;

      @include mq-down(md) {
        margin-top: 5px;
      }

      > .item {
        width: 50%;
        padding: 0 2px;
      } 
      
      .button {
        display: block;
        border: 1px solid #a4a4a4;
        border-radius: 3px;
        background-color: $white;
        text-align: center;
        padding: 5px;
        transition: background-color .3s ease-out;

        @include mq-down(md) {
          padding: 4px 5px;
        }

        > span {
          position: relative;
          font-size: 1.4rem;
          padding-left: 17px;
          transition: color .3s ease-out;

          @include mq-down(md) {
            font-size: 1.2rem;
          }

          &::before {
            content: '';
            position: absolute;
            display: block;
            width: 14px;
            height: 14px;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            background-image: url(/assets/images/common/ico_arrow_black.png);
            background-repeat: no-repeat;
            background-size: 14px 14px;
            transition: background-image .3s ease-out;

            @include mq-down(md) {
              width: 12px;
              height: 12px;
              background-size: 12px 12px;
            }
          }
        }

        &:hover {
          background-color: $color-gray-01;

          > span {
            color: $white;
            
            &::before {
              background-image: url(/assets/images/common/ico_arrow_white.png);
            }
          }
        }
      }
    }
  } 
}