.c-bg-full {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  &.-green {
    &::before {
      background-color: $color-lightgreen-01;
    }
  }

  &.-gray {
    &::before {
      background-color: $color-gray-08;
    }
  }
}

.c-bg-left {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(((100% - 1400px) / 2) + 1350px);
    height: 100%;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    z-index: -1;

    @include mq-down(md) {
      width: calc(100% - 25px);
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }

  &.-green {
    &::before {
      background-color: $color-lightgreen-01;
    }
  }
}

.c-bg-right {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: calc(((100% - 1400px) / 2) + 1350px);
    height: 100%;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    z-index: -1;

    @include mq-down(md) {
      width: calc(100% - 15px);
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }
  }

  &.-green {
    &::before {
      background-color: $color-lightgreen-01;
    }
  }

  &.-gray {
    &::before {
      background-color: $color-gray-08;
    }
  }
}