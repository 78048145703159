@import '_variables';
@import '_function';
@import '_keyframe';

/* ==========================================================
 fonts
========================================================== */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

@font-face {
  font-family: "DINAlternateBold";
  font-style: normal;
  font-weight: 700;
  src: local("Noto Sans CJK JP Light"),
    url("/assets/webfonts/DINAlternate-Bold.woff") format("woff"),
    url("/assets/webfonts/DINAlternate-Bold.ttf") format("truetype"),
}

/* ==========================================================
 vendor
========================================================== */
@import "vendor/slick.scss";

/* ==========================================================
 mixin
========================================================== */
@import "mixin/_hover.scss";
@import "mixin/_mediaquery.scss";
@import "mixin/_spacing.scss";
@import "mixin/_width.scss";

/* ==========================================================
 base
========================================================== */
@import "common/layout/_base.scss";
@import "common/layout/_grid.scss";
@import "common/layout/_reset.scss";
@import "common/layout/_section.scss";

/* ==========================================================
 components
========================================================== */
@import "common/components/_box.scss";
@import "common/components/_breadcrumb.scss";
@import "common/components/_button.scss";
@import "common/components/_card.scss";
@import "common/components/_cms.scss";
@import "common/components/_footer.scss";
@import "common/components/_form.scss";
@import "common/components/_header.scss";
@import "common/components/_heading.scss";
@import "common/components/_input.scss";
@import "common/components/_link.scss";
@import "common/components/_list.scss";
@import "common/components/_pagination.scss";
@import "common/components/_search.scss";
@import "common/components/_section.scss";
@import "common/components/_sidebar.scss";
@import "common/components/_slick-theme.scss";
@import "common/components/_tag.scss";
@import "common/components/_text.scss";

/* ==========================================================
 pages
========================================================== */
@import "pages/_404.scss";
@import "pages/_case-detail.scss";
@import "pages/_case.scss";
@import "pages/_cms-detail.scss";
@import "pages/_customer-detail.scss";
@import "pages/_customers.scss";
@import "pages/_features.scss";
@import "pages/_form.scss";
@import "pages/_knowhow.scss";
@import "pages/_liveoffice.scss";
@import "pages/_osaka.scss";
@import "pages/_request.scss";
@import "pages/_seminar-detail.scss";
@import "pages/_seminar-form.scss";
@import "pages/_seminar-report.scss";
@import "pages/_service.scss";
@import "pages/_top.scss";

/* ==========================================================
 helpers
========================================================== */
@import "common/helpers/_background.scss";
@import "common/helpers/_display.scss";
@import "common/helpers/_flex.scss";
@import "common/helpers/_hover.scss";
@import "common/helpers/_spacing.scss";
@import "common/helpers/_text.scss";
