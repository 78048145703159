/* p-section-seminar
---------------------------------------------------------- */
.p-section-seminar {
  padding-top: 80px;
  border-top: 1px dotted $color-gray-04;

  @include mq-down(md) {
    padding-top: 52px;
  }
}

.p-section-seminar-report {
  margin-top: 196px;

  @include mq-down(md) {
    margin-top: 90px;
  }
}
