/* l-grid-row
---------------------------------------------------------- */
.l-grid-row {
  display: flex;
  flex-wrap: wrap;

  // grid gutter
  $space: (5, 10, 15, 20, 25, 30);

  @each $gutter in $space {
    &.-gutter_#{$gutter} {
      margin-top: -#{$gutter * 2}px !important;
      margin-left: -#{$gutter}px !important;
      margin-right: -#{$gutter}px !important;

      > [class^="col"] {
        margin-top: #{$gutter * 2}px !important;
        padding-left: #{$gutter}px !important;
        padding-right: #{$gutter}px !important;
      }
    }

    &.-gutter_x_#{$gutter} {
      margin-left: -#{$gutter}px !important;
      margin-right: -#{$gutter}px !important;

      > [class^="col"] {
        padding-left: #{$gutter}px !important;
        padding-right: #{$gutter}px !important;
      }
    }

    &.-gutter_y_#{$gutter} {
      margin-top: -#{$gutter * 2}px !important;

      > [class^="col"] {
        margin-top: #{$gutter * 2}px !important;
      }
    }
  }

  @include mq-down(md) {
    @each $gutter in $space {
      &.-gutter_sm_#{$gutter} {
        margin-top: -#{$gutter * 2}px !important;
        margin-left: -#{$gutter}px !important;
        margin-right: -#{$gutter}px !important;

        > [class^="col"] {
          margin-top: #{$gutter * 2}px !important;
          padding-left: #{$gutter}px !important;
          padding-right: #{$gutter}px !important;
        }
      }

      &.-gutter_x_sm_#{$gutter} {
        margin-left: -#{$gutter}px !important;
        margin-right: -#{$gutter}px !important;

        > [class^="col"] {
          padding-left: #{$gutter}px !important;
          padding-right: #{$gutter}px !important;
        }
      }

      &.-gutter_y_sm_#{$gutter} {
        margin-top: -#{$gutter * 2}px !important;

        > [class^="col"] {
          margin-top: #{$gutter * 2}px !important;
        }
      }
    }
  }

  @include mq-up(md) {
    @each $gutter in $space {
      &.-gutter_md_#{$gutter} {
        margin-top: -#{$gutter * 2}px !important;
        margin-left: -#{$gutter}px !important;
        margin-right: -#{$gutter}px !important;

        > [class^="col"] {
          margin-top: #{$gutter * 2}px !important;
          padding-left: #{$gutter}px !important;
          padding-right: #{$gutter}px !important;
        }
      }

      &.-gutter_x_md_#{$gutter} {
        margin-left: -#{$gutter}px !important;
        margin-right: -#{$gutter}px !important;

        > [class^="col"] {
          padding-left: #{$gutter}px !important;
          padding-right: #{$gutter}px !important;
        }
      }

      &.-gutter_y_md_#{$gutter} {
        margin-top: -#{$gutter * 2}px !important;

        > [class^="col"] {
          margin-top: #{$gutter * 2}px !important;
        }
      }
    }
  }

  @include mq-up(lg) {
    @each $gutter in $space {
      &.-gutter_lg_#{$gutter} {
        margin-top: -#{$gutter * 2}px !important;
        margin-left: -#{$gutter}px !important;
        margin-right: -#{$gutter}px !important;

        > [class^="col"] {
          margin-top: #{$gutter * 2}px !important;
          padding-left: #{$gutter}px !important;
          padding-right: #{$gutter}px !important;
        }
      }

      &.-gutter_x_lg_#{$gutter} {
        margin-left: -#{$gutter}px !important;
        margin-right: -#{$gutter}px !important;

        > [class^="col"] {
          padding-left: #{$gutter}px !important;
          padding-right: #{$gutter}px !important;
        }
      }

      &.-gutter_y_lg_#{$gutter} {
        margin-top: -#{$gutter * 2}px !important;

        > [class^="col"] {
          margin-top: #{$gutter * 2}px !important;
        }
      }
    }
  }

  > .col {
    $i: 1;
    $col-count: 12 !default;
    $col-width: 100% / $col-count;

    width: 100%;

    @function col($columns) {
      @return $col-width * $columns;
    }

    @for $i from $col-count through 1 {
      &-#{$i} {
        width: $col-width * $i;
      }
    }

    @include mq-down(md) {
      @for $i from $col-count through 1 {
        &-sm-#{$i} {
          width: $col-width * $i;
        }
      }
    }

    @include mq-up(md) {
      @for $i from $col-count through 1 {
        &-md-#{$i} {
          width: $col-width * $i;
        }
      }
    }

    @include mq-up(lg) {
      @for $i from $col-count through 1 {
        &-lg-#{$i} {
          width: $col-width * $i;
        }
      }
    }
  }

  .w-100 {
    width: 100% !important;
  }
}
