/* c-list-pagination
---------------------------------------------------------- */
.c-list-pagination {
  @include mq-down(md) {
    margin: 0 -15px;
  }

  .list {
    display: flex;
    justify-content: center;
    margin-top: -10px;

    .page-numbers {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border: 3px solid $color-green-01;
      font-size: 1.6rem;
      text-align: center;
      color: $white;
      background-color: $color-green-01;
      transition: all .2s;
      margin: 10px 3px 0;

      @include mq-down(md) {
        border: 2px solid $color-green-01;
        width: 37px;
        height: 37px;
        margin: 10px 2px 0;
      }

      &:hover, &.current {
        background-color: $white;
        border-color: $black;
        text-decoration: none;
        color: $black;
        font-weight: bold;
      }

      &.next,
      &.prev {
        position: relative;
        display: block;
        width: 30px;
        text-indent: -9999px;
        overflow: hidden;
        border: none;
        background: transparent;
        padding: 0;

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          width: 7px;
          height: 12px;
          transform: translate(-50%, -50%);
        }
      }

      &.next {
        &::before {
          background-image: url(/assets/images/common/ico_next.png);
          background-position: 0 0;
          background-repeat: no-repeat;
          background-size: 100% auto;
        }
      }

      &.prev {
        &::before {
          background-image: url(/assets/images/common/ico_prev.png);
          background-position: 0 0;
          background-repeat: no-repeat;
          background-size: 100% auto;
        }
      }
    }
  }
}

/* c-list-pagination
---------------------------------------------------------- */
.c-box-pagination {
  margin-top: 70px;
  padding: 56px 0;
  background-color: #e3f4f0;
  border-radius: $border-radius-pc;

  @include mq-down(md) {
    margin-top: 46px;
    padding: 32px 0;
    border-radius: 15px;
  }

  &.-type-01 {
    margin-top: 100px;
    border-radius: 30px;

    @include mq-down(md) {
      margin-top: 82px;
      border-radius: 15px;
    }
  }
}
